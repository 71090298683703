import React from "react";
// import "../App.css";
// import "../style.css";
// import 'bootstrap/dist/css/bootstrap.min.css';

function FirstBanner({title,description,image,DetailText}) {


    return (
        <>
            <div className='py-4'>
                <h1 className="mb-4" data-aos="zoom-in">{title}</h1>
                {/* <video data-aos="zoom-in" src="/images/sound-scribe.mp4" autoPlay="true" height="500" loop muted/> */}

                <iframe className="soundVideo"
                src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/53cc97b34f2c76adf50af12da7330794/iframe?muted=true&loop=true&autoplay=true&controls=false"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>

                {/* <figure data-aos="zoom-in"><img alt="header_image" src={image} className="img-fluid rounded-3"/></figure> */}
                <p data-aos="zoom-in">{description}</p>
                <div className="row">
                    {DetailText?.map((ele) => {
                        return (
                            <>
                            <div className="col-xl-6" data-aos="zoom-in">
                                <h4>{ele.subtitle}</h4>
                                <p>{ele.subdescription}</p>
                            </div>
                            </>
                        );
                    })}
                </div>
            </div>
        </>
  );
}
export default FirstBanner;

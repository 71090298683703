/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-unused-vars */
import React, { useDebugValue, useState } from "react";

import { API, getAwsImageUrl, getAwsVideoUrl } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { isValid, validateRequire } from "../Validation/InputValidation";
import { Modal, Spinner } from "react-bootstrap";
import TextToMusic from "./TextToMusic";
import axios from "axios";
import { SetUserData, SetpopupReducerData } from "../store/reducer";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-hot-toast";
import DubVideos from "./DubAIDubbing";
import DubImageEnhancer from "./DubImageEnhancer";
import ConvertImage from "./ConvertImage";
import { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import ConvertInteriorImage from "./ConvertInteriorImage";
import { BsXCircle } from "react-icons/bs";

import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import { SetCompletedNotifications, SetPendingNotifications } from "../store/reducer/extra";

function ImageEnhancerTest({ category = {}, setCategory }) {
  const [needhelp, setNeedHelp] = useState(false);
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  let secretKey = User?.data?.secret_key;
  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [texttomusic, setTextToMusic] = useState([]);
  const [convertedImage, setConvertedImage] = useState("");
  const [Imagelisting, setImageListing] = useState([]);

  const [FIRST_IMAGE, SET_FIRST_IMAGE] = useState({ imageUrl: "" });
  const [SECOND_IMAGE, SET_SECOND_IMAGE] = useState({ imageUrl: "" });

  const dispatch = useDispatch();

  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [preview, setPreview] = useState("");


  //   const [inputField, setInputField] = useState({
  //     category: category?._id,
  //     modelType: "",
  //   });

  // const [durationTouched, setDurationTouched] = useState(false);
  const navigate = useNavigate();

  //   const { positive_prompt = "", negative_prompt = "" } = inputField;

  //   // console.log(fileName, "fileName");

  //   const handleChange = (e) => {
  //     setInputField({
  //       ...inputField,
  //       [e.target.name]: e.target.value,
  //     });
  //     setApiErrors({ message: "" });
  //     setApiErrors({});
  //   };

  const validateAll = () => {
    let err1 = {};
    err1.category = validateRequire(category);

    // err1.modelType = validateRequire(modelType);
    //  err1.video_duration= Number(video_duration) > 60 ? "Duration cannot be greater than 30" : "";

    return err1;
  };

  const handleChangeFile = (e) => {
    var file = e.target.files[0];
    setPreview(URL.createObjectURL(file));
    setFile(file);
    setFileName(file.name);
    setErrors({ ...errors, file: "" });
  };

  // const getConvertedImage = async (params) => {
  //   // console.log('params', params)
  //   let response = await axios.get(
  //     `${process.env.REACT_APP_CONVERTED_IMAGE_ENHANCE}?id=${params}`
  //   );

  //   if (response?.data?.status === "processing") {
  //     getConvertedImage(params);

  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //     console.log("response?.data?.rep_url", response?.data?.rep_url);
  //     setConvertedImage(response?.data?.rep_url);
  //     SET_SECOND_IMAGE({ imageUrl: response?.data?.rep_url });
  //   }
  //   console.log("response", convertedImage);
  // };

  const handleLogin = (e) => {
    dispatch(
      SetpopupReducerData({
        modalType: "LOGIN",
        showModal: true,
      })
    );
  };

  const fetchCompleteList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.notification}/${userId}`,
        method: "GET",
      });
      if (response.status) {
        console.log(response.data.tasks);
        if (response.data.tasks.PENDING) {
          dispatch(SetPendingNotifications(response.data.tasks.PENDING));
        } else {
          dispatch(SetPendingNotifications([]));
        }
        dispatch(SetCompletedNotifications(response.data.tasks.COMPLETED));
      } 
    } catch (error) {
      throw error;
    }
  };

  const handleEnhance = async (e) => {
    e.preventDefault();
    SET_FIRST_IMAGE({ imageUrl: "" });
    SET_SECOND_IMAGE({ imageUrl: "" });
    if (!userId) {
      // navigate("/login");
      handleLogin();
      return;
    }
    try {
      let err = validateAll();
      if (isValid(err)) {
        let imageUrl = await getAwsImageUrl(file, `${userId}/InputImage/`);
        // console.log(imageUrl, "imageUrl");

        const payload = {
          category: category?._id,
          secret_key: secretKey,
          // userId: userId,
          // uploaded_video: "",
          // category: category?._id,
          // target_language: "",
          // converted_video: "",
          // video_conversion_status: "",
          // audio_conversion_status: "",
          // music_conversion_status: "",
          // video_filename: "",
          image_filepath: imageUrl,
        };

        console.log(payload);
        setIsLoading(true);

        await API({
          url: apiURl.imageEnhancerNew,
          method: "POST",
          body: { ...payload },
          formData: false,
        }).then((data) => {
          if (data?.status || data?.status === "true") {
            console.log("data------>imageEnhancer", data);
            if (data?.data?.response?.error) {
              toast.error(data?.data?.response?.error);
            } else {
              console.log(data?.data?.response?.rep_id, "rep_id");
              console.log(data.data);
              SET_FIRST_IMAGE({
                imageUrl: data?.data?.savedRecord?.image_filepath,
              });

              fetchCompleteList();
              dispatch(
                SetpopupReducerData({
                  modalType: "PENDINGTASK",
                  showModal: true,
                  category: `${category.name}`
                })
              );
              setPreview("");
              setFileName("");
            }
            dispatch(SetUserData(data?.data?.updatedUserInfo));
            setImageListing((prevlist) => [...prevlist, payload]);
          } else {
            setApiErrors({ message: data?.message });
            setErrors({});
          }
        });
      } else {
        setErrors(err);
      }
    } catch (error) {
      setApiErrors({ message: error?.message });
    } finally {
      setIsLoading(false);
    }
  };

  function handlePreview() {
    setFile("");
    setFileName("");
    setPreview("");
  }

  function closeImageSliderComparison() {
    SET_FIRST_IMAGE({ imageUrl: "" });
    SET_SECOND_IMAGE({ imageUrl: "" });
    setFile("");
    setFileName("");
    setPreview("");
  }

  console.log(FIRST_IMAGE);
  console.log(SECOND_IMAGE);

  return (
    <>
      {needhelp ? (
        <DubImageEnhancer setNeedHelp={setNeedHelp} />
      ) : (
        <>
          <h2 className="text-center text-white mb-4">Image Enhancer</h2>
          <div className="refernceAudio">
            {/* <img src="/images/coming-soon.svg"></img> */}
            <form id="contact" method="post" className="form" role="form">
              <div className="row">
                <div className="col-lg-12 form-group pb-3">
                  <div className="d-flex justify-content-between">
                    <label htmlFor="audio_text" className="form-label">
                      Upload Image <span className="text-danger"> *</span>
                    </label>
                    <button
                      onClick={() => setNeedHelp(true)}
                      className="needHelp"
                    >
                      Need Help?
                    </button>
                  </div>

                  <div class="customUpload ">
                    {SECOND_IMAGE.imageUrl ? (
                      <div>
                        <div className="row">
                          <div className="col-12 position-relative">
                            <BsXCircle
                              onClick={closeImageSliderComparison}
                              className="preview-icon"
                            />
                            <ReactBeforeSliderComponent
                              firstImage={SECOND_IMAGE}
                              secondImage={FIRST_IMAGE}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {preview ? (
                          <div className="position-relative">
                            <BsXCircle
                              title="Delete"
                              onClick={handlePreview}
                              className="preview-icon"
                            />
                            <img width="100%" height="500px" src={preview} />
                          </div>
                        ) : (
                          <>
                            <input
                              className="form-control file-border"
                              type="file"
                              id="formFile"
                              accept="image/*"
                              onChange={handleChangeFile}
                            />
                            <span class="upload-btn" id="upload-btn">
                              {fileName}
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  {errors.file ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.file}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-md-12 form-group synBtn text-center">
                  {isLoading ? (
                    <button className="btn btn-primary mt-4">
                      <Spinner animation="border" size="lg" /> Processing...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary mt-4 bt-image-enhancer"
                      onClick={handleEnhance}
                      disabled={isLoading}
                    >
                      {/* <img src="/images/soundwave.png" alt="" />  */}
                      Enhance
                    </button>
                  )}

                  {/* <button className="" type="">
            <img src="/images/soundwave.png" /> synthesize
          </button> */}
                </div>
              </div>
            </form>
          </div>
          <div className="text-center">
            <Link
              to=""
              className="seeTitle"
              onClick={() => setCategory({ _id: "", name: "dashboard" })}
            >
              See All Images in Dashboard
            </Link>
          </div>
          {/* {convertedImage && (
            <ConvertInteriorImage
              inputImage={Imagelisting}
              outputImage={convertedImage}
            />
          )} */}
        </>
      )}
      
    </>
  );
}

export default ImageEnhancerTest;

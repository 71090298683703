import React from "react";
// import { Link } from "react-router-dom";
import moment from "moment";
import ProfileCard from "./ProfileCard";

function TextToMusic({ listingData = [] }) {
  console.log(listingData, "listingData");
  return (
    <div className="row">

      {listingData.length > 0
        ? listingData.map((item) => {

          return (
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
              <ProfileCard
                item={item}
                outputAudio={item?.converted_music}
              // createdAt={moment(item.createdAt).format("MM/DD/YYYY")}
              // converted_video1={item?.converted_video || item?.converted_music}
              // converted_video={item?.converted_video || item?.converted_music}
              />
            </div>
          )


        })
        : ""}
    </div>
  );
}

export default TextToMusic;

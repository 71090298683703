import React from 'react'

function StepInteriorDesign() {
  return (
    <>
        <div className='refernceAudio mb-5 text-center'>
        <iframe
                    className="textToVideo"
                    src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/0d1ddf766dc75b1c8cc5fa665ed57cc1/iframe?muted=true&loop=true&autoplay=true&controls=false"
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowfullscreen="true"
                  ></iframe>
            {/* <video controls>
                <source src="/images/soundscribe.mp4" type="video/mp4" />
                <source src="rabbit320.webm" type="video/webm" />
            </video> */}
            <div className='watchTitle'>
            Watch the video
                <span>or</span>
            Follow the instruction
            </div>
        </div>
    </>
  )
}

export default StepInteriorDesign
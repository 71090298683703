/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  BsStar,
  BsClock,
  BsCollection,
  BsSoundwave,
  BsHeadset,
  BsTrash,
  BsFillChatLeftTextFill,
  BsFileEarmarkTextFill,
  BsPersonPlus,
  BsList,
  BsX,BsPencilSquare
} from "react-icons/bs";
import { BiCopy } from "react-icons/bi";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import WebSocket from "ws";
import { apiURl } from "../store/actions";
import { API } from "../apiwrapper";
import moment from "moment";
// import VideoListings from "./VideoListings";
import SideBar from "./SideBar";
import ProfileCard from "./ProfileCard";
import VideoListings from "./VideoListings";
import VoiceChange from "./VoiceChange";
import TextToAudioListing from "./TextToAudioListing";
import TextToMusic from "./TextToMusic";
import ConvertTextToAudio from "./ConvertTextToAudio";
import ConvertTextToMusic from "./ConvertTextToMusic";
import ShopInVideo from "./ShopInVideo";
import ConvertVideoToText from "./ConvertVideoToText";
import VideoToTextListing from "./VideoToTextListing";
import ConvertImageToVideo from "./ConvertImageToVideo";
import ImageToVideoListing from "./ImageToVideoListing";
import SeoScribe from "./SeoScribe";
// import WebSocket from "websocket";

import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Package from "./Package";
import NewChat from "./NewChat";
import MYChat from "./MYChat";
import ChatHistory from "./ChatHistory";
import Extractdocument from "./Extractdocument";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASECONFIG } from "../config";
import { SetUserData } from "../store/reducer";
import ConvertTextToImage from "./ConvertTextToImage";
import ConvertTextToVideo from "./ConvertTextToVideo";
import InteriorDesign from "./InteriorDesign";
import ImageToStory from "./ImageToStory";
import Header from "../homeComponents/Header";
import ImageEnhancer from "./ImageEnhancer";
import { useRef } from "react";
import Introduction from "./Introduction";
import Apikey from "./Apikey";
import { Transaction } from "@sanity/client";
import TransactionHistory from "./TransactionHistory";
import ApiTable from "./ApiTable";
import CreateSecretkey from "../PopupModal/CreateSecretkey";


function Accounts() {

  

  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  
  let { catg, buyNow } = useParams();
  const [userVideoList, setUserVideoList] = useState([]);
  const [userTextToAudioList, setUserTextToAudioList] = useState([]);
  const [userTextToMusicList, setUserTextToMusicListList] = useState([]);
  const [userVideoToTextList, setUserVideoToTextList] = useState([]);
  const [userImageToVideoList, setUserImageToVideoList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [getTrashData, setTrashData] = useState([]);
  const [getFavouriteData, setFavouriteData] = useState([]);
  const [RecentViewData, setRecentViewedData] = useState([]);
  const [TextToImageData, setTextToImageData] = useState([]);
  const [TextToVideoData, setTextToVideoData] = useState([]);
  const [InteriorDesigner, setInteriorDesigner] = useState([]);
  const [ImageStory, setImageToStory] = useState([]);
  const [imageEnhancer, setImageEnhancer] = useState([]);
  const [GlobalVoiceAi, setGlobalVoiceAi] = useState([]);
  const [seoScribeData, setSeoScribeData] = useState([]);
  const [chathistory, SetchatHistroy] = useState();
  const [allCategory, setAllCategory] = useState([]);
  const [mainTools, setMainTools] = useState([]);
  const [labTools, setLabTools] = useState([]);
  const [category, setCategory] = useState({
    _id: "",
    name: "dashboard",
  });
  console.log("catg", buyNow, catg, category);

  const pythonCode = `function Square({ value, onSquareClick }) {
      return (
        <button className="square" onClick={onSquareClick}>
          {value}
        </button>
      );
  }`

const [showHide, setShowHide]=useState(true)

  console.log(chathistory);

  const [showCSKey, setshowCSKey]=useState(false);

  const [showLeftMenu, setShowLeftMenu] = useState(true);
  const [active, setActive] = useState(1);
  const [searchParams, setsearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = searchParams.get("id");
  const token = searchParams.get("token");
  const session = searchParams.get("session");
  const type = searchParams.get("type");
  const handleClick = (ele) => {
    console.log('ele', ele)
    setActive(ele);
  };
  const fetchVideoList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.allcontent}/${userId}`,
        method: "GET",
      });
      console.log("response----", response);
      if (response.status) {
        console.log(response?.data?.allData);

        setUserVideoList(response?.data?.AI_Dubbing);
        setUserTextToAudioList(response?.data?.text_to_music);
        setGlobalVoiceAi(response?.data?.text_to_speech);
        setUserVideoToTextList(response?.data?.Video_To_Text);
        setUserImageToVideoList(response?.data?.image_to_video);
        setTrashData(response?.data?.allTrashData);
        setTextToImageData(response?.data?.textToImage);
        setTextToVideoData(response?.data?.textToVideo);
        setInteriorDesigner(response?.data?.interiorDesigner);
        setImageToStory(response?.data?.image_to_story);
        setImageEnhancer(response?.data?.ImageEnhancer);
        setSeoScribeData(response?.data?.SeoScribe);
        setAllData([
          ...response?.data?.allData,
          // ...response?.data?.AI_Dubbing,
          // ...response?.data?.SoundScribe,
          // ...response?.data?.GlobalVoiceAi,
          // ...response?.data?.Video_To_Text,
        ]);
      } else {
        setUserVideoList([]);
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchAllCategory = async () => {
    try {
      await API({
        url: `${apiURl.allCategory}`,
        method: "GET",
      }).then((data) => {
        if (data?.status) {
          console.log(data, "data");
          let allCat = data?.data || [];
          setAllCategory(allCat.all_category);
          console.log(allCat);
          setMainTools(allCat.mainTools);
          setLabTools(allCat.labTools);
          //  setCategory({ _id: allCat?.[0]?._id, name: allCat?.[0]?.name });
        } else {
          setMainTools([]);
          setLabTools([]);
          setAllCategory([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const fetchFavouriteData = async () => {
    try {
      let response = await API({
        url: `${apiURl.favourite}/${userId}`,
        method: "GET",
      });

      if (response) {
        setFavouriteData(response?.data);
      } else {
        setFavouriteData([]);
      }
      console.log("favouraite", getFavouriteData);
    } catch (error) {
      //.log(error)
    }
  };

  const RecentViewedData = async () => {
    try {
      let response = await API({
        url: `${apiURl.recentViewed}/${userId}`,
        method: "GET",
      });

      if (response.status) {
        setRecentViewedData(response?.data);
      } else {
        setFavouriteData([]);
      }
    } catch (error) {
      //.log(error)
    }
  };
 

  useEffect(() => {
    if (category?.name === "dashboard") {
      fetchVideoList();
    }
  }, [category]);
  useEffect(() => {
    let index = allCategory.findIndex((ele) => ele.name === catg);
    console.log(catg, "index", index, allCategory);
    if (catg) {
      setCategory({
        _id: index >= 0 ? allCategory[index]?._id : "",
        name: catg,
      });
    }
    if (buyNow) {
      setActive(5);
    } else {
      setActive(1);
    }
  }, [allCategory, catg, buyNow]);

  useEffect(() => {
    fetchAllCategory();
    fetchFavouriteData();
    RecentViewedData();
  }, []);

  useEffect(() => {
    console.log("Effect running:", { token, session, type });
    if (token && session && type) {
      console.log("ankit");
      let payload = {
        type: type,
        token: token,
        session: session,
        id: userId,
      };

      // setsearchParams(payload);
      // console.log("payload111", payload);

      // let url = `${window.location.origin}/profile/${userId}`
      // console.log('url', url)
      // console.log('url', url)

      API({
        url: apiURl.paymentVerify,
        method: "POST",
        body: { ...payload },
      }).then((data) => {
        console.log("data?.data", data);
        if (data?.status || data?.status === "true") {
          toast.success("Payment Succsessfull");
          navigate("/");
          dispatch(SetUserData(data?.data));
        } else {
          console.log("toast");
          toast.error(data?.message);
        }
      });
    }
  }, [token, session, type]);

  console.log(category);

  return (

    <section className={`profileMain ${showLeftMenu ? "show" : ""}`}>
      {showCSKey ? <CreateSecretkey show={showCSKey} HandleClose={()=>setshowCSKey(false)}/>
      :""}
      <Header tab="accounts" />
      <div className="menuSectionLeft">
        <button className="toggleBtn" onClick={() => setShowLeftMenu(!showLeftMenu)}>
          <BsX className="cross" /> <BsList className="humber" />
        </button>
        <SideBar accountTools={mainTools} tab="accounts" />
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="py-5">
            <div className="d-flex justify-content-between align-items-center createNew"> 
              <h2 class="text-center text-white mb-4">API keys</h2>
              <button onClick={()=> setshowCSKey(true)}>Create New +</button>
            </div>
            <div className="cnt">
              <p>Your secret API keys are listed below. Please note that we do not display your secret API keys again after you generate them.</p>
              <p>Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, OpenAI may also automatically disable any API key that we've found has leaked publicly.</p>
            </div>
            <div className="table-responsive my-5 apiTable">
              <ApiTable/>
            </div>
            <div className="cnt">
              <h4>Default organization</h4>
              <p>If you belong to multiple organizations, this setting controls which organization is used by default when making requests with the API keys above.</p>
            </div>
          </div>
        </div>
        <button className="imageEn" onClick={()=> setShowHide(!showHide)}>Image enhancer</button>
      </div>
          
          {!showHide && (
          <div className="container">
            <h2 class="text-left text-white mb-4">Image enhancer</h2>
            <div className="stepIns">
              <h3>1. Structions</h3>
              <p>Your secret API keys are listed below. Please note that we do not display your secret API keys again after you generate them.</p>
              <p>Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, OpenAI may also automatically disable any API key that we've found has leaked publicly.</p>
            </div>
            <div className="apiSect mt-lg-5 mt-4">
              <h5>API</h5>
              <div className="copyCode d-flex justify-content-between align-items-center"><p>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</p> <button><BiCopy/></button></div>
            </div>
            <div className="apiSect mt-lg-5 mt-4">
              <h5>Response</h5>
              <div className="copyCode d-flex justify-content-between align-items-end">
              <pre className="py-4">
                {pythonCode}
              </pre>
              <button><BiCopy/></button>
              </div>
            </div>
            <div className="apiSect mt-lg-5 mt-4">
              <h5>Path Params</h5>
              <div className="copyCode w-100 py-4">
                <h6>versionID <span>string</span></h6>
                <p>Voiceflow project version ID. You can also use 'development' to identify the version on canvas or 'production' to identify the published version. However if using an alias this must be used in conjuction with the projectid header.</p>
              </div>
            </div>
            <div className="apiSect mt-lg-5 mt-4">
              <h5>Query Params</h5>
              <div className="copyCode w-100 py-4">
                <h6>Prototype <span>boolean</span></h6>
                <p>Voiceflow project version ID. You can also use 'development' to identify the version on canvas or 'production' to identify the published version. However if using an alias this must be used in conjuction with the projectid header.</p>
              </div>
            </div>
          </div>
          )}
    </section>
  );
}

export default Accounts;

import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../homeComponents/Header'

function TermsConditions() {
  return (
    <>
    <Header/>
    <section className='terms'>
        <div className='container'>
            <div className='row'>
                <div className="col-md-12">
                    <h1>Terms and Conditions</h1>
                    <h3>Introduction</h3>
                    <p>This is a set of terms and conditions that govern the agreement between you, a user of Exuber, and us, doing business as Metaverse Blockchain Solutions Private Limited, a company incorporated in India. Exuber is a distinctive platform that allows fans to interact with their favourite celebrities through meetings, customised content, and gaming.</p>
                    <p>We are delighted to offer you access to our Products and Services, which consist of the Exuber mobile application, website, and interactive features such as gaming, celebrity interactions, coupons, and other associated products and services. The primary function of Exuber is to provide user with opportunities to engage in activities such as playing games, earning points, and enjoying perks and benefits.</p>
                    <p>By installing, accessing, or using Exuber or any of its features, you acknowledge that you have read and agreed to these terms and conditions, as well as the Exuber Privacy Policy and other relevant terms available on the Exuber App, Exuber Website, or otherwise. It is essential to review these documents carefully before using Exuber because your use of the platform indicates your acceptance of these agreements, and you are legally bound by them. If you do not agree to these Terms and Conditions or other applicable agreements, please refrain from accessing or using Exuber or any of its features.</p>

                    <div className='priBox'>
                      <h3>Account and Registration</h3>
                      <p>User Account Creation: User must create an account on the gaming platform to access and utilise the features, including ability to redeem vouchers, coupons, or collectibles.</p>
                      <p>Eligibility: User must be at least 18 years old to create an account.</p>
                    </div>
                    <p>Account Responsibility: User is solely responsible for maintaining the confidentiality and security of their account login credentials (username, password, etc.) and that they are solely accountable for any activity conducted through their account. User is advised to create a strong password that contains minimum 8 characters including capital letters, numerals and special characters which cannot be easily cracked. Exuber shall not be responsible for any unauthorised login of the user's account. These restrictions defined for creating a strong password will be applicable only for passwords created on Exuber and won't apply to passwords created on social platforms (including but not limited to Facebook and Google) and their subsequent usage for login on Exuber.</p>

                    <p>Accurate Information: The user must provide accurate and up to date information during the registration process, including personal details and contact information.</p>
                    <p>Account Termination: Exuber reserves the right to terminate or suspend user accounts for violations of the terms and conditions, fraudulent activity, or any other reasons deemed necessary by the platform. If user violate any of these terms and conditions, engage in activities that could harm Exuber Products and Services or infringe upon third-party rights, or breach any laws or regulations, we may, at our discretion, disable your account and remove or disable any information you have shared </p>
                    <p>Disable Account: User shall have the right to disable the account at any time by enabling the "Disable Account" toggle in the settings. By doing so, User account will be disabled and you will no longer have access to the Services. User can recover the account by sending a mail to our customer support on support@exuber.ai with your registered email address and mobile number.</p>
                    <p>Data Privacy: the collection, storage, and usage of personal data during account registration process, in accordance with applicable data protection laws. User can access the privacy policy of Exuber on its official website.</p>
                    <p>User agree that: User have the right, authority and legal capacity to agree to these Terms and Conditions, Privacy Policy on behalf of yourself.</p>

                    <div className='priBox'>
                      <h3>Technical Failures</h3>
                      <p>Disclaimer of Liability: Exuber shall not be liable for any technical failures, interruptions, or disruptions that may occur during the use of the platform. These issues include server downtime, network outages, software glitches, or other technical difficulties.</p>
                      <p>Service Interruptions- The platform may experience periodic maintenance, updates, or upgrades that could result in temporary unavailability of the services. Exuber will make reasonable efforts to minimise disruptions and provide advance notice whenever possible.</p>
                      <p>Data Loss: Exuber shall not be responsible for any loss of user data, progress or virtual items resulting from technical failures. User is advised to regularly back up their data and take necessary precautions to safeguard their account information.</p>
                      <p>Reporting of Technical Issues: User is advised to report any technical issues they encounter while using the platform at support@Exuber.com.</p>
                     
                    </div>
                    <div className='priBox'>
                      <h3>WARRANTY AND LIMITATIONS ON LIABILITY</h3>
                      <p>Warranty: The platform is provided “as is” without any warranties or guarantees of any kind, either expressed or implied. The platform does not warrant the accuracy, completeness, or reliability of the content, functionality, or services provided.</p>
                      <p>User acceptance: User acknowledges and accepts that there is no warranty or guarantee regarding the performance, availability, or suitability of the platform for their specific needs.</p>  
                      <p>User responsibility: User shall be responsible for their own actions and decisions while using the platform. Exuber does not guarantee the success, results or outcomes of any user’s gaming activities</p>
                      <p>Exclusion of Liability: Exuber, its owners, operators and affiliates will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use or inability to use the platform. This includes damages for loss of profits, data, or goodwill.</p>
                      <p>Indemnification: User agree to indemnify and hold Exuber, its owners, operators and affiliates harmless from any claims, liabilities, damages, expenses or costs that may arise from their use of the platform or any violation of the terms and conditions.</p>
                      <p>Third Party Content and Services: Platform may include links, advertisement, or access to third party content or services. Exuber is not responsible for the availability, accuracy, or quality of these third-party offerings, and that any interactions or transactions with the third parties are solely between the user and the third party.</p>
                    </div>
                    <div className='priBox'>
                      <h3>PLATFORM USAGE</h3>
                      <p>License: Exuber grants user a limited, non-exclusive, non transferable license to use the software provided by Exuber solely for the purpose of playing games for personal entertainment.</p>
                      <p>Restriction on Use: User must use Exuber account only for playing games, watch content and buy Digital Collectibles, Shopping Vouchers on the Website/Mobile App and must not attempt to manipulate, decode, modify, disassemble, decompile, reverse or re-engineer Exuber software in any manner. Use of bots or automatic play is prohibited. Any attempt to use artificial intelligence that includes, but is not limited to, robots, in connection with our games and software is strictly forbidden. All the actions that user perform on or in connection with Exuber Website/Mobile App must be executed by you only through the user interface that is accessible on our Website/Mobile App.</p>
                      <p>Intellectual Property Rights: All the intellectual property rights, including copyrights and trademarks, related to the software belong to Exuber or its licensors. User may not claim any ownership or rights to the software.</p>
                      <p>Upgrades: Exuber may release updates, upgrades, or new versions of the software from time to time.</p>
                      <p>Software Compatibility and System Requirements: It is advisable to the user that their devices shall meet the minimum system requirements and compatibility specifications for using the software. Exuber is not responsible for any performance issues or limitations due to users’ hardware or software configurations.</p>
                      <p>Software Malfunctions and Support: Exuber strives to provide stable and reliable software, occasional malfunctions or errors may occur. Exuber will make reasonable efforts to address such issues and provide support, but not guarantee uninterrupted or error-free software operation.</p>
                      <p>Device Storage Permission: By using Exuber Products and Services, user agree to automatically download and install updates for as long as you user use them.</p>
                      <p>Third Party Software Services: The platform may integrate or utilise third party software or services. Exuber shall not be responsible for the availability, functionality, or quality of the third-party offerings, and that any use of such software or services is subject to their terms and conditions.</p>
                      <p>Fair Play Policy: Exuber takes the fair play and earn on Exuber very seriously and we make sure that every player gets an equal opportunity to avail Vouchers, Coupons and Collectibles on our Website/Mobile App. To ensure a safe, legal, and fair environment for all our users, we actively monitor all user actions, including deposits, identity verification, games, watching and earning, as well as individual hands, in order to prevent any form of malpractice or unfair play on our Website/Mobile App.</p>
                      <p>Every single game on our Website/Mobile App must be a fair individual effort, not a team effort. As part of our Fair Play Policy and to prevent any form of malpractices and in-game collusion, all user actions are monitored and recorded.</p>
                      <p>Any detection of a breach of our Fair Play Policy, including unauthorised deposits using stolen identities or collusion in gameplay, will result in prompt and decisive action by Exuber against the offending user. Such actions may include, but are not limited to, permanent termination of the user's account, as outlined in the "Breach and Consequences" section below.</p>
                    </div>
                    <div className='priBox'>
                      <h3>NO BRIBES, GIFTS OR FAVORS</h3>
                      <p>User shall not offer any Exuber employee any bribe, gifts, services, compensation, or any other favour that might influence or might appear to influence the employee in performing his/her duties at Exuber. Exuber employees shall not solicit any bribe, gifts, or services from you. Offering Exuber employees any cash, cash equivalents, gifts, gift cards, services, or favors is strictly prohibited.</p>
                    </div>
                    <div className='priBox'>
                      <h3>BREACH AND CONSEQUENCES</h3>
                      <p>Breach of Terms: If user breach of Exuber terms of service or use our website/Mobile App in a fraudulent manner or for illegal and/ or unlawful purposes or cheat Exuber and/ or any user or cause wrongful communication implicating Exuber including false or incorrect chargeback claim or claims and/or that user continued access to our Website/Mobile App is detrimental to the interests of Exuber or our users or the general public, (“disallowed activities”) then Exuber reserve the right to take one or all of the actions below at our own discretion and without prior notice, each of which will be without prejudice to each other :</p>
                      <p>Consequences of breach: Temporarily or permanent suspension of user's account or access to certain features. Loss of virtual currency, items, or progress within the game/platform.Removal of offensive or inappropriate content created by the user. Legal Action if the breach involves unlawful activities.</p>
                    </div>
                    <div className='priBox'>
                      <h3>CHANGES IN THE TERMS</h3>
                      <p>Terms may be modified or updated by Exuber, and user will be notified of any such changes as appropriate. The date of the last modification will be indicated at the top of these Terms. By continuing to use Exuber after any modifications or updates to these Terms, user acknowledge and agree to be bound by them. If user do not agree to these modified or updated Terms, user must discontinue using Exuber. It is Users’ responsibility to review these Terms periodically, and any changes will become effective immediately upon being posted on this page.</p>
                    </div>
                    <div className='priBox'>
                      <h3>INDEMNITY</h3>
                      <p>User acknowledge and agree to hold us, our parent(s), subsidiaries, and affiliates, and each of their respective officers, directors, employees, agents and advisors harmless from any and all claims, liabilities, costs, and expenses, including attorneys’ fees and expenses, that may arise from any breach of these Terms or the Privacy Policy by you or any other user of your account. You further agree to defend us and our affiliates against any such claims, liabilities, costs, and expenses.</p>
                    </div>
                    <div className='priBox'>
                      <h3>CANCEL, RETURN, EXCHANGE & REFUND POLICY</h3>
                      <p>Exuber policy regarding the purchase of digital assets/physical assets is non-refundable and non-exchangeable once the purchase has been completed. This policy is a part of Exuber standard terms and conditions, which Exuber encourages all users to review prior to making a purchase.</p>
                      <p>Exuber takes great pride in the quality and authenticity of its digital assets and are committed to ensuring that its customers are satisfied with their purchases. However, in the unlikely event that user experience any issues with a Fiat payment that has not resulted in a purchase, please contact at support@Exuber.com and also reach out to the concerned bank for assistance.</p>
                    </div>
                    <div className='priBox'>
                      <h3>LIMITATION OF LIABILITY</h3>
                      <p>Exuber shall not be liable to you for any loss or damages or claims including but not limited to the following:</p>
                      <ul className='listCnt'>
                        <li>1 Any loss of profit, opportunity or goodwill;</li>
                        <li>2 Any loss of data;</li>
                        <li>3 Any damage incurred as a result of your reliance on any advertisement appearing on the Service;</li>
                        <li>4 Any damage incurred as a result of any changes to the Service, its features and any temporary or permanent termination of our Products and Services;</li>
                        <li>5 Any damage incurred as a result of your failure to provide us with accurate information and your failure to keep your account details and password confidential and secure;</li>
                        <li>6 Any damage incurred as a result of the actions of another user.
                            Any dispute that may arise between you and any third party arising from your use of the Products and Services shall be only between user and a third party and you release us and our affiliates from any such claims and damages connected with such disputes.</li>
                      </ul>
                    </div>
                    <div className='priBox'>
                      <h3>GOVERNING LAW</h3>
                      <p>The validity, construction and enforceability of these Terms and Conditions, Privacy Policy shall be governed and construed in accordance with the laws of India.</p>
                    </div>
                    <div className='priBox'>
                      <h3>DISPUTE RESOLUTION</h3>
                      <p>User agree that any disputes arising from or related to these Legal Terms will be submitted to the jurisdiction of the courts in Gurugram, India. Metaverse Blockchain Solutions Private Limited reserves the right to bring proceedings in the courts of the country where user reside, if these Legal Terms were entered into as part of users’ trade or profession, or in the state of your principal place of business. The submission to jurisdiction clause is irrevocable, and user consent to submit to the exclusive jurisdiction of the courts specified in this provision.</p>
                    </div>
                    <div className='priBox'>
                      <h3>SEVERABILITY</h3>
                      <p>If any part of the terms and conditions are found to be invalid, unenforceable, or prohibited by law, that part will be removed from the terms and conditions and will not affect the validity or enforceability of the remaining terms and conditions. The rest of the terms and conditions will remain in effect and continue to be binding on both parties.</p>
                    </div>
                    <div className='priBox'>
                      <h3>GRIEVANCE REDRESSAL</h3>
                      <p>If user wish to complain about our Products and Services / the use of our Products and Services by others, please contact our Resident Grievance Officer, whose details are below:</p>
                      <p>
                        Name: Yogesh Malik<br/>
                        Designation: AVP - Compliance & Operations<br/>
                        Email ID: support@exuber.ai 
                      </p>
                      <p>We will endeavour to redress the complaint within one month from the date of receipt of the complaint.</p>
                      <p>Contact Us<br/>
                        If you have any questions or suggestions, do not hesitate to contact us at 
                        support@exuber.ai
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
    </>
  )
}

export default TermsConditions
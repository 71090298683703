import React from 'react'

function ChatbotAi() {
  return (
    <>
        <h1 data-aos="zoom-in">Exuber's Chatbot AI</h1>
        <p data-aos="zoom-in">Revolutionizing communication with intelligent, adaptive AI, capable of answering questions from an integrated knowledge base and the boundless world of the internet.</p>
        <div className="row gx-xl-5 langCity">
            <div className="col-sm-12 col-md-6 col-lg-4" data-aos="fade-up">
                <figure>
                    <img src="./images/integration.jpg" className="img-fluid" />
                    <figcaption>Unified Knowledge Base</figcaption>
                    <p className="mt-2">Easily create your organization and upload crucial documents. Let the AI chatbot answer questions directly from your files.</p>
                </figure>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4" data-aos="fade-up">
                <figure>
                    <img src="./images/knowledge-base.jpg" className="img-fluid" />
                    <figcaption>Advanced AI Integration</figcaption>
                    <p className="mt-2">Access the power of ChatGPT 3.5 and 4, pioneering the future of conversational AI.</p>
                </figure>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4" data-aos="fade-up">
                <figure>
                    <img src="./images/global-connectivity.jpg" className="img-fluid" />
                    <figcaption>Global Connectivity</figcaption>
                    <p className="mt-2">With internet access, the chatbot is always updated, ensuring accurate and timely responses.</p>
                </figure>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4" data-aos="fade-up">
                <figure>
                    <img src="./images/customizable-solutions.jpg" className="img-fluid" />
                    <figcaption>Customizable Solutions</figcaption>
                    <p className="mt-2">Tailor the chatbot to match your specific needs, whether for customer service, employee training, or beyond.</p>
                </figure>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4" data-aos="fade-up">
                <figure>
                    <img src="./images/seamless-integration.jpg" className="img-fluid" />
                    <figcaption>Seamless Integration</figcaption>
                    <p className="mt-2">Effortlessly blend with existing systems for a smooth user experience.</p>
                </figure>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4" data-aos="fade-up">
                <figure>
                    <img src="./images/knowledge-base.jpg" className="img-fluid" />
                    <figcaption>24/7 Accessibility</figcaption>
                    <p className="mt-2">Always available, always responsive. Your automated AI assistant that never sleeps.</p>
                </figure>
            </div>
        </div>
    </>
  )
}

export default ChatbotAi
import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { SetpopupReducerData } from '../store/reducer';

function CompleteTask() {
  const { PopupReducer } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      SetpopupReducerData({
        modalType: "",
        showModal: false,
      })
    );
  };
console.log('ccccctgory', PopupReducer.modal.category)
  const Category = PopupReducer.modal.category?.replace(/_/g, " ")
  console.log("popopopcompleted", Category, PopupReducer.modal.showModal, PopupReducer.modal.category)

  return (
    <>
      <Modal className="shareSocial" show={PopupReducer.modal.showModal} onHide={() => handleClose()}>
        <Modal.Header>
          <Modal.Title>Completed Task</Modal.Title>
          <button className="bgNone" onClick={() => handleClose()}>
            <img src="/images/closeBtn.png" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="pendingTask">
            <figure><img src="/images/completeTask.svg" alt="" /></figure>
            <p>Your {Category} request has been completed now you can check the result on dashboard or on your email.</p>
          </div>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default CompleteTask
import React from "react";
import { useState } from "react";
import {
  isValid,
  validateEmail,
  validateName,
} from "../Validation/InputValidation.js";
import { toast } from "react-toastify";
import axios from "axios";

function NewsletterForm() {
  const [newsletterInputs, setNewsletterInputs] = useState({
    name: "",
    email: "",
  });
  const [errors, setErrors] = useState({});

  const [apiErrors, setApiErrors] = useState({ message: "" });

  const { name = " ", email = " " } = newsletterInputs;

  const handlechange = (e) => {
    setNewsletterInputs({
      ...newsletterInputs,
      [e.target.name]: e.target.value,
    });
    handleValidate(e);
    setApiErrors({ message: "" });
  };
  const handleValidate = (e) => {
    const errors1 = {};
    switch (e.target.name) {
      case "name":
        errors1.name = validateName(e.target.value);
        break;
      case "email":
        errors1.email = validateEmail(e.target.value);
        break;
      default:
        break;
    }
    setErrors(errors1);
  };
  const validateAll = () => {
    let err1 = {};
    err1.name = validateName(name);
    err1.email = validateEmail(email);
    return err1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let err = validateAll();
      if (isValid(err)) {
        const apiURL = `${process.env.REACT_APP_API_BACKEND_URL}/v1/newsletter
          `;

        const reqObj = newsletterInputs;
        const response = await axios.post(apiURL, reqObj);

        if (response) {
          toast.success(
            "Thanks for subscribing our newsletter."
          );
          setNewsletterInputs({ name: "", email: ""});
        }
      }
    } catch (error) {
      setApiErrors({ message: error.message });
      toast.error(error.message);

      console.error("Error:", error);
    }
  };

  return (
    <form className="newsForm">
      <div className="row g-3">
        <div className="col-md-5">
          <input
            type="text"
            placeholder="Your Name"
            name="name"
            value={name}
            onChange={(e) => handlechange(e)}
          />
        </div>
        <div className="col-md-5">
          <input
            type="email"
            value={email}
            placeholder="Email Address"
            name="email"
            onChange={(e) => handlechange(e)}
          />
        </div>
        <div className="col-md-2">
          <button onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </form>
  );
}

export default NewsletterForm;

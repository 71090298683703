const apiURl = {
  countryCode: "/v1/countryCode",
  users: "/v1/users",
  languages: "/v1/languages",
  videoList: "/v1/videodata",
  moveToTrash: "/v1/move-to-trash",
  getTrashData: "/v1/get-trash-data",
  callComponantData: "/v1/product-detail",
  allCategory: "/v1/category",
  videoUpload: "/v1/videodata",
  videoUploadNew: "/v1/ai-dubbing-new",
  favourite: "/v1/favourite",
  // textToAudio: "/v1/text-to-audio",
  textToAudio: "/v1/new-text-to-speech",
  textToAudioNew: "/v1/text-to-speech-new",
  faceSearchNew: "/v1/face-search-new", //face-search",                   // http://localhost:3001/v1/face/search/aws   //face-search-new",
  changePassword: "/v1/password/change",
  textToMusic: "/v1/text-to-music",
  textToMusicNew: "/v1/text-to-music-new",
  register: "/v1/register",
  validateOTP: "/v1/verify/otp",
  allcontent: "/v1/allcontent",
  allcontentNew: "/v1/get-all-data",
  videoToText: "/v1/video-to-text",
  imageToVideo: "/v1/image-to-video",
  recentViewed: "/v1/get-all-data",
  deleteParmanent: "/v1/delete",
  samplevoice: "/v1/SampleVoices",
  payment: "/v1/payment",
  paymentVerify: "/v1/payment/verify",
  transaction: "/v1/transaction",
  resetPassword: "/v1/reset/password/otp",
  plan: "/v1/plan",
  // textToImage:"/v1/text-to-image",
  forgotPassword: "/v1/forgot/password/otp",
  textToImage: "/v1/ntext-to-image",
  textToImageNew: "/v1/text-to-image-new",
  textToVideo: "/v1/text-to-video",
  textToVideoNew: "/v1/text-to-video-new",
  interiordesign: "/V1/interiordesign",
  interiordesignNew: "/v1/interior-design-new",
  imagetostory: "/v1/imagetostory",
  imagetostoryNew: "/v1/image-to-story",
  languagesStory: "/v1/getCombinedData",
  imageEnhancer: "/v1/image/enhancer",
  imageEnhancerNew: "/v1/image-enhancer-new",
  imageEnhancerTest: "/v1/image/enhancer/RMQ",
  ImageToStoryVoice: "/v1/story-to-voice-new", //story/to/voice",
  newapi: "/v1/story/to/voice",
  generateContent: "/v1/generate_content",
  notification: "/v1/getcount",
};

export const NotificationMsg = {
  NotConnect: "Please connect your wallet to proceed.",
  NotLogin: "Please login to proceed.",
  wallet: "Please connect your wallet to proceed.",
  NotFound: `NO PAGE FOUND`,
  error: `Something went wrong`,
  mint: `Minted Successfully`,
  ipfs: `Data Converted into CID hash (content identifier)`,
  socket: `Connection not established`,
  connect: `Wallet Connected`,
  approved: `Collection hasbeen approved`,
  sell: `Sell broker created`,
  buyoffer: `Buy offer created`,
  buy: `Buy collection`,
  errorType: "File type is not valid",
  SelectNftType: "Please select contract type",
  reConnect: `Please disconnect other wallet`,
  fileUpload15MB: `Invalid file size!. Must be less than 15MB`,
  requRejected: `Request has been rejected.`,

  copyText: `Address copied successfully!`,
  changeWallet: `Please connect your %s wallet to proceed`,
  Balance: `You Don't have enough balance %s`,
  BuySuccess: `Bought successfully`,
  putOnSaleMsg: `Successfully Published`,
  putOnSaleBackMsg: `Successfully un-published`,
  offerCreate: `Offer created successfully`,
  offerCancel: `Offer canceled successfully`,
  Qty: `Please Enter The Qty`,
};

export const validationMessages = {
  image: `File is required`,
  name: `Please enter the name.`,
  nameMax: `Name is too long ( Maximum 100 characters )`,
  NoFcopies: `Copies are Required`,
  categories: `Categories are required`,
  price: `Price is required`,
  royalty: `Royalty is required`,
  coverimage: `Cover image is required`,
  descriptionMax: "Description is too long ( Maximum 1000 characters )",
  descriptionLow: "Please enter the description.",
  fName: `Please enter your first name.`,
  lName: `Please enter your last name.`,
  allowAlphabets: `This field allow alphabets only.`,
  emailReq: `Please enter your email address.`,
  validEmail: `Please enter a valid email address.`,
  passwReq: `Please enter your password.`,
  validPass: `Password must include:`,
  cpasswReq: `Please re-enter the password for confirmation.`,
  notMatchPassw: `Passwords must match.`,
  phoneReq: `Please enter your phone number.`,
  validMobile: `Please enter a valid phone number.`,
  reqEnquiryType: "Enquiry type is required.",
  reqQueryMessage: "Query message is required.",
  allowMax8: "Allow max 8 characters.",
  allowMax20: "Allow max 20 characters.",
  allowMin2: "Allow min 2 characters.",
  Time_from: "From time is required",
  royality: `Royality should be less Than %s or min 0`,
  otpEnter: "Please enter verification code.",
  validOtp: `Please enter a valid verification code.`,
  invalTime: "Please enter a valid time.",
  sameTime: "To time should be greater than Form time.",
  longBio: `Bio is too long ( Maximum 500 characters )`,
  longUserName: `Username is too long ( Maximum 25 characters )`,
  require: "This field is require.",
  reqTermAndPrivacy:
    "Please agree to the Terms & conditions and Privacy Policy",
  qtyGreaterThan: "Qty should be greater than 0.",
  reqPrice: "Please enter a price for your offer.",
  priceGreaterThan: "Amount should be greater than 0.",
  reqDate: "Please select date.",
};

export const fileToDataUri = (file) =>
  new Promise((resolve, reject) => {
    const fileBlob = URL.createObjectURL(file);
    const [type, format] = file.type.split("/");
    resolve({ url: fileBlob, type, format });
  });

export default apiURl;

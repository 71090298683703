import React from 'react'

function ReferenceAudio() {
  return (
    <div className='refernceAudio'>
        <form id="contact" method="post" className="form" role="form">
            <div className="row">
                <div className="col-lg-6 form-group pb-3">
                    <label>Choose reference audio</label>
                    <select className="form-control" id="country" required="true" name="country">
                        <option value="AF">Afghanistan</option>
                        <option value="AX">Åland Islands</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 form-group pb-3">
                    <label>Enter speech to synthesize</label>
                    <textarea className="form-control" id="message" name="message" placeholder="Message" rows="5"></textarea>
                    <p>Audio will be synthesized with the same pitch and rhythm as the reference audio.</p>
                    <label className='checBtn'>
                        <input type="checkbox" name="remember" id="remember"/> Enable pitch correction
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-12 form-group synBtn text-center">
                    <button className="" type=""><img src='/images/soundwave.png'/> synthesize</button>
                </div>
            </div>
        </form>
    </div>
  )
}

export default ReferenceAudio
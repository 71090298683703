import React from "react";
import { BsSend } from "react-icons/bs";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import MYChat from "./MYChat";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { API, getAwsImageUrl } from "../apiwrapper";

function NewChat({
  handleOrganisation,
  orgId,
  accessKey,
  organisation,
  selectedPersona,
  handleDropdownItemClick,
}) {
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  let email = User?.data?.email;
  const [conversationId, setConversationId] = useState("");

  const [chat, Setchat] = useState();
  const [apiresponse, Setapiresponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [docsExist, setDocsExist] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [isInternetAccess, setIsInternetAccess] = useState(true);

  console.log(apiresponse, "apiresponseapiresponse");

  const handleChatdata = (e) => {
    Setchat(e.target.value);
  };

  console.log(chat, "handlechatdata");
  // console.log(User?.data?.organization[0]?.access_Key); http://localhost:3001/v1/user/doc/654a59d614d7d29fd2573996

  const fetchUserDocs = async () => {
    try {
      await API({
        url: `/v1/user/doc/${selectedOrgId}`,
        method: "GET",
      }).then((resp) => {
        console.log(resp, "Docsssss");
        if (resp?.data?.orgDoc) {
          // console.log(resp, 'Docsssss')
          setDocsExist(resp?.data?.orgDoc);
          console.log(resp?.data?.orgDoc, "respkey");
        }
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    console.log(selectedOrgId, "okkkkkkkkkk");
    if (selectedOrgId) {
      fetchUserDocs();
      setIsInternetAccess(false);
    } else {
      setDocsExist([]);
      setIsInternetAccess(true);
    }
  }, [selectedOrgId]);

  const handleChat = async () => {
    setLoading(true); // Start loading
    if (!chat) {
      alert("Please ask a question");
      setLoading(false); // Stop loading on error
      return;
    }
    console.log(docsExist, "docesssss");

    if (docsExist.length > 0) {
      console.log("inside docs exist chat api");
      const requestData = {
        user_id: userId,
        user_input: chat,
      };

      console.log(requestData, "inside docs exist chat api");
      let accKey = chatMessages?.find(
        (ele) => ele._id === selectedOrgId
      )?.access_Key;
      const Authorization = {
        Authorization: accKey || accessKey,
      };

      try {
        console.log("Upload chat successful:", Authorization);
        const response = await axios.post(
          `${process.env.REACT_APP_API_BACKEND_URL_PYTHON}/chat`,
          requestData,
          { headers: Authorization }
        );
        console.log("Upload chat successful:", response.data);
        Setapiresponse(response.data);
        Setchat("");
        setLoading(false);
        console.log(organisation);
      } catch (error) {
        console.error("Upload failed:", error);
        setLoading(false);
      }
    } else {
      console.log("outside docs exist Api");
      const requestData = {};

      if (selectedPersona !== "Default") {
        console.log("selectpersona", selectedPersona);
        requestData.question = chat;
        requestData.user_presona = selectedPersona.split(" ")[0];
      } else {
        requestData.question = chat;
      }

      console.log(requestData, "selextppppp");

      chatMessages.forEach((ele) => {
        console.log(ele, selectedOrgId, "ele2222");
      });

      const Authorization = {
        Authorization: accessKey,
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BACKEND_URL_PYTHON}/advanced_chat`,
          requestData
          // { headers: Authorization }
        );
        console.log("Upload chat successful:advanced_chat", response.data);
        Setapiresponse(response.data);
        Setchat("");
        setLoading(false);
      } catch (error) {
        console.error("Upload failed:", error);
        setLoading(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleChat();
    }
  };

  const [chatMessages, setChatMessages] = useState([]);

  useEffect(() => {
    // Fetch chat messages and set them in the chatMessages state
    const organisation_listing = axios
      .get(
        // `https://ufxtr8f6xa.execute-api.ap-south-1.amazonaws.com/user/v1/upload/doc?email=${email}`
        `https://uatapi.exuber.ai/user/v1/upload/doc?email=${email}`
        // `https://ufxtr8f6xa.execute-api.ap-south-1.amazonaws.com/user/v1/users/${userId}`
      )
      .then((data) => {
        setChatMessages(data?.data?.data?.orgData);
      });
    console.log("organisation_listing_123", organisation_listing);
  }, []);

  return (
    <>
      <div>
        <div className="chatTab">
          <ul>
            <li>
              <button className="active">Chat GPT 3.5</button>
              <div className="intBox">
                <div className="intBoxMain">
                  <p className="mb-3 textLine">
                    Experience swift precision: our fastest model,{" "}
                    <span>Accessible to both free and premium users.</span>
                  </p>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="d-flex justify-content-between align-items-center">
                        <p>Access Internet</p>
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={isInternetAccess}
                            onChange={(e) =>
                              setIsInternetAccess(e.target.checked)
                            }
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="d-flex justify-content-between align-items-center">
                        <p>Auto Save Chats</p>
                        <label class="switch">
                          <input type="checkbox" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr className="line" />
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="userPersona">
                        <p>Organisation</p>
                        <div>
                          <select
                            value={selectedOrgId}
                            onChange={(e) => {
                              e.preventDefault();
                              setSelectedOrgId(e.target.value);
                              console.log("agaGS");
                            }}
                          >
                            <option value="">Default</option>
                            {chatMessages?.map((ele) => {
                              return (
                                <>
                                  <option value={ele?._id}>{ele?.name}</option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="userPersona">
                        <p>Choose User Persona</p>
                        <div className="personaDrop">
                          <Dropdown>
                            <Dropdown.Toggle>{selectedPersona}</Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                href="#/action-1"
                                onClick={() =>
                                  handleDropdownItemClick("Default")
                                }
                              >
                                Default
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-1"
                                onClick={(e) =>
                                  handleDropdownItemClick("Doctor Danielle")
                                }
                              >
                                Doctor Danielle
                                <div className="userHover">
                                  <img src="/images/doctor-image.jpg" />
                                  <p>
                                    Committed healer, constantly updating
                                    medical practices
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-2"
                                onClick={() =>
                                  handleDropdownItemClick("Developer Dave")
                                }
                              >
                                Developer Dave
                                <div className="userHover">
                                  <img src="/images/developer_dave.jpg" />
                                  <p>
                                    Codes solutions, passionate about tech
                                    innovations
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-3"
                                onClick={() =>
                                  handleDropdownItemClick("Teacher Tina")
                                }
                              >
                                Teacher Tina
                                <div className="userHover">
                                  <img src="/images/teacher-tina.jpg" />
                                  <p>
                                    Shapes young minds, always adapting teaching
                                    techniques
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-4"
                                onClick={() =>
                                  handleDropdownItemClick("Student Same")
                                }
                              >
                                Student Same
                                <div className="userHover">
                                  <img src="/images/student-sam.jpg" />
                                  <p>
                                    Eager academic, seeking knowledge and skills
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-5"
                                onClick={() =>
                                  handleDropdownItemClick("Traveler Tracy")
                                }
                              >
                                Traveler Tracy
                                <div className="userHover">
                                  <img src="/images/traveler_tracy.jpg" />
                                  <p>
                                    World explorer, always chasing unique
                                    experiences
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-6"
                                onClick={() =>
                                  handleDropdownItemClick("Artist Alex")
                                }
                              >
                                Artist Alex
                                <div className="userHover">
                                  <img src="/images/artist-alex.jpg" />
                                  <p>
                                    Crafts masterpieces, inspired by varied
                                    muses
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-7"
                                onClick={() =>
                                  handleDropdownItemClick("Entrepreneur Erin")
                                }
                              >
                                Entrepreneur Erin
                                <div className="userHover">
                                  <img src="/images/entrepreneur_erin.jpg" />
                                  <p>
                                    Drives his venture, eyes always on growth
                                    opportunities
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-8"
                                onClick={() =>
                                  handleDropdownItemClick("Chef Charlie")
                                }
                              >
                                Chef Charlie
                                <div className="userHover">
                                  <img src="/images/chef-charlie.jpg" />
                                  <p>
                                    Culinary expert, blending tradition with
                                    innovation
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-9"
                                onClick={() =>
                                  handleDropdownItemClick("Athlete Andy")
                                }
                              >
                                Athlete Andy
                                <div className="userHover">
                                  <img src="/images/athlete_andy.jpg" />
                                  <p>
                                    Trains relentlessly, pursuing every
                                    competitive edge
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-10"
                                onClick={() =>
                                  handleDropdownItemClick("Parent Patty")
                                }
                              >
                                Parent Patty
                                <div className="userHover">
                                  <img src="/images/parent_patty.jpg" />
                                  <p>
                                    Parent of young kids, ensuring a bright
                                    future
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-11"
                                onClick={() => handleDropdownItemClick("Other")}
                              >
                                Other
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* <select value="">
                                <option value="A">Apple</option>
                                <option value="B">Banana</option>
                                <option value="C">Cranberry</option>
                                </select> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <button>Chat GTP 4</button>
              <div className="intBox">
                <div className="intBoxMain">
                  <p className="mb-3 textLine">
                    This is Our premier model which excels in creativity and
                    complex reasoning,{" "}
                    <span>Accessible to our Plus users.</span>
                  </p>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="d-flex justify-content-between align-items-center">
                        <p>Access Internet</p>
                        <label class="switch">
                          <input type="checkbox" defaultChecked />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="d-flex justify-content-between align-items-center">
                        <p>Auto Save Chats</p>
                        <label class="switch">
                          <input type="checkbox" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr className="line" />
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="userPersona">
                        <p>Organisations</p>
                        <div>
                          <select>
                            {chatMessages?.data?.data?.organization?.map(
                              (ele) => {
                                {
                                  console.log(ele, "ele");
                                }
                                return (
                                  <>
                                    <option value={ele?.name}>
                                      {ele?.name}
                                    </option>
                                  </>
                                );
                              }
                            )}
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-xl-6">`
                            <div className="userPersona">
                            <p>Organisations</p>
                            <div>
                                <select value="">
                                <option value="A">Default</option>
                                <option value="B">JP Morgan</option>
                                <option value="C">Metaverk Blockchain Solutions</option>
                                <option value="D">CrossTower</option>
                                </select>
                            </div>
                            </div>
                        </div> */}
                    <div className="col-xl-6">
                      <div className="userPersona">
                        <p>Choose User Persona</p>
                        <div className="personaDrop">
                          <Dropdown>
                            <Dropdown.Toggle>{selectedPersona}</Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                href="#/action-1"
                                onClick={() =>
                                  handleDropdownItemClick("Doctor Danielle")
                                }
                              >
                                Doctor Danielle
                                <div className="userHover">
                                  <img src="/images/doctor-image.jpg" />
                                  <p>
                                    Committed healer, constantly updating
                                    medical practices
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-2"
                                onClick={() =>
                                  handleDropdownItemClick("Developer Dave")
                                }
                              >
                                Developer Dave
                                <div className="userHover">
                                  <img src="/images/developer_dave.jpg" />
                                  <p>
                                    Codes solutions, passionate about tech
                                    innovations
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-3"
                                onClick={() =>
                                  handleDropdownItemClick("Teacher Tina")
                                }
                              >
                                Teacher Tina
                                <div className="userHover">
                                  <img src="/images/teacher-tina.jpg" />
                                  <p>
                                    Shapes young minds, always adapting teaching
                                    techniques
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-4"
                                onClick={() =>
                                  handleDropdownItemClick("Student Same")
                                }
                              >
                                Student Same
                                <div className="userHover">
                                  <img src="/images/student-sam.jpg" />
                                  <p>
                                    Eager academic, seeking knowledge and skills
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-5"
                                onClick={() =>
                                  handleDropdownItemClick("Traveler Tracy")
                                }
                              >
                                Traveler Tracy
                                <div className="userHover">
                                  <img src="/images/traveler_tracy.jpg" />
                                  <p>
                                    World explorer, always chasing unique
                                    experiences
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-6"
                                onClick={() =>
                                  handleDropdownItemClick("Artist Alex")
                                }
                              >
                                Artist Alex
                                <div className="userHover">
                                  <img src="/images/artist-alex.jpg" />
                                  <p>
                                    Crafts masterpieces, inspired by varied
                                    muses
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-7"
                                onClick={() =>
                                  handleDropdownItemClick("Entrepreneur Erin")
                                }
                              >
                                Entrepreneur Erin
                                <div className="userHover">
                                  <img src="/images/entrepreneur_erin.jpg" />
                                  <p>
                                    Drives his venture, eyes always on growth
                                    opportunities
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-8"
                                onClick={() =>
                                  handleDropdownItemClick("Chef Charlie")
                                }
                              >
                                Chef Charlie
                                <div className="userHover">
                                  <img src="/images/chef-charlie.jpg" />
                                  <p>
                                    Culinary expert, blending tradition with
                                    innovation
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-9"
                                onClick={() =>
                                  handleDropdownItemClick("Athlete Andy")
                                }
                              >
                                Athlete Andy
                                <div className="userHover">
                                  <img src="/images/athlete_andy.jpg" />
                                  <p>
                                    Trains relentlessly, pursuing every
                                    competitive edge
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-10"
                                onClick={() =>
                                  handleDropdownItemClick("Parent Patty")
                                }
                              >
                                Parent Patty
                                <div className="userHover">
                                  <img src="/images/parent_patty.jpg" />
                                  <p>
                                    Parent of young kids, ensuring a bright
                                    future
                                  </p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-11"
                                onClick={() => handleDropdownItemClick("Other")}
                              >
                                Other
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* <select value="">
                                <option value="A">Apple</option>
                                <option value="B">Banana</option>
                                <option value="C">Cranberry</option>
                                </select> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="inputChatBox">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 px-4">
                <div className="chatBox">
                  <input
                    type="text"
                    // placeholder="Very fast, great for most everyday tasks."
                    placeholder="Start a new chat here..."
                    onChange={handleChatdata}
                    onKeyPress={handleKeyPress}
                    value={chat}
                  />
                  <button onClick={handleChat} disabled={loading}>
                    {loading ? <div className="loader">...</div> : <BsSend />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {apiresponse && ( */}
      <MYChat
        orgId={orgId}
        access_Key={accessKey}
        apidata={apiresponse}
        chatInput={chat}
        conversationId={conversationId}
        setConversationId={setConversationId}
      />
      {/* )} */}
    </>
  );
}

export default NewChat;

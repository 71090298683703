import React, { useDebugValue, useEffect, useState } from "react";

import { API, getAwsImageUrl, getAwsVideoUrl } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { isValid, validateRequire } from "../Validation/InputValidation";
import { Spinner } from "react-bootstrap";
import TextToMusic from "./TextToMusic";
import axios from "axios";
import ConvertAudio from "./ConvertAudio";
import { SetUserData, SetpopupReducerData } from "../store/reducer";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-hot-toast";
import DubAudios from "./DubAudio";
import ConvertImage from "./ConvertImage";

import { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import ImageToStoryGenrerate from "./ImageToStoryGenrerate";
import ConvertStoryToAudio from "./ConvertStoryToAudio";
import { BsXCircle } from "react-icons/bs";
import {
  SetCompletedNotifications,
  SetPendingNotifications,
} from "../store/reducer/extra";

function ImageToStory({ category = {}, setCategory, id }) {
  const [needhelp, setNeedHelp] = useState(false);
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  let secretKey = User?.data?.secret_key;
  // console.log(secretKey,"secretKey)))))))))))))))))))))))))))))))))))))))")
  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [texttomusic, setTextToMusic] = useState([]);
  const [convertedStory, setConvertedStory] = useState("");
  const [viewStoryData, setViewStoryData] = useState(false);
  const [imageToStoryData, setImageToStoryData] = useState(null);

  console.log(convertedStory, "convertedStory", id);

  const dispatch = useDispatch();

  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [preview, setPreview] = useState("");
  const [languages, setLanguage] = useState([]);
  const [Genre, setGenre] = useState([]);

  // const [selectedLanguage, setSelectedLanguage] = useState("");
  // console.log(selectedLanguage,"selectedLanguagefirst");

  const [objId, setobjId] = useState("");
  console.log(objId, "objId");
  const [inputField, setInputField] = useState({
    category: category?._id,
    modelType: "",
    target_language: "",
    genre: "",
    word_count: "",
  });
  const [durationTouched, setDurationTouched] = useState(false);
  const navigate = useNavigate();

  const { target_language = "", genre = "", word_count = "" } = inputField;

  const handleChange = (e) => {
    setInputField({
      ...inputField,
      [e.target.name]: e.target.value,
    });
    setApiErrors({ message: "" });
    setApiErrors({});
  };

  const fetchImageToStoryData = async () => {
    try {
      const resp = await API({
        url: `${apiURl.callComponantData}/${id}/${userId}`,
        method: "GET",
      });

      console.log("fetchImageToStoryDatafsdfdsSco", resp);

      if (resp) {
        setImageToStoryData(resp?.data); // Assuming resp.data contains your desired data
      } else {
        setImageToStoryData(null);
      }
    } catch (error) {
      console.error("Error fetching face search data:", error);
      setImageToStoryData(null);
    }
  };

  useEffect(() => {
    if (id) {
      console.log("fetchImageToStoryDatarrrrrr", id);
      fetchImageToStoryData();
    }
  }, [id]);

  const fetchCompleteList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.notification}/${userId}`,
        method: "GET",
      });
      if (response.status) {
        console.log(response.data.tasks);
        if (response.data.tasks.PENDING) {
          dispatch(SetPendingNotifications(response.data.tasks.PENDING));
        } else {
          dispatch(SetPendingNotifications([]));
        }
        dispatch(SetCompletedNotifications(response.data.tasks.COMPLETED));
      }
    } catch (error) {
      throw error;
    }
  };

  console.log(target_language, "target_languagefirst");

  const validateAll = () => {
    // let err1 = {};
    // err1.category = validateRequire(category);
    // err1.word_count = validateRequire(word_count);

    // err1.word_count =
    //   Number(word_count) > 500 ? "Duration cannot be greater than 500" : "";

    let errors = {};

    // Word Count: Ensure it's a number and optionally within a range
    if (!word_count) {
      errors.word_count = "Word count is required";
    } else if (isNaN(word_count)) {
      errors.word_count = "Word count must be a number";
    } else if (word_count <= 0 || word_count > 500) {
      errors.word_count = "Word count must be between 1 and 500";
    }

    // Target Language: Ensure that a language is selected
    if (!target_language) {
      errors.target_language = "Please select a language";
    }

    // Genre: Ensure that a genre is selected
    if (!genre) {
      errors.genre = "Please select a genre";
    }

    // File Upload: Ensure an image file is uploaded
    if (!file) {
      errors.file = "Please upload an image";
    }

    return errors;
  };

  const handleChangeFile = (e) => {
    var file = e.target.files[0];
    setPreview(URL.createObjectURL(file));
    setFile(file);
    setFileName(file.name);
    setErrors({ ...errors, file: "" });
  };

  const fetchLanguage = async () => {
    try {
      await API({
        url: `${apiURl.languagesStory}`,
        method: "GET",
      }).then((data) => {
        console.log("lagnguagedata", data);

        if (data?.status) {
          let allLang = data?.langData || [];
          let allgenre = data?.genreData || [];
          setLanguage(allLang);
          setGenre(allgenre);
        } else {
          setLanguage([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetchLanguage();
  }, []);

  // const getConvertedStory = async (params, objID) => {
  //   const URI = `${process.env.REACT_APP_CONVERTED_IMAGE_TO_STORY}?id=${params}&obj_id=${objID}`;
  //   let response = await axios.get(URI);
  //   if (response?.data?.status === "processing") {
  //     getConvertedStory(params, objID);

  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //     console.log("response?.data?", response.data.response_json);
  //     console.log("response?.data?.rep_url", response?.data?.rep_url);
  //     setConvertedStory(response?.data?.response_json);
  //   }
  //   console.log(objID);
  //   console.log("response", convertedStory);
  // };

  const handleLogin = (e) => {
    dispatch(
      SetpopupReducerData({
        modalType: "LOGIN",
        showModal: true,
      })
    );
  };

  const handleConvert = async (e) => {
    e.preventDefault();
    if (!userId) {
      handleLogin();
      return;
    }
    try {
      // let err = validateAll();
      // if (isValid(err)) {
      let err = validateAll();
      setErrors(err);

      // Check if errors object is empty (no errors)
      if (Object.keys(err).length === 0) {
        let imageUrl = await getAwsImageUrl(file, `${userId}/InputImage/`);

        const payload = {
          // ...inputField,
          // userId: userId,
          image_filepath: imageUrl,
          category: category?._id,
          word_count: word_count,
          genre: genre,
          target_language: target_language,
          secret_key: secretKey,
          type: "image_to_story",
        };
        setIsLoading(true);

        await API({
          url: apiURl.imagetostoryNew,
          method: "POST",
          body: { ...payload },
          formData: false,
        }).then((data) => {
          console.log(data, "dataimage");
          if (data?.status || data?.status === "true") {
            console.log("data------>ImageTostorydata", data);
            fetchCompleteList();
            dispatch(
              SetpopupReducerData({
                modalType: "PENDINGTASK",
                showModal: true,
                category: `${category.name}`,
              })
            );
            // console.log(secretKey,"secretKey)))))))))))))))))))))))))))))))))))))))")
            // setobjId(data?.data.userInfo?.id);
            // if (data?.data?.response?.error) {
            //   toast.error(data?.data?.response?.error);
            // } else {
            //   console.log("DATA", data)
            //   // getConvertedStory(data?.data?.response?.rep_id, data?.data.userInfo?.id);
            //   // toast.success(
            //   //   "Your project is in the works! We'll drop you an email once it's all set and ready. Stay tuned!",
            //   //   { duration: 7000 }
            //   // );
            // }

            dispatch(SetUserData(data?.data?.updatedUserInfo));
          } else {
            setApiErrors({ message: data?.message });
            setErrors({});
          }
        });
      } else {
        setErrors(err);
      }
    } catch (error) {
      setApiErrors({ message: error?.message });
    } finally {
      setIsLoading(false);
    }
  };

  function handlePreview() {
    setFile("");
    setFileName("");
    setPreview("");
  }

  return (
    <>
      {needhelp ? (
        <DubAudios setNeedHelp={setNeedHelp} />
      ) : (
        <>
          <h2 className="text-center text-white mb-4">Image To Story</h2>
          <div className="refernceAudio">
            <form id="contact" method="post" className="form" role="form">
              <div className="row">
                <div className="col-lg-12 form-group pb-3">
                  <div className="d-flex justify-content-between">
                    <label htmlFor="audio_text" className="form-label">
                      Upload Image <span className="text-danger"> *</span>
                    </label>
                    <button
                      onClick={() => setNeedHelp(true)}
                      className="needHelp"
                    >
                      Need Help?
                    </button>
                  </div>
                  <div class="customUpload ">
                    {preview ? (
                      <div className="position-relative">
                        <BsXCircle
                          title="Delete"
                          onClick={handlePreview}
                          className="preview-icon"
                        />

                        <img width="100%" height="500px" src={preview} />
                      </div>
                    ) : (
                      <>
                        <input
                          className="form-control file-border"
                          type="file"
                          id="formFile"
                          accept="image/*"
                          onChange={handleChangeFile}
                        />
                        <span class="upload-btn" id="upload-btn">
                          {fileName}
                        </span>
                      </>
                    )}
                  </div>

                  {errors.file ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.file}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-4">
                  <label htmlFor="modelType" className="form-label">
                    Words limit<span className="text-danger"> *</span>
                  </label>
                  <input
                    className="form-select"
                    type="text"
                    value={word_count}
                    name="word_count"
                    onChange={handleChange}
                  />
                  {/* <label className="pt-2">maximum: 500 Words</label> */}
                  <span className="text-danger"> </span>
                  {errors.word_count ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.word_count}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-4">
                  <label htmlFor="modelType" className="form-label">
                    Select language<span className="text-danger"> *</span>
                  </label>
                  <select
                    className="form-select"
                    type="text"
                    value={target_language}
                    name="target_language"
                    onChange={handleChange}
                  >
                    <option value={""}>Select Language</option>
                    {languages?.map((ele) => (
                      <option key={ele._id} value={ele.lang}>
                        {ele.langName}
                      </option>
                    ))}
                  </select>

                  {errors.target_language ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.target_language}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-4">
                  <label htmlFor="modelType" className="form-label">
                    Genre<span className="text-danger"> *</span>
                  </label>
                  <select
                    className="form-select"
                    type="text"
                    value={genre}
                    name="genre"
                    onChange={handleChange}
                  >
                    <option value={""}>Select Genre</option>
                    {Genre?.map((ele) => (
                      <option key={ele._id} value={ele.genreName}>
                        {ele.genreName}
                      </option>
                    ))}
                  </select>

                  {errors.genre ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.genre}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-md-12 form-group synBtn text-center">
                  {isLoading ? (
                    <button className="btn btn-primary mt-4">
                      <Spinner animation="border" size="lg" /> Processing...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary mt-4 bt-image-to-story"
                      onClick={handleConvert}
                      disabled={isLoading}
                    >
                      {/* <img src="/images/soundwave.png" alt="" /> */}
                      Tell Me A Story
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="text-center">
            <Link
              to=""
              className="seeTitle"
              onClick={() => setCategory({ _id: "", name: "dashboard" })}
            >
              See All Stories in Dashboard
            </Link>
          </div>
          {/* {convertedStory && ( */}
          {id && (
            <ImageToStoryGenrerate
              id={id}
              outputStory={imageToStoryData?.converted_output}
              setConvertedStory={setConvertedStory}
              target_language={target_language}
              category={category}
              setCategory={setCategory}
              objId={objId}
              viewStoryData={viewStoryData}
              setViewStoryData={setViewStoryData}
            />
          )}
        </>
      )}
    </>
  );
}

export default ImageToStory;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Modal, ModalHeader } from "react-bootstrap";
// import FriendsWithSharePopup from "./FriendsWithSharePopup";
import ShareSocialMedia from "../profile/ShareSocialMedia";
import { BASECONFIG } from "../config";

import {
  FaYoutube,
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";
import { SetpopupReducerData } from "../store/reducer";
import { useDispatch } from "react-redux";

function AIToolSidebar({
  mainTools,
  labTools,
  allCategory = [],
  category,
  setCategory,
  accountTools,
  tab,
}) {
  const [toggle, setToggle] = useState(false);
  const { User = {} } = useSelector((state) => state);
  console.log("User?.data?.referralCode", User?.data?.referralCode);
  const referralCode = User?.data?.referralCode;
  let userId = User?.data?._id;

  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const [link, setLink] = useState("");
  const url = referralCode;
  console.log("url", url);
  useEffect(() => {
    setLink(url);
  }, [referralCode]);

  // const handleClickOutside = (event) => {
  //   if (ref.current && !ref.current.contains(event.target)) {
  //     setToggle(false);
  //   }

  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const handleClick = () => {
    if (!User?.data?.jwtToken) {
      dispatch(
        SetpopupReducerData({
          modalType: "LOGIN",
          showModal: true,
        })
      );
    }
  };

  return (
    <>
      <div className="sidebarMain">
        {tab === "accounts" ? (
          <>Accounts Sidebar</>
        ) : (
          <div className="product">
            <div
              className="dashboardBtn mb-3"
              onClick={() => setCategory({ _id: "", name: "dashboard" })}
            >
              <Link
                to={`/AITool/dashboard`}
                className={category?.name === "dashboard" ? "active" : ""}
              >
                <span>
                  <img src="/images/dashboard.png" />
                </span>
                Dashboard
              </Link>
            </div>
            <ul>
              {mainTools?.map((ele) => (
                <li
                  key={ele._id}
                  onClick={() => {
                    setCategory({ _id: ele._id, name: ele.name });
                    handleClick();
                  }}
                  className={category.name === ele.name ? "active" : ""}
                >
                  <Link
                    to={`/AITool/${ele.name}`}
                    className={`${ele.name} text-capitalize `}
                  >
                    <span>
                      <img src={ele.icon} />
                    </span>
                    {ele.name?.replace(/_/g, " ")}
                  </Link>
                </li>
              ))}
            </ul>

            {/* <h2 className="createAi"><img src="/images/plusIcon.png" /> Create new <span>AI</span></h2> */}
          </div>
        )}

        {tab !== "accounts" && (
          <div className="product">
            <div className="dashboardBtn mb-3">
              <Link
                to=""
                // className={category?.name === "dashboard" ? "active" : ""}
              >
                <span>
                  <img src="/images/dashboard.png" />
                </span>
                Lab Tools
              </Link>
            </div>

            <ul>
              {labTools?.map((ele) => (
                <li
                  key={ele._id}
                  onClick={() => setCategory({ _id: ele._id, name: ele.name })}
                  className={category.name === ele.name ? "active" : ""}
                >
                  <Link
                    to={`/AITool/${ele.name}`}
                    className={`${ele.name} text-capitalize `}
                  >
                    <span>
                      <img src={ele.icon} />
                    </span>
                    {ele.name?.replace(/_/g, " ")}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* <div className="product">
          <div className="shareWtFrnd">
            <button onClick={() => setShowModal(true)}>
              <img src="/images/group.png" /> Share With Friends
            </button>
          </div>
          <h3>Community</h3>
          <ul>
            <li>
              <a
                href="https://www.youtube.com/@exuber.official"
                target="_blank"
              >
                <span>
                  <FaYoutube />
                </span>{" "}
                YouTube
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/exuber.official"
                target="_blank"
              >
                <span>
                  <FaFacebookF />
                </span>{" "}
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/exuber/"
                target="_blank"
              >
                <span>
                  <FaLinkedinIn />
                </span>{" "}
                Linkedin
              </a>
            </li>
            <li>
              <a href="https://twitter.com/Exuber_AI" target="_blank">
                <span>
                  <FaTwitter />
                </span>{" "}
                Twitter
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/exuber_ai/" target="_blank">
                <span>
                  <FaInstagram />
                </span>{" "}
                Instagram
              </a>
            </li>
          </ul>
        </div> */}
      </div>
      <Modal className="shareSocial" show={showModal}>
        <ModalHeader>
          <div class="modal-title h4">Share With Friends</div>
          <button className="bgNone" onClick={() => setShowModal(false)}>
            <img src="/images/closeBtn.png" />
          </button>
        </ModalHeader>
        <Modal.Body>
          <ShareSocialMedia link={link}></ShareSocialMedia>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AIToolSidebar;

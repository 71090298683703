import React, { useState } from "react";
import { useEffect } from "react";
import CKEditor from "react-ckeditor-component";
import { useSelector, useDispatch } from "react-redux";
import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import {SetSEOContent} from "../store/reducer/extra";

function OptimisedEditer({id}) {


  const dispatch = useDispatch();
  const { Extra = {} } = useSelector((state) => state);
  let cont = Extra?.optimizeContent?.content;
  const [scorScribeData, setScorScribeData] = useState(null);
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  // const { Extra = {} } = useSelector((state) => state);
  // let cont = Extra?.seoContent?.content;

  const fetchScoScribeData = async () => {
    try {
      const resp = await API({
        url: `${apiURl.callComponantData}/${id}/${userId}`,
        method: "GET",
      });

      console.log('dfsdfsfsfsdfdsoriginal', resp)

      if (resp) {
        setScorScribeData(resp?.data); // Assuming resp.data contains your desired data
      } else {
        setScorScribeData(null);
      }
    } catch (error) {
      console.error("Error fetching face search data:", error);
      setScorScribeData(null);
    }
  };

  useEffect(() => {
    if (id) {
      console.log("ScoScribeDatarrrrrroriginal", id);
      fetchScoScribeData();
    }
  }, [id]);
  // let keywords = Extra?.keywords;

  // console.log(keywords);

  // console.log(cont)
  // const [state, setState] = useState({
  //   content: cont
  // });
  // const updateContent = (newContent) => {
  //   setState({
  //     content: newContent
  //   });
  // };

  // const onChange = (evt) => {
  //   console.log("onChange fired with event info: ", evt);
  //   var newContent = evt.editor.getData();
  //   setState({
  //     content: newContent
  //   });
  // };

  const onBlur = (evt) => {
    console.log("onBlur event called with event info: ", evt);
  };

  const afterPaste = (evt) => {
    console.log("afterPaste event called with event info: ", evt);
  };
  return (
    <CKEditor
      activeClass="p10"
      content={scorScribeData?.optimised_content}
      events={{
        blur: onBlur,
        afterPaste: afterPaste,
        // change: onChange
      }}
    />
  )
}

export default OptimisedEditer
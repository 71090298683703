import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
//  import { SIGNUP_USER_DATA } from "../../store/actions/ActionTypes";
import { SetpopupReducerData } from "../store/reducer/PopupReducer";
// import { isValid, validateEmail } from "../../Validation/InputValidation";
// import ImageComponent from "../image-component";
function ForgetPassword() {
  console.log("You are in forget modal pouppu");
  const dispatch = useDispatch();
  const { PopupReducer } = useSelector((state) => state);

  const { modalType = "", showModal = false } = PopupReducer?.modal || {}; 
  // const [inpData, setInpData] = useState({
  //   email: "",
  // });
  // const [errors, setErrors] = useState({});
  // const [apiErrors, setApiErrors] = useState({ message: "" });

  // const { email = "" } = inpData;
  const handleClosePopup = (e) => {
    dispatch(SetpopupReducerData({ modalType: "", showModal: false }));
  };
  // const handleChange = (e) => {
  //   setInpData({ ...inpData, [e.target.name]: e.target.value });
  // };
  // const handleValidate = (e) => {
  //   const errors1 = {};
  //   switch (e.target.name) {
  //     case "email":
  //       errors1.email = validateEmail(e.target.value);
  //       break;

  //     default:
  //       break;
  //   }
  //   setErrors(errors1);
  // };
  // const validateAll = () => {
  //   let err1 = {};

  //   err1.email = validateEmail(email);

  //   return err1;
  // };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     let err = validateAll();
  //     if (isValid(err)) {
  //       await API({
  //         url: apiURl.forgetpassword,
  //         method: "POST",
  //         body: { ...inpData, event: "forgetpassword" },
  //         formData: false,
  //       }).then((data) => {
  //         if (data?.status || data?.status === "true") {
  //           // toast(`${data?.message}`, { type: "success" });
  //           dispatch(
  //             SetpopupReducerData({
  //               modalType: "OTP",
  //               showModal: true,
  //               buyModal: false,
  //             })
  //           );
  //           dispatch({
  //             type: SIGNUP_USER_DATA,
  //             payload: { ...inpData, forgetpassword: true },
  //           });
  //         } else {
  //           setApiErrors({ message: data?.message });
  //         }
  //       });
  //     } else {
  //       setErrors(err);
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     handleSubmit(event);
  //   }
  // };
  return (
    <>
    <Modal
        show={showModal}
        hide={handleClosePopup}
        size="md offerPoup loginWidth modal-dialog-centered"
      >
        <>
          <div className="pop_content login-screen">
            {/* <div className="close-button">
              <a href="#" onClick={handleClosePopup}>
                <ImageComponent src="/images/cross-button.svg" />
              </a>
            </div> */}
            <h2>Forgot Password?</h2>
            <p className="mt-1 mb-4">
              Please enter your registered email address to receive a
              verification code.
            </p>
            <div className="login-section">
              <form
                action=""
                method="post"
                // onSubmit={handleSubmit}
                // onKeyDown={handleKeyDown}
              >
                <div className="poupBox mb-4">
                  <div className="form-div inputWithIcon m-0 sdfg">
                    <div className="icon">
                      <img src="/images/envelop.svg" alt="" />
                    </div>
                    {/* <label for="name">
                    Email <span className="text-danger"> *</span>
                  </label> */}
                    <input
                      type="email"
                      id="Email"
                      name="email"
                      // value={email}
                      placeholder="Email"
                      // onChange={handleChange}
                      // onBlur={handleValidate}
                    />
                    {/* {errors.email ? (
                      <span
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.email}
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                  {/* {apiErrors.message && (
                    <span
                      className="text-danger mb-2 d-block"
                      style={{ fontSize: "14px" }}
                    >
                      {apiErrors.message}
                    </span>
                  )} */}
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    onClick={handleClosePopup}
                    type="button"
                    className="cancel-button m-0"
                  >
                    Cancel
                  </button>
                  <button
                    className="creat-button m-0"
                    type="submit"
                    // onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default ForgetPassword;

import React from 'react'

function ImageEnhancer() {
  return (
    <>
    
    
        <h1 data-aos="zoom-in">Exuber's Image Enhancer</h1>
        {/* <iframe className='textToVideo'
            src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/22028b41b02d9c3a5f55bd20cf5fe471/iframe?muted=true&loop=true&autoplay=true&controls=false"
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe> */}

        <iframe className='textToVideo'
        src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/f0897748a7876fe9d1f08b217fc07e33/iframe?muted=true&loop=true&autoplay=true&controls=false"
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
        

        <p data-aos="zoom-in">Step into the world of visual perfection with Exuber's Image Enhancer tool.</p>
        <div className='row'>
            <div className='col-xl-6'>
                <h4>Enhance Visual Impact:</h4>
                <p>Elevate the impact of your images with Exuber's Image Enhancer tool. Whether it's for personal or professional use, this tool effortlessly enhances the visual quality of your images, making them sharper, more vibrant, and visually captivating. Transform ordinary photos into extraordinary visuals that stand out and leave a lasting impression.</p>
            </div>
            <div className='col-xl-6'>
                <h4>Save Editing Time:</h4>
                <p>Say goodbye to complex editing software and time-consuming manual adjustments. Exuber's Image Enhancer automates the enhancement process, allowing you to achieve professional-looking results with just a few clicks. This saves you valuable time, making it ideal for busy professionals, content creators, and anyone who wants to enhance their images without the hassle of extensive editing.</p>
            </div>
        </div>


    
    </>
  )
}

export default ImageEnhancer
import React from "react";
import { useSelector } from "react-redux";
// import ChangePassword from "../PopUp/ChangePassword";
// import CreateNewPassword from "../PopUp/CreateNewPassword";

import Login from "./Login";
import SignUp from "./SignUp";
import ForgetPassword from "./ForgetPassword";
import Congratulation from "../profile/Congratulation";
import WalletPopup from "../profile/WalletPopup";

import OTPValidation from "./OtpValidation";
import ResetPassword from "../profile/ResetPassword";
import PendingTask from "./PendingTask";
import CompleteTask from "./CompleteTask";

function OpenModal() {
  const { PopupReducer } = useSelector((state) => state);
  console.log("PopupReducer", PopupReducer);
  const { modalType = "", showModal = false } = PopupReducer?.modal || {};


  return (
    <>
      {showModal && (
        <>
          {modalType === "LOGIN" && <Login />}
          {modalType === "SIGNUP" && <SignUp />}
          {modalType === "CONGRATULATIONS" && <Congratulation />}
          {modalType === "OTP" && <OTPValidation />}
          {modalType === "RESETPASSWORD" && <ResetPassword />}
          {modalType === "PENDINGTASK" && <PendingTask />}
          {modalType === "COMPLETEDTASK" && <CompleteTask />}
          {/* {modalType === "SOCKET"} */}
          {/* {modalType === "CHANGEPASSWORD" && <ChangePassword />}  */}
          {/* {modalType === "CHANGE PASSWORD" && <CreateNewPassword />} */}
          {/* {modalType === "WALLET" && <WalletPopup />} */}
          {/* {modalType === "FORGETPASSWORD" && <ForgetPassword />} */}
          {/* {modalType === "OTP" && <VerifyOTP />}
          {modalType === "CHANGE PASSWORD" && <CreateNewPassword />}
          {modalType === "CHANGEPASSWORD" && <ChangePassword />}  */}
        </>
      )}
    </>
  );
}

export default OpenModal;

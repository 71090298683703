import React, { useEffect, useState } from "react";
import { API } from "../apiwrapper";
import axios from "axios";

import { BsChatLeftDots, BsTrash, BsPlusSquare } from "react-icons/bs";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

function ChatHistory({ setConversationId, newChat, chathistory }) {
  // console.log(conversations?.conversationId,'hdusahdxiusahdi')
  const [showTrashButton, setShowTrashButton] = useState(false); // State to track whether to show the trash button
  const redTextStyle = {
    color: "White",
  };
  const [conversations, setConversations] = useState([]);
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  // let orgId = User?.data?.organization[0]?._id;
  let orgId = User?.data?.organization?.[0]?._id || "null";

  let messageId = User?.message?._id;

  const deleteChat = async () => {
    try {
      const reqObj = {
        userId: userId,
        orgId: orgId,
      };

      const response = await axios.post(
        // `https://ufxtr8f6xa.execute-api.ap-south-1.amazonaws.com/user/v1/delete/all/chat/messages`,
        `https://uatapi.exuber.ai/user/user/v1/delete/all/chat/messages`,
        reqObj
      );
      const responseData = response.data;
      toast.success(response.data.message);
      setConversations([]);
      newChat([]);
      setConversationId("");
    } catch (error) {
      throw error;
    }
  };

  //  Delete Messages
  const [chatMessages, setChatMessages] = useState([]);
  const deleteChatMessage = async (id) => {
    console.log(id);
    try {
      // Make a DELETE request to delete the chat message
      const response = await axios.delete(
        // `https://ufxtr8f6xa.execute-api.ap-south-1.amazonaws.com/user/v1/delete/chat/message/${id}`
        `https://uatapi.exuber.ai/user/v1/delete/chat/message/${id}`
      );

      console.log(response);
      // After successfully deleting the message, update your chatMessages state
      const updatedConversations = conversations.filter(
        (conv) => conv.conversationId !== id
      );
      setConversations(updatedConversations);
      newChat([]);
    } catch (error) {
      console.error("Error deleting chat message: ", error);
    }
  };

  const startNewChat = () => {
    if (chathistory.length > 0) {
      console.log("newchatchat");
      newChat([]);
      setConversationId("");
      getConversationId();
    } else {
      toast.error("Please start a conversation first!");
    }
  };

  const openChatBack = async (id) => {
    console.log("convstnid", id);
    setConversationId(id);

    try {
      const response = await axios.get(
        // `https://ufxtr8f6xa.execute-api.ap-south-1.amazonaws.com/user/v1/get/chat/new/history/${id}`
        `https://uatapi.exuber.ai/user/v1/get/chat/new/history/${id}`
      );

      setShowTrashButton(!showTrashButton);
      // setConversationId(response.data?.data?.chatRecord?.conversationId);
      newChat(response?.data?.data?.chatRecord?.userChat);
    } catch (error) {
      console.error("Error deleting chat message: ", error);
    }
  };

  const getConversationId = async () => {
    if (!userId) {
      console.log("Please Authenticate yourself by login again.");
      return;
    } else {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BACKEND_URL}/v1/get-all-conversation/${userId}/${orgId}`
        );
        console.log(
          `Conversation Ids for this ${userId}`,
          response.data.data.conversations
        );

        setConversations((prev) => [...response?.data?.data?.conversations]);
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };

  useEffect(() => {
    getConversationId();
  }, []);

  const formatDate = (dt) => {
    const date = new Date(dt);
    return date.toDateString();
  };

  return (
    <>
      <div className="mainChatHistort">
        <div className="clearChat top">
          <button onClick={startNewChat}>
            <BsPlusSquare /> New Chat
          </button>
        </div>
        <h6>
          <span style={redTextStyle}>Chat History</span>
        </h6>
        <div className="conversationsContainerhistory">
          {conversations?.map((item, idx) => (
            <div className="hisBox">
              {/* <h6>Today</h6> item?.userChat?.userQstn idx */}
              <div className="date">{formatDate(item?.userChat?.date)}</div>
              <ul>
                <li className="d-flex justify-content-between">
                  <Link onClick={() => openChatBack(item.conversationId)}>
                    <BsChatLeftDots /> {item?.userChat?.userQstn}
                  </Link>
                  {showTrashButton && (
                    <button
                      onClick={() => deleteChatMessage(item.conversationId)}
                    >
                      <BsTrash />
                    </button>
                  )}
                </li>
              </ul>
            </div>
          ))}
        </div>
        <div className="clearChat">
          <button onClick={deleteChat}>
            <BsTrash /> Clear all chats
          </button>
        </div>
      </div>
    </>
  );
}

export default ChatHistory;

import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { logger } from "redux-logger";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { User, Loader, Theme, PopupReducer } from "./reducer";
import Extra from "./reducer/extra";

const reducer = {
  User,
  Loader,
  Theme,
  PopupReducer,
  Extra
};

const rootReducer = combineReducers(reducer);

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
let middleware = () =>
  getDefaultMiddleware({ serializableCheck: false }).concat(logger);

if (process.env.NODE_ENV === "production") {
  middleware = (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false });
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

export const persistor = persistStore(store);
try {
  persistor();
} catch (error) {
  console.log("Error creating Redux persistor", error);
}

import React from 'react'
import Header from '../homeComponents/Header'
import { Link } from 'react-router-dom'

function PrivacyPolicy() {
  return (
    <>
    <Header/>
    <section className='terms'>
        <div className='container'>
            <div className='row'>
                <div className="col-md-12">
                    <h1>Privacy Policy</h1>
                    <p>Welcome to the "Exuber" website doing business as Metaverse Blockchain Solutions Private Limited. We appreciate your decision to use our Application and are committed to safeguarding your privacy. Our Privacy Policy outlines how we collect, use, and disclose information about you when you use our Application.</p>
                    <p>Exuber offers a range of services to its users, including the Exuber App, website, features, and associated services. Our services include Gaming P2E, Celebrity AI Collectibles, and other offerings. This Privacy Policy has been created to provide clarity on our information practices and how we handle user data.</p>
                    <p>Your use of Exuber constitutes a binding agreement between you and our company. This agreement includes the terms outlined in this Privacy Policy, as well as our "Terms and Conditions" and any other relevant policies. If you do not agree to the terms of this agreement, including this Privacy Policy, the "Terms and Conditions," and any other relevant policies, please refrain from accessing, installing, or using Exuber or any of its features.</p>
                    <p>This Privacy Policy applies only to the information provided by users while using Exuber and does not cover information that is made available to Exuber from third-party websites, apps, landing pages, and other sources. Any information that we obtain from these sources will be subject to their respective privacy policies and terms of use. Kindly be aware that this policy is applicable solely to Exuber, and it does not encompass any websites, apps, landing pages, or third-party services that might be accessible through Exuber, regardless of any promotion or inclusion on our platform.</p>
                    <p>By using Exuber, you confirm and guarantee that any registration or other information you provide to us is accurate, complete, and truthful. You agree to maintain the accuracy and completeness of this information at all times.</p>
                    <div className='priBox'>
                      <h3>Information We Collect The below information is collected by Exuber:</h3>
                      <p>1) Information collected with a prompt:<br/>
                        In order to use Exuber, we may need you to authenticate your account using your PhoneNumber,Email via OTP(One Time Password), or otherwise, and we will ask you to provide certain information including your Email Address,First Name ,Last Name ,PhoneNumber ,etc. You may also provide us with information related to your use of Exuber, including how to contact you so that we can provide you with customer support. For example, you may send us an email with information relating to our app performance, or other issues.
                        </p>
                    <p>2) Information collected without a prompt:<br/>
                        Device Information: We may collect the below with respect to any device through which Exuber is used or accessed: Device identifiers, IP address, operating system, operating system version, device make, display features, content type and non-personal information related to ads, profile information.
                        </p>
                    </div>
                    <p>Exuber may access the information stored on your device. Exuber may also collect information provided by third parties who may disclose to Exuber personal or non-personal information collected by them. Exuber has no control over or responsibility in respect of third-party information practices or information collection methods.</p>
                    <p>Exuber may also collect tracking information and your location including cookies, etc which is completely the choice of the user.You may allow or deny Exuber to collect this information by changing the permissions in device settings.</p>
                    <p>Usage and Log Information. We collect service-related, diagnostic, and performance information. This includes information about your activity (such as how you use Exuber, how you interact with others using Exuber, etc.), log files, and diagnostics, crash information, website, and performance logs and reports, etc.</p>

                    <div className='priBox'>
                      <h3>Information We Do Not Collect</h3>
                      <p>We do not collect any Sensitive Personal Data or information such as password related to other services, financial information such as bank account or credit card or debit card or other payment instrument details, physical, physiological and mental health condition, sexual orientation, medical records and history, or biometric information.</p>
                      <h6>How We Use Information</h6>
                     <p>We may use all the information we have to help us operate, provide, improve, understand, customise, support and market Exuber.</p>
                     <p>Our Services: We operate and provide Exuber, including providing customer support, and improving, fixing, and customising Exuber by using the said information. We understand how users use Exuber, and analyse and use the information that we have to evaluate and improve Exuber, research, develop and test new services and features and conduct troubleshooting activities. We may also use your information to respond to you when you contact us. We may also use your information for (i) improving search results, content loading, performance, etc. and provide you better services and options and send you communications including email.</p>
                     <p>Safety and Security: We use information to try and ensure safety and security of our users and their information. We always verify accounts and activity, and promote security on and off Exuber, such as by investigating suspicious activity or violations of our policies and ensuring that Exuber is being used legally.</p>
                    </div>
                    <div className='priBox'>
                      <h3>Managing Your Information</h3>
                      <p>If you would like to manage, change, limit, or delete your information, we allow you to do that through the settings feature available on Exuber. You can change your settings to manage certain information available to other users. You can manage your contacts, groups, and broadcast lists, or use our block feature to manage the users with whom you want to communicate with.</p>

                      <h3>How We Share Information</h3>
                      <p>We may share your information in ways including the following:</p>
                      <p>With our business partners, affiliates, investors: Public information like your name, username and profile pictures and any content posted by you (subject to your personal settings).</p>
                      <p>We may share information about you with third party service providers who perform services on our behalf and optimise the search options to prevent fraud and for legal, safety and security reasons.</p>
                      <p>We may share information about you if we reasonably believe that disclosing the information is needed to:</p>
                      <p>(i) comply with any valid legal process, governmental request, or applicable law, rule, or regulation,</p>
                      <p>(ii) investigate, remedy, or enforce potential violations of this Policy, the Terms and Conditions and the Content Policy.</p>
                    </div>
                    <div className='priBox'>
                      <h3>Links</h3>
                      <p>Exuber may also include links of other websites which are governed by their respective privacy policies, and are beyond Exuber's control. Once the User leaves Exuber's app , use of any information provided by the User is governed by the privacy policy of the operator of the site which the User is visiting for which Exuber is not responsible. If the User can't find the privacy policy of any of these sites via a link from the site's homepage, the User may contact the site directly for more information.</p>
                    </div>
                    <div className='priBox'>
                      <h3>Security</h3>
                      <p>While we make efforts to employ industry-standard security measures to protect your information, we want to clarify that we cannot assume responsibility for its security or safety. It is important to acknowledge that unforeseen events beyond our control may still pose risks to the confidentiality and integrity of your information. Nonetheless, we strive to implement reasonable security measures to safeguard your data.</p>
                    </div>
                    <div className='priBox'>
                      <h3>Disclaimer</h3>
                      <p>Several deceptive emails, websites, blogs etc. claiming to be from or associated with Exuber may or are circulating on the Internet (“Deceptive Communication”). Such Deceptive Communication may include our logo, photos, links, content or other information. Some Deceptive Communication may call the User requesting the User to provide login name, password etc. or inform the User that the User has won a prize/ gift or provide a method to commit an illegal/ unauthorised act or deed or request detailed personal information or a payment of some kind. The sources and contents of these Deceptive Communications and accompanying materials are in no way associated with Exuber. For your own protection, we strongly recommend not responding to such Deceptive Communication. You agree that we shall not be liable for any loss, damage and harm that you may suffer by relying and/or acting upon such Deceptive Communications.</p>
                    </div>
                    <div className='priBox'>
                      <h3>Conditions of Use</h3>
                      <p>Exuber does not warrant that this portal, its servers, or email sent by us or on our behalf are virus free. Exuber will not be liable for any damages of any kind arising from the use of this portal, including, but not limited to compensatory, direct, indirect, incidental, punitive, special and consequential damages, loss of data, goodwill, business opportunity, income or profit, loss of or damage to property and claims of third parties. In no event will Exuber be liable for any damages whatsoever.</p>
                    </div>
                    <div className='priBox'>
                      <h3>Retention of Data</h3>
                      <p>Your personal information may be retained and may continue to be used until: (i) the relevant purposes for the use of your personal information described in this Privacy Policy are no longer applicable; and (ii) we are no longer required by applicable law, regulations, contractual obligations or legitimate business purposes to retain your personal information and the retention of your personal information is not required for the establishment, exercise or defense of any legal claim.</p>
                    </div>
                    <div className='priBox'>
                      <h3>Updates To Our Policy</h3>
                      <p>We may amend or update this Policy. We will provide you notice of amendments to this Policy, as appropriate, and update the “Last Modified” date at the top of this Policy. We constantly strive for excellence and improvements for this,the policy will be amended from time to time and the updated version can be referred to at “Last Modified” date .Your continued use of Exuber confirms your acceptance of this policy.</p>
                    </div>
                    <div className='priBox'>
                      <h3>Contact Us</h3>
                      <p>If you have questions about this Policy, email at <Link to="">support@exuber.ai</Link> Copyright © 2023 Exuber
                      </p>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
    </>
  )
}

export default PrivacyPolicy
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-unused-vars */
import React, { useDebugValue, useState } from "react";

import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import {
  isValid,
  validateRequire,
} from "../../components/Validation/InputValidation";
import { Spinner } from "react-bootstrap";
import TextToMusic from "./TextToMusic"
import axios from "axios";
import ConvertAudio from "./ConvertAudio";
import { SetUserData, SetpopupReducerData } from "../store/reducer";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-hot-toast";
import DubVideos from "./DubAIDubbing";
import DubTextToMusic from "./DubTextToMusic";
import { Link } from "react-router-dom";
import { SetCompletedNotifications, SetPendingNotifications } from "../store/reducer/extra";



function ConvertTextToMusic({ category = {}, setCategory }) {
  const[needhelp, setNeedHelp]=useState(false)
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  let secretKey = User?.data?.secret_key;
  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [texttomusic,setTextToMusic] = useState([]);
  const[convertedAudio,setConvertedAudio]=useState("");
  const dispatch = useDispatch();
  const [inputField, setInputField] = useState({
    category: category?._id,
    model_version: "",
    prompt: "",
    duration: ""
  });
  const [durationTouched, setDurationTouched] = useState(false);
  const navigate = useNavigate();

  const { model_version = "", prompt = "" ,duration= ""} = inputField;

  const handleChange = (e) => {
    setInputField({
      ...inputField,
      [e.target.name]: e.target.value,
    });
    setApiErrors({ message: "" });
    setApiErrors({});
  };

  const fetchCompleteList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.notification}/${userId}`,
        method: "GET",
      });
      if (response.status) {
        console.log(response.data.tasks);
        if (response.data.tasks.PENDING) {
          dispatch(SetPendingNotifications(response.data.tasks.PENDING));
        } else {
          dispatch(SetPendingNotifications([]));
        }
        dispatch(SetCompletedNotifications(response.data.tasks.COMPLETED));
      } 
    } catch (error) {
      throw error;
    }
  };

  const validateAll = () => {
    let err1 = {};
    err1.category = validateRequire(category);
    err1.prompt = validateRequire(prompt);
    err1.modelType = validateRequire(model_version);
    err1.music_duration= Number(duration) > 30 ? "Duration cannot be greater than 30" : "";

    return err1;
  };
  

  // const getConvertedAudio = async (params) => {

  //       // console.log('params', params)
  //   let response = await axios.get(`${process.env.REACT_APP_CONVERTED_VIDEO}?id=${params}`);

  //   if(response?.data?.status === "processing"){
  //     getConvertedAudio(params);
     
  //     setIsLoading(true);
      
      
  //   }
  //   else{
  //     setIsLoading(false);
  //     console.log('response?.data?.rep_url', response?.data?.rep_url)
  //     setConvertedAudio(response?.data?.rep_url)
     
  //   }
  //   console.log("response", convertedAudio);
  
  // };

  const handleLogin = (e) => {
    dispatch(
      SetpopupReducerData({
        modalType: "LOGIN",
        showModal: true,
      })
    );
  };
  

  const handleConvert = async (e) => {
    e.preventDefault();
    if (!userId) {
      handleLogin();
      return;
    }
    try {
      let err = validateAll();
      if (isValid(err)) {
        const payload = {
          // ...inputField,
          secret_key: secretKey,  
          category: category?._id,
          prompt: prompt,
          model_version: model_version,
          duration: duration,
          
          
        };
        setIsLoading(true);
        await API({
          url: apiURl.textToMusicNew,
          method: "POST",
          body: { ...payload },
          formData: false,
        }).then((data) => {
          if (data?.status || data?.status === "true") {
            // setInputField({
            //   uploaded_video: "",
            //   target_language: "",
            //   category: "",
            //   modelType: "",
            //   prompt: "",
            // });
            // setCategory({ _id: "", name: "dashboard" });
            // navigate(`/profile/${userId}`);
          //  setTextToMusic((prevlist)=>[...prevlist,payload])
          console.log('data------>soundscribe', data)
          fetchCompleteList()
              dispatch(
                SetpopupReducerData({
                  modalType: "PENDINGTASK",
                  showModal: true,
                  category: `${category.name}`
                })
              );
          // if (data?.data?.response?.error) {
          //   toast.error(data?.data?.response?.error);
          // } else {
          //   getConvertedAudio(data?.data?.response?.rep_id);
          // }
           
            dispatch(SetUserData(data?.data?.updatedUserInfo));
          } else {
            setApiErrors({ message: data?.message });
            setErrors({});
          }
        });
      } else {
        setErrors(err);
      }
    } catch (error) {
      setApiErrors({ message: error?.message });
    } finally {
      setIsLoading(false);
    }
  };



  return (
    <>
       {needhelp? <DubTextToMusic setNeedHelp ={setNeedHelp}/> : <>
      <h2 className="text-center text-white mb-4">Text to Music</h2>
      <div className="refernceAudio">
      {/* <img src="/images/coming-soon.svg"></img> */}
       <form id="contact" method="post" className="form" role="form">
          <div className="row">
            <div className="col-lg-12 form-group pb-3">
            <div className="d-flex justify-content-between">
              <label htmlFor="audio_text" className="form-label">
                Audio Text <span className="text-danger"> *</span>
              </label>
              <button onClick={()=>setNeedHelp(true)} className="needHelp">Need Help?</button>
              </div>
              <textarea
                className="form-control"
                type="text"
                id="audio_text"
                name="prompt"
                value={prompt}
                rows={8}
                onChange={handleChange}
              />
              {errors.prompt ? (
                <span className="text-danger" style={{ fontSize: "14px" }}>
                  {errors.prompt}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="modelType" className="form-label">
                Genre <span className="text-danger"> *</span>
              </label>
              <select
                className="form-select"
                onChange={handleChange}
                value={model_version}
                name="model_version"
              >
                <option value={""}>Select Genre</option>
                <option value={"melody"} title="Melody. A 1.5 billion parameter model that you can prompt with both text and audio">Melody</option>
                <option value={"encode-decode"}title="Large. A 3.5 billion parameter model that you can prompt with text">Encode Decode</option>
                <option value={"large"} title="If set to 'encode-decode', the audio specified via 'input_audio' will simply be encoded and then decoded.">Large</option>
              </select>
              {errors.modelType ? (
                <span className="text-danger" style={{ fontSize: "14px" }}>
                  {errors.modelType}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="modelType" className="form-label">
               Select Duration<span className="text-danger"> *</span>
              </label>
              <input
               className="form-control"
               type="text"
               value={duration}
               name="duration"
               onChange={handleChange}
               
               />
               <span >* maximum: 30</span>
            {errors.music_duration ? (
                <span className="text-danger" style={{ fontSize: "14px" }}>
                  {errors.music_duration}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          {apiErrors.message ? (
            <span className="text-danger text-center">{apiErrors.message}</span>
          ) : (
            ""
          )}

          <div className="row">
            <div className="col-xs-12 col-md-12 form-group synBtn text-center">
              {isLoading ? (
                <button className="btn btn-primary mt-4">
                  <Spinner animation="border" size="lg" /> Processing...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary mt-4 bt-soundsScribe"
                  onClick={handleConvert}
                  disabled={isLoading}
                >
                  <img src="/images/soundwave.png" alt="" /> Convert Now
                </button>
              )}

              {/* <button className="" type="">
            <img src="/images/soundwave.png" /> synthesize
          </button> */}
            </div>
          </div>
        </form> 
      </div>
      <div className="text-center">
        <Link to= "" className="seeTitle" onClick={() => setCategory({ _id: "", name: "dashboard" })} >
          See All Music in Dashboard
        </Link>
      </div>
      <div className="text-center">
        <a class="seeTitle" href=""></a>
      </div>
     {convertedAudio && 
      <ConvertAudio outputAudio={convertedAudio} />
     }
     </>}
    </>
  );
}

export default ConvertTextToMusic;

import React from 'react'
import { BsEnvelope,BsTelephonePlus,BsPinMap } from "react-icons/bs";
import { Link } from 'react-router-dom';
function ContactAddress() {
  return (
    <>
        <div className='cnAddress text-center'>
        <h1>Connect with Exuber: We're Here to Help and Listen!</h1>
        <p>Let's get the Show on the Road. Contact us Today!</p>
        <ul className='d-none'>
            <li className='d-flex align-items-center'>
                <div className='img'><BsEnvelope/></div>
                <div className='text'>
                    <span>Email Us</span>
                    <Link to="">turnkey@turnkeytix.com</Link>
                </div>
            </li>
            <li className='d-flex align-items-center d-none'>
                <div className='img'><BsTelephonePlus/></div>
                <div className='text'>
                    <span>Call Us</span>
                    <Link to="">+1 (888) 553-46-11</Link>
                </div>
            </li>
            <li className='d-flex align-items-center d-none'>
                <div className='img'><BsPinMap/></div>
                <div className='text'>
                    <span>Visit Us</span>
                    <Link to="">5687, Business Avenue, New York, USA 5687</Link>
                </div>
            </li>
        </ul>
        </div>
    </>
  )
}

export default ContactAddress
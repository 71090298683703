import React from 'react'

function InteriorDesigne() {
  return (
    <>




        <h1 data-aos="zoom-in">Exuber's Interior Designer.</h1>
        <iframe className='textToVideo'
            src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/0387b8597c9a48afd21415938369b0d6/iframe?muted=true&loop=true&autoplay=true&controls=false"
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
        {/* <iframe className='textToVideo'
            src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/22028b41b02d9c3a5f55bd20cf5fe471/iframe?muted=true&loop=true&autoplay=true&controls=false"
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe> */}

        <p data-aos="zoom-in">Dive into a world where your imagination merges with technology. Exuber's Interior Design AI tool allows you to visualize and reshape your home's interiors, turning your design fantasies into realistic visualizations.</p>
        <div className='row'>
            <div className='col-xl-6' data-aos="zoom-in">
                <h4>Personalized Design Realization:</h4>
                <p>No more guessing games! Upload your room's photo, provide your design preferences, and watch as our AI transforms it according to your vision.</p>
            </div>
            <div className='col-xl-6' data-aos="zoom-in">
                <h4>Efficient Iterations:</h4>
                <p>Tweak, re-imagine, and refine your designs with ease. Whether you lean towards positive enhancements or wish to remove certain elements, our tool adapts, offering you a canvas to perfect your interior dreams.</p>
            </div>
        </div>

    
    </>
  )
}

export default InteriorDesigne
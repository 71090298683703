import React, { useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import { BiSearchAlt2 } from "react-icons/bi";
import UploadFile from "./UploadFile";
import { BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";

import { useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  isValid,
  validateEmail,
  validateName,
} from "../Validation/InputValidation.js";
import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";

function CreatUser({ viewData, setViewData, users, setOrgUsers }) {
  const orgId = viewData?._id;

  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState({ message: "" });
  const { User = {} } = useSelector((state) => state);
  const [orgListing, SetorgListing] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  console.log(users, "apiErrors");

  let userId = User?.data?._id;
  const [inputField, setInputField] = useState({
    name: "",
    email: "",
    roleType: "Devloper",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { name = " ", email = " ", roleType = " " } = inputField;
  // console.log(name,email,roleType,"userdata");
  const [userDeleteId, setUserDeleteId] = useState();
  const [Delete, setDelete] = useState([]);

  console.log(Delete, "Delete");
  console.log(userDeleteId, "userdeleteid");
  console.log(orgId, "orgIdorgId");

  const handlechange = (e) => {
    setInputField({
      ...inputField,
      [e.target.name]: e.target.value,
    });
    handleValidate(e);
    setApiErrors({ message: "" });
  };

  const handleValidate = (e) => {
    const errors1 = {};
    switch (e.target.name) {
      case "name":
        errors1.name = validateName(e.target.value);
        break;
      case "email":
        errors1.email = validateEmail(e.target.value);
        break;
      default:
        break;
    }
    setErrors(errors1);
  };

  const validateAll = () => {
    let err1 = {};
    err1.name = validateName(name);
    err1.email = validateEmail(email);
    return err1;
  };

  const fetchUserData = async () => {
    console.log("orrrrrr", orgId);
    try {
      //   const response = await axios.get(
      //   `${process.env.REACT_APP_API_BACKEND_URL}/v1/user/doc/${userId}/${orgId}`
      // );
      await API({
        url: `/v1/user/doc/${orgId}`,
        method: "GET",
      }).then((resp) => {
        console.log(resp);
        setOrgUsers(resp?.data?.orgUser);
        // SetorgListing(resp?.data["0"].orgUsers);
        setUserDeleteId(resp?.data["0"].userId._id);
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (orgId) {
      fetchUserData();
    }
  }, [orgId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let err = validateAll();
      console.log(err, "apiURL_1", inputField);

      if (isValid(err)) {
        const apiURL_1 = `${process.env.REACT_APP_API_BACKEND_URL}/v1/add/orgUser/new
        `;
        const requestData = {
          userId: userId,
          orgId: orgId,
          orgUser: [
            {
              name: name,
              email: email,
              roleType: roleType,
            },
          ],
        };
        const response = await axios.post(apiURL_1, requestData);

        console.log("response use data:", response.data);

        if (response.status) {
          console.log("usererewrr", response);
          toast.success(response?.data?.message);
          setShow(false);
          setIsLoading(false);
          fetchUserData();
          setInputField("");
        } else {
          console.log("response use data:", response);

          setApiErrors({ message: response.message });
          toast.error(response?.message);
        }
      }
    } catch (error) {
      setApiErrors({ message: error.message });
      toast.error(error.message);

      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  const searchDocuments = async (searchQuery) => {
    try {
      await API({
        url: `/v1/doc/name?userId=${userId}&orgId=${orgId}&name=${searchQuery}`,
        method: "GET",
      }).then((resp) => {
        if (resp) {
          console.log(resp, "datauserserach");
          setOrgUsers(resp?.data[0]?.orgUsers);
        } else {
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const handleCheckboxClick = (docId) => {
    setDelete((prevDelete) =>
      prevDelete.includes(docId)
        ? prevDelete.filter((currentId) => currentId !== docId)
        : [...prevDelete, docId]
    );
  };

  const handleDelete = async () => {
    try {
      if (Delete.length > 0) {
        const payload = {
          orgUserId: [...Delete],
          userId: userId,
          orgId: orgId,
          isDeleted: true,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_API_BACKEND_URL}/v1/deleteUserOrg`,
          payload
        );
        if (response.status == true) {
          console.log(response, "data");
          fetchUserData();
          setDelete([]);
          toast.success("User deleted successfully");
        } else {
          fetchUserData();
          setDelete([]);
          toast.error(response?.data?.message);
        }
        setSelectedUserIds([]);
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      toast.success(error);
    }
  };

  const deleteDocument = async (orgId) => {
    try {
      const data = {
        orgUserId: orgId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_BACKEND_URL}/v1/upload/doc/${userDeleteId}`,
        data
      );
      if (response.status) {
        fetchUserData();
        console.log("Organization deleted successfully");
        toast.success("Organization deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      toast.success(error);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <div className="docSearch">
            <button>
              <BiSearchAlt2 onClick={() => searchDocuments(searchQuery)} />
            </button>
            <input
              type="text"
              placeholder=""
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                searchDocuments(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-md-3">
          <button className="creatNew" onClick={() => setShow(true)}>
            Add New User{" "}
            <span>
              <FaPlus />
            </span>
          </button>
        </div>
        <caption className="text-light mt-2">List of users</caption>
        <div className="col-md-12 mt-4">
          <div className="uploadTable">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Role</th>
                  <th scope="col">
                    {" "}
                    &nbsp;
                    {Delete.length > 1 && <BsTrash onClick={handleDelete} />}
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.length > 0 ? (
                  users?.map(
                    (ele) =>
                      !ele.isDeleted && (
                        <tr key={ele._id}>
                          <td>
                            <div className="d-flex align-items-center align-self-stretch">
                              <div className="d-flex align-items-center align-self-stretch">
                                <input
                                  type="checkbox"
                                  onClick={(e) => handleCheckboxClick(ele._id)}
                                />
                                {ele.name}
                              </div>
                            </div>
                          </td>

                          <td>{ele.email}</td>
                          <td>{ele.roleType}</td>
                          {Delete.length === 1 && Delete.includes(ele._id) ? (
                            <td>
                              <Link to="#" onClick={() => handleDelete()}>
                                <BsTrash />
                              </Link>
                            </td>
                          ) : (
                            <td>&nbsp;</td>
                          )}
                        </tr>
                      )
                  )
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center text-danger">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="md"
        // centered
        className="modalForm"
      >
        <Modal.Header closeButton>
          <h4>Add New User</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="form">
            <div class="mb-4">
              <input
                type="text"
                class="form-control"
                id="name"
                name="name"
                placeholder="User name"
                value={name}
                onChange={handlechange}
              />
              {errors.name ? (
                <span className="text-danger" style={{ fontSize: "14px" }}>
                  {errors.name}
                </span>
              ) : (
                ""
              )}
            </div>
            <div class="mb-4">
              <input
                type="email"
                class="form-control"
                id="Email"
                name="email"
                placeholder="Enter your email"
                value={email}
                autoComplete={false}
                onChange={(e) => {
                  if (e.target.value.includes(" ")) {
                    e.target.value = e.target.value.replace(" ", "");
                  }
                  handlechange(e);
                }}
              />
              {errors.email ? (
                <span className="text-danger" style={{ fontSize: "14px" }}>
                  {errors.email}
                </span>
              ) : (
                ""
              )}
            </div>
            {/* <div class="mb-4">
              <input type="text" class="form-control" id="organizationName" placeholder="Role" />
            </div> */}
            <div class="mb-4">
              <select
                className="form-select"
                name="roleType"
                id="roleType"
                value={roleType}
                onChange={handlechange}
              >
                <option>Devloper</option>
                <option>Manager</option>
                <option>Tester</option>
                <option>Designer</option>
                <option>Other</option>
              </select>
            </div>
            <div className="text-center py-3">
              <button
                className="viewBtn blue px-4 py-2 m-0"
                onClick={handleSubmit}
              >
                Add
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreatUser;

// import React, { useEffect } from "react";
// import { FaPlus } from "react-icons/fa6";
// import { BiSearchAlt2 } from "react-icons/bi";
// import UploadFile from "./UploadFile";
// import { BsTrash } from "react-icons/bs";
// import { Link } from "react-router-dom";

// import { useState } from "react";

// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// import {
//   isValid,
//   validateEmail,
//   validateName,
// } from "../Validation/InputValidation.js";
// import { API } from "../apiwrapper";
// import { apiURl } from "../store/actions";
// import { toast } from "react-toastify";
// import axios from "axios";
// import { useSelector } from "react-redux";

// function CreatUser({ viewData, setViewData, users, setOrgUsers }) {
//   const orgId = viewData?._id;
//   console.log(users,"viewDataviewData");

//   const [show, setShow] = useState(false);
//   const [errors, setErrors] = useState({});
//   const [apiErrors, setApiErrors] = useState({ message: "" });
//   const { User = {} } = useSelector((state) => state);
//   const [orgListing, SetorgListing] = useState();
//   const [searchQuery, setSearchQuery] = useState();
//   const [selectedUserIds, setSelectedUserIds] = useState([]);
//   console.log(apiErrors, "apiErrors");

//   let userId = User?.data?._id;
//   const [inputField, setInputField] = useState({
//     name: "",
//     email: "",
//     roleType: "Devloper",
//   });
//   const [isLoading, setIsLoading] = useState(false);
//   const { name = " ", email = " ", roleType = " " } = inputField;
//   // console.log(name,email,roleType,"userdata");
//   const [userDeleteId, setUserDeleteId] = useState();
//   const [Delete, setDelete] = useState([]);

//   console.log(Delete, "Delete");
//   console.log(userDeleteId, "userdeleteid");
//   console.log(orgId, "orgIdorgId");

//   const handlechange = (e) => {
//     setInputField({
//       ...inputField,
//       [e.target.name]: e.target.value,
//     });
//     handleValidate(e);
//     setApiErrors({ message: "" });
//   };

//   const handleValidate = (e) => {
//     const errors1 = {};
//     switch (e.target.name) {
//       case "name":
//         errors1.name = validateName(e.target.value);
//         break;
//       case "email":
//         errors1.email = validateEmail(e.target.value);
//         break;
//       default:
//         break;
//     }
//     setErrors(errors1);
//   };

//   const validateAll = () => {
//     let err1 = {};
//     err1.name = validateName(name);
//     err1.email = validateEmail(email);
//     return err1;
//   };

//   const fetchUserData = async () => {
//     console.log("orrrrrr", orgId);
//     try {
//       //   const response = await axios.get(
//       //   `${process.env.REACT_APP_API_BACKEND_URL}/v1/user/doc/${userId}/${orgId}` http://localhost:3001/v1/upload/doc?email=anil1900@yopmail.com&userId=651ff20cf85cc538395e1cc5
//       // );
//       await API({
//         url: `/v1/user/doc/${orgId}`,
//         method: "GET",
//       }).then((resp) => {
//         console.log(resp, "ddddddddddddddddddd1");
//         setOrgUsers(resp?.data?.orgUser);
//         // SetorgListing(resp?.data["0"].orgUsers);
//         setUserDeleteId(resp?.data["0"].userId._id);
//       });
//     } catch (error) {
//       throw error;
//     }
//   };

//   useEffect(() => {
//     if (orgId) {
//       fetchUserData();
//     }
//   }, [orgId]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       setIsLoading(true);
//       let err = validateAll();
//       console.log(err, "apiURL_1", inputField);

//       if (isValid(err)) {
//         const apiURL_1 = `${process.env.REACT_APP_API_BACKEND_URL}/v1/add/orgUser/new
//         `;
//         const requestData = {
//           userId: userId,
//           orgId: orgId,
//           orgUser: [
//             {
//               name: name,
//               email: email,
//               roleType: roleType,
//             },
//           ],
//         };
//         const response = await axios.post(apiURL_1, requestData);

//         console.log("response use data:", response.data);

//         if (response.status) {
//           console.log('usererewrr', response)
//           toast.success(response?.data?.message);
//           setShow(false);
//           setIsLoading(false);
//           fetchUserData();
//         } else {
//           console.log("response use data:", response);

//           setApiErrors({ message: response.message });
//           toast.error(response?.message);
//         }
//       }
//     } catch (error) {
//       setApiErrors({ message: error.message });
//       toast.error(error.message);

//       console.error("Error:", error);
//       setIsLoading(false);
//     }
//   };

//   const searchDocuments = async (searchQuery) => {
//     try {
//       const { data } = await axios.get(
//         `${process.env.REACT_APP_API_BACKEND_URL}/v1/user/search?query=${searchQuery}&id=${userId}`
//       );
//       if (data.status) {
//         SetorgListing(data.data[0].organizationdata);
//         console.log("Search ashish results:", data);
//         setOrgUsers(data?.data);
//       }
//     } catch (error) {
//       console.error("Error searching documents:", error);
//     }
//   };

//   const handleCheckboxClick = (docId) => {
//     setDelete((prevDelete) =>
//       prevDelete.includes(docId)
//         ? prevDelete.filter((currentId) => currentId !== docId)
//         : [...prevDelete, docId]
//     );
//   };

//   const handleDelete = async () => {
//     try {
//       if (Delete.length > 0) {
//         const payload = {
//           orgUserId: [...Delete],
//           userId: userId,
//           orgId: orgId,
//           isDeleted: true,
//         };
//         const response = await axios.post(
//           `${process.env.REACT_APP_API_BACKEND_URL}/v1/deleteUserOrg`,
//           payload
//         );
//         if (response.status) {
//           console.log(response);
//           fetchUserData();
//           setDelete([]);
//           toast.success("User deleted successfully");
//         }
//         setSelectedUserIds([]);
//       }
//     } catch (error) {
//       console.error("Error deleting document:", error);
//       toast.success(error);
//     }
//   };

//   const deleteDocument = async (orgId) => {
//     try {
//       const data = {
//         orgUserId: orgId,
//       };

//       const response = await axios.post(
//         `${process.env.REACT_APP_API_BACKEND_URL}/v1/upload/doc/${userDeleteId}`,
//         data
//       );
//       if (response.status) {
//         fetchUserData();
//         console.log("Organization deleted successfully");
//         toast.success("Organization deleted successfully");
//       }
//     } catch (error) {
//       console.error("Error deleting document:", error);
//       toast.success(error);
//     }
//   };

//   return (
//     <>
//       <div className="row">
//         <div className="col-md-9">
//           <div className="docSearch">
//             <button>
//               <BiSearchAlt2 onClick={() => searchDocuments(searchQuery)} />
//             </button>
//             <input
//               type="text"
//               placeholder=""
//               value={searchQuery}
//               onChange={(e) => {
//                 setSearchQuery(e.target.value);
//                 searchDocuments(e.target.value);
//               }}
//             />
//           </div>
//         </div>
//         <div className="col-md-3">
//           <button className="creatNew" onClick={() => setShow(true)}>
//             Add New User{" "}
//             <span>
//               <FaPlus />
//             </span>
//           </button>
//         </div>
//         <caption className="text-light mt-2">List of users</caption>
//         <div className="col-md-12 mt-4">
//           <div className="uploadTable">
//             <table class="table">
//               <thead>
//                 <tr>
//                   <th scope="col">Name</th>
//                   <th scope="col">Email</th>
//                   <th scope="col">Role</th>
//                   <th scope="col">
//                     {" "}
//                     &nbsp;
//                     {Delete.length > 1 && (
//                       <BsTrash onClick={handleDelete} />
//                     )}
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {users?.map(
//                   (ele) =>
//                     !ele.isDeleted && (
//                       <tr key={ele._id}>
//                         <td>
//                           <div className="d-flex align-items-center align-self-stretch">
//                             <div className="d-flex align-items-center align-self-stretch">
//                               <input
//                                 type="checkbox"
//                                 onClick={(e) => handleCheckboxClick(ele._id)}
//                               />
//                               {ele.name}
//                             </div>
//                           </div>
//                         </td>

//                         <td>{ele.email}</td>
//                         <td>{ele.roleType}</td>
//                         {Delete.length === 1 && Delete.includes(ele._id) ? (
//                           <td>
//                             <Link to="#" onClick={() => handleDelete()}>
//                               <BsTrash />
//                             </Link>
//                           </td>
//                         ) : (
//                           <td>&nbsp;</td>
//                         )}
//                       </tr>
//                     )
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>

//       <Modal
//         show={show}
//         onHide={() => setShow(false)}
//         size="md"
//         // centered
//         className="modalForm"
//       >
//         <Modal.Header closeButton>
//           <h4>Add New User</h4>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="form">
//             <div class="mb-4">
//               <input
//                 type="text"
//                 class="form-control"
//                 id="name"
//                 name="name"
//                 placeholder="User name"
//                 value={name}
//                 onChange={handlechange}
//               />
//               {errors.name ? (
//                 <span className="text-danger" style={{ fontSize: "14px" }}>
//                   {errors.name}
//                 </span>
//               ) : (
//                 ""
//               )}
//             </div>
//             <div class="mb-4">
//               <input
//                 type="email"
//                 class="form-control"
//                 id="Email"
//                 name="email"
//                 placeholder="Enter your email"
//                 value={email}
//                 autoComplete={false}
//                 onChange={(e) => {
//                   if (e.target.value.includes(" ")) {
//                     e.target.value = e.target.value.replace(" ", "");
//                   }
//                   handlechange(e);
//                 }}
//               />
//               {errors.email ? (
//                 <span className="text-danger" style={{ fontSize: "14px" }}>
//                   {errors.email}
//                 </span>
//               ) : (
//                 ""
//               )}
//             </div>
//             {/* <div class="mb-4">
//               <input type="text" class="form-control" id="organizationName" placeholder="Role" />
//             </div> */}
//             <div class="mb-4">
//               <select
//                 className="form-select"
//                 name="roleType"
//                 id="roleType"
//                 value={roleType}
//                 onChange={handlechange}
//               >
//                 <option>Devloper</option>
//                 <option>Manager</option>
//                 <option>Tester</option>
//                 <option>Designer</option>
//                 <option>Other</option>
//               </select>
//             </div>
//             <div className="text-center py-3">
//               <button
//                 className="viewBtn blue px-4 py-2 m-0"
//                 onClick={handleSubmit}
//               >
//                 Add
//               </button>
//             </div>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// }

// export default CreatUser;

/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-unused-vars */
import React, { useDebugValue, useState } from "react";

import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Route, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import {
  isValid,
  validateRequire,
} from "../../components/Validation/InputValidation";
import { Spinner } from "react-bootstrap";
import TextToMusic from "./TextToMusic";
import axios from "axios";
import ConvertAudio from "./ConvertAudio";
import { SetUserData, SetpopupReducerData } from "../store/reducer";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-hot-toast";
import DubAudios from "./DubAudio";
import ConvertImage from "./ConvertImage";
import { Link } from "react-router-dom";
import DubImage from "./DubImage";
import DubTextToImage from "./DubTextToImage";
import {
  SetCompletedNotifications,
  SetPendingNotifications,
} from "../store/reducer/extra";

function ConvertTextToImage({ category = {}, setCategory }) {
  const [needhelp, setNeedHelp] = useState(false);
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  let secretKey = User?.data?.secret_key;
  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [texttomusic, setTextToMusic] = useState([]);
  const [convertedAudio, setConvertedAudio] = useState("");
  const dispatch = useDispatch();
  const [inputField, setInputField] = useState({
    category: category?._id,
    modelType: "",
    prompt: "",
    image_height: "",
    image_width: "",
  });
  const [durationTouched, setDurationTouched] = useState(false);
  const navigate = useNavigate();

  const { prompt = "", image_height = "", image_width = "" } = inputField;

  const handleChange = (e) => {
    setInputField({
      ...inputField,
      [e.target.name]: e.target.value,
    });
    setApiErrors({ message: "" });
    setApiErrors({});
  };

  const fetchCompleteList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.notification}/${userId}`,
        method: "GET",
      });
      if (response.status) {
        console.log(response.data.tasks);
        if (response.data.tasks.PENDING) {
          dispatch(SetPendingNotifications(response.data.tasks.PENDING));
        } else {
          dispatch(SetPendingNotifications([]));
        }
        dispatch(SetCompletedNotifications(response.data.tasks.COMPLETED));
      }
    } catch (error) {
      throw error;
    }
  };

  const validateAll = () => {
    // let err1 = {};
    // err1.category = validateRequire(category);
    // err1.prompt = validateRequire(prompt);
    // // err1.modelType = validateRequire(modelType);
    // // err1.music_duration= Number(music_duration) > 30 ? "Duration cannot be greater than 30" : "";

    // return err1;
    let errors = {};
    if (!inputField.prompt.trim()) {
      errors.prompt = "Prompt is required";
    }
    if (!inputField.image_height) {
      errors.image_height = "Image height is required";
    }
    if (!inputField.image_width) {
      errors.image_width = "Image width is required";
    }

    return errors;
  };

  // const getConvertedAudio = async (params) => {

  //   // console.log('params', params)
  //   let response = await axios.get(`${process.env.REACT_APP_CONVERTED_IMAGE}?id=${params}`);

  //   if (response?.data?.status === "processing") {
  //     getConvertedAudio(params);

  //     setIsLoading(true);

  //   }
  //   else {
  //     setIsLoading(false);
  //     console.log('response?.data?.rep_url', response?.data?.rep_url)
  //     setConvertedAudio(response?.data?.rep_url)

  //   }
  //   console.log("response", convertedAudio);

  // };

  const handleLogin = (e) => {
    dispatch(
      SetpopupReducerData({
        modalType: "LOGIN",
        showModal: true,
      })
    );
  };

  const handleConvert = async (e) => {
    e.preventDefault();
    if (!userId) {
      handleLogin();
      return;
    }
    try {
      // let err = validateAll();
      // if (isValid(err)) {
      let err = validateAll();
      setErrors(err);

      // Check if errors object is empty (no errors)
      if (Object.keys(err).length === 0) {
        const payload = {
          ...inputField,
          secret_key: secretKey,
          category: category?._id,
        };
        setIsLoading(true);
        await API({
          url: apiURl.textToImageNew,
          method: "POST",
          body: { ...payload },
          formData: false,
        }).then((data) => {
          if (data?.status || data?.status === "true") {
            console.log("data------>texttoimage", data);
            if (data?.data?.response?.error) {
              toast.error(data?.data?.response?.error);
            } else {
              console.log(
                "data------>texttoimage",
                data,
                category,
                category.name
              );
              console.log(data?.data?.response, "rep_id");
              console.log(data.data);
              fetchCompleteList();
              dispatch(
                SetpopupReducerData({
                  modalType: "PENDINGTASK",
                  showModal: true,
                  category: `${category.name}`,
                })
              );
              console.log("data------>texttoimage", data);
              console.log(data, "daaaaaaaaaata", data?.data?.updatedUserInfo);
              // getConvertedAudio(data?.data?.response?.rep_id);
            }

            dispatch(SetUserData(data?.data?.updatedUserInfo));
          } else {
            setApiErrors({ message: data?.message });
            setErrors({});
          }
        });
      } else {
        setErrors(err);
      }
    } catch (error) {
      setApiErrors({ message: error?.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {needhelp ? (
        <DubTextToImage setNeedHelp={setNeedHelp} />
      ) : (
        <>
          <h2 className="text-center text-white mb-4">Text To Image</h2>
          <div className="refernceAudio">
            {/* <img src="/images/coming-soon.svg"></img> */}
            <form id="contact" method="post" className="form" role="form">
              <div className="row">
                <div className="col-lg-12 form-group pb-3">
                  <div className="d-flex justify-content-between">
                    <label htmlFor="audio_text" className="form-label">
                      Write Prompt <span className="text-danger"> *</span>
                    </label>
                    <button
                      onClick={() => setNeedHelp(true)}
                      className="needHelp"
                    >
                      Need Help?
                    </button>
                  </div>
                  <textarea
                    // className="form-control"
                    className={`form-control ${
                      errors.prompt ? "is-invalid" : ""
                    }`}
                    type="text"
                    id="prompt"
                    name="prompt"
                    value={prompt}
                    rows={8}
                    onChange={handleChange}
                  />
                  {/* {errors.prompt ? (
                  <span className="text-danger" style={{ fontSize: "14px" }}>
                    {errors.prompt}
                  </span>
                ) : (
                  ""
                )} */}
                  {errors.prompt && (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.prompt}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="modelType" className="form-label">
                    Select Width<span className="text-danger"> *</span>
                  </label>
                  <select
                    // className="form-select"
                    className={`form-select ${
                      errors.image_width ? "is-invalid" : ""
                    }`}
                    type="text"
                    value={image_width}
                    name="image_width"
                    onChange={handleChange}
                  >
                    <option value={""}>Select Width</option>
                    <option value={"384"}>384</option>
                    <option value={"512"}>512</option>
                    <option value={"576"}>576</option>
                    <option value={"640"}>640</option>
                    <option value={"704"}>704</option>
                    <option value={"768"}>768</option>
                    <option value={"960"}>960</option>
                    <option value={"1024"}>1024</option>
                    <option value={"1152"}>1152</option>
                    <option value={"1280"}>1280</option>
                    <option value={"1536"}>1536</option>
                    <option value={"1792"}>1792</option>
                    <option value={"2048"}>2048</option>
                  </select>

                  {/* {errors.width ? (
                  <span className="text-danger" style={{ fontSize: "14px" }}>
                    {errors.width}
                  </span>
                ) : (
                  ""
                )} */}
                  {errors.image_width && (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.image_width}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="modelType" className="form-label">
                    Select Height<span className="text-danger"> *</span>
                  </label>
                  <select
                    // className="form-select"
                    className={`form-select ${
                      errors.image_height ? "is-invalid" : ""
                    }`}
                    type="text"
                    value={image_height}
                    name="image_height"
                    onChange={handleChange}
                  >
                    <option value={""}>Select Height</option>
                    <option value={"384"}>384</option>
                    <option value={"512"}>512</option>
                    <option value={"576"}>576</option>
                    <option value={"640"}>640</option>
                    <option value={"704"}>704</option>
                    <option value={"768"}>768</option>
                    <option value={"960"}>960</option>
                    <option value={"1024"}>1024</option>
                    <option value={"1152"}>1152</option>
                    <option value={"1280"}>1280</option>
                    <option value={"1536"}>1536</option>
                    <option value={"1792"}>1792</option>
                    <option value={"2048"}>2048</option>
                  </select>

                  {/* {errors.height ? (
                  <span className="text-danger" style={{ fontSize: "14px" }}>
                    {errors.height}
                  </span>
                ) : (
                  ""
                )} */}
                  {errors.image_height && (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.image_height}
                    </span>
                  )}
                </div>
              </div>
              {apiErrors.message ? (
                <span className="text-danger text-center">
                  {apiErrors.message}
                </span>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col-xs-12 col-md-12 form-group synBtn text-center">
                  {isLoading ? (
                    <button className="btn btn-primary mt-4">
                      <Spinner animation="border" size="lg" /> Processing...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary mt-4 bt-Text-to-image"
                      onClick={handleConvert}
                      disabled={isLoading}
                    >
                      {/* <img src="/images/soundwave.png" alt="" />  */}
                      Convert Now
                    </button>
                  )}

                  {/* <button className="" type="">
            <img src="/images/soundwave.png" /> synthesize
          </button> */}
                </div>
              </div>
            </form>
          </div>
          <div className="text-center">
            <Link
              to=""
              className="seeTitle"
              onClick={() => setCategory({ _id: "", name: "dashboard" })}
            >
              See All Image in Dashboard
            </Link>
          </div>
          {convertedAudio && <ConvertImage outputImage={convertedAudio} />}
        </>
      )}
    </>
  );
}

export default ConvertTextToImage;

import React from 'react'

function StepCard() {
  return (
    <div className='row justify-content-center'>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/step-1.png'/></figure>
                <span>01</span>
                <p>Kick off the process by hitting the <i>"Browse"</i> button and exploring your device for the perfect video.</p>
            </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/step-2.png'/></figure>
                <span>02</span>
                <p>Select your video from the file explorer to start uploading it to Exuber.</p>
            </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/step-3.png'/></figure>
                <span>03</span>
                <p>Once uploaded, it's time for the fun part. Pick your desired language from our vast array to bring a new voice to your video.</p>
            </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/step-4.png'/></figure>
                <span>04</span>
                <p>Let's give your video some character! Pick a voice type from our diverse selection and hit play to preview how it sounds.</p>
            </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/step-5.png'/></figure>
                <span>05</span>
                <p>Everything set? Click <i>"Continue"</i> and watch as Exuber transforms your video with our groundbreaking AI dubbing feature.</p>
            </div>
        </div>
    </div>
  )
}

export default StepCard
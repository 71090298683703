import { createSlice } from "@reduxjs/toolkit";

export const extraSlice = createSlice({
  name: "extras",
  initialState: {},
  reducers: {
    forgotEmail: (state, { payload }) => {
      state.forgotEmail = payload.data;
    },
    seoContent: (state, {payload}) => {
      state.seoContent = payload.data;
    },
    optimizeContent: (state, {payload}) => {
      state.optimizeContent = payload.data;
    },
    seoKeywords: (state, {payload}) => {
      state.keywords = payload.data;
    },
    notificationsPending: (state, {payload}) => {
      state.pending = payload.data;
    },
    notificationsCompleted: (state, {payload}) => {
      state.completed = payload.data;
    }
  },
});

// Action creators are generated for each case reducer function
const { forgotEmail, seoContent, optimizeContent, seoKeywords, notificationsPending, notificationsCompleted } = extraSlice.actions;

export const SetForgetEmail = (data) => async (dispatch) => {
  console.log(data);
  if(!data) throw Error("Mising Data parameter");
  dispatch(forgotEmail({data}));
}

export const SetSEOContent = (data) => async (dispatch) => {
  console.log(data);
  if(!data) throw Error("Mising Data parameter");
  dispatch(seoContent({data}));
}
export const SetOptimizeContent = (data) => async (dispatch) => {
  console.log(data);
  if(!data) throw Error("Mising Data parameter");
  dispatch(optimizeContent({data}));
}

export const SetSEOKeywords = (data) => async (dispatch) => {
  console.log(data);
  if(!data) throw Error("Mising Data parameter");
  dispatch(seoKeywords({data}));
}

export const SetPendingNotifications = (data) => async (dispatch) => {
  console.log("PendingNotifications", data);
  if(!data) throw Error("Mising Data parameter");
  dispatch(notificationsPending({data}));
}

export const SetCompletedNotifications = (data) => async (dispatch) => {
  console.log("CompletedNotifications", data);
  if(!data) throw Error("Mising Data parameter");
  dispatch(notificationsCompleted({data}));
}


export default extraSlice.reducer;
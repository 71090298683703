import React from 'react'
import { Modal } from 'react-bootstrap'
import { BiCopy } from "react-icons/bi";

function CreateSecretkey( {show,HandleClose}) {
  return (
    <>
     <Modal className="shareSocial" show={show} onHide={() => HandleClose()}>
        <Modal.Header>
          <Modal.Title>Create new secret key</Modal.Title>
            <button className="bgNone" onClick={() => HandleClose()}>
            <img src="/images/closeBtn.png" />
          </button>
        </Modal.Header>
        <Modal.Body>
        <div className="shareKey">
            <label>Name <span>Optional</span></label>
            <div className='d-flex'>
                <input type='text' placeholder=''/>
                <button className='copyBtn'><BiCopy/></button>
            </div>
            <div className='footerBtn'>
                <button className='cancel' onClick={() => HandleClose()}>Cancel</button>
                <button>Create secret key</button>
            </div>
        </div>
        
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CreateSecretkey
import { BASECONFIG } from "../config";

const api = async ({
  body,
  headers = {},
  method,
  signal,
  url,
  formData = false,
}) => {
  let token = localStorage.getItem("token");
  console.log(' body',  body)
  headers["Token"] = `${token}`;
  headers["Access-Control-Allow-Origin"] = "*";
  headers["Authorization"] = "Bearer 5qYWluQHNvdXJjZXNvZnRzb2x1dGlvbnMuY29";
  if (!formData) {
    headers["content-type"] = "application/json";
  }
  // else{
  //  headers ['Content-Type'] =  'multipart/form-data';
  // }
 console.log('formData', headers)
  try {
    const response = await fetch(BASECONFIG.BASE_URL + url, {
      method,
      headers,
      body: body ? (formData ? body : JSON.stringify(body)) : null,
      signal,
    });
    if (!response.ok) {
      throw await response.clone().json();
    }

    return response.clone().json();
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

export const allApi = async ({
  body,
  headers = {},
  method,
  signal,
  url,
  formData = false,
}) => {
  // headers['Access-Control-Allow-Origin'] = '*';
  console.log({ formData });
  try {
    return await fetch(url, {
      method,
      headers,
      body: body ? (formData ? body : JSON.stringify(body)) : null,
      signal,
    })
      .then((response) => {
        if (response.url.includes("X-Amz-Credential")) {
          return true;
        }
        return response.clone().json();
      })
      .then((data) => {
        console.log({ data });
        return data;
      });
  } catch (error) {
    throw Error(error);
  }
};

export const getAwsImageUrl = async (image, folder = "user") => {
  try {
    if (typeof image === "string") {
      return image;
    }
    let fd = new FormData();
    fd.append("image", image);
    const data = await allApi({
      url: `${BASECONFIG.AWS_URL}/aws/pre/${folder}`,
      method: "POST",
      //formData: true,
      headers: {
        "Content-Type": "application/json",
      },
      body: [
        {
          fieldname: "file",
          originalname: image?.name,
        },
      ],
    });
    await getFinalUrl(image, data?.result[0]?.file?.url, folder);
    return data?.result[0]?.file?.urlGet;
  } catch (error) {
    throw Error(error);
  }
};

const getFinalUrl = async (image, urls, folder) => {
  try {
    return await allApi({
      url: `${urls}`,
      method: "PUT",
      formData: true,
      body: image,
    }).then((data) => {
      console.log({ data });
      return true;
    });
    // return true;
  } catch (error) {
    throw Error(error);
  }
};
export const getAwsVideoUrl = async (image, folder = "user") => {
  console.log("image", image);
  try {
    if (typeof image === "string") {
      return image;
    }
    // let fd = new FormData();
    // fd.append("file", image);
    const data = await allApi({
      url: `${BASECONFIG.AWS_URL}/aws/pre/${folder}`,
      method: "POST",
      //formData: true,
      headers: {
        "Content-Type": "application/json",
      },
      body: [
        {
          fieldname: "file",
          originalname: image?.name,
        },
      ],
    });
    await getFinalUrl(image, data?.result[0]?.file?.url, folder);
    return data?.result[0]?.file?.urlGet;
  } catch (error) {
    throw Error(error);
  }
};

export default api;

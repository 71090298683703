import React from 'react'

function ImageStepCard() {
  return (
    <div className='row justify-content-center'>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/steponeimage.jpeg'/></figure>
                <span>01</span>
                <p>Craft Your Prompt: Begin by typing out the text or idea you wish to visualize.</p>
            </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/steptwoimage.jpeg'/></figure>
                <span>02</span>
                <p>Dimension Decisions: Choose the desired height and width for your image.</p>
            </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/stepthreeimage.jpeg'/></figure>
                <span>03</span>
                <p>Transformation Time: Simply click on 'Convert Now' and let our AI do its magic!.</p>
            </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/stepfourimage.jpeg'/></figure>
                <span>04</span>
                <p>Witness the Magic: Head over to your dashboard to preview and marvel at your newly created image.</p>
            </div>
        </div>
        {/* <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/step-5.png'/></figure>
                <span>05</span>
                <p>Everything set? Click <i>"Continue"</i> and watch as Exuber transforms your video with our groundbreaking AI dubbing feature.</p>
            </div>
        </div> */}
    </div>
  )
}

export default ImageStepCard
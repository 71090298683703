import React from 'react'

function AudioStepCard() {
  return (
    <div className='row justify-content-center'>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/steponeaudio.jpg'/></figure>
                <span>01</span>
                <p>Begin by typing the text you wish to transform into music. Whether it's poetry or a brand slogan, let your words take a musical form.</p>
            </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/steptwoaudio.jpg'/></figure>
                <span>02</span>
                <p> Choose the genre. From classical to pop, select the musical style that resonates with your text.</p>
            </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/stepthreeaudio.jpg'/></figure>
                <span>03</span>
                <p>Decide the duration. Craft a short jingle or a full-length piece; it's your creation.</p>
            </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/stepfouraudio.jpg'/></figure>
                <span>04</span>
                <p>Hit <i>'Convert Now'</i>, and let SoundScribe work its magic. Your text is now a unique piece of music!</p>
            </div>
        </div>
        {/* <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/step-5.png'/></figure>
                <span>05</span>
                <p>Everything set? Click <i>"Continue"</i> and watch as Exuber transforms your video with our groundbreaking AI dubbing feature.</p>
            </div>
        </div> */}
    </div>
  )
}

export default AudioStepCard
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "../css/Style.css";
import { API, getAwsVideoUrl } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import {
  isValid,
  validateRequire,
} from "../../components/Validation/InputValidation";
import VideoListings from "./VideoListings";

function ConvertVideo({category={}}) {
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  const [errors, setErrors] = useState({});

  const [apiErrors, setApiErrors] = useState({ message: "" });
  const [languages, setLanguage] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const[videolisting,setVideoListing] = useState([]);

  const [inputField, setInputField] = useState({
    uploaded_video: "",
    target_language: "",
    category: "",
    modelType: "",
    prompt: "",
  });
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();

  const handleChangeCategory = (val) => {
    setInputField({
      ...inputField,
      uploaded_video: "",
      target_language: "",
      category: val,
      modelType: "",
      prompt: "",
    });
    setApiErrors({ message: "" });
    setErrors({});
  };
  const {
    uploaded_video = "",
    target_language = "",
    // category = { _id: "", name: "" },
    modelType = "",
    prompt = "",
  } = inputField;

  const handleChange = (e) => {
    setInputField({
      ...inputField,
      [e.target.name]: e.target.value,
    });
    setApiErrors({ message: "" });
    setApiErrors({});
  };

  const fetchLanguage = async () => {
    try {
      await API({
        url: `${apiURl.languages}`,
        method: "GET",
      }).then((data) => {
        if (data?.status) {
          let allLang = data?.data || [];
          setLanguage(allLang);
        } else {
          setLanguage([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const fetchAllCategory = async () => {
    try {
      await API({
        url: `${apiURl.allCategory}`,
        method: "GET",
      }).then((data) => {
        if (data?.status) {
          console.log(data, "data");
          let allCat = data?.data || [];
          setAllCategory(allCat);
        } else {
          setAllCategory([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const validateAll = () => {
    let err1 = {};
    err1.category = validateRequire(category);
    if (category?.name === "video_dubbing") {
      err1.file = validateRequire(file);
      err1.target_language = validateRequire(target_language);
    } else if (category?.name === "text_to_audio") {
      err1.prompt = validateRequire(prompt);
    } else {
      err1.prompt = validateRequire(prompt);
      err1.modelType = validateRequire(modelType);
    }
    return err1;
  };
  const handleChangeFile = (e) => {
    var file = e.target.files[0];
    setFile(file);
    setErrors({ ...errors, file: "" });
  };

  // const handleSelectChange = (e) => {
  //   setTargetLanguage(e.target.value);
  //   setErrors({ ...errors, target_language: "" });
  // };

  const handleConvert = async (e) => {
    e.preventDefault();
    if (!userId) {
      navigate("/login");
      return;
    }
    try {
      let err = validateAll();
      if (isValid(err)) {
        let awsUrl = await getAwsVideoUrl(file, `${userId}/InputVideos/`);
        const urlParts = awsUrl.split("/");
        const video_filename = urlParts[urlParts.length - 1];
        const payload = {
          ...inputField,
          userId: userId,
          uploaded_video: awsUrl,
          category: category?._id,
          // target_language: target_language,
          // converted_video: "",
          video_conversion_status: "",
          audio_conversion_status: "",
          music_conversion_status: "",
          video_filename: video_filename,
        };

        let endUrl =
          category.name === "video_dubbing"
            ? apiURl.videoUpload
            : category?.name === "text_to_audio"
            ? apiURl.textToAudio
            : category?.name === "text_to_music"
            ? apiURl.textToMusic
            : apiURl.videoUpload;
        await API({
          url: endUrl,
          method: "POST",
          body: { ...payload },
          formData: false,
        }).then((data) => {
          if (data?.status || data?.status === "true") {
            setLoading(true);
            // setFileUrl(resp.data.url);
            // toast.success(data?.message);
            // navigate(`/profile/${userId}`);
            setVideoListing((prevlist)=>[...prevlist,payload])
           
          } else {
            setApiErrors({ message: data?.message });
            setErrors({});
          }
        });
      } else {
        setErrors(err);
      }
    } catch (error) {
      setApiErrors({ message: error?.message });
    }
  };
  console.log(inputField, "inpf");
  useEffect(() => {
    fetchLanguage();
    fetchAllCategory();
  }, []);
  return (
    <>
      <section className="mt-5 text-center  videoFile">
        <div className="container">
          <h3>Change Language of Video</h3>
          <p>Best video converter tool to convert any language video</p>

          <div className="row justify-content-center mt-4">
            <div className="col-6">
              <div className="outerBox">
                <div className="row pb-5">
                  {/* <div className="col-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="category"
                        id="category_name1"
                        value={"video_dubbing"}
                        checked={category === "video_dubbing"}
                        // onChange={(e) => setCategory_name(e.target.value)}
                        onChange={handleChangeCategory}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="category_name1"
                      >
                        Video Dubbing
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="category"
                        id="category_name2"
                        value={"text_to_audio"}
                        checked={category === "text_to_audio"}
                        // onChange={(e) => setCategory_name(e.target.value)}
                        onChange={handleChangeCategory}
                      />
                      <label className="form-check-label" htmlFor="category_name2">
                        Text To Audio
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="category"
                        id="category_name3"
                        value={"text_to_music"}
                        checked={category === "text_to_music"}
                        // onChange={(e) => setCategory_name(e.target.value)}
                        onChange={handleChangeCategory}
                      />
                      <label className="form-check-label" htmlFor="category_name3">
                        Text To Music
                      </label>
                    </div>
                  </div> */}

                  <div className="col-12">
                    <label htmlFor="formFile" className="form-label">
                      Convert Categoryes <span className="text-danger"> *</span>
                    </label>
                    <select
                      className="form-select"
                      onChange={(e) =>
                        handleChangeCategory({
                          _id: e.target.value,
                          name: allCategory?.find(
                            (ele) => ele._id === e.target.value
                          )?.name,
                        })
                      }
                      value={category?._id}
                      name="category"
                    >
                      <option value={""}>Select Categorie</option>
                      {allCategory?.map((ele) => (
                        <option key={ele._id} value={ele._id}>
                          {ele.name?.replace(/_/g, " ")}
                        </option>
                      ))}
                    </select>
                    {errors.category ? (
                      <span
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.category}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row align-items-start text-start">
                  {category.name === "video_dubbing" ? (
                    <>
                      <div className="col-6">
                        <label htmlFor="formFile" className="form-label">
                          Upload Video <span className="text-danger"> *</span>
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          id="formFile"
                          accept="audio/*,video/*"
                          onChange={handleChangeFile}
                        />
                        {errors.file ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "14px" }}
                          >
                            {errors.file}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-6">
                        <label htmlFor="formFile" className="form-label">
                          Target Language{" "}
                          <span className="text-danger"> *</span>
                        </label>
                        <select
                          className="form-select"
                          onChange={handleChange}
                          value={target_language}
                          name="target_language"
                        >
                          <option value={""}>Select Language</option>
                          {languages?.map((ele) => (
                            <option key={ele.code} value={ele.code}>
                              {ele.name}
                            </option>
                          ))}
                        </select>
                        {errors.target_language ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "14px" }}
                          >
                            {errors.target_language}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : category?.name === "text_to_audio" ? (
                    <>
                      <div className="col-12">
                        <label htmlFor="audio_text" className="form-label">
                          Audio Text <span className="text-danger"> *</span>
                        </label>
                        <textarea
                          className="form-control"
                          type="text"
                          id="audio_text"
                          name="prompt"
                          value={prompt}
                          rows={8}
                          onChange={handleChange}
                        />
                        {errors.prompt ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "14px" }}
                          >
                            {errors.prompt}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-6">
                        <label htmlFor="music_text" className="form-label">
                          Music Text <span className="text-danger"> *</span>
                        </label>
                        <textarea
                          className="form-control"
                          type="text"
                          id="music_text"
                          rows={4}
                          name="prompt"
                          value={prompt}
                          onChange={handleChange}
                        />
                        {errors.prompt ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "14px" }}
                          >
                            {errors.prompt}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-6">
                        <label htmlFor="modelType" className="form-label">
                          Genre* <span className="text-danger"> *</span>
                        </label>
                        <select
                          className="form-select"
                          onChange={handleChange}
                          value={modelType}
                          name="modelType"
                        >
                          <option value={""}>Select Model</option>
                          <option value={"melody"}>Melody</option>
                          <option value={"encode-decode"}>Encode Decode</option>
                          <option value={"large"}>Large</option>
                        </select>
                        {errors.modelType ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "14px" }}
                          >
                            {errors.modelType}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}
                  {apiErrors.message ? (
                    <span className="text-danger text-center">
                      {apiErrors.message}
                    </span>
                  ) : (
                    ""
                  )}
                  <div className="row mt-5">
                    <div className="col-6">
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </button>
                    </div>
                    <div className="col-6 text-end">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleConvert}
                      >
                        Convert Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
            <VideoListings listing={videolisting}/>
            <div className="col-12 text-center my-5">
              <div className="d-inline-block position-relative">
                {!loading ? <div className="custom-loader"></div> : ""}
                <iframe title="Preview" src={fileUrl} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ConvertVideo;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SetUserData, SetpopupReducerData } from "../store/reducer";
import { Dropdown, Modal } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { FaRegUser } from "react-icons/fa";
import { BiLogInCircle, BiMenuAltRight } from "react-icons/bi";
import WalletPopup from "../profile/WalletPopup";
import { UpdateUserPoints } from "../store/reducer/user";
import { BsFillBellFill } from "react-icons/bs";
import NotifyPendingCard from "./NotifyPendingCard";
import NotifyCompletedCard from "./NotifyCompletedCard";
import PendingTask from "../PopupModal/PendingTask";
import CompleteTask from "../PopupModal/CompleteTask";
import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import {
  SetCompletedNotifications,
  SetPendingNotifications,
} from "../store/reducer/extra";
import { gapi } from "gapi-script";

const pendingTasksStyles = {
  position: "absolute",
  right: "-6px",
  bottom: "8px",
  fontSize: "17px",
};

const Header = ({ tab, data }) => {
  console.log(data);
  const dispatch = useDispatch();
  const { User = {} } = useSelector((state) => state);
  const { Extra = {} } = useSelector((state) => state);
  let pendingtask = Extra?.pending;
  let completetask = Extra?.completed;
  console.log(Extra);
  console.log(User);
  const [showModal, setSHowModal] = useState(false);

  const [showNotify, setShowNotify] = useState(1);
  const [showPendingtsk, setShowPendingtsk] = useState(false);
  const [showCompletetsk, setShowCompletetsk] = useState(false);
  // const [pendingtask, setPendingTask] = useState([]);
  // const [completetask, setCompleteTask] = useState([]);

  console.log("User1234", User?.data);

  let userId = User?.data?._id;
  const location = useLocation();
  let path = location.pathname.split("/");
  console.log("path", path);
  const navigate = useNavigate();
  const handleShowLogin = () => {
    if (!User?.data?.jwtToken) {
      dispatch(
        SetpopupReducerData({
          modalType: "LOGIN",
          showModal: true,
        })
      );
    }
  };
  const handleShowSignUp = () => {
    if (!User?.data?.jwtToken) {
      dispatch(
        SetpopupReducerData({
          modalType: "SIGNUP",
          showModal: true,
        })
      );
    }
  };

  const Logout = (e) => {
    e.preventDefault();
    dispatch(SetUserData({}));
    localStorage.removeItem("token");
    localStorage.clear();
    navigate("/");
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const fetchCompleteList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.notification}/${userId}`,
        method: "GET",
      });
      if (response.status) {
        console.log(response.data.tasks);
        if (response.data.tasks.PENDING) {
          dispatch(SetPendingNotifications(response.data.tasks.PENDING));
        } else {
          dispatch(SetPendingNotifications([]));
        }
        dispatch(SetCompletedNotifications(response.data.tasks.COMPLETED));
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetchCompleteList();
  }, [data, userId]);

  return (
    <>
      {" "}
      <header className="headerHome headerTow">
        {/* Msg Box */}
        {/* <div className="alert textBlock fade show"></div> */}

        {showPendingtsk ? (
          <PendingTask
            show={showPendingtsk}
            HandleClose={() => setShowPendingtsk(false)}
          />
        ) : (
          ""
        )}
        {showCompletetsk ? (
          <CompleteTask
            show={showCompletetsk}
            HandleClose={() => setShowCompletetsk(false)}
          />
        ) : (
          ""
        )}
        <div className="headerTwo">
          <div className="container-fluid">
            <div className="row align-item-center justify-content-between">
              <div className="col-auto logo" onClick={reloadPage}>
                <Link to="/">
                  <img src="/images/logo.svg"></img>
                </Link>
              </div>

              <div className="col-auto d-flex align-items-center">
                <div className="loginBar order-xl-2">
                  <ul className="logins">
                    {tab !== "accounts" && (
                      <li>
                        {/* {(!User && !path.includes("profile")) ||
                          (!path.includes("homedemo") && ( */}
                        <Link
                          to="/AITool"
                          // to={User ? `/profile/${userId}` : "/"}
                          className="createAi"
                          onClick={handleShowLogin}
                        >
                          <img src="/images/plusIcon.png" /> Create <i>new</i>{" "}
                          <span>AI</span>
                        </Link>
                        {/* ))} */}
                      </li>
                    )}

                    {!User?.data?.jwtToken ? (
                      <>
                        <li>
                          <button
                            data-toggle="modal"
                            data-target="/loginModal"
                            onClick={handleShowLogin}
                          >
                            Login
                          </button>
                        </li>
                        <li
                          className="signUp"
                          data-toggle="modal"
                          data-target="/loginModal"
                          onClick={handleShowSignUp}
                        >
                          <button>Sign Up</button>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              className="nav-link dropdown-toggle userProfile"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img
                                src={User?.data?.image || "/images/profile.jpg"}
                                alt=""
                              />{" "}
                              <span>
                                {User?.data?.firstName && User?.data?.lastName
                                  ? `${User?.data?.firstName} ${User?.data?.lastName}`
                                  : "User"}{" "}
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu ul>
                              <DropdownItem
                                className="dropdown-item"
                                onClick={() =>
                                  navigate(`/profile/edit/${userId}`)
                                }
                              >
                                {" "}
                                <FaRegUser /> Profile
                              </DropdownItem>
                              <DropdownItem
                                className="dropdown-item"
                                data-toggle="modal"
                                data-target="/loginModal"
                                onClick={Logout}
                              >
                                <BiLogInCircle /> Logout
                              </DropdownItem>
                              <DropdownItem
                                className="dropdown-item"
                                data-toggle="modal"
                                data-target=""
                                onClick={() =>
                                  navigate(`/account/api-keys/${userId}`)
                                }
                              >
                                <BiLogInCircle /> View API keys
                              </DropdownItem>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                        {tab !== "accounts" && (
                          <li>
                            <button
                              className="points"
                              onClick={() => setSHowModal(true)}
                            >
                              <img src="/images/mvIcon.png" />
                              {User?.data?.points || 0}
                            </button>
                          </li>
                        )}
                        {tab !== "accounts" && (
                          <li className="position-relative notify">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                className="nav-link dropdown-toggle userProfile"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <BsFillBellFill
                                  className="position-relative"
                                  style={{ cursor: "pointer" }}
                                />
                                <span style={pendingTasksStyles}>
                                  {pendingtask?.length > 0 &&
                                  completetask?.length > 0
                                    ? pendingtask.length + completetask.length
                                    : 0}
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu ul>
                                <div className="mainNotify">
                                  <div className="d-flex justify-content-between align-items-center mb-lg-4 mb-3">
                                    <h2>Notifications</h2>
                                    <Link>See All</Link>
                                  </div>
                                  <div className="d-flex penToggal mb-4">
                                    <button
                                      className={
                                        showNotify === 1 ? "active" : ""
                                      }
                                      onClick={() => setShowNotify(1)}
                                    >
                                      Pending ({pendingtask?.length})
                                    </button>
                                    <button
                                      className={
                                        showNotify === 2 ? "active" : ""
                                      }
                                      onClick={() => setShowNotify(2)}
                                    >
                                      Completed ({completetask?.length})
                                    </button>
                                  </div>
                                  <div className="innMainNotfy">
                                    <div
                                      className={
                                        showNotify === 1
                                          ? "pending d-block"
                                          : "d-none"
                                      }
                                    >
                                      <NotifyPendingCard
                                        pendingtask={pendingtask}
                                      />
                                    </div>

                                    <div
                                      className={
                                        showNotify === 2
                                          ? "complete d-block"
                                          : "d-none"
                                      }
                                    >
                                      <NotifyCompletedCard
                                        completetask={completetask}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                        )}
                      </>
                    )}
                  </ul>
                </div>
                <div className="order-xl-1">
                  <div className="stellarnav">
                    <ul>
                      {/* <li onClick={reloadPage}>
                        <Link to="/">Home</Link>
                      </li> */}
                      <li className="">
                        <Link to="#">Product</Link>
                        <ul>
                          <li onClick={reloadPage}>
                            <Link to="/AITool/AI_Dubbing">Video Dubbing</Link>
                          </li>
                          <li onClick={reloadPage}>
                            <Link to="https://exuber.ai/blog/soundscribe/">
                              SoundScribe
                            </Link>
                          </li>
                          <li onClick={reloadPage}>
                            <Link to="https://exuber.ai/blog/text-to-image/">
                              Text to Image
                            </Link>
                          </li>
                          <li onClick={reloadPage}>
                            <Link to="https://exuber.ai/blog/text-to-video/">
                              Text to Music
                            </Link>
                          </li>
                          <li onClick={reloadPage}>
                            <Link
                              to=""
                              className="d-flex justify-content-between align-items-center"
                            >
                              ShopInVideo{" "}
                              <i className="ms-2 d-inline-block">Upcoming</i>
                            </Link>{" "}
                          </li>
                          <li onClick={reloadPage}>
                            <Link to="https://exuber.ai/blog/text-to-speech/">
                              Text to Speech
                            </Link>
                          </li>
                          <li onClick={reloadPage}>
                            <Link to="https://exuber.ai/blog/chatbot/">
                              ChatBot AI
                            </Link>
                          </li>
                          <li onClick={reloadPage}>
                            {/* <Link to="https://exuber.ai/blog/interiordesigner/">
                              Interior Designer
                            </Link> */}
                          </li>
                          <li onClick={reloadPage}>
                            <Link to="https://exuber.ai/blog/image-to-story/">
                              Image to Story
                            </Link>
                          </li>
                          <li onClick={reloadPage}>
                            <Link to="https://exuber.ai/blog/image-enhancer/">
                              Image Enhancer
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* <li className="" onClick={reloadPage}>
                        <Link to="" >Learn</Link>
                      </li> */}
                      <li className="" onClick={reloadPage}>
                        <Link to="/aboutus">About Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Modal size="lg" className="walletPopup" show={showModal}>
        <Modal.Header>
          <h2>Wallet</h2>
          <button className="bgNone" onClick={() => setSHowModal(false)}>
            <img src="/images/closeBtn.png" />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <WalletPopup setSHowModal={setSHowModal} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Header;

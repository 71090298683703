import React from "react";
// import "../App.css";
// import "../style.css";
// import 'bootstrap/dist/css/bootstrap.min.css';

function Services({title,description,list}) {


    return (
        <>
     
        <section id="hero" className="advanceTool firstSection d-none">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-xl-8">
                        <h3>{title}</h3>
                        <p>{description}</p>
                        <ul className="advanceToolList">
                            {list?.map((items) => {
                                return <>
                                <li >
                                    <h4>{items?.title}</h4>
                                    <p>{items?.description}</p>
                                </li>
                                </>
                            })}                                        
                        </ul>
                    </div>
                </div>
            </div>
        </section>
      
        </>
  );
}
export default Services;
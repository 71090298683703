import React from 'react'

function ShopInVideo() {
  return (
    <div>
      <h2 className="text-center text-white mb-4">Shop In Video</h2>
      <div className="refernceAudio text-center comingSoon">
      <img src="/images/coming-soon.svg"></img>
      </div>
    </div>
  )
}

export default ShopInVideo;

/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiURl } from "../store/actions";
import { API, getAwsVideoUrl } from "../apiwrapper";
import { isValid, validateRequire } from "../Validation/InputValidation";
import { useNavigate } from "react-router";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import ConvertedVideoList from "./ConvertedVideoList";
import { SetpopupReducerData } from "../store/reducer/PopupReducer";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-hot-toast";
import { SetUserData, UpdateUserPoints } from "../store/reducer";
import { Link } from "react-router-dom";
import DubAIDubbing from "./DubAIDubbing";

import { BsInfoCircle } from "react-icons/bs";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  SetCompletedNotifications,
  SetPendingNotifications,
} from "../store/reducer/extra";

function DisabledExample() {
  return (
    <OverlayTrigger overlay={<Tooltip>Arpit!</Tooltip>}>
      <span className="d-inline-block">
        <p style={{ pointerEvents: "none" }}>g</p>
      </span>
    </OverlayTrigger>
  );
}

function NaturalSpeech({ category = {}, setCategory }) {
  const { User = {} } = useSelector((state) => state);
  console.log("categoryyy", category);
  let userId = User?.data?._id;
  let secretKey = User?.data?.secret_key;
  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState({ message: "" });
  const [languages, setLanguage] = useState([]);
  const [categorizedLangauage, setCategorizedLanguage] = useState([]);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [videolisting, setVideoListing] = useState([]);
  const [convertedVideo, setConvertedVideo] = useState([]);
  const [thumbnail, setThumbnail] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState("");
  const [samplevoice, setSampleVoice] = useState("");
  const [voice, setVoice] = useState("");
  const dispatch = useDispatch();
  const [needhelp, setNeedHelp] = useState(false);
  const [audiofile, setAudioFile] = useState("");
  const [audiofileName, setAudioFileName] = useState("");

  const [inputField, setInputField] = useState({
    uploaded_video: "",
    target_language: "",
    category: "",
    // modelType: "",
    // prompt: "",
    custom_voice_path: "",
  });

  const navigate = useNavigate();

  const { target_language = "" } = inputField;

  const handleSignUp = (e) => {
    dispatch(
      SetpopupReducerData({
        modalType: "WALLET",
        showModal: true,
      })
    );
  };

  const handleLogin = (e) => {
    dispatch(
      SetpopupReducerData({
        modalType: "LOGIN",
        showModal: true,
      })
    );
  };

  const fetchCompleteList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.notification}/${userId}`,
        method: "GET",
      });
      if (response.status) {
        console.log(response.data.tasks);
        if (response.data.tasks.PENDING) {
          dispatch(SetPendingNotifications(response.data.tasks.PENDING));
        } else {
          dispatch(SetPendingNotifications([]));
        }
        dispatch(SetCompletedNotifications(response.data.tasks.COMPLETED));
      }
    } catch (error) {
      throw error;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "target_language") {
      fetchLanguageSet(value);
    } else if (name === "voice_name") {
      setSelectedVoice(value);
    }

    setInputField({
      ...inputField,
      [name]: value,
    });
    setApiErrors({ message: "" });
    setApiErrors({});
  };

  console.log("target_language", target_language);
  const fetchLanguage = async () => {
    try {
      await API({
        url: `${apiURl.languages}`,
        method: "GET",
      }).then((data) => {
        console.log("dataAAAAA", data);
        console.log("check--", data?.data);
        let newarr = data?.data;

        var obj = {};
        newarr?.map((i) => {
          {
            console.log("iiiii", i);
            obj[i["langCode"]] = i["langName"];
          }
        });
        console.log("objects----", obj);
        setVoice(obj);

        if (data?.status) {
          let allLang = data?.data || [];

          setLanguage(allLang);
        } else {
          setLanguage([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const fetchLanguageSet = async (langCode) => {
    try {
      await API({
        url: `${apiURl.languages}?langCode=${langCode}`,
        method: "GET",
      }).then((data) => {
        console.log("data", data);
        if (data?.status) {
          let allLang = data?.data || [];
          console.log("alllang", allLang);
          setCategorizedLanguage(allLang);
          fetchSampleVoice();
        } else {
          setCategorizedLanguage([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const fetchSampleVoice = async () => {
    var samplevoice = selectedVoice;
    if (samplevoice) {
      console.log("samplevoice", samplevoice.split("("));
      var result = samplevoice.split("(");
      var gender = result[1].replace(")", "");
      console.log("gender", gender);
    }
    // var cc= obj[target_language];
    console.log("cc", voice[target_language]);
    try {
      await API({
        url: `${apiURl.samplevoice}/${voice[target_language]}/${gender}/${result[0]}`,
        method: "GET",
      }).then((data) => {
        console.log("data", data);
        if (data?.status) {
          setSampleVoice(data?.data);
          // let allLang = data?.data || [];
          // console.log("alllang", allLang);
          // setCategorizedLanguage(allLang);
        } else {
          // setCategorizedLanguage([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };
  console.log("sampleVoice", samplevoice);

  const validateAll = () => {
    let err1 = {};
    err1.target_language = validateRequire(target_language);
    err1.file = validateRequire(file);
    // err1.category = validateRequire(category);
    // if (category?.name === "video_dubbing") {
    //   err1.file = validateRequire(file);
    //   err1.target_language = validateRequire(target_language);
    // } else if (category?.name === "text_to_audio") {
    //   err1.prompt = validateRequire(prompt);
    // } else {
    //   err1.prompt = validateRequire(prompt);
    //   err1.modelType = validateRequire(modelType);
    // }
    return err1;
  };

  const handleChangeFile = (e) => {
    var file = e.target.files[0];
    // var size = file.size / 1024 / 1024; // in MB
    // if (size > 10) {
    //   // setErrors({ ...errors, file: "File size exceeds 10MB. Please select a smaller file." });
    //   toast.error("File size exceeds 10MB. Please select a smaller file.");
    // } else {
    setFile(file);
    setFileName(file.name);
    setErrors({ ...errors, file: "" });
    // }
  };

  const handleAudioFile = (e) => {
    var file = e.target.files[0];

    setAudioFile(file);
    setAudioFileName(file.name);
    setErrors({ ...errors, file: "" });
  };

  // const handleSelectChange = (e) => {
  //   setTargetLanguage(e.target.value);
  //   setErrors({ ...errors, target_language: "" });
  // };

  const getConvertedVideo = async (params) => {
    let response = await axios.get(
      `${process.env.REACT_APP_CONVERTED_VIDEO}?id=${params}`
    );

    if (response?.data?.status === "processing") {
      getConvertedVideo(params);
      setIsLoading(true);
    } else if (response?.data?.rep_url) {
      setIsLoading(false);
      setConvertedVideo(response?.data?.rep_url);
    } else if (response?.data?.error) {
      toast.error(response?.data?.error);
      setIsLoading(false);
    }
  };

  const handleConvert = async (e) => {
    e.preventDefault();
    if (!userId) {
      handleLogin();
      return;
    }
    if (User?.data?.points !== 0) {
      setIsLoading(true);
      try {
        let err = validateAll();
        if (isValid(err)) {
          let awsUrl = await getAwsVideoUrl(file, `${userId}/InputVideos/`);
          let audioUrl = await getAwsVideoUrl(
            audiofile,
            `${userId}/InputAudios/`
          );
          console.log(audioUrl, "audioUrl");
          const urlParts = awsUrl.split("/");
          const video_filename = urlParts[urlParts.length - 1];
          console.log("video_filename", video_filename);

          const payload = {
            ...inputField,
            // userId: userId,
            secret_key: secretKey,
            video_filepath: awsUrl,
            category: category?._id,
            target_language: target_language,
            converted_video: "",
            // video_conversion_status: "",
            // audio_conversion_status: "",
            // music_conversion_status: "",
            // thumbnail: thumbnail,
            video_filename: video_filename,
            custom_voice_path: audioUrl,
            voice_name: selectedVoice ? selectedVoice : "",
          };
          console.log("payload", payload);

          setIsLoading(true);
          await API({
            url: apiURl.videoUploadNew,
            method: "POST",
            body: { ...payload },
            formData: false,
          }).then((data) => {
            if (data?.status || data?.status === "true") {
              // setInputField({
              //   uploaded_video: "",
              //   target_language: "",
              //   category: "",
              //   modelType: "",
              //   prompt: "",
              // });
              // setCategory({ _id: "", name: "dashboard" });
              console.log("dataN1", data);
              //******************** */ dispatch(SetUserData(data?.data?.updatedUserInfo));
              // navigate(`/profile/${userId}`);
              //************************ */ setVideoListing((prevlist) => [...prevlist, payload]);

              fetchCompleteList();
              dispatch(
                SetpopupReducerData({
                  modalType: "PENDINGTASK",
                  showModal: true,
                  category: `${category.name}`,
                })
              );
              // if (data?.data?.response?.error) {
              //   toast.error(data?.data?.response?.error);
              // } else {
              //   getConvertedVideo(data?.data?.response?.rep_id);
              // }
            } else {
              setApiErrors({ message: data?.message });
              setErrors({});
            }
          });
        } else {
          setErrors(err);
        }
      } catch (error) {
        setApiErrors({ message: error?.message });
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error("You Reached the limit");
      handleSignUp();
    }
  };

  useEffect(() => {
    fetchLanguage();
    if (selectedVoice) {
      fetchSampleVoice();
    }
  }, [selectedVoice]);

  return (
    <>
      {needhelp ? (
        <DubAIDubbing setNeedHelp={setNeedHelp} />
      ) : (
        <>
          <h2 className="text-center text-white mb-4">AI Dubbing</h2>
          <div className="refernceAudio">
            <form id="contact" method="post" className="form" role="form">
              <div className="row">
                <div className="col-12 mb-4">
                  <div className="d-flex justify-content-between">
                    <label className="form-label w-auto">
                      Upload Video <span className="text-danger">* </span>
                      <span className="text-danger">
                        {/* <p> */}
                        1. The face should be visible for 100% time in the
                        video. 2. Audio should be recognized easily in the
                        video.
                        {/* </p> */}
                      </span>
                      {/* <OverlayTrigger
                        overlay={
                          <Tooltip>
                            1. The face should be visible for 100% time in the
                            video. <p></p> 2. Audio should be recognized easily
                            in the video.{" "}
                          </Tooltip>
                        }
                      >
                        <span className="d-inline-block">
                          <p style={{ pointerEvents: "none" }}>
                            <BsInfoCircle />
                          </p>
                        </span>
                      </OverlayTrigger> */}
                    </label>
                    <button
                      onClick={() => setNeedHelp(true)}
                      className="needHelp"
                    >
                      Need Help?
                    </button>
                  </div>
                  {/* <img src={thumbnail} style={{width: "200px" , height:"300px"}}/> */}
                  <div class="customUpload">
                    <input
                      className="form-control file-border"
                      type="file"
                      id="formFile"
                      accept="video/*"
                      onChange={handleChangeFile}
                    />
                    <span class="upload-btn" id="upload-btn">
                      {fileName}
                    </span>
                  </div>

                  {errors.file ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.file}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-xxl-4 col-md-6 form-group pb-3">
                  <label>
                    Choose a language<span className="text-danger"> *</span>
                  </label>
                  <select
                    className="form-control"
                    id="country"
                    onChange={handleChange}
                    value={target_language}
                    name="target_language"
                  >
                    <option value={""}>Select Language</option>
                    {languages?.map((ele) => (
                      <option key={ele.code} value={ele.langCode}>
                        {ele.langName}
                      </option>
                    ))}
                  </select>
                  {errors.target_language ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.target_language}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-xxl-4 col-md-6 form-group pb-3">
                  <label>
                    Choose a voice<span className="text-danger"> *</span>
                  </label>
                  <select
                    className="form-control"
                    id="country"
                    onChange={handleChange}
                    value={selectedVoice}
                    name="voice_name"
                  >
                    <option value={""}>Choose Voice</option>
                    {categorizedLangauage[0]?.allLang?.map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                  </select>
                  {errors.voice_name ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.voice_name}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {/* {samplevoice && ( */}
                <div className="col-xxl-4 form-group pb-3">
                  <label>
                    Sample Voice<span className="text-danger"> *</span>
                  </label>
                  <audio src={samplevoice} controls />
                </div>
                {/* )} */}
              </div>
              {apiErrors.message ? (
                <span className="text-danger text-center">
                  {apiErrors.message}
                </span>
              ) : (
                ""
              )}
              <p class="text-center">OR</p>

              <div class="customUpload sizedecrese">
                <input
                  className="form-control file-border"
                  type="file"
                  id="formFile"
                  accept="audio/*,video/*"
                  onChange={handleAudioFile}
                />
                <span class="upload-btn" id="upload-btn">
                  {audiofileName}
                </span>
              </div>
              {errors.file ? (
                <span className="text-danger" style={{ fontSize: "14px" }}>
                  {errors.file}
                </span>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col-xs-12 col-md-12 form-group synBtn text-center">
                  {isLoading ? (
                    <button className="btn btn-primary mt-4">
                      <Spinner animation="border" size="lg" /> Processing...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary mt-4"
                      onClick={handleConvert}
                      disabled={isLoading}
                    >
                      Continue
                    </button>
                  )}

                  {/* <button className="" type="">
            <img src="/images/soundwave.png" /> synthesize
          </button> */}
                </div>
              </div>
            </form>
          </div>
          {convertedVideo && (
            <ConvertedVideoList
              inputed_Video={videolisting}
              outputed_Video={convertedVideo}
            />
          )}
        </>
      )}
    </>
  );
}

export default NaturalSpeech;

import React, { useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import axios from "axios";
import { Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsBoxArrowInDown, BsTrash } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import { API, getAwsImageUrl } from "../apiwrapper";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function DocumentUpload({ viewData, setViewData, documents, setOrgDocs }) {
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  const orgId = viewData?._id;
  console.log(viewData.access_Key, orgId, "organizationid");
  const accessKey = viewData?.access_Key;

  console.log(documents, "documentsabc--------");
  // console.log(orgId,"orgId");

  const [orgListing, SetorgListing] = useState();
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState(null);
  // const [selectedFile, setSelectedFile] = useState(null);
  const [url, setUrl] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Delete, setDelete] = useState([]);

  const [searchQuery, setSearchQuery] = useState();
  console.log(fileType, "fileType");

  const fetchUserData = async () => {
    try {
      console.log("newchatataa1");
      await API({
        url: `/v1/user/doc/${orgId}`,
        method: "GET",
      }).then((resp) => {
        console.log(resp, "RESP");

        const response = resp.data.orgDoc;
        if (resp?.data?.orgDoc) {
          console.log(response);
          SetorgListing(resp?.data?.orgDoc);
          setOrgDocs(resp?.data?.orgDoc);
          // console.log(resp?.data?.orgDoc, "respkey");
        }
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (orgId) {
      fetchUserData();
    }
  }, [orgId]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  console.log(fileType, "filetype");

  const handleUpload = async () => {
    if (fileType === "url") {
      if (!url) {
        alert("Please enter a URL");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);

      const sendata = {
        type: fileType,
        url: url,
        name: url,
      };

      const requestData = {
        data: sendata,
      };

      const Authorization = {
        Authorization: `${accessKey}`,
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BACKEND_URL_PYTHON}/upload_url`,
          requestData,
          { headers: Authorization }
        );

        console.log("Upload url successful:", response.data);

        if (response.status) {
          setShow(false);

          try {
            const headers = {
              Authorization: "Bearer YOUR_ACCESS_TOKEN",
            };

            const Authorization = {
              Authorization: `${accessKey}`,
            };

            const response1 = await axios.get(
              `${process.env.REACT_APP_API_BACKEND_URL_PYTHON}/get_documents`,
              { headers: Authorization }
            );

            const IdDoc = response1.data.data[0].documentID;
            if (response1.status) {
              let uploaddoc = await getAwsImageUrl(url, "uploddocument");

              const uploaddata = {
                userId: userId,
                orgId: orgId,
                orgDoc: [
                  {
                    type: fileType,
                    docURL: uploaddoc,
                    uploaded_docId: IdDoc,
                    DocName: url,
                  },
                ],
              };

              try {
                const response2 = await axios.post(
                  `${process.env.REACT_APP_API_BACKEND_URL}/v1/upload/doc`, //v1/upload/doc/
                  uploaddata
                );
                console.log("Upload url in node api:", response2.data);

                if (response2.status) {
                  toast.success("URl Uploaded sucessfully");
                  fetchUserData();
                  setShow(false);
                }
              } catch (error) {
                console.error("Upload failed:", error);
                toast.error(error);
              }
            }
          } catch (error) {
            console.error("Error fetching document IDs:", error);
          }
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    } else {
      if (!file) {
        alert("Please select a file");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `${accessKey}`);
      console.log(myHeaders, "myHeaders");
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BACKEND_URL_PYTHON}/uploadDocuments`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `${accessKey}`,
            },
          }
        );
        console.log("Upload doc text and pdf successful:", response.data);

        if (response.status) {
          setShow(false);

          const Authorization = {
            Authorization: `Bearer ${accessKey}`,
          };
          console.log(Authorization, "Authorization");

          try {
            const response_1 = await axios.get(
              `${process.env.REACT_APP_API_BACKEND_URL_PYTHON}/get_documents`,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `${accessKey}`,
                },
              }
            );

            const IdDoc = response_1.data.data[0].documentID;
            if (response_1.status) {
              let uploaddoc = await getAwsImageUrl(file, "uploddocument");

              const uploaddata = {
                userId: userId,
                orgId: orgId,
                orgDoc: [
                  {
                    type: fileType,
                    docURL: uploaddoc,
                    uploaded_docId: IdDoc,
                    DocName: file.name,
                  },
                ],
              };

              try {
                const response2 = await axios.post(
                  `${process.env.REACT_APP_API_BACKEND_URL}/v1/upload/doc`,
                  uploaddata
                );
                console.log("Upload url in node api:", response2.data);
                if (response2.status) {
                  toast.success("Document Uploaded sucessfully");
                  fetchUserData();
                  setShow(false);
                }
              } catch (error) {
                console.error("Upload failed:", error);
                toast.error(error);
              }
            }
          } catch (error) {
            console.error("Error fetching document IDs:", error);
          }
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };

  const handleCheckboxClick = (docId) => {
    setDelete((prevDelete) =>
      prevDelete.includes(docId)
        ? prevDelete.filter((currentId) => currentId !== docId)
        : [...prevDelete, docId]
    );
  };

  //   Delete doc method post
  // http://localhost:3001/v1/deletedoc
  // body data
  // {
  //     "userId":"64d9bee4803b340eb805a973",
  //     "orgId":"64e7390218999a8ead370886",
  //     "docId":["64e73aac18999a8ead370894","64e73b6018999a8ead3708a0"],
  //     "isDeleted":true
  // }

  const Authorization = {
    Authorization: `${accessKey}`,
    // Authorization:"Bearer VF.DM.64e7607424372e000898ce5a.rebRLTZdyn2PZIKP"
  };

  const handleDelete = async (documentId, docId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BACKEND_URL_PYTHON}/delete_document/${documentId}`,
        { headers: Authorization }
      );

      if (response.status) {
        //  console.log(docId, "AnkitdocId");

        const data = {
          userId: userId,
          orgId: orgId,
          docId: [docId],
          isDeleted: true,
        };

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BACKEND_URL}/v1/deletedoc`,
            data
          );
          if (response.status == true) {
            fetchUserData();

            console.log("Document deleted successfully abc");
            toast.success("Document deleted successfully");
          } else {
            fetchUserData();

            toast.error(response?.data?.message);
          }
        } catch (error) {
          console.error("Error deleting document:", error);
          toast.success(error);
        }

        // console.log("Document deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const searchDocuments = async (searchQuery) => {
    try {
      console.log("newchatataa1");
      await API({
        url: `/v1/doc/name?type=abc&userId=${userId}&orgId=${orgId}&docName=${searchQuery}`,
        method: "GET",
      }).then((resp) => {
        if (resp) {
          // console.log(resp?.data[0]?.orgDoc, "newrespapi");
          setOrgDocs(resp?.data[0]?.orgDoc);
        } else {
        }
      });
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="row">
      <div className="col-md-9">
        <div className="docSearch">
          <button>
            <BiSearchAlt2 onClick={() => searchDocuments(searchQuery)} />
          </button>
          <input
            type="text"
            placeholder=""
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              searchDocuments(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="col-md-3">
        <Dropdown className="dropDownMenu">
          <Dropdown.Toggle id="dropdown-button-dark-example1" variant="">
            Upload Document
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setFileType("url");
                handleShow();
              }}
            >
              URL(s)
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setFileType("text");
                handleShow();
              }}
            >
              Text <span>10mb max</span>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setFileType("pdf");
                handleShow();
              }}
            >
              PDF <span>10mb max</span>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setFileType("doc");
                handleShow();
              }}
            >
              DOC <span>10mb max</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {fileType === "url" && (
          <Modal className="modalForm" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Enter Url</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="text"
                className="form-control"
                placeholder="Enter URL"
                value={url}
                onChange={handleUrlChange}
              />
              <div className="text-center py-3">
                <Button
                  className="viewBtn blue px-4 py-2 m-0"
                  onClick={handleUpload}
                >
                  Upload
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        )}
        {fileType === "text" && (
          <Modal className="modalForm" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Enter Text</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input type="file" accept=".txt" onChange={handleFileChange} />
              <div className="text-center py-3">
                <Button
                  className="viewBtn blue px-4 py-2 m-0"
                  onClick={handleUpload}
                >
                  Upload
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        )}
        {fileType === "pdf" && (
          <Modal className="modalForm" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Enter Pdf</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input type="file" accept=".pdf" onChange={handleFileChange} />
              <div className="text-center py-3">
                <Button
                  className="viewBtn blue px-4 py-2 m-0"
                  onClick={handleUpload}
                >
                  Upload
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        )}
        {fileType === "doc" && (
          <Modal className="modalForm" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Enter Doc</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input type="file" accept=".pdf" onChange={handleFileChange} />
              <div className="text-center py-3">
                <Button
                  className="viewBtn blue px-4 py-2 m-0"
                  onClick={handleUpload}
                >
                  Upload
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>

      <div className="col-md-12 mt-4">
        <div className="uploadTable">
          <table class="table">
            {/* <caption>List of users</caption> */}
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Date</th>
                <th scope="col">Doc URL</th>
                <th scope="col"> &nbsp; </th>
                {/* <th scope="col">
                    {" "}
                    &nbsp;
                    {Delete.length > 1 && (
                      <BsTrash onClick={handleDeleteUser} />
                    )}
                  </th> */}
              </tr>
            </thead>
            <tbody>
              {documents?.length > 0 ? (
                documents?.map((ele) => (
                  <tr key={ele.uploaded_docId}>
                    {!ele.isDeleted && (
                      <>
                        <td>
                          <div className="d-flex align-items-center align-self-stretch">
                            <div className="d-flex align-items-center align-self-stretch">
                              <input
                                type="checkbox"
                                onClick={() =>
                                  handleCheckboxClick(ele.uploaded_docId)
                                }
                              />
                              {ele.DocName}
                            </div>
                          </div>
                        </td>
                        <td>{ele.type}</td>

                        {/* <td align='center'><img src='/images/checkIcon.png'></img></td> */}
                        <td>{ele.Date.split("T")[0]}</td>
                        <td>
                          <a href={ele.docURL} target="_blank">
                            <BsBoxArrowInDown />
                          </a>{" "}
                        </td>
                        {Delete.includes(ele.uploaded_docId) ? (
                          <td>
                            <Link to="">
                              <BsTrash
                                onClick={() =>
                                  handleDelete(ele.uploaded_docId, ele._id)
                                }
                              />
                            </Link>
                          </td>
                        ) : (
                          <td>&nbsp;</td>
                        )}
                        {/* {Delete.length === 1 && Delete.includes(ele._id) ? (
                          <td>
                            <Link to="#" onClick={() => handleDeleteUser()}>
                              <BsTrash />
                            </Link>
                          </td>
                        ) : (
                          <td>&nbsp;</td>
                        )} */}
                      </>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center text-danger">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DocumentUpload;

// import React, { useState } from "react";
// import { BiSearchAlt2 } from "react-icons/bi";
// import axios from "axios";
// import { Dropdown, Modal } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { BsBoxArrowInDown, BsTrash } from "react-icons/bs";
// import Button from "react-bootstrap/Button";
// import { API, getAwsImageUrl } from "../apiwrapper";
// import { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { toast } from "react-toastify";

// function DocumentUpload({ viewData, setViewData, documents, setOrgDocs }) {
//   const { User = {} } = useSelector((state) => state);
//   let userId = User?.data?._id;
//   const orgId = viewData?._id;
//   console.log(viewData.access_Key, orgId, "organizationid");
//   const accessKey = viewData?.access_Key;

//   // console.log(accessKey,"belwalkeya");
//   // console.log(orgId,"orgId");

//   const [orgListing, SetorgListing] = useState();
//   const [fileType, setFileType] = useState("");
//   const [file, setFile] = useState(null);
//   // const [selectedFile, setSelectedFile] = useState(null);
//   const [url, setUrl] = useState("");
//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const [Delete, setDelete] = useState([]);

//   // console.log(url, "url");

//   const [searchQuery, setSearchQuery] = useState();
//   console.log(orgId, "orrrrfddsfg");

//   const fetchUserData = async () => {
//     try {
//       console.log("newchatataa1");
//       await API({
//         url: `/v1/user/doc/${userId}/${orgId}`,
//         method: "GET",
//       }).then((resp) => {
//         const response = resp.data.orgDoc;
//         if (resp?.data?.orgDoc) {
//           console.log(response);
//           SetorgListing(resp?.data?.orgDoc);
//           setOrgDocs(resp?.data?.orgDoc);
//           console.log(resp?.data?.orgDoc, "respkey");
//         }
//       });
//     } catch (error) {
//       throw error;
//     }
//   };

//   useEffect(() => {
//     if (orgId) {
//       fetchUserData();
//     }
//   }, [orgId]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//   };

//   const handleUrlChange = (event) => {
//     setUrl(event.target.value);
//   };

//   console.log(fileType, "filetype");

//   const handleUpload = async () => {
//     if (fileType === "url") {
//       if (!url) {
//         alert("Please enter a URL");
//         return;
//       }
//       const formData = new FormData();
//       formData.append("file", file);

//       const sendata = {
//         type: fileType,
//         url: url,
//         name: url,
//       };

//       const requestData = {
//         data: sendata,
//       };

//       const Authorization = {
//         Authorization: `${accessKey}`,
//       };

//       try {
//         const response = await axios.post(
//           `${process.env.REACT_APP_API_BACKEND_URL_PYTHON}/upload_url`,
//           requestData,
//           { headers: Authorization }
//         );

//         console.log("Upload url successful:", response.data);

//         if (response.status) {
//           setShow(false);

//           try {
//             const headers = {
//               Authorization: "Bearer YOUR_ACCESS_TOKEN",
//             };

//             const Authorization = {
//               Authorization: `${accessKey}`,
//             };

//             const response1 = await axios.get(
//               `${process.env.REACT_APP_API_BACKEND_URL_PYTHON}/get_documents`,
//               { headers: Authorization }
//             );

//             const IdDoc = response1.data.data[0].documentID;
//             if (response1.status) {
//               let uploaddoc = await getAwsImageUrl(url, "uploddocument");

//               const uploaddata = {
//                 userId: userId,
//                 orgId: orgId,
//                 orgDoc: [
//                   {
//                     type: fileType,
//                     docURL: uploaddoc,
//                     uploaded_docId: IdDoc,
//                     DocName: url,
//                   },
//                 ],
//               };

//               try {
//                 const response2 = await axios.post(
//                   `${process.env.REACT_APP_API_BACKEND_URL}/v1/upload/doc`, //v1/upload/doc/
//                   uploaddata
//                 );
//                 console.log("Upload url in node api:", response2.data);

//                 if (response2.status) {
//                   toast.success("URl Uploaded sucessfully");
//                   fetchUserData();
//                   setShow(false);
//                 }
//               } catch (error) {
//                 console.error("Upload failed:", error);
//                 toast.error(error);
//               }
//             }
//           } catch (error) {
//             console.error("Error fetching document IDs:", error);
//           }
//         }
//       } catch (error) {
//         console.error("Upload failed:", error);
//       }
//     } else {
//       if (!file) {
//         alert("Please select a file");
//         return;
//       }
//       const formData = new FormData();
//       formData.append("file", file);
//       var myHeaders = new Headers();
//       myHeaders.append("Authorization", `${accessKey}`,);
//       console.log(myHeaders,"myHeaders");
//       try {
//         const response = await axios.post(
//           `${process.env.REACT_APP_API_BACKEND_URL_PYTHON}/uploadDocuments`,
//           formData,
//           {
//             headers:
//             {
//               "Content-Type": "multipart/form-data",
//               Authorization: `${accessKey}`,
//             },
//           }
//         );
//         console.log("Upload doc text and pdf successful:", response.data);

//         if (response.status) {
//           setShow(false);

//           const Authorization = {
//             Authorization: `Bearer ${accessKey}`,
//           };
//           console.log(Authorization, "Authorization");

//           try {
//             const response_1 = await axios.get(
//               `${process.env.REACT_APP_API_BACKEND_URL_PYTHON}/get_documents`,
//               { headers:   {
//                 "Content-Type": "multipart/form-data",
//                 Authorization: `${accessKey}`,
//               }, }
//             );

//             const IdDoc = response_1.data.data[0].documentID;
//             if (response_1.status) {
//               let uploaddoc = await getAwsImageUrl(file, "uploddocument");

//               const uploaddata = {
//                 userId: userId,
//                 orgId: orgId,
//                 orgDoc: [
//                   {
//                     type: fileType,
//                     docURL: uploaddoc,
//                     uploaded_docId: IdDoc,
//                     DocName: file.name,
//                   },
//                 ],
//               };

//               try {
//                 const response2 = await axios.post(
//                   `${process.env.REACT_APP_API_BACKEND_URL}/v1/upload/doc`,
//                   uploaddata
//                 );
//                 console.log("Upload url in node api:", response2.data);
//                 if (response2.status) {
//                   toast.success("Document Uploaded sucessfully");
//                   fetchUserData();
//                   setShow(false);
//                 }
//               } catch (error) {
//                 console.error("Upload failed:", error);
//                 toast.error(error);
//               }
//             }
//           } catch (error) {
//             console.error("Error fetching document IDs:", error);
//           }
//         }
//       } catch (error) {
//         console.error("Upload failed:", error);
//       }
//     }
//   };

//   const handleCheckboxClick = (docId) => {
//     setDelete((prevDelete) =>
//       prevDelete.includes(docId)
//         ? prevDelete.filter((currentId) => currentId !== docId)
//         : [...prevDelete, docId]
//     );
//   };

// //   Delete doc method post
// // http://localhost:3001/v1/deletedoc
// // body data
// // {
// //     "userId":"64d9bee4803b340eb805a973",
// //     "orgId":"64e7390218999a8ead370886",
// //     "docId":["64e73aac18999a8ead370894","64e73b6018999a8ead3708a0"],
// //     "isDeleted":true
// // }

//   const Authorization = {
//     Authorization: `${accessKey}`,
//     // Authorization:"Bearer VF.DM.64e7607424372e000898ce5a.rebRLTZdyn2PZIKP"
//   };

//   const handleDelete = async (documentId, docId) => {

//     try {
//       const response = await axios.delete(
//         `${process.env.REACT_APP_API_BACKEND_URL_PYTHON}/delete_document/${documentId}`,
//         { headers: Authorization }
//       );

//       if (response.status) {
//         // console.log(docId, "AnkitdocId");

//         const data = {
//           userId: userId,
//           orgId: orgId,
//           docId: [docId],
//           isDeleted: true,
//         };

//         try {
//           const response = await axios.post(
//             `${process.env.REACT_APP_API_BACKEND_URL}/v1/deletedoc`,
//             data
//           );
//           if (response.status) {
//             fetchUserData();

//             console.log("Document deleted successfully");
//             toast.success("Document deleted successfully");
//           }
//         } catch (error) {
//           console.error("Error deleting document:", error);
//           toast.success(error);
//         }

//         console.log("Document deleted successfully");
//       }
//     } catch (error) {
//       console.error("Error deleting document:", error);
//     }
//   };

//   const searchDocuments = async (searchQuery) => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_BACKEND_URL}/v1/doc/name?query=${searchQuery}&id=${userId}`
//       );
//       if (response.status) {
//         console.log("Search  results Belwal:", response);
//         SetorgListing(response?.data?.data[0].orgDoc);
//         setOrgDocs(response?.data?.data?.orgDoc);
//       }
//     } catch (error) {
//       console.error("Error searching documents:", error);
//     }
//   };

//   return (
//     <div className="row">
//       <div className="col-md-9">
//         <div className="docSearch">
//           <button>
//             <BiSearchAlt2 onClick={() => searchDocuments(searchQuery)} />
//           </button>
//           <input
//             type="text"
//             placeholder=""
//             value={searchQuery}
//             onChange={(e) => {
//               setSearchQuery(e.target.value);
//               searchDocuments(e.target.value);
//             }}
//           />
//         </div>
//       </div>
//       <div className="col-md-3">
//         <Dropdown className="dropDownMenu">
//           <Dropdown.Toggle id="dropdown-button-dark-example1" variant="">
//             Upload Document
//           </Dropdown.Toggle>
//           <Dropdown.Menu>
//             <Dropdown.Item
//               onClick={() => {
//                 setFileType("url");
//                 handleShow();
//               }}
//             >
//               URL(s)
//             </Dropdown.Item>
//             <Dropdown.Item
//               onClick={() => {
//                 setFileType("text");
//                 handleShow();
//               }}
//             >
//               Text <span>10mb max</span>
//             </Dropdown.Item>
//             <Dropdown.Item
//               onClick={() => {
//                 setFileType("pdf");
//                 handleShow();
//               }}
//             >
//               PDF <span>10mb max</span>
//             </Dropdown.Item>
//             <Dropdown.Item
//               onClick={() => {
//                 setFileType("doc");
//                 handleShow();
//               }}
//             >
//               DOC <span>10mb max</span>
//             </Dropdown.Item>
//           </Dropdown.Menu>
//         </Dropdown>

//         {fileType === "url" && (
//           <Modal className="modalForm" show={show} onHide={handleClose}>
//             <Modal.Header closeButton>
//               <Modal.Title>
//                 <h4>Enter Url</h4>
//               </Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               <input
//                 type="text"
//                 className="form-control"
//                 placeholder="Enter URL"
//                 value={url}
//                 onChange={handleUrlChange}
//               />
//               <div className="text-center py-3">
//                 <Button
//                   className="viewBtn blue px-4 py-2 m-0"
//                   onClick={handleUpload}
//                 >
//                   Upload
//                 </Button>
//               </div>
//             </Modal.Body>
//           </Modal>
//         )}
//         {fileType === "text" && (
//           <Modal className="modalForm" show={show} onHide={handleClose}>
//             <Modal.Header closeButton>
//               <Modal.Title>
//                 <h4>Enter Text</h4>
//               </Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               <input type="file" accept=".txt" onChange={handleFileChange} />
//               <div className="text-center py-3">
//                 <Button
//                   className="viewBtn blue px-4 py-2 m-0"
//                   onClick={handleUpload}
//                 >
//                   Upload
//                 </Button>
//               </div>
//             </Modal.Body>
//           </Modal>
//         )}
//         {fileType === "pdf" && (
//           <Modal className="modalForm" show={show} onHide={handleClose}>
//             <Modal.Header closeButton>
//               <Modal.Title>
//                 <h4>Enter Pdf</h4>
//               </Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               <input type="file" accept=".pdf" onChange={handleFileChange} />
//               <div className="text-center py-3">
//                 <Button
//                   className="viewBtn blue px-4 py-2 m-0"
//                   onClick={handleUpload}
//                 >
//                   Upload
//                 </Button>
//               </div>
//             </Modal.Body>
//           </Modal>
//         )}
//         {fileType === "doc" && (
//           <Modal className="modalForm" show={show} onHide={handleClose}>
//             <Modal.Header closeButton>
//               <Modal.Title>
//                 <h4>Enter Doc</h4>
//               </Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               <input type="file" accept=".pdf" onChange={handleFileChange} />
//               <div className="text-center py-3">
//                 <Button
//                   className="viewBtn blue px-4 py-2 m-0"
//                   onClick={handleUpload}
//                 >
//                   Upload
//                 </Button>
//               </div>
//             </Modal.Body>
//           </Modal>
//         )}
//       </div>

//       <div className="col-md-12 mt-4">
//         <div className="uploadTable">
//           <table class="table">
//             {/* <caption>List of users</caption> */}
//             <thead>
//               <tr>
//                 <th scope="col">Name</th>
//                 <th scope="col">Type</th>
//                 <th scope="col">Date</th>
//                 <th scope="col">Doc URL</th>
//                 <th scope="col"> &nbsp; </th>
//                 {/* <th scope="col">
//                     {" "}
//                     &nbsp;
//                     {Delete.length > 1 && (
//                       <BsTrash onClick={handleDeleteUser} />
//                     )}
//                   </th> */}
//               </tr>
//             </thead>
//             <tbody>
//               {documents?.map((ele) => (
//                 <tr key={ele.uploaded_docId}>
//                   {!ele.isDeleted && (
//                     <>
//                       <td>
//                         <div className="d-flex align-items-center align-self-stretch">
//                           <div className="d-flex align-items-center align-self-stretch">
//                             <input
//                               type="checkbox"
//                               onClick={() =>
//                                 handleCheckboxClick(ele.uploaded_docId)
//                               }
//                             />
//                             {ele.DocName}
//                           </div>
//                         </div>
//                       </td>
//                       <td>{ele.type}</td>

//                       {/* <td align='center'><img src='/images/checkIcon.png'></img></td> */}
//                       <td>{ele.Date.split("T")[0]}</td>
//                       <td>
//                         <a href={ele.docURL} target="_blank">
//                           <BsBoxArrowInDown />
//                         </a>{" "}
//                       </td>
//                       {Delete.includes(ele.uploaded_docId) ? (
//                         <td>
//                           <Link to="">
//                             <BsTrash
//                               onClick={() =>
//                                 handleDelete(ele.uploaded_docId, ele._id)
//                               }
//                             />
//                           </Link>
//                         </td>
//                       ) : (
//                         <td>&nbsp;</td>
//                       )}
//                       {/* {Delete.length === 1 && Delete.includes(ele._id) ? (
//                           <td>
//                             <Link to="#" onClick={() => handleDeleteUser()}>
//                               <BsTrash />
//                             </Link>
//                           </td>
//                         ) : (
//                           <td>&nbsp;</td>
//                         )} */}
//                     </>
//                   )}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DocumentUpload;

import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../homeComponents/Header";
import Footer from "../homeComponents/Footer";

function Layout() {
  return (
    <>
      <div id="main">
        {/* <Header /> */}
        <Outlet />
        <Footer/>
      </div>
    </>
  );
}

export default Layout;

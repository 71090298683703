import React from 'react'

function TestimonialCard() {
  return (
    <>
      <div className="col-md-6">
        <div className="textBox d-lg-flex">
            <div>
              <h4>Jordan T. <span>Digital Artist</span></h4>
              <div className='rating'><img src="./images/rating.png" alt="" /></div>
              <p>"Exuber has truly revolutionized my creative process! The AI tools seamlessly integrate into my workflow, providing solutions that once seemed out of reach. From 'Text to Video' to the intuitive 'AI Chatbot', every feature feels meticulously crafted for modern innovators. Highly recommended for anyone looking to amplify their creativity!" </p>
            </div>
            <figure><img src="./images/quote.png" alt="" /></figure>
          </div>
      </div>
      <div className="col-md-6">
        <div className="textBox d-lg-flex">
            <div>
              <h4>Priya S. <span>Startup Founder</span></h4>
              <div className='rating'><img src="./images/rating.png" alt="" /></div>
              <p>"Discovering Exuber has been a game-changer for my business. The platform's unique combination of advanced AI and user-friendly design has made tasks like global communication and content creation a breeze. With Exuber, the possibilities seem truly limitless. Kudos to the team for creating such a masterpiece!" </p>
            </div>
            <figure><img src="./images/quote.png" alt="" /></figure>
          </div>
      </div>   
    </>
  )
}

export default TestimonialCard
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-unused-vars */
import React, { useEffect, useDebugValue, useState } from "react";

import { API, getAwsVideoUrl } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Route, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import {
  isValid,
  validateRequire,
} from "../../components/Validation/InputValidation";
import { Spinner } from "react-bootstrap";
import TextToMusic from "./TextToMusic";
import axios from "axios";
import ConvertAudio from "./ConvertAudio";
import { SetUserData, SetpopupReducerData } from "../store/reducer";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-hot-toast";
import DubAudios from "./DubAudio";
import ConvertImage from "./ConvertImage";
import { Link } from "react-router-dom";
import DubImage from "./DubImage";
import {
  SetCompletedNotifications,
  SetPendingNotifications,
} from "../store/reducer/extra";

function ConvertTextToImage({ category = {}, setCategory }) {
  const [needhelp, setNeedHelp] = useState(false);
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  let secretKey = User?.data?.secret_key;
  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [textToAudio, setTextToAudio] = useState([]);
  const [convertedAudio, setConvertedAudio] = useState("");
  const dispatch = useDispatch();
  const [prompt, setPrompt] = useState("");
  const [languages, setLanguage] = useState([]);
  const [voice, setVoice] = useState("");
  const [samplevoice, setSampleVoice] = useState("");
  const [categorizedLangauage, setCategorizedLanguage] = useState([]);
  const [audiofileName, setAudioFileName] = useState("");
  const [audiofile, setAudioFile] = useState("");

  const [selectedVoice, setSelectedVoice] = useState("");

  const [inputField, setInputField] = useState({
    target_language: "",
    category: category?._id,
    prompt: "",
    custom_voice_path: "",
  });

  const navigate = useNavigate();

  const { target_language = "" } = inputField;

  const validateAll = () => {
    // let err1 = {};
    // err1.category = validateRequire(category);
    // err1.prompt = validateRequire(prompt);
    // // err1.modelType = validateRequire(modelType);
    // // err1.music_duration= Number(music_duration) > 30 ? "Duration cannot be greater than 30" : "";

    // return err1;
    let errors = {};

    // Prompt Validation
    if (!prompt.trim()) {
      errors.prompt = "Please enter a prompt.";
    }

    if (!audiofile) {
      // Target Language Validation
      if (!target_language) {
        errors.target_language = "Please select a language.";
      }

      // Voice Name Validation
      if (!selectedVoice) {
        errors.voice_name = "Please choose a voice.";
      }
    }

    // Audio File Validation
    if (audiofile && !audiofile.type.startsWith("audio/")) {
      errors.file = "Invalid file type. Please upload an audio file.";
    }

    // If the audio file is uploaded, it's either this or target_language and voice_name, not both
    if (audiofile && (target_language || selectedVoice)) {
      errors.file_conflict =
        "Either upload an audio file or select a target language and voice, not both.";
    }

    return errors;
  };

  const fetchCompleteList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.notification}/${userId}`,
        method: "GET",
      });
      if (response.status) {
        console.log(response.data.tasks);
        if (response.data.tasks.PENDING) {
          dispatch(SetPendingNotifications(response.data.tasks.PENDING));
        } else {
          dispatch(SetPendingNotifications([]));
        }
        dispatch(SetCompletedNotifications(response.data.tasks.COMPLETED));
      }
    } catch (error) {
      throw error;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "target_language") {
      fetchLanguageSet(value);
    } else if (name === "voice_name") {
      setSelectedVoice(value);
    }

    setInputField({
      ...inputField,
      [name]: value,
    });
    setApiErrors({ message: "" });
    setApiErrors({});
  };

  const fetchLanguageSet = async (langCode) => {
    try {
      await API({
        url: `${apiURl.languages}?langCode=${langCode}`,
        method: "GET",
      }).then((data) => {
        console.log("data", data);
        if (data?.status) {
          let allLang = data?.data || [];
          console.log("alllang", allLang);
          setCategorizedLanguage(allLang);
          fetchSampleVoice();
        } else {
          setCategorizedLanguage([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const fetchLanguage = async () => {
    try {
      await API({
        url: `${apiURl.languages}`,
        method: "GET",
      }).then((data) => {
        console.log("dataAAAAA", data);
        console.log("check--", data?.data);
        let newarr = data?.data;

        var obj = {};
        newarr?.map((i) => {
          {
            console.log("iiiii", i);
            obj[i["langCode"]] = i["langName"];
          }
        });
        console.log("objects----", obj);
        setVoice(obj);

        if (data?.status) {
          let allLang = data?.data || [];

          setLanguage(allLang);
        } else {
          setLanguage([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const fetchSampleVoice = async () => {
    var samplevoice = selectedVoice;
    if (samplevoice) {
      console.log("samplevoice", samplevoice.split("("));
      var result = samplevoice.split("(");
      var gender = result[1].replace(")", "");
      console.log("gender", gender);
    }
    // var cc= obj[target_language];
    console.log("cc", voice[target_language]);
    try {
      await API({
        url: `${apiURl.samplevoice}/${voice[target_language]}/${gender}/${result[0]}`,
        method: "GET",
      }).then((data) => {
        console.log("data", data);
        if (data?.status) {
          setSampleVoice(data?.data);
          // let allLang = data?.data || [];
          // console.log("alllang", allLang);
          // setCategorizedLanguage(allLang);
        } else {
          // setCategorizedLanguage([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const handleLogin = (e) => {
    dispatch(
      SetpopupReducerData({
        modalType: "LOGIN",
        showModal: true,
      })
    );
  };

  const handleAudioFile = (e) => {
    var file = e.target.files[0];

    setAudioFile(file);
    setAudioFileName(file.name);
    setErrors({ ...errors, file: "" });
  };

  const handleTextToAudio = async (e) => {
    e.preventDefault();
    if (!userId) {
      handleLogin();
      return;
    }
    try {
      let err = validateAll();
      setErrors(err);

      if (Object.keys(err).length === 0) {
        let audioUrl = await getAwsVideoUrl(
          audiofile,
          `${userId}/InputAudios/`
        );

        const payload = {
          ...inputField,
          secret_key: secretKey,
          category: category?._id,
          // userId: userId,
          prompt: prompt,
          custom_voice_path: audioUrl ? audioUrl : "",
          voice_name: selectedVoice ? selectedVoice : "",
        };

        console.log(payload);

        setIsLoading(true);
        // return;
        await API({
          url: apiURl.textToAudioNew,
          method: "POST",
          body: { ...payload },
          formData: false,
        }).then((data) => {
          if (data?.status || data?.status === true) {
            setPrompt("");

            console.log(data?.data);

            fetchCompleteList();
            dispatch(
              SetpopupReducerData({
                modalType: "PENDINGTASK",
                showModal: true,
                category: `${category.name}`,
              })
            );

            // dispatch(SetUserData(data?.data?.updatedUserInfo));
          }
        });
      } else {
        setErrors(err);
      }
    } catch (error) {
      setApiErrors({ message: error?.message });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLanguage();
    if (selectedVoice) {
      fetchSampleVoice();
    }
  }, [selectedVoice]);

  return (
    <>
      {needhelp ? (
        <DubImage setNeedHelp={setNeedHelp} />
      ) : (
        <>
          <h2 className="text-center text-white mb-4">Text To Speech</h2>
          <div className="refernceAudio">
            {/* <img src="/images/coming-soon.svg"></img> */}
            <form id="contact" method="post" className="form" role="form">
              <div className="row">
                <div className="col-12 mb-4">
                  <div className="d-flex justify-content-between">
                    <label htmlFor="audio_text" className="form-label">
                      Write Prompt <span className="text-danger"> *</span>
                      &nbsp; &nbsp;
                      <span className="text-danger">
                        Some parts of the sentences may not be synchronized
                      </span>
                    </label>
                    <button
                      onClick={() => setNeedHelp(true)}
                      className="needHelp"
                    >
                      Need Help?
                    </button>
                  </div>
                  <textarea
                    className="form-control"
                    type="text"
                    id="prompt"
                    name="prompt"
                    value={prompt}
                    rows={8}
                    onChange={(e) => setPrompt(e.target.value)}
                  />
                  {errors.prompt ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.prompt}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-xxl-4 col-md-6 form-group pb-3">
                  <label>
                    Choose a language<span className="text-danger"> *</span>
                  </label>
                  <select
                    className="form-control"
                    id="country"
                    onChange={handleChange}
                    value={target_language}
                    name="target_language"
                  >
                    <option value={""}>Select Language</option>
                    {languages?.map((ele) => (
                      <option key={ele.code} value={ele.langCode}>
                        {ele.langName}
                      </option>
                    ))}
                  </select>
                  {errors.target_language ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.target_language}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-xxl-4 col-md-6 form-group pb-3">
                  <label>
                    Choose a voice<span className="text-danger"> *</span>
                  </label>
                  <select
                    className="form-control"
                    id="country"
                    onChange={handleChange}
                    value={selectedVoice}
                    name="voice_name"
                  >
                    <option value={""}>Choose Voice</option>
                    {categorizedLangauage[0]?.allLang?.map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                  </select>
                  {errors.voice_name ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.voice_name}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-xxl-4 form-group pb-3">
                  <label>
                    Sample Voice<span className="text-danger"> *</span>
                  </label>
                  <audio src={samplevoice} controls />
                </div>
              </div>

              {apiErrors.message ? (
                <span className="text-danger text-center">
                  {apiErrors.message}
                </span>
              ) : (
                ""
              )}

              <p class="text-center">OR</p>

              <div class="customUpload sizedecrese">
                <input
                  className="form-control file-border"
                  type="file"
                  id="formFile"
                  accept="audio/*,video/*"
                  onChange={handleAudioFile}
                />
                <span class="upload-btn" id="upload-btn">
                  {audiofileName}
                </span>
              </div>
              {errors.file_conflict && (
                <span className="text-danger" style={{ fontSize: "14px" }}>
                  {errors.file_conflict}
                </span>
              )}

              <div className="row">
                <div className="col-xs-12 col-md-12 form-group synBtn text-center">
                  {isLoading ? (
                    <button className="btn btn-primary mt-4">
                      <Spinner animation="border" size="lg" /> Processing...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary mt-4"
                      onClick={handleTextToAudio}
                      disabled={isLoading}
                    >
                      <img src="/images/soundwave.png" alt="" /> Text to Speech
                    </button>
                  )}

                  {/* <button className="" type="">
            <img src="/images/soundwave.png" /> synthesize
          </button> */}
                </div>
              </div>
            </form>
          </div>
          <div className="text-center">
            <Link
              to=""
              className="seeTitle"
              onClick={() => setCategory({ _id: "", name: "dashboard" })}
            >
              See All Speeches in Dashboard
            </Link>
          </div>
          {convertedAudio && <ConvertImage outputImage={convertedAudio} />}
        </>
      )}
    </>
  );
}

export default ConvertTextToImage;

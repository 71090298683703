/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API, getAwsImageUrl } from "../apiwrapper";
import { apiURl } from "../store/actions";
import {
  isValid,
  validateFirstName,
  validateLastName,
} from "../Validation/InputValidation.js";
import { fileToDataUri } from "../store/actions/api-url";
import { SetUserData, SetpopupReducerData } from "../store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { BsKeyFill } from "react-icons/bs";

import { toast } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";




function EditProfile() {
  const dispatch = useDispatch();
  const { User = {} } = useSelector((state) => state);
  console.log("User", User);
  const [errors, setErrors] = useState({});
  const inputRef = useRef(null);
  const { userId } = useParams();
  const [Imagehow, setImagehow] = useState(null);
  const navigate = useNavigate();
  const [inpData, setInpData] = useState({
    firstName: "",
    lastName: "",
    // contact: "",
    // email: "",
    image: "",
  });
  const [apiErrors, setApiErrors] = useState({ message: "" });

  const [handlePasswordChange, setHandlePasswordChange] = useState({
    email: User?.data?.email,
    currentpassword: "",
    newpassword: "",
    cnewpassword: "",
  });

  const handlePasswordChangeMethod = (e) => {
    setHandlePasswordChange({
      ...handlePasswordChange,
      [e.target.name]: e.target.value,
    });
    handleValidate(e);
    setApiErrors({ message: "" });
  };

  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const { firstName = "", lastName = "", contact = "", email = "" } = inpData;
  const fetchUserData = async () => {
    try {
      await API({
        url: `/v1/users/${userId}`,
        method: "GET",
      }).then((resp) => {
        if (resp?.status) {
          let data = resp.data;
          let obj = {};
          obj.firstName = data.firstName || "";
          obj.lastName = data?.lastName || "";
          obj.contact = data?.contact || "";
          obj.email = data?.email || "";
          obj.image = data.image || "";
          setInpData(obj);
        }
      });
    } catch (error) {
      throw error;
    }
  };
  const handleChange = (e) => {
    // handleValidate(e);
    setInpData({ ...inpData, [e.target.name]: e.target.value });
  };
  const handleClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const handleProfileImage = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    const dataUri = await fileToDataUri(fileObj);
    setImagehow(dataUri.url);
    setInpData({
      ...inpData,
      image: fileObj || "",
    });
  };
  const handleValidate = (e) => {
    const errors1 = {};
    switch (e.target.name) {
      case "firstName":
        errors1.firstName = validateFirstName(e.target.value);
        break;
      case "lastName":
        errors1.lastName = validateLastName(e.target.value);
        break;
      default:
        break;
    }
    setErrors(errors1);
  };
  const validateAll = () => {
    let err1 = {};
    err1.firstName = validateFirstName(firstName);
    err1.lastName = validateLastName(lastName);
    return err1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userId) return;
    let pImgUrl = await getAwsImageUrl(inpData?.image, "userProfile");
    let payload = {
      ...inpData,
      image: pImgUrl || "",
    };
    console.log("payload", payload);

    let submitButton = document.getElementById("profileSubmit");
    submitButton.disabled = true;
    try {
      // let err = validateAll();
      // if (isValid(err)) {
      await API({
        url: `${apiURl.users}/${userId}`,
        body: payload,
        method: "PUT",
        formData: false,
        headers: {},
      }).then((data) => {
        dispatch(SetUserData({ ...data?.data }));
        navigate(-1);
      });
      // }
      // else {
      //   setErrors(err);
      // }
    } catch (error) {
      throw error;
    } finally {
      submitButton.disabled = false;
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  const changePassword = async (e) => {
    e.preventDefault();
    console.log(handlePasswordChange);
    try {
      const response = await API({
        url: apiURl.changePassword, // Replace with your API endpoint for OTP validation
        method: "POST",
        body: { ...handlePasswordChange }, // Send OTP to the server for validation
        formData: false,
      });
      toast.success(response.message);
      setChangePasswordModal(false);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePassword = () => {
    setChangePasswordModal(true);
  };

  return (
    <>
      <section className="profileMain">
        <div className="container">
          <div className="row justify-content-center py-5">
            <div className="col-md-12 col-lg-7">
              <div className="profileBox">
                <div className="editProfileBox">
                  <h4>Edit Profile</h4>
                </div>
                <div className="text-center editImg">
                  <figure className="d-inline-block">
                    <img
                      className="img-fluid"
                      alt=""
                      src={
                        Imagehow
                          ? Imagehow
                          : inpData?.image
                          ? inpData?.image
                          : User?.data?.image || "/images/profile.jpg"
                      }
                      width={"150px"}
                      height={`150px`}
                    />
                    <Link onClick={handleClick}>
                      <i className="fas fa-camera"></i>
                    </Link>
                  </figure>
                </div>
                <div className="formBox">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-div inputWithIcon">
                        <div className="icon">
                          <img src="/images/name-icon.svg" alt="" />
                        </div>
                        <input
                          type="text"
                          id="First_Name"
                          name="firstName"
                          value={firstName || User?.signUpdata?.firstName}
                          placeholder="firstName"
                          // onBlur={handleValidate}
                          onChange={(e) => {
                            if (e.target.value.includes(" ")) {
                              e.target.value = e.target.value.replace(" ", "");
                            }
                            handleChange(e);
                          }}
                        />
                        {/* {errors.firstName ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "14px" }}
                            >
                              {errors.firstName}
                            </span>
                          ) : (
                            ""
                          )} */}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-div inputWithIcon">
                        <div className="icon">
                          <img src="/images/name-icon.svg" alt="" />
                        </div>
                        <input
                          type="text"
                          id="First_Name"
                          name="lastName"
                          value={lastName || User?.signUpdata?.lastName}
                          placeholder="last name"
                          // onBlur={handleValidate}
                          onChange={(e) => {
                            if (e.target.value.includes(" ")) {
                              e.target.value = e.target.value.replace(" ", "");
                            }
                            handleChange(e);
                          }}
                        />
                        {/* {errors.lastName ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "14px" }}
                            >
                              {errors.lastName}
                            </span>
                          ) : (
                            ""
                          )} */}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-div inputWithIcon">
                        <div className="icon">
                          <img src="/images/envelop.svg" alt="" />
                        </div>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          disabled
                          placeholder="email"
                          onBlur={handleValidate}
                          onChange={handleChange}
                        />
                        {errors.email ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "14px" }}
                          >
                            {errors.email}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-div inputWithIcon">
                        <div className="icon">
                          <img src="/images/mobile-icon.svg" alt="" />
                        </div>
                        <input
                          type="text"
                          id="contact"
                          name="contact"
                          value={contact}
                          disabled
                          placeholder="9999 000 1542"
                          onBlur={handleValidate}
                          onChange={handleChange}
                        />
                        {errors.contact ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "14px" }}
                          >
                            {errors.contact}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <input
                      style={{ display: "none" }}
                      ref={inputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleProfileImage}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end py-3">
                  <div className="col-auto me-3">
                    <button
                      className="creat-button my-0"
                      onClick={() => handleChangePassword()}
                    >
                      Change Password
                    </button>
                  </div>
                  <div className="col-auto me-3">
                    <button
                      className="cancel-button my-0"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-auto d-flex justify-content-end">
                    <button
                      className="creat-button px-4 m-0"
                      id="profileSubmit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={changePasswordModal}
        hide={() => setChangePasswordModal(false)}
        size="md offerPoup loginWidth modal-dialog-centered"
      >
        <>
          <div className="pop_content login-screen">
            {/* <div className="close-button">
              <a href="#" onClick={()=>setChangePasswordModal(false)}>
                <ImageComponent src="/images/cross-button.svg" />
              </a>
            </div> */}
            <h2>Change Password?</h2>
            <p className="mt-1 mb-4">Please change your password here...</p>
            <div className="login-section">
              <form
                onSubmit={changePassword}
                // onKeyDown={handleKeyDown}
              >
                <div className="poupBox mb-4">
                  <div className="form-div inputWithIcon m-0">
                    <div className="icon">
                      <BsKeyFill />
                    </div>

                    <input
                      type="password"
                      id="currentpassword"
                      name="currentpassword"
                      // value={email}
                      placeholder="current password"
                      onChange={(e) => handlePasswordChangeMethod(e)}
                      // onBlur={handleValidate}
                    />
                    {/* {errors.email ? (
                      <span
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.email}
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                  {/* {apiErrors.message && (
                    <span
                      className="text-danger mb-2 d-block"
                      style={{ fontSize: "14px" }}
                    >
                      {apiErrors.message}
                    </span>
                  )} */}
                  <div className="form-div inputWithIcon mt-3 mb-3">
                    <div className="icon">
                      <BsKeyFill />
                    </div>

                    <input
                      type="password"
                      id="newpassword"
                      name="newpassword"
                      // value={email}
                      placeholder="new password"
                      onChange={(e) => handlePasswordChangeMethod(e)} // onBlur={handleValidate}
                    />
                    {/* {errors.email ? (
                      <span
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.email}
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div className="form-div inputWithIcon m-0">
                    <div className="icon">
                      <BsKeyFill />
                    </div>

                    <input
                      type="password"
                      id="cnewpassword"
                      name="cnewpassword"
                      // value={email}
                      placeholder="confirm new password"
                      onChange={(e) => handlePasswordChangeMethod(e)} // onBlur={handleValidate}
                    />
                    {/* {errors.email ? (
                      <span
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.email}
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    onClick={() => setChangePasswordModal(false)}
                    type="button"
                    className="cancel-button m-0"
                  >
                    Cancel
                  </button>
                  <button
                    className="creat-button m-0"
                    type="submit"
                    // onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default EditProfile;

import React from 'react'
import StepCard from './StepCard'
import StepVideo from './StepVideo'
import StepAudio from './StepAudio'
import AudioStepCard from './AudioStepCard'
import StepImage from './StepImage'
import ImageStepCard from './ImageStepCard'

function DubImage({setNeedHelp}) {
  return (
    <>  
        <div className='position-relative'>
            <button className='backBtn align-items-start' onClick={()=>setNeedHelp(false)}>Back</button>
            <h2 class="text-center text-white mb-4">How to Dub Text To Speech</h2>
        </div>
       <StepImage/>
       <ImageStepCard/>
    </>
  )
}

export default DubImage
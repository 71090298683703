import React, { useCallback, useEffect, useRef, useState } from "react";
import Page from "../homeComponents/Page";

import Services from "../homeComponents/Services";
// import UserExpirence from "../homeComponents/UserExpirence";
// import TextFeature from "../homeComponents/Features/TextFeature";

import { FaRegArrowAltCircleRight } from "react-icons/fa";
//
import Feature from "../homeComponents/Features/Feature";
import FirstBanner from "../homeComponents/FirstBanner";
// import Insight from "../homeComponents/Security";
import BottomBanner from "../homeComponents/BottomBanner";
import SecondBanner from "../homeComponents/SecondBanner";
import MidBanner from "../homeComponents/MidBanner";
import ThirdBanner from "../homeComponents/ThirdBanner";
import ChatbotAi from "../homeComponents/ChatbotAi";

import { FaAngleRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SetpopupReducerData } from "../store/reducer";

import AOS from "aos";
import "aos/dist/aos.css";
import TexttoImage from "../homeComponents/TexttoImage";
import TexttoVideo from "../homeComponents/TexttoVideo";
import InteriorDesigne from "../homeComponents/InteriorDesigne";
import ImagetoStory from "../homeComponents/ImagetoStory";
import ImageEnhancer from "../homeComponents/ImageEnhancer";
import Header from "../homeComponents/Header";
import TestimonialCard from "../homeComponents/TestimonialCard";
// import { duration } from "moment/moment";

function Home() {
  const [product, setProduct] = useState([]);
  const dispatch = useDispatch();
  const [overview, setOverView] = useState([]);
  const [feature, setFeature] = useState([]);
  const [uploadDocument, setUploadDocument] = useState([]);
  const [textBlock, setTextBlock] = useState([]);
  const [exuber, setExuber] = useState([]);
  const [insightText, setInsightText] = useState([]);
  const [audio, setAudio] = useState([]);
  const [audioText, setAudioText] = useState([]);
  const [globalAI, setGlobalAI] = useState([]);
  const [toolkitData, setToolKitData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [AiData, setAIData] = useState([]);
  const navigate = useNavigate();

  // const [scale, setScale] = useState(1.5);
  // const scaleRef = useRef(scale);
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  let token = localStorage.getItem("token");
  const fecthProducts = useCallback(async () => {
    const res = await fetch(process.env.REACT_APP_CONTENT_URL);
    const data = await res.json();

    const title = data?.story?.content?.body[0]?.title;
    const description = data?.story?.content?.body[0]?.description;
    const newdata = {
      title: title,
      description: description,
    };

    setProduct(newdata);
  });
  const handleShowLogin = () => {
    navigate("/AITool");
  };

  const fecthOverview = useCallback(async () => {
    const res = await fetch(process.env.REACT_APP_CONTENT_URL);
    const data = await res.json();
    console.log("data", data);
    const image = data?.story?.content?.body[1]?.image;
    const title = data?.story?.content?.body[1]?.title;
    const detail =
      data?.story?.content?.body[1]?.description?.content[0]?.content[0]?.text;

    //  const description = data?.story?.content?.body[1]?.description;
    const newdata = {
      title: title,
      image: image,
      detail: detail,
      subdescription:
        data?.story?.content?.body[1]?.description?.content[1]?.content?.map(
          (ele) => {
            return {
              subtopic: ele?.content[0]?.content[0]?.text,
            };
          }
        ),
    };

    setOverView(newdata);
  });

  const fecthFeature = useCallback(async () => {
    const res = await fetch(process.env.REACT_APP_CONTENT_URL);
    const data = await res.json();

    const title = data?.story?.content?.body[2]?.title;
    const detail = data?.story?.content?.body[2]?.description;

    const newdata = {
      title: title,
      description: detail,
      Images: data?.story?.content?.body[3]?.columns?.map((ele) => {
        return {
          image: ele?.image,
          title: ele?.title,
          description: ele?.description?.content[0]?.content[0]?.text,
        };
      }),
    };

    setFeature(newdata);
  });

  const fecthDocument = useCallback(async () => {
    const res = await fetch(process.env.REACT_APP_CONTENT_URL);
    const data = await res.json();

    const title = data?.story?.content?.body[5]?.heading_text;
    const image = data?.story?.content?.body[7]?.image?.filename;
    const discription = data?.story?.content?.body[7]?.text;
    const newdata = {
      title: title,
      image: image,
      description: discription,
    };

    setUploadDocument(newdata);
  });

  const fecthText = useCallback(async () => {
    const res = await fetch(process.env.REACT_APP_CONTENT_URL);
    const data = await res.json();

    const newdata = {
      DetailText: data?.story?.content?.body[8]?.columns?.map((ele) => {
        return {
          miniTitle: ele?.title,
          minidescription: ele?.description,
        };
      }),
    };

    setTextBlock(newdata);
  });

  const fecthExuber = useCallback(async () => {
    const res = await fetch(process.env.REACT_APP_CONTENT_URL);
    const data = await res.json();

    const title = data?.story?.content?.body[9]?.title;
    const description =
      data?.story?.content?.body[9]?.description?.content[0]?.content[0]?.text;
    const image = data?.story?.content?.body[9]?.image;

    const newdata = {
      title,
      description,
      image,
    };

    setExuber(newdata);
  });

  const fecthTextBlock = useCallback(async () => {
    const res = await fetch(process.env.REACT_APP_CONTENT_URL);
    const data = await res.json();

    const newdata = {
      DetailText: data?.story?.content?.body[11]?.columns?.map((ele) => {
        return {
          miniTitle: ele?.title,
          minidescription: ele?.description,
        };
      }),
    };

    setInsightText(newdata);
  });

  const fecthAutomatedText = useCallback(async () => {
    const res = await fetch(process.env.REACT_APP_CONTENT_URL);
    const data = await res.json();

    const title = data?.story?.content?.body[12]?.title;
    const description =
      data?.story?.content?.body[12]?.description?.content[0]?.content[0]?.text;
    const image = data?.story?.content?.body[12]?.image;

    const newdata = {
      title,
      description,
      image,
    };

    setAudio(newdata);
  });

  const fecthAudioText = useCallback(async () => {
    const res = await fetch(process.env.REACT_APP_CONTENT_URL);
    const data = await res.json();

    const newdata = {
      DetailText: data?.story?.content?.body[13]?.columns?.map((ele) => {
        return {
          subtitle: ele?.title,
          subdescription: ele?.description,
        };
      }),
    };
    setAudioText(newdata);
  });

  const fecthGlobalAI = useCallback(async () => {
    const res = await fetch(process.env.REACT_APP_CONTENT_URL);
    const data = await res.json();

    const title = data?.story?.content?.body[14]?.title;

    const newdata = {
      title: title,
      // description:detail,
      Images: data?.story?.content?.body[16]?.columns?.map((ele) => {
        return {
          image: ele?.image,
          title: ele?.name,
          description: ele?.title,
        };
      }),
    };

    setGlobalAI(newdata);
  });

  const fecthToolKit = useCallback(async () => {
    const res = await fetch(process.env.REACT_APP_CONTENT_URL);
    const data = await res.json();

    const title = data?.story?.content?.body[17]?.title;
    const description =
      data?.story?.content?.body[17]?.description?.content[0]?.content[0]?.text;
    const image = data?.story?.content?.body[17]?.image;

    const newdata = {
      title,
      description,
      image,
    };

    setToolKitData(newdata);
  });

  const fecthGridText = useCallback(async () => {
    const res = await fetch(process.env.REACT_APP_CONTENT_URL);
    const data = await res.json();

    const newdata = {
      DetailText: data?.story?.content?.body[19]?.columns?.map((ele) => {
        return {
          subtitle: ele?.title,
          subdescription: ele?.description,
        };
      }),
    };
    setGridData(newdata);
  });

  const fecthAI = useCallback(async () => {
    const res = await fetch(process.env.REACT_APP_CONTENT_URL);
    const data = await res.json();

    const title = data?.story?.content?.body[20]?.title;
    const description =
      data?.story?.content?.body[20]?.description?.content[0]?.content[0]?.text;
    const image = data?.story?.content?.body[20]?.image;

    const newdata = {
      title,
      description,
      image,
    };

    setAIData(newdata);
  });

  useEffect(() => {
    fecthProducts();
    fecthOverview();
    fecthFeature();
    fecthDocument();
    fecthText();
    fecthExuber();
    fecthTextBlock();
    fecthAutomatedText();
    fecthAudioText();
    fecthGlobalAI();
    fecthToolKit();
    fecthGridText();
    fecthAI();
  }, []);

  // useEffect(() => {
  //   const iframe = document.getElementById("zoomIframe");

  //   const handleScroll = () => {
  //     let newScale = 1.5 - window.scrollY / 2000;
  //     if (newScale < 0.5) newScale = 0.5;
  //     if (newScale > 1.5) newScale = 1.5;
  //     setScale(newScale);
  //   };

  //   const animate = () => {
  //     const diff = scale - scaleRef.current;
  //     if (Math.abs(diff) > 0.001) {
  //       scaleRef.current += diff / 20;
  //       iframe.style.transform = `scale(${scaleRef.current})`;
  //     }
  //     requestAnimationFrame(animate);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   animate();

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [scale]);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <>
      <Header />
      <Services title={product?.title} description={product?.description} />

      <div className="homeVideo">
        {/* <iframe id="" title="theater" src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/efe0b38cfb42ebc3e9aa0b3ebee6c188/iframe?muted=true&loop=true&autoplay=true&controls=false" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"  frameborder="0"></iframe> */}
        <iframe
          id=""
          title="theater"
          src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/e29b5939432f999790a157b9cf5ca71e/iframe?muted=true&loop=true&autoplay=true&controls=false"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          frameborder="0"
        ></iframe>
        <div className="bannerContent">
          <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-lg-10" onClick={reloadPage}>
                <h1>
                  Elevating creativity through the power of artificial
                  intelligence.
                </h1>
                <p>
                  Exuber is an applied AI research shaping the next era of art,
                  entertainment and human creativity.
                </p>
                <div className="text-center pt-lg-5 pt-4 aos-init aos-animate">
                  <button onClick={handleShowLogin}>
                    <span>
                      <FaRegArrowAltCircleRight />
                    </span>{" "}
                    Try Exuber for Free
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="videoBox" id="parallex">
  <iframe id="zoomIframe" title="theater" src="https://www.youtube.com/embed/D0UnqGm_miA?autoplay=1&mute=1&loop=1&playlist=D0UnqGm_miA&rel=0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"  frameborder="0"></iframe>
  <video id="zoomIframe" className='videoTag' src="/images/homeVideo.mp4" autoPlay="true" loop muted  />
  <iframe id="zoomIframe" title="theater" src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/efe0b38cfb42ebc3e9aa0b3ebee6c188/iframe?muted=true&loop=true&autoplay=true&controls=false" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"  frameborder="0"></iframe>
  <img id="theaterImg" alt="theater background" className="theaterImg" src="/images/theater.jpg" />
</div> */}

      <section className="mainSection">
        <aside className="leftPanel">
          <div className="row h-100 align-items-center">
            <div className="col-md-12">
              <div className="leftCategory" data-aos="fade-right">
                <nav id="navigation-menu">
                  <ul className="sideCat">
                    <li>
                      <a href="#two">
                        <FaAngleRight /> AI Dubbing
                      </a>
                    </li>
                    <li>
                      <a href="#five">
                        <FaAngleRight /> Text to Music
                      </a>
                    </li>
                    <li>
                      <a href="#eight">
                        <FaAngleRight /> Text to Image
                      </a>
                    </li>
                    <li>
                      <a href="#one">
                        <FaAngleRight /> Text to Video
                      </a>
                    </li>
                    {/* <li><a href="#four"><FaAngleRight/> ShopInVideo</a></li> */}
                    <li>
                      <a href="#six">
                        <FaAngleRight /> Text To Audio
                      </a>
                    </li>
                    <li>
                      <a href="#seven">
                        <FaAngleRight /> ChatBot AI
                      </a>
                    </li>
                    <li>
                      <a href="#three">
                        <FaAngleRight /> Interior Designer
                      </a>
                    </li>
                    <li>
                      <a href="#nine">
                        <FaAngleRight /> Image to Story
                      </a>
                    </li>
                    <li>
                      <a href="#ten">
                        <FaAngleRight /> Image Enhancer
                      </a>
                    </li>
                    <li>
                      <a href="#eleven">
                        <FaAngleRight /> SEO scribe
                      </a>
                    </li>

                    {/* <li><a href="#two"><FaAngleRight/> Exuber's AI Dubbing</a></li>
              <li><a href="#four"><FaAngleRight/> Exuber's ShopInVideo</a></li>
              <li><a href="#five"><FaAngleRight/> SoundScribe</a></li>
              <li><a href="#eight"><FaAngleRight/> Exuber's Text to Image</a></li>
              <li><a href="#six"><FaAngleRight/> GlobalSpeakAI</a></li>
              <li><a href="#seven"><FaAngleRight/> ChatBot AI</a></li> */}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </aside>

        <aside className="topSection">
          <div id="two" className="sectionTwo">
            <div className="container py-5">
              <div className="row justify-content-end">
                <div className="col-xl-9">
                  <Feature
                    title={feature?.title}
                    detail={feature?.description}
                    Images={feature?.Images}
                  />
                  <div className="text-center pt-5" data-aos="zoom-in">
                    {/* {!User?.data?.jwtToken ? ( */}
                    <Link
                      className="tryExuber try_ai_dubbing"
                      to={`/AITool/AI_Dubbing`}
                      onClick={handleShowLogin}
                    >
                      <span>
                        <FaRegArrowAltCircleRight />
                      </span>
                      Try AI Dubbing
                    </Link>
                    {/* ):("")} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="five" className="sectionFive">
            <div className="container py-5">
              <div className="row justify-content-end">
                <div className="col-xl-9">
                  <FirstBanner
                    title={audio?.title}
                    description={audio?.description}
                    image={audio?.image}
                    DetailText={audioText?.DetailText}
                  />
                  <div className="text-center pt-4" data-aos="fade-up">
                    {/* {!User?.data?.jwtToken ? ( */}
                    <Link
                      className="tryExuber try_sound_cribe"
                      to={`/AITool/text_to_music`}
                      // onClick={handleShowLogin}
                    >
                      <span>
                        <FaRegArrowAltCircleRight />
                      </span>
                      Try SoundScribe
                    </Link>
                    {/* ):("")} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="eight" className="sectionEight">
            <div className="container py-5">
              <div className="row justify-content-end">
                <div className="col-xl-9">
                  <TexttoImage />
                  <div className="text-center pt-5" data-aos="zoom-in">
                    {/* {!User?.data?.jwtToken ? ( */}
                    <Link
                      className="tryExuber try_text_to_image"
                      to={`/AITool/text_to_image`}
                      // onClick={handleShowLogin}
                    >
                      <span>
                        <FaRegArrowAltCircleRight />
                      </span>
                      Try Exuber's Text to Image
                    </Link>
                    {/* ):("")} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="one" className="sectionOne">
            <div className="container py-5">
              <div className="row justify-content-end">
                <div className="col-xl-9">
                  <TexttoVideo />
                  <div className="text-center pt-5" data-aos="zoom-in">
                    {/* {!User?.data?.jwtToken ? ( */}
                    <Link
                      className="tryExuber try_text_to_video"
                      to={`/AITool/text_to_video`}
                      // onClick={handleShowLogin}
                    >
                      <span>
                        <FaRegArrowAltCircleRight />
                      </span>
                      Try Exuber's Text to Video
                    </Link>
                    {/* ):("")} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="four" className="sectionFour d-none">
            <div className="container py-5">
              <div className="row justify-content-end">
                <div className="col-xl-9">
                  <SecondBanner
                    name={exuber?.title}
                    discription={exuber?.description}
                    image={exuber?.image}
                    subdescriptions={insightText?.DetailText}
                  />
                  <div className="text-center pt-5" data-aos="fade-up">
                    {/* {!User?.data?.jwtToken ? ( */}
                    <Link
                      className="tryExuber try_shopInvideo"
                      to={`/profile/${userId}/shopInVideo's`}
                      onClick={handleShowLogin}
                    >
                      <span>
                        <FaRegArrowAltCircleRight />
                      </span>
                      Try Exuber's ShopInVideo
                    </Link>
                    {/* ):("")} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="six" className="sectionSix py-xl-5">
            <div className="container py-5">
              <div className="row justify-content-end">
                <div className="col-xl-9 pt-xl-5">
                  <Feature title={globalAI?.title} Images={globalAI?.Images} />
                  <div className="text-center pt-5" data-aos="fade-up">
                    {/* {!User?.data?.jwtToken ? ( */}
                    <Link
                      className="tryExuber try_global_speak_AI"
                      to={`/AITool/text_to_speech`}
                      onClick={handleShowLogin}
                    >
                      <span>
                        <FaRegArrowAltCircleRight />
                      </span>
                      Try GlobalSpeakAI
                    </Link>
                    {/* ):("")} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="seven" className="sectionFour">
            <div className="container py-5">
              <div className="row justify-content-end">
                <div className="col-xl-9">
                  <ChatbotAi />
                  <div className="text-center pt-5" data-aos="zoom-in">
                    {/* {!User?.data?.jwtToken ? ( */}
                    <Link
                      className="tryExuber try_chatbot_AI"
                      to={`/AITool/ChatBot`}
                      onClick={handleShowLogin}
                    >
                      <span>
                        <FaRegArrowAltCircleRight />
                      </span>
                      Try ChatBot AI
                    </Link>
                    {/* ):("")} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="three" className="sectionThree">
            <div className="container py-5">
              <div className="row justify-content-end">
                <div className="col-xl-9">
                  <InteriorDesigne />
                  <div className="text-center pt-5" data-aos="zoom-in">
                    {/* {!User?.data?.jwtToken ? ( */}
                    <Link
                      className="tryExuber try_interior_designer_AI"
                      to={`AITool/interiorDesigner`}
                      // onClick={handleShowLogin}
                    >
                      <span>
                        <FaRegArrowAltCircleRight />
                      </span>
                      Try Interior Designer AI
                    </Link>
                    {/* ):("")} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="nine" className="sectionNine">
            <div className="container py-5">
              <div className="row justify-content-end">
                <div className="col-xl-9">
                  <ImagetoStory />
                  <div className="text-center pt-5" data-aos="zoom-in">
                    {/* {!User?.data?.jwtToken ? ( */}
                    <Link
                      className="tryExuber try_image_to_story_AI"
                      to={`/AITool/image_to_story`}
                      //  onClick={handleShowLogin}
                    >
                      <span>
                        <FaRegArrowAltCircleRight />
                      </span>
                      Try Image to Story AI
                    </Link>
                    {/* ):("")} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="ten" className="sectionTen">
            <div className="container py-5">
              <div className="row justify-content-end">
                <div className="col-xl-9">
                  <ImageEnhancer />
                  <div className="text-center pt-5" data-aos="zoom-in">
                    {/* {!User?.data?.jwtToken ? ( */}
                    <Link
                      className="tryExuber try_imageEnhancer"
                      to={`/AITool/ImageEnhancer`}
                      onClick={handleShowLogin}
                    >
                      <span>
                        <FaRegArrowAltCircleRight />
                      </span>
                      Try Image Enhancer
                    </Link>
                    {/* ):("")} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="eleven" className="sectionEleven">
            <div className="container py-5">
              <div className="row justify-content-end">
                <div className="col-xl-9">
                  <h1 data-aos="zoom-in">Exuber's SEO scribe</h1>
                  <iframe
                    className="textToVideo"
                    src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/f69d72d534a50cd3896876db308f5a3f/iframe?muted=true&loop=true&autoplay=true&controls=false"
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowfullscreen="true"
                  ></iframe>

                  <p data-aos="zoom-in">
                    Boost Your Content's Visibility with Exuber's SEO Scribe -
                    Where Every Word Counts!
                  </p>
                  <div className="row">
                    <div className="col-xl-6">
                      <h4>Instant SEO Enhancement:</h4>
                      <p>
                        Simply input your content, and our tool will dissect and
                        suggest the most impactful keywords, ensuring your
                        content tops search engine rankings.
                      </p>
                    </div>
                    <div className="col-xl-6">
                      <h4>Smart Rephrasing:</h4>
                      <p>
                        With just a click on the 'Optimize' button, witness your
                        content being seamlessly rephrased, integrating all
                        vital keywords without compromising its original
                        essence. Elevate your content strategy effortlessly with
                        Exuber!
                      </p>
                    </div>
                  </div>

                  <div className="text-center pt-5" data-aos="zoom-in">
                    {/* {!User?.data?.jwtToken ? ( */}
                    <Link
                      className="tryExuber try_seoScribe"
                      to={`/AITool/SeoScribe`}
                      onClick={handleShowLogin}
                    >
                      <span>
                        <FaRegArrowAltCircleRight />
                      </span>
                      Try SEO Scribe
                    </Link>
                    {/* ):("")} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </section>
      <section className="testimonials">
        <div className="container">
          <h3 className="sayTitle">What Creatives Are Saying</h3>
          <div className="row">
            <TestimonialCard />
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import {
  isValid,
  validateEmail,
  validateRequirePass,
} from "../Validation/InputValidation.js";
import { SetUserData } from "../store/reducer/user";
import { SetpopupReducerData } from "../store/reducer/PopupReducer";
import { Link, useNavigate } from "react-router-dom";
// import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { SetForgetEmail } from "../store/reducer/extra";
import { func } from "prop-types";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLoginBtn from "react-facebook-login";

function Login() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);

  const [show, setShow] = useState(false);
  const [user, setUser] = useState(null);
  const [userFB, setUserFB] = useState(null);
  const [profile, setProfile] = useState([]);
  //   const {} = useSelector((state) => state);

  const [inpData, setInpData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState({ message: "", response: "" });
  const [rememberMe, setRememberMe] = useState(false);
  // const [notVerify, setNotVerify] = useState(false);

  const url = process.env.REACT_APP_LIVE_URL;
  const [showPass, setShowPass] = useState(false);
  const { email, password } = inpData;
  const [verify, setVerify] = useState(true);
  const navigate = useNavigate();
  const [forgotEmail, setForgotEmail] = useState("");

  const handleClosePopup = () => {
    dispatch(SetpopupReducerData({ modalType: "", showModal: false }));
  };
  const handleSignUp = (e) => {
    dispatch(
      SetpopupReducerData({
        modalType: "SIGNUP",
        showModal: true,
      })
    );
  };
  const handleForgetPassword = () => {
    dispatch(
      SetpopupReducerData({ modalType: "FORGETPASSWORD", showModal: true })
    );
  };
  const handleChange = (e) => {
    setInpData({ ...inpData, [e.target.name]: e.target.value });
    handleValidate(e);
    setApiErrors({ message: "" });
  };
  const handleValidate = (e) => {
    const errors1 = {};
    switch (e.target.name) {
      case "email":
        errors1.email = validateEmail(e.target.value);
        break;
      case "password":
        errors1.password = validateRequirePass(e.target.value);
        break;
      default:
        break;
    }
    setErrors(errors1);
  };
  const validateAll = () => {
    let err1 = {};
    err1.email = validateEmail(email);
    err1.password = validateRequirePass(password);
    return err1;
  };
  const handleVerify = async (e, buy) => {
    e.preventDefault();
    try {
      let err = validateAll();
      if (isValid(err)) {
        await API({
          url: apiURl.forgetpassword,
          method: "POST",
          body: { email: inpData.email, event: "forgetpassword" },
          formData: false,
        }).then((data) => {
          if (data?.status || data?.status === "true") {
            // toast(`${data?.message}`, { type: "success" });
            // dispatch(
            //   SetpopupReducerData({
            //     modalType: "OTP",
            //     showModal: true,
            //     buyModal: buy || false,
            //   })
            // );
            // dispatch({
            //   type: SIGNUP_USER_DATA,
            //   payload: { ...inpData },
            // });
          } else {
            setApiErrors({ message: data?.message });
          }
        });
      } else {
        setErrors(err);
      }
    } catch (error) {
      setApiErrors({ message: error.message });
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setApiErrors({ message: "" });
    try {
      let err = validateAll();
      if (isValid(err)) {
        if (rememberMe) {
          setCookiesData();
        }
        await API({
          url: apiURl?.users,
          method: "POST",
          body: { ...inpData, api_type: "login", transporttype: false },
          formData: false,
        }).then((data) => {
          if (data?.status || data?.status === "true") {
          

            localStorage.setItem("token", data?.data?.jwtToken);
            dispatch(SetUserData({ ...data?.data, signupLogin: false }));
            setVerify(true);
            handleClosePopup();

          } else {
            if (data?.data) {
              setVerify(data?.data?.email?.isVerified);
            } else {
              setVerify(true);
            }
            setApiErrors({ message: data?.message, response: data?.response });
          }
        });
      } else {
        setErrors(err);
      }
    } catch (error) {
      setApiErrors({ message: error?.message });
      throw error;
    }
  };
  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);

    // let obj = {};
    // obj.email = e.target.checked ? email : "";
    // obj.password = e.target.checked ? password : "";
    // document.cookie = `email=${obj.email}; path=${url}`;
    // document.cookie = `password=${obj.password}; path=${url}`;
  };
  const setCookiesData = () => {
    let obj = {};
    obj.email = email ? email : "";
    obj.password = password ? password : "";
    document.cookie = `email=${obj.email}; path=${url}`;
    // document.cookie = `password=${obj.password}; path=${url}`;
  };
  const getCookiesData = () => {
    const data = decodeURIComponent(document.cookie);
    let userEmail = data
      .split(";")
      .find((ele) => ele.startsWith("email="))
      ?.split("=")[1];
    // let userPass = data
    //   .split(";")
    //   .find((ele) => ele.startsWith(" password="))
    //   ?.split("=")[1];
    setInpData({ ...inpData, email: userEmail });
  };
  useEffect(() => {
    getCookiesData();
  }, []);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleVerifyOTP = (e) => {
    dispatch(
      SetpopupReducerData({
        modalType: "OTP",
        showModal: true,
      })
    );
  };

  const handleForgot = async (e) => {
    e.preventDefault();
    try {
      const response = await API({
        url: apiURl.forgotPassword,
        method: "POST",
        body: {
          email: forgotEmail,
        },
        formData: false,
      });
      toast.success(response.message);
      setShow(false);
      handleVerifyOTP();
      dispatch(SetForgetEmail({ forgotEmail }));
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => setUser(tokenResponse),
  });

  const fetchData = async () => {
    try {
      const userInfoRes = await axios.get(
        `https://openidconnect.googleapis.com/v1/userinfo?access_token=${user.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        }
      );

      const obj = userInfoRes?.data;

      console.log(obj);

      const payload = {
        firstName: obj?.given_name,
        lastName: obj?.family_name,
        email: obj?.email,
        profileUrl: obj?.picture,
        gId: obj?.sub,
        token: user.access_token,
        method: "GOOGLE",
        isVerified: true,
      };

      console.log(payload);

      const response = await API({
        url: apiURl.register,
        method: "POST",
        body: payload,
        formData: false,
      });

      if (response.status) {
        console.log(response.data);
        localStorage.setItem("token", response?.data?.jwtToken);

        dispatch(SetUserData({ ...response?.data, signupLogin: false }));
        console.log(response);
        handleClosePopup();
        console.log(response.message);
        toast.success(response.message);
      }
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  const componentClicked = () => {
    console.log("Login with facebook button clciked");
  };

  const responseFacebook = async (response) => {
    try {
      const name = response.name.split(" ");

      const payload = {
        firstName: name[0],
        lastName: name[1],
        email: response?.email,
        profileUrl: response?.picture?.data?.url,
        gId: response?.userID,
        token: response?.accessToken,
        method: "FACEBOOK",
        isVerified: true,
      };

      console.log(payload)

      const res = await API({
        url: apiURl.register,
        method: "POST",
        body: payload,
        formData: false,
      });

      console.log(payload);
      if (res.status) {
        console.log(res.data);
        localStorage.setItem("token", res?.data?.jwtToken);

        dispatch(SetUserData({ ...res?.data, signupLogin: false }));
        handleClosePopup();
        toast.success(res.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div>
        <Modal
          show={showModal}
          hide={handleClosePopup}
          size="md offerPoup loginWidth modal-dialog-centered"
        >
          <div className="pop_content login-screen">
            <h2>Log in</h2>
            <div className="login-section">
              <form
                action=""
                method="post"
                onSubmit={handleSubmit}
                onKeyDown={handleKeyDown}
              >
                <div className="poupBox">
                  <div className="form-div inputWithIcon">
                    <div className="icon">
                      <img src="/images/envelop.svg" alt="" />
                    </div>
                    <input
                      className=""
                      type="email"
                      id="UserName"
                      name="email"
                      value={email}
                      placeholder="Email"
                      onChange={handleChange}
                      // onBlur={handleValidate}
                    />
                    {errors.email ? (
                      <span
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.email}
                      </span>
                    ) : (
                      ""
                    )}
                    {!verify && (
                      <div className="col-12 mt-2 text-end">
                        <button
                          className="btn btn-primary btn-sm "
                          onClick={handleVerify}
                        >
                          Verify{" "}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="form-div inputWithIcon">
                    <div className="icon">
                      <img src="/images/password.svg" alt="" />
                    </div>
                    <input
                      className="pe-5"
                      type={showPass ? "text" : "password"}
                      name="password"
                      autocomplete=""
                      placeholder="Password"
                      required=""
                      value={password}
                      id="id_password"
                      onChange={handleChange}
                      // onBlur={handleValidate}
                    />
                    <i
                      className={`${
                        showPass ? " fa fa-eye " : " fa fa-eye-slash "
                      } eye-icon`}
                      id="togglePassword"
                      onClick={() => setShowPass(!showPass)}
                    ></i>
                    {errors.password ? (
                      <span
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.password}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="d-flex justify-content-end">
                    <div className="forgot-div">
                      <a
                        href="#"
                        onClick={() => {
                          setShow(true);
                          setShowModal(false);
                        }}
                      >
                        Forgot password?
                      </a>
                    </div>
                  </div>
                  {apiErrors.message && (
                    <span
                      className="text-danger text-start my-2 d-block"
                      style={{ fontSize: "14px" }}
                    >
                      {apiErrors.message}
                    </span>
                  )}
                </div>

                <div className="d-flex justify-content-between py-3">
                  <div className="col-5">
                    <button
                      className="cancel-button my-0"
                      onClick={handleClosePopup}
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-5 d-flex justify-content-end">
                    <input
                      className="creat-button px-4 m-0"
                      type="submit"
                      id="submit"
                      onClick={handleSubmit}
                      value="Log in"
                    />
                  </div>
                </div>
                <div className="socialMediaBtn">
                  <div className="orTitle"><span>Or Continue with</span></div>
                    <div className="d-flex justify-content-between socialMediaBtn">
                      <button className="googleBtn" onClick={() => googleLogin()}><i class="fab fa-google"></i> Login with Google</button>
                      <button className="facebookBtn"><FacebookLoginBtn appId="972149347184713" autoLoad={false} fields="name, email, picture" onClick={componentClicked} callback={responseFacebook} icon="fa-facebook"/></button>
                    </div>
                  </div>   
                <div className="form-div mt-4 mb-0">
                  <p className="regLnk">
                    Not a member yet?{" "}
                    <Link href="#" onClick={handleSignUp}>
                      Sign up
                    </Link>
                  </p>
                </div>
              </form>

            </div>
          </div>
        </Modal>
      </div>
      <Modal
        show={show}
        hide={handleClosePopup}
        size="md offerPoup loginWidth modal-dialog-centered"
      >
        <>
          <div className="pop_content login-screen">
            {/* <div className="close-button">
              <a href="#" onClick={handleClosePopup}>
                <ImageComponent src="/images/cross-button.svg" />
              </a>
            </div> */}
            <h2>Forgot Password?</h2>
            <p className="mt-1 mb-4">
              Please enter your registered email address to receive a
              verification code.
            </p>
            <div className="login-section">
              <form
                onSubmit={handleForgot}
                // onKeyDown={handleKeyDown}
              >
                <div className="poupBox mb-4">
                  <div className="form-div inputWithIcon m-0">
                    <div className="icon">
                      <img src="/images/envelop.svg" alt="" />
                    </div>
                    {/* <label for="name">
                    Email <span className="text-danger"> *</span>
                  </label> */}
                    <input
                      type="email"
                      id="forgotEmail"
                      name="forgotEmail"
                      value={forgotEmail}
                      placeholder="Email"
                      onChange={(e) => setForgotEmail(e.target.value)}
                      // onBlur={handleValidate}
                    />
                    {/* {errors.email ? (
                      <span
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.email}
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                  {/* {apiErrors.message && (
                    <span
                      className="text-danger mb-2 d-block"
                      style={{ fontSize: "14px" }}
                    >
                      {apiErrors.message}
                    </span>
                  )} */}
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    onClick={handleClosePopup}
                    type="button"
                    className="cancel-button m-0"
                  >
                    Cancel
                  </button>
                  <button
                    className="creat-button m-0"
                    type="submit"
                    // onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default Login;

// import React from 'react'
import React, { useEffect, useRef, useState } from "react";
import { API, getAwsVideoUrl, getAwsImageUrl } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Route, json, useNavigate } from "react-router-dom";
import { isValid, validateRequire } from "../Validation/InputValidation";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { SetUserData, SetpopupReducerData } from "../store/reducer";
import "react-toastify/dist/ReactToastify.css";
import {
  SetCompletedNotifications,
  SetPendingNotifications,
} from "../store/reducer/extra";
import { BsXCircle } from "react-icons/bs";

function FaceSearch({ category = {}, setCategory, id }) {
  console.log("iiiid", id);

  const videoRef = useRef(null);
  const [startTime, setStartTime] = useState(0);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [faceSearchData, setFaceSearchData] = useState(null);
  const [click, setClick] = useState(false);
  const [preview, setPreview] = useState("");
  const [imagepreview, setImagePreview] = useState("");

  const handleTimeClick = (time) => {
    setStartTime(time);
    videoRef.current.currentTime = time;
    if (videoRef.current.paused) {
      videoRef.current.play();
    }
  };

  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  let secretKey = User?.data?.secret_key;
  const dispatch = useDispatch();
  // const [inputField, setInputField] = useState({
  //   image_file: "",
  //   video_file: "",
  // });

  const [inputField, setInputField] = useState({
    image_file: "",
    video_file: "",
    image_file_name: "", // New state variable for image file name
    video_file_name: "", // New state variable for video file name
  });

  //***************************************************************************
  const handleVideoInputChange = (e) => {
    const file = e.target.files[0];
    setPreview(URL.createObjectURL(file));

    if (file) {
      setInputField({
        ...inputField,
        video_file: file,
        video_file_name: file.name,
      });
    }
  };

  const handleImageInputChange = (e) => {
    const file = e.target.files[0];
    setImagePreview(URL.createObjectURL(file));
    if (file) {
      setInputField({
        ...inputField,
        image_file: file,
        image_file_name: file.name,
      });
    }
  };
  //**********************************************************************

  const fetchFaceSearchData = async () => {
    try {
      const resp = await API({
        url: `${apiURl.callComponantData}/${id}/${userId}`,
        method: "GET",
      });

      if (resp) {
        setFaceSearchData(resp?.data); // Assuming resp.data contains your desired data
      } else {
        setFaceSearchData(null);
      }
    } catch (error) {
      console.error("Error fetching face search data:", error);
      setFaceSearchData(null);
    }
  };

  useEffect(() => {
    if (id) {
      console.log("rrrrrr", id);
      fetchFaceSearchData();
    }
  }, [id]);

  const fetchCompleteList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.notification}/${userId}`,
        method: "GET",
      });
      if (response.status) {
        console.log(response.data.tasks);
        if (response.data.tasks.PENDING) {
          dispatch(SetPendingNotifications(response.data.tasks.PENDING));
        } else {
          dispatch(SetPendingNotifications([]));
        }
        dispatch(SetCompletedNotifications(response.data.tasks.COMPLETED));
      }
    } catch (error) {
      throw error;
    }
  };

  const handleLogin = (e) => {
    dispatch(
      SetpopupReducerData({
        modalType: "LOGIN",
        showModal: true,
      })
    );
  };

  function handlePreview() {
    setInputField("");
    setPreview("");
    setImagePreview("");
  }

  const validateAll = () => {
    let errors = {};

    // Validate video file selection
    if (!inputField.video_file) {
      errors.video_file = "Please upload a video file.";
    }

    // Validate image file selection
    if (!inputField.image_file) {
      errors.image_file = "Please upload an image file.";
    }

    return errors;
  };

  const handleFaceSearch = async (e) => {
    setLoading(true);

    e.preventDefault();
    if (!userId) {
      handleLogin();
      return;
    }

    let err = validateAll();
    setErrors(err);

    if (Object.keys(err).length === 0) {
      try {
        // const formData = new FormData();
        let awsUrl = await getAwsVideoUrl(
          inputField?.video_file,
          `${userId}/InputVideos/`
        );

        let awsImageURL = await getAwsImageUrl(
          inputField?.image_file,
          `${userId}/InputImage/`
        );

        // Update the formData object
        // formData.append(
        //   "image_file",
        //   inputField?.image_file,
        //   inputField?.image_file?.name
        // );

        // formData.append(
        //   "video_file",
        //   inputField?.video_file,
        //   inputField?.video_file.name
        // );
        //   formData.append("video_file", inputField?.video_file);
        // formData.append("image_file", inputField?.image_file);
        // formData.append("secretKey", secretKey);
        // formData.append("category", category?._id);
        // formData.append("awsVideoURL", awsUrl);
        // formData.append("awsImageURL", awsImageURL);

        const payload = {
          awsVideoURL: awsUrl,
          secretKey: secretKey,
          awsImageURL: awsImageURL,
          category: category?._id,
        };

        console.log("ddddddddd", payload, awsImageURL, inputField?.image_file);
        // return;
        await API({
          url: apiURl.faceSearchNew,
          method: "POST",
          body: { ...payload },
          formData: false,
        }).then((data) => {
          console.log("sdfds", data);

          if (data?.status || data?.status === true) {
            console.log("Face Search ", data);
            fetchCompleteList();
            dispatch(
              SetpopupReducerData({
                modalType: "PENDINGTASK",
                showModal: true,
                category: `${category.name}`,
              })
            );

            dispatch(SetUserData(data?.data?.updatedUserInfo));
          }
          setLoading(false);
        });
      } catch (error) {
        console.error("Error in face search:", error);
      }
    } else {
      setLoading(false); // Stop the loading if there are validation errors
    }
  };

  const Click = () => {
    setClick(true);
  };

  return (
    <div className="container">
      {console.log("dsfldsfsd", faceSearchData, "video_timeline")}
      {faceSearchData ? (
        <div className="row">
          <div className="col-md-12">
            <h2 class="text-center text-white mb-4">
              Face Search in Video Result
            </h2>
          </div>
          {/* 88888888888888888888888888888888888888888888888888888 */}
          {faceSearchData?.awsVideoURL ? (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="fileTitle text-white mb-4 "> Input video</div>
                  <video ref={videoRef} controls width="100%" height="400px">
                    <source
                      src={faceSearchData?.awsVideoURL}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="col-md-6">
                  <div className="fileTitle text-white mb-4 "> Input Image</div>
                  <img
                    width="100%"
                    height="380px"
                    src={faceSearchData?.awsImageURL}
                  />
                </div>

                <div className="col-md-12">
                  <div className="soundBarDet">
                    <h3>Person Detection (05)</h3>

                    <div className="soundBar_2">
                      {faceSearchData?.video_timeline?.map((item) => (
                        <div onClick={() => handleTimeClick(item)}>&nbsp;</div>
                      ))}
                    </div>
                    <div className="mergeBtn">
                      <button onClick={Click}>Merge all clips</button>
                    </div>
                  </div>
                </div>
              </div>

              {click === true && (
                <div className="row">
                  <div className="col-md-7">
                    <div className="fileTitle text-white mb-4 ">
                      {" "}
                      Merged video
                    </div>
                    <video ref={videoRef} controls width="100%">
                      <source
                        src={faceSearchData?.converted_output}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <h2 class="text-center text-white mb-4">Face Search in Video</h2>
          </div>
          <div className="row">
            <div className="col-md-7">
              {preview ? (
                <div className="position-relative">
                  <BsXCircle
                    title="Delete"
                    onClick={handlePreview}
                    className="preview-icon"
                  />
                  <video width="100%" height="500px" controls>
                    <source src={preview} type="video/mp4" />
                  </video>
                </div>
              ) : (
                <>
                  <div className="file-upload">
                    <label for="upload" className="file-upload__label">
                      <div className="fileTitle">
                        Upload video
                        <p>
                          video must be of max 1 min <br />
                        </p>
                        {inputField?.video_file_name && (
                          <p>Selected Video: {inputField?.video_file_name}</p>
                        )}
                      </div>
                    </label>

                    <input
                      id="upload"
                      className="file-upload__input"
                      type="file"
                      name="video_file"
                      onChange={handleVideoInputChange}
                    />
                    {errors.video_file && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.video_file}
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="col-md-5">
              {imagepreview ? (
                <div className="position-relative">
                  <BsXCircle
                    title="Delete"
                    onClick={handlePreview}
                    className="preview-icon"
                  />
                  <img width="100%" height="500px" src={imagepreview} />
                </div>
              ) : (
                <>
                  <div className="file-upload_2 uploadImage_2">
                    <label for="upload" className="file-upload__label_2">
                      <div className="fileTitle_2">
                        {/* <figure>
                      <img src={"/images/imageUpload.svg"} alt="" />
                    </figure> */}
                        Upload Image
                        <p>
                          Image to be uploaded should be <br />
                          front facing and clear
                        </p>
                        {inputField?.image_file_name && (
                          <p>Selected Image: {inputField?.image_file_name}</p>
                        )}
                      </div>
                    </label>

                    <input
                      id="uploadd"
                      className="file-upload__input_2"
                      type="file"
                      name="image_file"
                      onChange={handleImageInputChange}
                    />
                    {errors.image_file && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.image_file}
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
            {loading ? (
              <div className="col-md-12 synBtn mt-4 text-end">
                <button className="btn btn-primary mt-4">
                  <Spinner animation="border" size="lg" /> Processing...
                </button>
              </div>
            ) : (
              <div className="col-md-12 synBtn mt-4 text-end">
                <button className="ms-auto" onClick={handleFaceSearch}>
                  Find
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default FaceSearch;

{
  /* <div className="col-md-7">
                        <div className='fileTitle text-white mb-4 '> Output video</div>
                          <video ref={videoRef} controls width="100%">
                              <source src= {videoUrl}type="video/mp4" />
                              Your browser does not support the video tag.
                          </video>
                </div> */
}

{
  /* <div className="row">
                    <div className='col-md-12'>
                        <div className='soundBarDet'>
                            <h3>Person Detection (05)</h3>
                            
                            <div className='soundBar_2'>
                            {video_timeline?.map((item) => (
                            <div onClick={() => handleTimeClick(item)}>&nbsp;</div>
                            ))}
                
                            </div>
                            <div className='mergeBtn'><button >Merge all clips</button></div>
                        </div>

                    </div>
                </div> */
  //         <div className="row">
  //     <div className="col-md-7">
  //       <div className="file-upload">
  //         <label for="upload" className="file-upload__label">
  //           <div className="fileTitle">
  //             <figure>
  //               <img src="/images/videoUpload.svg" alt="" />
  //             </figure>
  //             Upload video
  //           </div>
  //         </label>
  //         <video width={320} height={240} controls="">
  //           <source
  //             src={faceSearchData?.input_video_file_path}
  //             type="video/mp4"
  //           />
  //           Your browser does not support the video tag.
  //         </video>
  //         <input
  //           id="upload"
  //           className="file-upload__input"
  //           type="file"
  //           name="video_file"
  //           onChange={(e) =>
  //             setInputField({
  //               ...inputField,
  //               video_file: e.target.files[0],
  //             })
  //           }
  //         />
  //       </div>
  //     </div>
  //     <div className="col-md-5">
  //       <div className="file-upload uploadImage">
  //         <label for="upload" className="file-upload__labell">
  //           <div className="fileTitle">
  //             <figure>
  //               <img src="/images/imageUpload.svg" alt="" />
  //             </figure>
  //             Upload Image
  //             <p>
  //               upload Image which you want <br />
  //               to search in video.
  //             </p>
  //           </div>
  //         </label>
  //         <video width={320} height={240} controls="">
  //           <source
  //             src={faceSearchData?.input_image_file_path}
  //             type="video/mp4"
  //           />
  //           Your browser does not support the video tag.
  //         </video>
  //         <input id="uploadd" className="file-upload__inputt" type="file" name="image_file" onChange={(e) => setInputField({ ...inputField, image_file: e.target.files[0] })} />
  //       </div>
  //     </div>
  //     {loading ? (
  //       <div className="col-md-12 synBtn mt-4 text-end">
  //         <button className="btn btn-primary mt-4">
  //           <Spinner animation="border" size="lg" /> Processing...
  //         </button>
  //       </div>
  //     ) : (
  //       <div className="col-md-12 synBtn mt-4 text-end">
  //         <button className="ms-auto" onClick={handleFaceSearch}>
  //           Find
  //         </button>
  //       </div>
  //     )}
  //   </div>
}

import React, { useEffect, useState } from "react";
import DocumentUpload from "./DocumentUpload";
import CreatUser from "./CreatUser";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
function UploadDocument({ viewData, setViewData }) {
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;

  console.log(User, "Userbelwallal");
  const [show, setShow] = useState(1);
  const [orgDocs, setOrgDocs] = useState([]);
  const [orgUsers, setOrgUsers] = useState([]);

  console.log(viewData?._id, "orgUsers");
  console.log(orgDocs, "doclisting ");

  const fetchOrgDetails = async () => {
    try {
      const { data } = await axios.get(
        // `${process.env.REACT_APP_API_BACKEND_URL}/v1/user/doc/${userId}/${viewData?._id}`
        `${process.env.REACT_APP_API_BACKEND_URL}/v1/user/doc/${viewData?._id}`
      );
      console.log(data, "response");
      if (data?.status) {
        setOrgDocs(data?.data?.orgDoc);
        setOrgUsers(data?.data?.orgUser);
      }
      return;
    } catch (error) {
      throw error;
    } finally {
    }
  };

  useEffect(() => {
    fetchOrgDetails();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div class="chatTab">
            <button
              className="backBtn align-items-start"
              onClick={() => {
                setViewData(null);
              }}
            >
              Back
            </button>
            <ul>
              <li>
                <button
                  class={show === 1 ? "active" : ""}
                  onClick={() => setShow(1)}
                >
                  Documents
                </button>
              </li>
              <li>
                <button
                  class={show === 2 ? "active" : ""}
                  onClick={() => setShow(2)}
                >
                  Users
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={show === 1 ? "d-block" : "d-none"}>
        <DocumentUpload
          viewData={viewData}
          setViewData={setViewData}
          documents={orgDocs}
          setOrgDocs={setOrgDocs}
        />
      </div>

      <div className={show === 2 ? "d-block" : "d-none"}>
        <CreatUser
          viewData={viewData}
          setViewData={setViewData}
          users={orgUsers}
          setOrgUsers={setOrgUsers}
        />
      </div>

      <div className="row align-items-center justify-content-between d-none">
        <div className="col-md-12 text-center notFoundData">
          <img src="/images/notFound.png"></img>
          <h2>No data Source exist</h2>
          <p>Upload text,PDF, URLs and DOC file</p>
        </div>
      </div>
    </>
  );
}

export default UploadDocument;

// older
// import React, { useEffect, useState } from "react";
// import DocumentUpload from "./DocumentUpload";
// import CreatUser from "./CreatUser";
// import { Navigate } from "react-router-dom";
// import axios from "axios";
// import { useSelector } from "react-redux";
// function UploadDocument({ viewData, setViewData }) {
//   const { User = {} } = useSelector((state) => state);
//   let userId = User?.data?._id;
//   const [show, setShow] = useState(1);
//   const [orgDocs, setOrgDocs] = useState([]);
//   const [orgUsers, setOrgUsers] = useState([]);

//   console.log(viewData, "viewData", userId);

//   const fetchOrgDetails = async () => {
//     try {
//       const { data } = await axios.get(
//         `${process.env.REACT_APP_API_BACKEND_URL}/v1/user/doc/${userId}/${viewData?._id}`
//       );
//       console.log(data, "response");
//       if (data?.status) {
//         setOrgDocs(data?.data?.orgDoc);
//         setOrgUsers(data?.data?.orgUser);
//       }
//       return;
//     } catch (error) {
//       throw error;
//     } finally {
//     }
//   };

//   useEffect(() => {
//     fetchOrgDetails();
//   }, [viewData]);

//   return (
//     <>
//       <div className="row">
//         <div className="col-xl-12">
//           <div class="chatTab">
//             <button
//               className="backBtn align-items-start"
//               onClick={() => {
//                 setViewData(null);
//               }}
//             >
//               Back
//             </button>
//             <ul>
//               <li>
//                 <button
//                   class={show === 1 ? "active" : ""}
//                   onClick={() => setShow(1)}
//                 >
//                   Documents
//                 </button>
//               </li>
//               <li>
//                 <button
//                   class={show === 2 ? "active" : ""}
//                   onClick={() => setShow(2)}
//                 >
//                   Users
//                 </button>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>

//       <div className={show === 1 ? "d-block" : "d-none"}>
//         <DocumentUpload
//           viewData={viewData}
//           setViewData={setViewData}
//           documents={orgDocs}
//           setOrgDocs={setOrgDocs}
//         />
//       </div>

//       <div className={show === 2 ? "d-block" : "d-none"}>
//         <CreatUser
//           viewData={viewData}
//           setViewData={setViewData}
//           users={orgUsers}
//           setOrgUsers={setOrgUsers}
//         />
//       </div>

//       <div className="row align-items-center justify-content-between d-none">
//         <div className="col-md-12 text-center notFoundData">
//           <img src="/images/notFound.png"></img>
//           <h2>No data Source exist</h2>
//           <p>Upload text,PDF, URLs and DOC file</p>
//         </div>
//       </div>
//     </>
//   );
// }

// export default UploadDocument;

import React from 'react'
import StepInteriorDesign from './StepInteriorDesign'
import AudioStepCard from './AudioStepCard'

function DubInteriorDesign({setNeedHelp}) {
  return (
    <>  
        <div className='position-relative'>
            <button className='backBtn align-items-start' onClick={()=>setNeedHelp(false)}>Back</button>
            <h2 class="text-center text-white mb-4">How to Dub Interior Design</h2>
        </div>
       <StepInteriorDesign/>
       <AudioStepCard/>
    </>
  )
}

export default DubInteriorDesign
import React, { useCallback, useEffect, useRef, useState } from "react";
import Page from "../homeComponents/Page";

import Services from "../homeComponents/Services";
// import UserExpirence from "../homeComponents/UserExpirence";
// import TextFeature from "../homeComponents/Features/TextFeature";


// 
import Feature from "../homeComponents/Features/Feature";
import FirstBanner from "../homeComponents/FirstBanner";
// import Insight from "../homeComponents/Security";
import BottomBanner from "../homeComponents/BottomBanner";
import SecondBanner from "../homeComponents/SecondBanner";
import MidBanner from "../homeComponents/MidBanner";
import ThirdBanner from "../homeComponents/ThirdBanner";
import ChatbotAi from "../homeComponents/ChatbotAi";

import { FaAngleRight,FaRegArrowAltCircleRight,FaArrowRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import AOS from 'aos'
import 'aos/dist/aos.css'
import TexttoImage from "../homeComponents/TexttoImage";
import TexttoVideo from "../homeComponents/TexttoVideo";
import InteriorDesigne from "../homeComponents/InteriorDesigne";
import ImagetoStory from "../homeComponents/ImagetoStory";
// import { duration } from "moment/moment";
function HomeDemo() {
    const [product, setProduct] = useState([]);
    const [overview, setOverView] = useState([]);
    const [feature, setFeature] = useState([]);
    const [uploadDocument, setUploadDocument] = useState([]);
    const [textBlock, setTextBlock] = useState([]);
    const [exuber, setExuber] = useState([]);
    const [insightText, setInsightText] = useState([]);
    const [audio, setAudio] = useState([]);
    const [audioText, setAudioText] = useState([]);
    const [globalAI, setGlobalAI] = useState([]);
    const [toolkitData, setToolKitData] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [AiData, setAIData] = useState([]);
    // const [scale, setScale] = useState(1.5);
    // const scaleRef = useRef(scale);
    const { User = {} } = useSelector((state) => state);
    let userId = User?.data?._id;
  
    const fecthProducts = useCallback(async () => {
      const res = await fetch(process.env.REACT_APP_CONTENT_URL);
      const data = await res.json();
  
      const title = data?.story?.content?.body[0]?.title;
      const description = data?.story?.content?.body[0]?.description;
      const newdata = {
        title: title,
        description: description,
      };
  
      setProduct(newdata);
    });
  
    const fecthOverview = useCallback(async () => {
      const res = await fetch(process.env.REACT_APP_CONTENT_URL);
      const data = await res.json();
      console.log("data", data);
      const image = data?.story?.content?.body[1]?.image;
      const title = data?.story?.content?.body[1]?.title;
      const detail =
        data?.story?.content?.body[1]?.description?.content[0]?.content[0]?.text;
  
      //  const description = data?.story?.content?.body[1]?.description;
      const newdata = {
        title: title,
        image: image,
        detail: detail,
        subdescription:
          data?.story?.content?.body[1]?.description?.content[1]?.content?.map(
            (ele) => {
              return {
                subtopic: ele?.content[0]?.content[0]?.text,
              };
            }
          ),
      };
  
      setOverView(newdata);
    });
    
  
    const fecthFeature = useCallback(async () => {
      const res = await fetch(process.env.REACT_APP_CONTENT_URL);
      const data = await res.json();
    
  
      const title = data?.story?.content?.body[2]?.title;
      const detail = data?.story?.content?.body[2]?.description;
  
      const newdata = {
        title: title,
        description: detail,
        Images: data?.story?.content?.body[3]?.columns?.map((ele) => {
          return {
            image: ele?.image,
            title: ele?.title,
            description: ele?.description?.content[0]?.content[0]?.text,
          };
        }),
      };
  
      setFeature(newdata);
    });
  
    const fecthDocument = useCallback(async () => {
      const res = await fetch(process.env.REACT_APP_CONTENT_URL);
      const data = await res.json();
  
      const title = data?.story?.content?.body[5]?.heading_text;
      const image = data?.story?.content?.body[7]?.image?.filename;
      const discription = data?.story?.content?.body[7]?.text;
      const newdata = {
        title: title,
        image: image,
        description: discription,
      };
  
      setUploadDocument(newdata);
    });
  
    const fecthText = useCallback(async () => {
      const res = await fetch(process.env.REACT_APP_CONTENT_URL);
      const data = await res.json();
  
      const newdata = {
        DetailText: data?.story?.content?.body[8]?.columns?.map((ele) => {
          return {
            miniTitle: ele?.title,
            minidescription: ele?.description,
          };
        }),
      };
  
      setTextBlock(newdata);
    });
  
    const fecthExuber = useCallback(async () => {
      const res = await fetch(process.env.REACT_APP_CONTENT_URL);
      const data = await res.json();
  
      const title = data?.story?.content?.body[9]?.title;
      const description =
        data?.story?.content?.body[9]?.description?.content[0]?.content[0]?.text;
      const image = data?.story?.content?.body[9]?.image;
  
      const newdata = {
        title,
        description,
        image,
      };
  
      setExuber(newdata);
    });
  
    const fecthTextBlock = useCallback(async () => {
      const res = await fetch(process.env.REACT_APP_CONTENT_URL);
      const data = await res.json();
  
      const newdata = {
        DetailText: data?.story?.content?.body[11]?.columns?.map((ele) => {
          return {
            miniTitle: ele?.title,
            minidescription: ele?.description,
          };
        }),
      };
  
      setInsightText(newdata);
    });
  
    const fecthAutomatedText = useCallback(async () => {
      const res = await fetch(process.env.REACT_APP_CONTENT_URL);
      const data = await res.json();
  
      const title = data?.story?.content?.body[12]?.title;
      const description =
        data?.story?.content?.body[12]?.description?.content[0]?.content[0]?.text;
      const image = data?.story?.content?.body[12]?.image;
  
      const newdata = {
        title,
        description,
        image,
      };
  
      setAudio(newdata);
    });
  
    const fecthAudioText = useCallback(async () => {
      const res = await fetch(process.env.REACT_APP_CONTENT_URL);
      const data = await res.json();
  
      const newdata = {
        DetailText: data?.story?.content?.body[13]?.columns?.map((ele) => {
          return {
            subtitle: ele?.title,
            subdescription: ele?.description,
          };
        }),
      };
      setAudioText(newdata);
    });
  
    const fecthGlobalAI = useCallback(async () => {
      const res = await fetch(process.env.REACT_APP_CONTENT_URL);
      const data = await res.json();
  
      const title = data?.story?.content?.body[14]?.title;
  
      const newdata = {
        title: title,
        // description:detail,
        Images: data?.story?.content?.body[16]?.columns?.map((ele) => {
          return {
            image: ele?.image,
            title: ele?.name,
            description: ele?.title,
          };
        }),
      };
  
      setGlobalAI(newdata);
    });
  
    const fecthToolKit = useCallback(async () => {
      const res = await fetch(process.env.REACT_APP_CONTENT_URL);
      const data = await res.json();
  
      const title = data?.story?.content?.body[17]?.title;
      const description =
        data?.story?.content?.body[17]?.description?.content[0]?.content[0]?.text;
      const image = data?.story?.content?.body[17]?.image;
  
      const newdata = {
        title,
        description,
        image,
      };
  
      setToolKitData(newdata);
    });
  
    const fecthGridText = useCallback(async () => {
      const res = await fetch(process.env.REACT_APP_CONTENT_URL);
      const data = await res.json();
  
      const newdata = {
        DetailText: data?.story?.content?.body[19]?.columns?.map((ele) => {
          return {
            subtitle: ele?.title,
            subdescription: ele?.description,
          };
        }),
      };
      setGridData(newdata);
    });
  
    const fecthAI = useCallback(async () => {
      const res = await fetch(process.env.REACT_APP_CONTENT_URL);
      const data = await res.json();
  
      const title = data?.story?.content?.body[20]?.title;
      const description =
        data?.story?.content?.body[20]?.description?.content[0]?.content[0]?.text;
      const image = data?.story?.content?.body[20]?.image;
  
      const newdata = {
        title,
        description,
        image,
      };
  
      setAIData(newdata);
    });
  
    useEffect(() => {
      fecthProducts();
      fecthOverview();
      fecthFeature();
      fecthDocument();
      fecthText();
      fecthExuber();
      fecthTextBlock();
      fecthAutomatedText();
      fecthAudioText();
      fecthGlobalAI();
      fecthToolKit();
      fecthGridText();
      fecthAI();
    }, []);
  
    // useEffect(() => {
    //   const iframe = document.getElementById("zoomIframe");
    
    //   const handleScroll = () => {
    //     let newScale = 1.5 - window.scrollY / 2000; 
    //     if (newScale < 0.5) newScale = 0.5;
    //     if (newScale > 1.5) newScale = 1.5;
    //     setScale(newScale);
    //   };
    
    //   const animate = () => {
    //     const diff = scale - scaleRef.current;
    //     if (Math.abs(diff) > 0.001) {
    //       scaleRef.current += diff / 20;
    //       iframe.style.transform = `scale(${scaleRef.current})`;
    //     }
    //     requestAnimationFrame(animate);
    //   };
    
    //   window.addEventListener("scroll", handleScroll);
    //   animate();
    
    //   return () => {
    //     window.removeEventListener("scroll", handleScroll);
    //   };
    // }, [scale]);
  
  
    useEffect(()=> {
        AOS.init({duration: 2000})
    }, [])
    
     
  return (
    <>
        <div className='homeVideo'>
            <iframe id="zoomIframe" title="theater" src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/efe0b38cfb42ebc3e9aa0b3ebee6c188/iframe?muted=true&loop=true&autoplay=true&controls=false" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"  frameborder="0"></iframe>
            <div className="position-absolute bannerContent">
                <div className="container text-center">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <h1>Elevating creativity through the power of artificial intelligence.</h1>
                            <p>Exuber is an applied AI research shaping the next era of art, entertainment and human creativity.</p>
                            <Link to=""><FaRegArrowAltCircleRight/> Try Exuber for Free</Link>
                        </div>
                    </div>
                    
                </div>    
            </div>
        </div>
  <section className="mainSection">
  <aside className="leftPanel">
    <div className="row h-100 align-items-center">
      <div className="col-md-12">
        <div className="leftCategory" data-aos="fade-right">
          <nav id="navigation-menu">
            <ul className="sideCat">
              <li><a href="#two"><FaArrowRight/> Exuber's AI Dubbing</a></li>
              <li><a href="#five"><FaArrowRight/> SoundScribe</a></li>
              <li><a href="#eight"><FaArrowRight/> Exuber's Text to Image</a></li>
              <li><a href="#one"><FaArrowRight/> Exuber's Text to Video</a></li>
              <li><a href="#four"><FaArrowRight/> Exuber's ShopInVideo</a></li>
              <li><a href="#six"><FaArrowRight/> GlobalSpeakAI</a></li>
              <li><a href="#seven"><FaArrowRight/> ChatBot AI</a></li>
              <li><a href="#three"><FaArrowRight/> Interior Designer</a></li>
              <li><a href="#nine"><FaArrowRight/> Image to Story</a></li>
              
              {/* <li><a href="#two"><FaAngleRight/> Exuber's AI Dubbing</a></li>
              <li><a href="#four"><FaAngleRight/> Exuber's ShopInVideo</a></li>
              <li><a href="#five"><FaAngleRight/> SoundScribe</a></li>
              <li><a href="#eight"><FaAngleRight/> Exuber's Text to Image</a></li>
              <li><a href="#six"><FaAngleRight/> GlobalSpeakAI</a></li>
              <li><a href="#seven"><FaAngleRight/> ChatBot AI</a></li> */}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </aside>

  <aside className="topSection">

    <div id="two" className="sectionTwo">
      <div className="container py-5">
          <div className="row justify-content-end">
            <div className="col-xl-9">
              <h1 className="title_2">How to use AI Dubbing</h1>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 1</h3>
                <h2>Write a prompt</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 2</h3>
                <h2>Adjust Settings</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 3</h3>
                <h2>Step 3</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="buttonPannel">
                <Link to="" className="active">Read more</Link>
                <Link to="">Try AI Dubbing</Link>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div id="five" className="sectionFive">
      <div className="container py-5">
        <div className="row justify-content-end">
        <div className="col-xl-9">
              <h1 className="title_2">How to use SoundScribe</h1>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 1</h3>
                <h2>Write a prompt</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 2</h3>
                <h2>Adjust Settings</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 3</h3>
                <h2>Step 3</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="buttonPannel">
                <Link to="" className="active">Read more</Link>
                <Link to="">Try AI Dubbing</Link>
              </div>
            </div>
        </div>
      </div>
    </div>

    <div id="eight" className="sectionEight">
      <div className="container py-5">
          <div className="row justify-content-end">
          <div className="col-xl-9">
              <h1 className="title_2">How to use Exuber's Text to Image</h1>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 1</h3>
                <h2>Write a prompt</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 2</h3>
                <h2>Adjust Settings</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 3</h3>
                <h2>Step 3</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="buttonPannel">
                <Link to="" className="active">Read more</Link>
                <Link to="">Try AI Dubbing</Link>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div id="one" className="sectionOne">
      <div className="container py-5">
          <div className="row justify-content-end">
          <div className="col-xl-9">
              <h1 className="title_2">How to use Exuber's Text to Video</h1>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 1</h3>
                <h2>Write a prompt</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 2</h3>
                <h2>Adjust Settings</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 3</h3>
                <h2>Step 3</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="buttonPannel">
                <Link to="" className="active">Read more</Link>
                <Link to="">Try AI Dubbing</Link>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div id="four" className="sectionFour">
      <div className="container py-5">
        <div className="row justify-content-end">
        <div className="col-xl-9">
              <h1 className="title_2">How to use Exuber's ShopInVideo</h1>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 1</h3>
                <h2>Write a prompt</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 2</h3>
                <h2>Adjust Settings</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 3</h3>
                <h2>Step 3</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="buttonPannel">
                <Link to="" className="active">Read more</Link>
                <Link to="">Try AI Dubbing</Link>
              </div>
            </div>
        </div>
      </div>
    </div>

    <div id="six" className="sectionSix py-xl-5">
      <div className="container py-5">
        <div className="row justify-content-end">
        <div className="col-xl-9">
              <h1 className="title_2">How to use GlobalSpeakAI</h1>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 1</h3>
                <h2>Write a prompt</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 2</h3>
                <h2>Adjust Settings</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 3</h3>
                <h2>Step 3</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="buttonPannel">
                <Link to="" className="active">Read more</Link>
                <Link to="">Try AI Dubbing</Link>
              </div>
            </div>
        </div>
      </div>
    </div>

    <div id="seven" className="sectionFour">
      <div className="container py-5">
          <div className="row justify-content-end">
          <div className="col-xl-9">
              <h1 className="title_2">How to use ChatBot AI</h1>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 1</h3>
                <h2>Write a prompt</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 2</h3>
                <h2>Adjust Settings</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 3</h3>
                <h2>Step 3</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="buttonPannel">
                <Link to="" className="active">Read more</Link>
                <Link to="">Try AI Dubbing</Link>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div id="three" className="sectionThree">
      <div className="container py-5">
          <div className="row justify-content-end">
          <div className="col-xl-9">
              <h1 className="title_2">How to use Interior Designer</h1>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 1</h3>
                <h2>Write a prompt</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 2</h3>
                <h2>Adjust Settings</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 3</h3>
                <h2>Step 3</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="buttonPannel">
                <Link to="" className="active">Read more</Link>
                <Link to="">Try AI Dubbing</Link>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div id="nine" className="sectionNine">
      <div className="container py-5">
          <div className="row justify-content-end">
            <div className="col-xl-9">
              <h1 className="title_2">How to use Image to Story</h1>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 1</h3>
                <h2>Write a prompt</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 2</h3>
                <h2>Adjust Settings</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="stepDesign">
                <h3><FaArrowRight/> Step 3</h3>
                <h2>Step 3</h2>
                <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
              </div>
              <div className="buttonPannel">
                <Link to="" className="active">Read more</Link>
                <Link to="">Try AI Dubbing</Link>
              </div>
            </div>
          </div>
        </div>
    </div>




  </aside>

</section>

    </>
  )
}

export default HomeDemo
// OTPValidation.js

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { SetUserData } from "../store/reducer/user";
import { SetpopupReducerData } from "../store/reducer/PopupReducer";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

function OTPValidation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [otp, setOTP] = useState("");
  const [apiError, setApiError] = useState("");
  const { User = {} } = useSelector((state) => state);
  const { Extra = {} } = useSelector((state) => state);
  const userEmail = User?.data?.email;
  const forgotEmail = Extra?.forgotEmail?.forgotEmail;
  console.log("forgotEmail",forgotEmail);
  console.log("UserUser",User);

  console.log(Extra);

  const { PopupReducer } = useSelector((state) => state);

  const handleClosePopup = () => {
    dispatch(SetpopupReducerData({ modalType: "", showModal: false }));
  };

  const handleCongratulation = (e) => {
    dispatch(
      SetpopupReducerData({
        modalType: "CONGRATULATIONS",
        showModal: true,
      })
    );
  };

  const handleResetPassword = (e) => {
    dispatch(
      SetpopupReducerData({
        modalType: "RESETPASSWORD",
        showModal: true,
      })
    );
  };

  const handleSubmitOTP = async (e) => {
    e.preventDefault();
    // handleClosePopup();
    try {
      // Validate OTP and proceed with the signup process
      const reqObj = {otp};

      if(userEmail){
        // console.log('reach', userEmail)
        reqObj.email = userEmail
      } else if(User?.signUpdata){
        // console.log('reachd', userEmail)
        reqObj.email = User?.signUpdata?.email;
      } else {
        // console.log('reachddddd', userEmail)
        reqObj.email = forgotEmail;
      }

      

      console.log( "emaidata", reqObj,  userEmail);

      const response = await API({
        url: apiURl.validateOTP, // Replace with your API endpoint for OTP validation
        method: "POST",
        body: reqObj, // Send OTP to the server for validation
        formData: false,
      });

      if (response?.status || response?.status === "true") {
        // OTP validation successful
        // Dispatch any necessary actions, e.g., setting user data

        if (forgotEmail) {
          console.log("You are ehere");
          handleResetPassword();
          // handleClosePopup();
        } else if (userEmail) {
          dispatch(SetUserData({ ...response?.data, signupLogin: false }));
          handleCongratulation();
        } else if (User.signUpdata) {
          localStorage.setItem("token", User?.signUpdata?.jwtToken);
          dispatch(SetUserData({ ...User?.signUpdata }));
          handleCongratulation();
        }

        // Redirect to the desired page, e.g., profile page
        // navigate("/profile");
      } else {
        setApiError(response?.message);
      }
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  return (
    <Modal
      show={PopupReducer.modal.showModal}
      // show={false}
      hide={handleClosePopup}
      size="md offerPoup loginWidth modal-dialog-centered"
    >
      <div className="otp-validation-container pop_content login-screen">
        <h2>OTP Validation</h2>
        <p>
          Please enter the OTP sent to your email to complete the signup
          process.
        </p>
        <div className="otp-form">
          <form onSubmit={handleSubmitOTP}>
            <div className="form-group mt-3">
              <input
                type="text"
                id="otp"
                name="otp"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
              />
            </div>
            {apiError && <p className="text-danger">{apiError}</p>}
            <div className="d-flex justify-content-between mt-3">
              <div className="form-group mt-3">
                <button
                  type="submit"
                  onClick={() => handleClosePopup()}
                  className="cancel-button m-0"
                >
                  Cancel
                </button>
              </div>
              <div className="form-group mt-3">
                <button type="submit" className="btn btn-primary">
                  Verify OTP
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default OTPValidation;

import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import ShareSocialMedia from "./ShareSocialMedia";
import { BiX } from "react-icons/bi";
import { API, getAwsImageUrl, getAwsVideoUrl } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Row, Spinner } from "react-bootstrap";
import axios from "axios";
import { useEffect } from "react";
import ConvertStoryToAudio from "./ConvertStoryToAudio";

function ImageToStoryGenrerate({
  id,
  outputStory,
  inputImage,
  setConvertedStory,
  target_language,
  category,
  setCategory,
  objId,
}) {
  const [viewStoryData, setViewStoryData] = useState(false);

  console.log(
    outputStory,
    target_language,
    "outputStory",
    objId,
    "category",
    category,
    setCategory
  );

  // console.log(outputStory, "outputStoryoutputStoryoutputStory");

  const audioUrl = outputStory;
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  const linkRef = useRef();
  const [show, setShow] = useState(false);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [imageToStoryData, setImageToStoryData] = useState(null);
  const [content, setContent] = useState("");

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(audioUrl)
      .then(() => toast.success("Link copied to clipboard"))
      .catch((err) => toast.error("Something went wrong", err));
  };

  const fetchUrlContent = async () => {
    try {
      const response = await axios.get(outputStory);
      console.log(response.data, "storystory");
      setContent(response.data);
    } catch (error) {
      console.error("Error fetching URL content:", error);
    }
  };
  useEffect(() => {
    if (outputStory) {
      fetchUrlContent();
    }
  }, [outputStory]);

  const fetchImageToStoryData = async () => {
    try {
      const resp = await API({
        url: `${apiURl.callComponantData}/${id}/${userId}`,
        method: "GET",
      });

      console.log("fetchImageToStoryDatafsdfdsSco", resp);

      if (resp) {
        setImageToStoryData(resp?.data); // Assuming resp.data contains your desired data
      } else {
        setImageToStoryData(null);
      }
    } catch (error) {
      console.error("Error fetching face search data:", error);
      setImageToStoryData(null);
    }
  };

  useEffect(() => {
    if (id) {
      console.log("fetchImageToStoryDatarrrrrr", id);
      fetchImageToStoryData();
    }
  }, [id]);

  const handleDownload = async () => {
    const response = await fetch(audioUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "audio.mp3"; // or any other name you want
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return (
    <div>
      {!viewStoryData ? (
        <div className="refernceAudio text-center mt-5 ">
          <Row className="text-center videoTitle">
            <div className="refernceAudio">
              <form id="contact" method="post" className="form" role="form">
                <div className="row">
                  <div className="col-lg-12 form-group pb-3">
                    <div className="d-flex justify-content-between"></div>
                    <div className="d-flex justify-content-between">
                      <label htmlFor="audio_text" className="form-label">
                        Generated Story <span className="text-danger"> *</span>
                      </label>
                    </div>
                    <textarea
                      className="form-control"
                      type="text"
                      id="prompt"
                      name="prompt"
                      // value={content}
                      value={outputStory}
                      rows={8}
                      onChange={(e) => setConvertedStory(e.target.value)}
                    />
                    {/* {errors.prompt ? (
                <span className="text-danger" style={{ fontSize: "14px" }}>
                  {errors.prompt}
                </span>
              ) : (
                ""
              )} */}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12 col-md-12 form-group synBtn text-center">
                    {/* {isLoading ? ( */}
                    {/* <button className="btn btn-primary mt-4">
                      <Spinner animation="border" size="lg" /> Processing...
                    </button> */}
                    {/* ) : ( */}
                    <button
                      type="button"
                      className="btn btn-primary mt-4"
                      // onClick={handleConvert}
                      // disabled={isLoading}
                      onClick={() => setViewStoryData(true)}
                    >
                      Generate Audio
                    </button>
                    {/* )} */}
                  </div>
                </div>
              </form>
            </div>
          </Row>

          <Modal className="shareSocial" show={show}>
            <Modal.Header>
              <Modal.Title>Publish To MintAVibe</Modal.Title>
              <button className="bgNone" onClick={() => setShow(false)}>
                <img src="/images/closeBtn.png" />
              </button>
            </Modal.Header>
            <Modal.Body>
              <ShareSocialMedia link={outputStory}></ShareSocialMedia>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <ConvertStoryToAudio
          viewStoryData={viewStoryData}
          setViewStoryData={setViewStoryData}
          // outputStory={content}
          outputStory={outputStory}
          setConvertedStory={setConvertedStory}
          target_language={imageToStoryData?.target_language}
          category={imageToStoryData?.category}
          setCategory={setCategory}
          objId={imageToStoryData?._id}
        />
      )}
    </div>
  );
}

export default ImageToStoryGenrerate;

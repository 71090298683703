import React from 'react'
import StepCard from './StepCard'
import StepTextToImage from './StepTextToImage'

function DubTextToImage({setNeedHelp}) {
  return (
    <>  
        <div className='position-relative'>
            <button className='backBtn align-items-start' onClick={()=>setNeedHelp(false)}>Back</button>
            <h2 class="text-center text-white mb-4">How to Dub Text To Image</h2>
        </div>
        <StepTextToImage/>
        <StepCard/>
    </>
  )
}

export default DubTextToImage
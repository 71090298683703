import React from 'react'
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import Header from './Header';

function AIDubbing() {
  return (
    <>
    <Header />
    <div className="bgColor">
        <section className="aiDubbing">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 my-auto">
                        <h1>AI Dubbing that surprisingly human</h1>
                        <p>Interacting with artificial intelligence used to feel difficult, overwhelming, and a bit robotic. With Jasper Chat, simply ask your friendly chatbot to generate ideas, revise your content, or even make you laugh.</p>
                        <a href="" className="link"><FaRegArrowAltCircleRight/> Try Exuber for Free</a>
                    </div>
                    <div className="col-md-6">
                    <iframe id="zoomIframe" title="theater" src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/efe0b38cfb42ebc3e9aa0b3ebee6c188/iframe?muted=true&loop=true&autoplay=true&controls=false" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"  frameborder="0"></iframe>
                    </div>
                </div>
            </div>
        </section>
        <section className="getStarted">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 text-center">
                        <h2>Get started with AI Dubbing</h2>
                        <p>Interacting with artificial intelligence used to feel difficult, overwhelming, and a bit robotic. With Jasper Chat, simply ask your friendly chatbot to generate ideas, revise your content, or even make you laugh.</p>
                    </div>
                    <div className="col-lg-10 mt-5">
                        <div id="banner-slider" className="owl-carousel owl-theme">
                            <div className="item"> 
                                <img src="./images/tool_image.png" alt=""/>
                                <div className="stepBox_2">
                                    <h3><i className="fas fa-arrow-right"></i> Step 1</h3>
                                    <h4>Write a prompt</h4>
                                    <p>Exuber is an applied AI research shaping the next era of art,  entertainment and human creativity.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="whyExuber">
            <div className="container">
                <h4>Why Exuber ?</h4>
                <div className="row">
                    <div className="col-md-4">
                        <div className="whyBox">
                            <h6>Endless features</h6>
                            <p>New AI Magic Tools are being released weekly, in addition to our fully-featured timeline video editor. Remove backgrounds with Green Screen, create custom LUTs, and add subtitles or export transcripts all in the same app. Say goodbye to clunky third-party plugins and external softwares — in Runway you have it all.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="whyBox">
                            <h6>Edit faster</h6>
                            <p>Your workflow has never been faster. We’re leveraging the latest technologies that AI has to offer to create unique next-generation tooling, but with a familiar feeling and easy entry-point. Make manual rotoscoping, sourcing stock imagery, and outsourcing storyboarding a thing of the past.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="whyBox">
                            <h6>In the cloud</h6>
                            <p>Pick up work from anywhere in the world. Collaborate in real-time. Keep your assets centralized, and just a click away. Use Runway by simply opening your browser and logging in — no software downloads or hard drive storage required.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    </>
  )
}

export default AIDubbing
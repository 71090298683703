import React from 'react'

function TextToVideoStepCard() {
  return (
    <div className='row justify-content-center'>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/text_to_video_1.jpeg'/></figure>
                <span>01</span>
                <p>Craft Your Story: Type your narrative, separating distinct sentences with a pipe symbol "|".</p>
            </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/text_to_video_2.jpeg'/></figure>
                <span>02</span>
                <p> Determine Video Length & Quality: Choose your preferred video duration and select the desired Frames Per Second (FPS) for optimal video smoothness.</p>
            </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/text_to_video_3.jpeg'/></figure>
                <span>03</span>
                <p>Elevate with Audio: Optionally, upload your personalized audio to complement the video.</p>
            </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/text_to_video_4.jpeg'/></figure>
                <span>04</span>
                <p>Transformation Begins: Click 'Convert'. Sit back as we craft your vision into video.</p>
            </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 mb-4'>
            <div className='stepBox'>
                <figure><img className='img-fluid' src='/images/text_to_video_5.jpeg'/></figure>
                <span>05</span>
                <p>Stay Notified: Expect an email update within 15-20 minutes once your video is ready. Simply login to Exuber or click the link in the email to preview your creation.</p>
            </div>
        </div>
    </div>
  )
}

export default TextToVideoStepCard
import React from 'react'
import { Modal } from 'react-bootstrap'
import { BiCopy } from 'react-icons/bi'

function Congrtulation({show, HandleClose}) {
  return (
    <>
    <Modal className="congratulation" show={show} onHide={() => HandleClose()}>
        <Modal.Body>
        <button className="bgNone" onClick={() => HandleClose()}>
            <img src="/images/closeBtn.png" />
          </button>
        <div className="congTitle_2 text-center">
            <figure><img src="/images/win.svg" /></figure>
            <h1>Congratulations!</h1>
            <p>You won <span>3000</span> Exuber points, You can use your ponts to get benefits of tools</p>
            <button>Let's Start</button>
        </div>
        
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Congrtulation
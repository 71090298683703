import React from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { FaPlus } from "react-icons/fa6";
// import { Dropdown, } from "react-bootstrap";
// import Dropdown from 'react-bootstrap/Dropdown';
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import UploadFile from "./UploadFile";
import Organisations from "./Organisations";
import { useState } from "react";
import UploadDocument from "./UploadDocument";

import { AiFillCamera } from "react-icons/ai";
import { Route, useNavigate, Navigate } from "react-router-dom";
import axios from "axios";
import NewChat from "./NewChat";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiURl } from "../store/actions";
import { SetUserData, SetpopupReducerData } from "../store/reducer";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { API, getAwsImageUrl } from "../apiwrapper";
import { toast } from "react-toastify";

function Extractdocument() {
  const [viewData, setViewData] = useState(null);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState();
  const [image, setImage] = useState();
  const [organizationSearch, setOrganizationSearch] = useState([]);

  const [inputField, setInputField] = useState({
    organizationName: "",
    type: "IT",
  });

  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;

  const { organizationName = "", type = " " } = inputField;

  const handleChange = (e) => {
    setInputField({
      ...inputField,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const handleProfileImage = async (event) => {
    const image = event.target.files && event.target.files[0];
    setImage(image);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     setIsLoading(true);

  //     const ApiURL = `${process.env.REACT_APP_API_BACKEND_URL_PYTHON}/createnewAssistant`;
  //     const response = await axios.post(ApiURL, inputField);
  //     console.log("Response:", response);

  //     if (response.data?.status != "failed") {
  //       console.log("status success", response?.data?.status);
  //       const access_Key = response.data?.access_key;
  //       // const image_url = await getAwsImageUrl(image, `${userId}/ImageUrl/`);
  //       const apiURL_1 = `${process.env.REACT_APP_API_BACKEND_URL}/v1/users/${userId}`;

  //       const organizationData = {
  //         name: organizationName,
  //         type: type,

  //         access_Key: access_Key,
  //       };
  //       const requestData = {
  //         organization: organizationData,
  //       };
  //       console.log(access_Key, "organizationData: ---->", organizationData);

  //       const response_1 = await axios.put(apiURL_1, requestData);

  //       console.log("Response_1:", response_1.data);

  //       if (response_1.status || response.status) {
  //         toast.success("Organization Add suceessfully");
  //         dispatch(
  //           SetUserData({
  //             ...User?.data,
  //             organization: response_1.data?.data?.organization,
  //           })
  //         );
  //         setShow(false);
  //         setIsLoading(false);
  //       }
  //     } else {
  //       toast.error(response.data?.error);
  //       setShow(false);
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     toast.error(error);
  //     setIsLoading(false);
  //   }
  // };

  const handleSubmit = async (searchQuery) => {
    try {
      const apiURL_1 = `${process.env.REACT_APP_API_BACKEND_URL}/v1/users/${userId}`;

      const organizationData = {
        name: organizationName,
        type: type,
      };
      const requestData = {
        organization: organizationData,
      };
      console.log("organizationData: ---->", organizationData);

      const response_1 = await axios.put(apiURL_1, requestData);
      if (response_1?.status) {
        toast.success(response_1?.data?.message);
        dispatch(
          SetUserData({
            ...User?.data,
            organization: response_1.data?.data?.organization,
          })
        );
        setShow(false);
        setIsLoading(false);
        // dispatch(
        //   SetpopupReducerData({
        //     modalType: "PENDINGTASK",
        //     showModal: true,
        //   })
        // );
        setInputField("");
      }
    } catch (error) {
      // console.error("Error searching documents:", error);
      console.error("Error:", error);
      toast.error(error);
      setIsLoading(false);
    }
  };

  // const searchDocuments = async (searchQuery) => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_BACKEND_URL}/v1/searchOrgName?id=${userId}&name=${searchQuery}`
  //     );
  //     if (response.status) {
  //       console.log("docsearchbelwallalalllll", response?.data);
  //       setOrganizationSearch(response?.data?.data);
  //     }
  //   } catch (error) {
  //     console.error("Error searching documents:", error);
  //   }
  // };

  // const searchDocuments = async (searchQuery) => {
  //   try {
  //     await API({
  //       url: `/v1/searchOrgName?id=${userId}&name=${searchQuery}`,
  //       method: "GET",
  //     }).then((resp) => {
  //       if (resp) {
  //         console.log("docsearchbelwallalalllll", resp?.data);
  //         setOrganizationSearch(resp?.data);
  //       } else {
  //         setOrganizationSearch(resp?.data);
  //       }
  //     });
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  const searchDocuments = async (searchQuery) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_BACKEND_URL}/v1/searchOrgName?id=${userId}&name=${searchQuery}`
      );
      // console.log(resp?.data?.data, "RERERERERER");
      setOrganizationSearch(resp?.data?.data || []);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  return (
    <>
      {!viewData ? (
        <>
          {" "}
          <div className="row">
            <div className="col-md-9">
              <div className="docSearch">
                <button>
                  <BiSearchAlt2 onClick={() => searchDocuments(searchQuery)} />
                </button>
                <input
                  type="text"
                  placeholder=""
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    searchDocuments(e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="col-md-3">
              <button className="creatNew" onClick={() => setShow(true)}>
                Create New{" "}
                <span>
                  <FaPlus />
                </span>
              </button>
            </div>
          </div>
          <div className="row align-items-center justify-content-between d-none">
            <div className="col-md-12 text-center notFoundData">
              <img src="/images/notFound.png"></img>
              <h2>No data Source exist</h2>
              <p>Upload text,PDF, URLs and DOC file</p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <Organisations
                viewData={viewData}
                setViewData={setViewData}
                organizationSearch={organizationSearch}
                setOrganizationSearch={setOrganizationSearch}
              />
            </div>
          </div>
        </>
      ) : (
        <UploadDocument viewData={viewData} setViewData={setViewData} />
      )}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="md"
        className="modalForm"
      >
        <Modal.Header closeButton>
          <h4>
            Add Organization{" "}
            <div>
              <h6>While adding, Please wait for atleast 3 min.</h6>
            </div>
          </h4>
        </Modal.Header>
        <Modal.Body>
          <form className="form" onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="orgProfile">
                {/* <figure> */}
                {/* <img src="/images/profile.jpg" alt="Profile"  value={image}
                    onChange={handleChange} />   */}

                {/* <input
                        style={{ display: "none" }}
                        ref={inputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleProfileImage}
                      />
                  <span onClick={handleClick}><AiFillCamera /></span> */}
                {/* </figure> */}
              </div>
            </div>
            <div className="mb-4">
              <input
                type="text"
                className="form-control"
                id="organizationName"
                name="organizationName"
                placeholder="Organization Name"
                value={organizationName}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <select
                className="form-select"
                type="text"
                name="type"
                value={type}
                onChange={handleChange}
              >
                <option>IT Firm</option>
                <option>Financial Services</option>
                <option>Educational Institution</option>
                <option>HealthCare Company</option>
                <option>Entertainment & Media</option>
                <option>Manufacturing Firm</option>
                <option>Other</option>
                {/* Add more options */}
              </select>
            </div>

            <div className="row">
              <div className="col-xs-12 col-md-12 form-group synBtn text-center">
                {isLoading ? (
                  <button className="btn btn-primary mt-4">
                    <Spinner animation="border" size="lg" /> Processing...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary mt-4"
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Extractdocument;

import React from 'react'
import NewsletterForm from './NewsletterForm';
import { Link } from 'react-router-dom';

import {FaFacebookF,FaTwitter,FaLinkedinIn,FaInstagram,FaYoutube,FaDiscord} from "react-icons/fa";
import { useState } from 'react';
import Congrtulation from '../PopupModal/Congrtulation';
function Newsletter() {
    const [showCong, setShowCong]=useState()
  return (
    <>
        {showCong ? <Congrtulation show={showCong} HandleClose={setShowCong}/>
        :""
        }
        <section className='newsletter'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10">
                        <h2 className='neswTitle pb-4 pb-lg-5'>Sign up for our newsletter to stay up to <span>date with tech news!</span></h2>                    
                        <NewsletterForm/>
                    </div>
                </div>
            </div>
        </section>
        <section className='ftMenuSection'>
            <div className="container">
                <div className='ftlogo text-center'><img src='/images/logo.svg'/></div>
                <div className="row justify-content-between">
                    <div className="col-md-6 col-lg-4">
                        <div className='ftABout'>
                            <h5>About Exuber</h5>
                            <p>Exuber stands as a pioneering force in the realm of applied AI research, poised to define the forthcoming era of art, entertainment, and human creativity. With a steadfast commitment to innovation, Exuber delves into the intricate fusion of artificial intelligence and creative expression.</p>
                        </div>    
                    </div>
                    <div className="col-md-3 col-lg-2">
                        <h5>Product</h5>
                        <ul className='ftMenu'>
                        <li><Link to="https://exuber.ai/blog/ai-dubbing/">Video Dubbing</Link></li>
                        <li><Link to="https://exuber.ai/blog/soundscribe/">SoundScribe</Link></li>
                        <li><Link to="https://exuber.ai/blog/text-to-image/">Text to Image</Link></li>
                        <li><Link to="https://exuber.ai/blog/text-to-video/">Text to Music</Link></li>
                        <li><Link to="">ShopInVideo</Link></li>
                        <li><Link to="https://exuber.ai/blog/text-to-speech/">Text to Speech</Link></li>
                        <li><Link to="https://exuber.ai/blog/chatbot/">ChatBot AI</Link></li>
                        <li><Link to="https://exuber.ai/blog/interiordesigner/">Interior Designer</Link></li>
                        <li><Link to="https://exuber.ai/blog/image-to-story/">Image to Story</Link></li>
                        <li><Link to="https://exuber.ai/blog/image-enhancer/">Image Enhancer</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3 col-lg-3">
                    <h5>Company</h5>
                        <ul className='ftMenu'>
                            <li><Link to="/aboutus">About Us</Link></li>
                            <li><Link to="/aboutus/:chooseus?">Why Us</Link></li>
                            <li><Link to="/profile/package">Pricing</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                            <li><a href="https://exuber.ai/blog">Blog</a></li>
                            <li><Link to="/termsconditions">Terms & Conditions</Link></li>
                            <li><Link to="/privacy">Privacy Policy</Link></li>
                            <li><button className='btn btn-primanry' onClick={()=>setShowCong(true)}>Congratulation Popup</button></li>
                        </ul>
                    </div>
                </div>
                <div className='copyRight d-flex justify-content-between align-items-center'>
                <p>© Copyright 2023 exuber</p>
                    <ul className="socialMedia">
                        <li><Link to="https://www.facebook.com/exuber.official" target='_blank'><FaFacebookF /></Link></li>
                        <li><Link to="https://twitter.com/Exuber_AI" target='_blank'><FaTwitter /></Link></li>
                        <li><Link to="https://www.linkedin.com/company/exuber/" target='_blank'><FaLinkedinIn /></Link></li>
                        <li><Link to="https://www.instagram.com/exuber_ai/" target='_blank'><FaInstagram /></Link></li>
                        <li><Link to="https://www.youtube.com/@exuber.official" target="_blank"><FaYoutube/></Link></li>
                        <li><Link to="https://discord.com/invite/AVRb76pG" target="_blank"><FaDiscord/></Link></li>
                    </ul>
                </div>
            </div>
        </section>
    </>
  )
}

export default Newsletter
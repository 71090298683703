/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-unused-vars */
import React, { useDebugValue, useState } from "react";

import { API, getAwsVideoUrl } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import {
  isValid,
  validateRequire,
} from "../../components/Validation/InputValidation";
import { Spinner } from "react-bootstrap";
import TextToMusic from "./TextToMusic";
import axios from "axios";
import ConvertAudio from "./ConvertAudio";
import { SetUserData, SetpopupReducerData } from "../store/reducer";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-hot-toast";
import DubAudios from "./DubAudio";
import ConvertImage from "./ConvertImage";

import { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import DubTextToVideo from "./DubTextToVideo";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsInfoCircle } from "react-icons/bs";
import {
  SetCompletedNotifications,
  SetPendingNotifications,
} from "../store/reducer/extra";

function ConvertTextToVideo({ category = {}, setCategory }) {
  const [needhelp, setNeedHelp] = useState(false);
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  let secretKey = User?.data?.secret_key;
  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [texttomusic, setTextToMusic] = useState([]);
  const [convertedAudio, setConvertedAudio] = useState("");
  const dispatch = useDispatch();

  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");

  const [inputField, setInputField] = useState({
    prompt: "",
    fps: 5,
    video_duration: "",

    // image_width: "",
  });
  const [durationTouched, setDurationTouched] = useState(false);
  const navigate = useNavigate();

  const { prompt = "", fps = "", video_duration = "" } = inputField;

  const handleChange = (e) => {
    setInputField({
      ...inputField,
      [e.target.name]: e.target.value,
    });
    setApiErrors({ message: "" });
    setApiErrors({});
  };

  const handleLogin = (e) => {
    dispatch(
      SetpopupReducerData({
        modalType: "LOGIN",
        showModal: true,
      })
    );
  };

  const fetchCompleteList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.notification}/${userId}`,
        method: "GET",
      });
      if (response.status) {
        console.log(response.data.tasks);
        if (response.data.tasks.PENDING) {
          dispatch(SetPendingNotifications(response.data.tasks.PENDING));
        } else {
          dispatch(SetPendingNotifications([]));
        }
        dispatch(SetCompletedNotifications(response.data.tasks.COMPLETED));
      }
    } catch (error) {
      throw error;
    }
  };

  const validateAll = () => {
    let err1 = {};
    err1.category = validateRequire(category);
    err1.prompt = validateRequire(prompt);
    // err1.prompt = validateRequire(prompt);
    // err1.modelType = validateRequire(modelType);
    err1.video_duration =
      Number(video_duration) > 60 ? "Duration cannot be greater than 30" : "";

    return err1;
  };

  const handleChangeFile = (e) => {
    var file = e.target.files[0];
    setFile(file);
    setFileName(file.name);
    setErrors({ ...errors, file: "" });
  };

  // const getConvertedAudio = async (params) => {

  //    console.log('params', params)
  //   let response = await axios.get(`${process.env.REACT_APP_CONVERTED_IMAGE}?id=${params}`);

  //   if(response?.data?.status === "processing"){
  //     getConvertedAudio(params);

  //     setIsLoading(true);
  //   }
  //   else{
  //     setIsLoading(false);
  //     console.log('response?.data?.rep_url', response?.data?.rep_url)
  //     setConvertedAudio(response?.data?.rep_url)

  //   }
  //   console.log("response", convertedAudio);

  // };

  const handleConvert = async (e) => {
    e.preventDefault();
    if (!userId) {
      handleLogin();
      return;
    }
    try {
      let err = validateAll();
      if (isValid(err)) {
        let awsUrl = await getAwsVideoUrl(file, `${userId}/InputVideos/`);

        const payload = {
          ...inputField,
          category: category?._id,
          audio_filepath: awsUrl,
          secret_key: secretKey,
          // userId: userId,
          // uploaded_video: "",

          // target_language: "",
          // converted_video: "",
          // video_conversion_status: "",
          // audio_conversion_status: "",
          // music_conversion_status: "",
          // video_filename: "",
        };
        setIsLoading(true);

        await API({
          url: apiURl.textToVideoNew,
          method: "POST",
          body: { ...payload },
          formData: false,
        }).then((data) => {
          if (data?.status || data?.status === "true") {
            console.log("data------>texttovideo", data);
            fetchCompleteList();
            dispatch(
              SetpopupReducerData({
                modalType: "PENDINGTASK",
                showModal: true,
                category: `${category.name}`,
              })
            );
            // if (data?.data?.response?.error) {
            //   toast.error(data?.data?.response?.error);

            // }

            // else {
            //   // getConvertedAudio(data?.data?.response?.rep_id);
            //   toast.success("Your project is in the works! We'll drop you an email once it's all set and ready. Stay tuned!",{ duration: 7000 });
            // }

            dispatch(SetUserData(data?.data?.updatedUserInfo));
          } else {
            setApiErrors({ message: data?.message });
            setErrors({});
          }
        });
      } else {
        setErrors(err);
      }
    } catch (error) {
      setApiErrors({ message: error?.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {needhelp ? (
        <DubTextToVideo setNeedHelp={setNeedHelp} />
      ) : (
        <>
          <h2 className="text-center text-white mb-4">Text To Video</h2>
          <div className="refernceAudio">
            {/* <img src="/images/coming-soon.svg"></img> */}
            <form id="contact" method="post" className="form" role="form">
              <div className="row">
                <div className="col-lg-12 form-group pb-3">
                  <div className="d-flex justify-content-between">
                    <label htmlFor="audio_text" className="form-label">
                      Text{" "}
                      <span className="text-danger">
                        {" "}
                        * Give your prompts by using the pipe symbol e.g. A man
                        repairing his car in his garage | OR A butterfy flying |
                        cat running{" "}
                      </span>
                      {/* <OverlayTrigger overlay={<Tooltip>Merge your two prompts by using the pipe symbol <p></p> e.g. A man repairing his car | in his garage. </Tooltip>}>
                  <span className="d-inline-block">
                    <p style={{ pointerEvents: 'none' }}>
                    <BsInfoCircle />
                    </p>
                  </span>
                </OverlayTrigger> */}
                    </label>
                    <button
                      onClick={() => setNeedHelp(true)}
                      className="needHelp"
                    >
                      Need Help?
                    </button>
                  </div>
                  <textarea
                    className="form-control"
                    type="text"
                    id="prompt"
                    name="prompt"
                    value={prompt}
                    rows={8}
                    onChange={handleChange}
                  />
                  {errors.prompt ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.prompt}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="modelType" className="form-label">
                    Duration<span className="text-danger"> *</span>
                  </label>
                  <input
                    className="form-select"
                    type="text"
                    value={video_duration}
                    name="video_duration"
                    onChange={handleChange}
                  />

                  <span className="text-danger"> *</span>

                  {errors.video_duration ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.video_duration}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="modelType" className="form-label">
                    Frames Per Second <span className="text-danger"> *</span>
                  </label>
                  <select
                    className="form-select"
                    type="text"
                    value={fps}
                    name="fps"
                    onChange={handleChange}
                  >
                    {/* <option value={""}>Select FPS</option> */}
                    <option value={"5"}>5</option>
                    <option value={"15"}>15</option>
                    <option value={"40"}>20</option>
                    <option value={"50"}>25</option>
                    {/* <option value={"60"}>60</option> */}
                  </select>

                  {errors.fps ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.fps}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* 
          {apiErrors.message ? (
            <span className="text-danger text-center">{apiErrors.message}</span>
          ) : (
            ""
          )} */}
              <div className="row">
                <div className="col-xs-12 col-md-12 form-group">
                  <label htmlFor="modelType" className="form-label">
                    maximum: 60 Second
                  </label>

                  <div class="customUpload sizedecrese">
                    <input
                      className="form-control file-border"
                      type="file"
                      id="formFile"
                      accept="audio/*"
                      onChange={handleChangeFile}
                    />
                    <span class="upload-btn" id="upload-btn">
                      {/* {fileName} */}
                    </span>
                  </div>

                  {errors.file ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.file}
                    </span>
                  ) : (
                    ""
                  )}
                  <label htmlFor="modelType" className="form-label pt-2">
                    Optional <span className="text-danger"> *</span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-12 form-group synBtn text-center">
                  {isLoading ? (
                    <button className="btn btn-primary mt-4">
                      <Spinner animation="border" size="lg" /> Processing...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary mt-4 bt-text-to-video"
                      onClick={handleConvert}
                      disabled={isLoading}
                    >
                      <img src="/images/soundwave.png" alt="" /> Convert Now
                    </button>
                  )}

                  {/* <button className="" type="">
            <img src="/images/soundwave.png" /> synthesize
          </button> */}
                </div>
              </div>
            </form>
          </div>
          <div className="text-center">
            <Link
              to=""
              className="seeTitle"
              onClick={() => setCategory({ _id: "", name: "dashboard" })}
            >
              See All Videos in Dashboard
            </Link>
          </div>
          {convertedAudio && <ConvertImage outputImage={convertedAudio} />}
        </>
      )}
    </>
  );
}

export default ConvertTextToVideo;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { Link, useLocation, useNavigate } from "react-router-dom";

function NotifyCompletedCard({ completetask }) {




  const formatDate = (dt) => {
    const date = new Date(dt);
    return date.toDateString();
  };


  return (
    <>
      <div className="noifyListMain">
        {completetask?.map((ele) => {
          return (
            <>
              <div className="noifyList d-flex">
                <div className="position-relative">
                  <figure>
                    <img src={ele?.image_filepath} alt="" />
                  </figure>
                  <div className="toolIcon">
                    <img
                      src={ele?.categoryIcon || "/images/SeoScribe.svg"}
                      alt=""
                    />
                  </div>
                </div>
                <Link to={`/AITool/${ele?.categoryName}`}>
                  <div className="ms-lg-4 ms-0 mt-4 mt-lg-0">
                    <p>Your task for {ele?.categoryName} has been completed.</p>
                    <div className="date">{formatDate(ele.updatedAt)}</div>
                  </div>
                </Link>
                {/* <div className="ms-lg-4 ms-0 mt-4 mt-lg-0">
                  <p>Your task for {ele?.categoryName} has been completed.</p>
                  <div className="date">{formatDate(ele.updatedAt)}</div>
                </div> */}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default NotifyCompletedCard;




//  <>
// <div className="noifyListMain">
//   {completetask?.map((ele) => {
//     return (
//       <>
//         {showConvertTextToMusic && (
//           <div className="py-5">
//             <ConvertTextToMusic
//               category={ele?.categoryName}
//               setCategory={setCategory}
//             />
//           </div>
//         )}
//         <div className="noifyList d-flex">
//           <div className="position-relative">
//             <figure>
//               <img src={ele?.image_filepath} alt="" />
//             </figure>
//             <div className="toolIcon">
//               <img
//                 src={ele?.categoryIcon || "/images/SeoScribe.svg"}
//                 alt=""
//               />
//             </div>
//           </div>

          
//           <div
//             className="ms-lg-4 ms-0 mt-4 mt-lg-0"
//             onClick={toggleConvertTextToMusic}
//           >
//             <p>Your task for {ele?.categoryName} has been completed.</p>
//             <div className="date">{formatDate(ele.updatedAt)}</div>
//           </div>
         

          
//         </div>
//       </>
//     );
//   })}
// </div>
// </> 

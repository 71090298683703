import React from 'react'
import Header from '../homeComponents/Header'
import ContactForm from '../homeComponents/ContactForm'
import ContactAddress from '../homeComponents/ContactAddress'

function ContactUs() {
  return (
    <>
        <Header />
        <section className="contactSection" id="contactUs">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <ContactAddress />
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUs
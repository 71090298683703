import React from 'react'
import Newsletter from './Newsletter'

function Footer() {
  return (
    <>
        <Newsletter></Newsletter>
    </>
  )
}

export default Footer
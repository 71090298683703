import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetOptimizeContent } from "../store/reducer/extra";
import { Spinner } from "react-bootstrap";
import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import SeoScribe from "./SeoScribe";
import { SetpopupReducerData } from "../store/reducer/PopupReducer";
import {
  SetCompletedNotifications,
  SetPendingNotifications,
} from "../store/reducer/extra";

function Keyword({ category, show, setShow, id, newshortcontent }) {
  const dispatch = useDispatch();
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  let secretKey = User?.data?.secret_key;
  const { Extra = {} } = useSelector((state) => state);
  let keywords = Extra?.keywords;
  let cont = Extra?.seoContent?.content;

  const [isLoading, setIsLoading] = useState(false);

  const [allChecked, setAllChecked] = useState(false);
  const [keywordPayload, setKeywordPayload] = useState({});
  const [scorScribeData, setScorScribeData] = useState(null);
  const [selectedKeywords, setSelectedKeywords] = useState({});

  // console.log(
  //   "hello",
  //   scorScribeData?.extracted_keywords,
  //   scorScribeData?.suggested_keywords
  // );
  let suggestedData = {};

  const fetchScoScribeData = async () => {
    try {
      const resp = await API({
        url: `${apiURl.callComponantData}/${id}/${userId}`,
        method: "GET",
      });
      console.log("dfsdfsfsfsdfdskeyword", resp);

      if (resp) {
        setScorScribeData(resp?.data); // Assuming resp.data contains your desired data
      } else {
        setScorScribeData(null);
      }
      // findCommonKeywords()
    } catch (error) {
      console.error("Error fetching face search data:", error);
      setScorScribeData(null);
    }
  };

  useEffect(() => {
    if (id) {
      console.log("ScoScribeDatarrrrrr", id);
      fetchScoScribeData();
    }
  }, [id]);

  const fetchCompleteList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.notification}/${userId}`,
        method: "GET",
      });
      if (response.status) {
        console.log(response.data.tasks);
        if (response.data.tasks.PENDING) {
          dispatch(SetPendingNotifications(response.data.tasks.PENDING));
        } else {
          dispatch(SetPendingNotifications([]));
        }
        dispatch(SetCompletedNotifications(response.data.tasks.COMPLETED));
      }
    } catch (error) {
      throw error;
    }
  };

  // const findCommonKeywords = () => {
  //   const extractedKeys = new Set(Object.keys(scorScribeData?.extracted_keywords || {}));
  //   const suggestedKeys = new Set(Object.keys(scorScribeData?.suggested_keywords || {}));
  //   const commonKeys = [...extractedKeys].filter((item) => suggestedKeys.has(item));
  //   console.log(commonKeys, 'commonkeyssssss',scorScribeData?.extracted_keywords,scorScribeData?.suggested_keywords, commonKeys.reduce((acc, key) => ({ ...acc, [key]: scorScribeData.suggested_keywords[key] }), {}))
  //   return commonKeys.reduce((acc, key) => ({ ...acc, [key]: scorScribeData.suggested_keywords[key] }), {});
  // };

  // const handleChange = (e, key_name) => {
  //   console.log("keyname", e, key_name);

  //   //  const suggestedData={
  //   //   key:e,
  //   //   value:key_name,
  //   //  }

  //   const suggestedData = {
  //     key: e.target.checked, // true or false depending on checkbox state
  //     value: key_name,
  //   };

  //   // Update the selectedKeywords state
  //   setSelectedKeywords((prevSelectedKeywords) => {
  //     // Copying the current state
  //     const updatedSelection = { ...prevSelectedKeywords };

  //     return updatedSelection;
  //   });

  //   console.log("suggestedData", suggestedData);
  //   console.log(e.target.checked, key_name);
  //   // keywords.forEach((key, idx) => {
  //   //   if (e.target.checked && key.keyword === key_name) {
  //   //     console.log(key.suggest);
  //   //     obj[key_name] = key.suggest;
  //   //   } else if (!e.target.checked && key.keyword === key_name) {
  //   //     delete obj[key_name];
  //   //   }
  //   // });
  // };
  const handleChange = (e, key_name, value) => {
    setSelectedKeywords((prevSelectedKeywords) => {
      const isChecked = e.target.checked;
      console.log(
        isChecked,
        "isCheckedisChecked",
        key_name,
        "key_namekey_name"
      );
      if (isChecked) {
        // Add the keyword to selectedKeywords
        console.log(
          prevSelectedKeywords,
          "prevSelectedKeywordsprevSelectedKeywords"
        );
        return { ...prevSelectedKeywords, [key_name]: value };
      } else {
        // Remove the keyword from selectedKeywords
        const updatedKeywords = { ...prevSelectedKeywords };
        delete updatedKeywords[key_name];
        return updatedKeywords;
      }
    });
  };

  console.log("adfdsfsdfsdf", selectedKeywords);
  let allKeys = {};
  // const handleSelectAll = (e) => {
  //   if (e.target.checked) {
  //     keywords.forEach((key, idx) => {
  //       let { present, ...rest } = key;
  //       allKeys[rest.keyword] = rest.suggest;
  //     });
  //     setAllChecked(true);
  //   } else {
  //     setAllChecked(false);
  //   }
  // };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setAllChecked(isChecked);
    if (isChecked) {
      // Add all keywords to selectedKeywords
      const newSelectedKeywords = {};
      Object.entries(scorScribeData?.suggested_keywords || {}).forEach(
        ([keyword, value]) => {
          newSelectedKeywords[keyword] = value;
        }
      );
      setSelectedKeywords(newSelectedKeywords);
    } else {
      // Clear selectedKeywords
      setSelectedKeywords({});
    }
  };

  const handleOptimisedContent = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (allChecked) {
        setKeywordPayload(allKeys);
      } else {
        // console.log(obj);
        // setKeywordPayload(obj);
      }

      let reqObj = {
        // userId: userId,
        id: scorScribeData?._id,
        category: scorScribeData?.category,
        secret_key: secretKey,
        suggested_keywords: selectedKeywords,
        // generated_content: scorScribeData?.generated_content,
        generated_content: newshortcontent,

        type: "optimise_content",
      };
      try {
        console.log(reqObj, "reqObj");
        const response = await axios.post(
          `${process.env.REACT_APP_API_BACKEND_URL}/v1/optimise-content-new`,
          reqObj
        );
        if (response.status === 200 && response.status) {
          fetchCompleteList();
          dispatch(
            SetpopupReducerData({
              modalType: "PENDINGTASK",
              showModal: true,
              category: `${category.name}`,
            })
          );
          setIsLoading(false);
          // dispatch(SetOptimizeContent({content}));
          // dispatch(SetSEOKeywords(keywords));
          // setIsLoading(false);
        } else {
          alert("Error");
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div
        className="d-flex justify-content-between p-3 keyHeader align-items-center"
        id={"toggle-btn"}
      >
        <h3>Keywords</h3>
        <button
          type="button"
          className="toggle-btn"
          onClick={() => setShow(!show)}
        >
          <img src="/images/closeBtn.svg"></img>
        </button>
      </div>
      <div className="keywordMain">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                <label>
                  <input
                    type="checkbox"
                    name="checkbox"
                    onChange={(e) => handleSelectAll(e)}
                  />
                  Select all
                </label>
              </th>
              <th scope="col" align="right">
                Present
              </th>
              <th scope="col" align="right">
                Suggest
              </th>
            </tr>
          </thead>
          {scorScribeData?.suggested_keywords ? (
            <tbody>
              <tr>
                <td>
                  {Object.entries(scorScribeData?.suggested_keywords || {}).map(
                    ([keyword, value]) => {
                      return (
                        <label>
                          <input
                            type="checkbox"
                            name="checkbox"
                            checked={!!selectedKeywords[keyword]}
                            onChange={(e) => handleChange(e, keyword, value)}
                          />
                          {keyword}
                        </label>
                      );
                    }
                  )}
                </td>
                <td align="right">
                  {Object.entries(scorScribeData?.extracted_keywords || {}).map(
                    ([keyword, value]) => {
                      return <div className="blueBox">{value}</div>;
                    }
                  )}
                </td>
                <td align="right">
                  {Object.entries(scorScribeData?.suggested_keywords || {}).map(
                    ([keyword, suggestvalue]) => {
                      return (
                        <>
                          <div className="greenBox" style={{ width: "100px" }}>
                            {suggestvalue}
                          </div>
                        </>
                      );
                    }
                  )}
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="3">
                  It seems our AI is taking a break so it didn't generate the
                  keywords. Please try again after some time !!
                </td>
              </tr>
            </tbody>
          )}
        </table>
        {scorScribeData?.suggested_keywords && (
          <div className="optButton">
            {isLoading ? (
              <button className="btn btn-primary">
                <Spinner animation="border" size="lg" /> Processing...
              </button>
            ) : (
              <button onClick={handleOptimisedContent}>
                Optimise your Content
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Keyword;

// {keywords?.map((key, idx) => {
//   return (
//     <tr>
//       <th scope="row" valign="middle">
//         <label>
//           <input
//             onChange={(e, keyName) => handleChange(e, key.keyword)}
//             type="checkbox"
//             name="checkbox"
//             // checked={allChecked && true}
//           />
//           {key.keyword}
//         </label>
//       </th>
//       <td align="right">
//         <div className="blueBox">{key.present}</div>
//       </td>
//       <td align="right">
//         <div className="greenBox">{key.suggest.split(" ")[0]}</div>
//       </td>
//     </tr>
//   );
// })}

// const handleChange = (e, key_name) => {
//   console.log(e.target.checked, key_name);
//   keywords.forEach((key, idx) => {
//     if (e.target.checked && key.keyword === key_name) {
//       console.log(key.suggest);
//       obj[key_name] = key.suggest;
//     } else if (!e.target.checked && key.keyword === key_name) {
//       delete obj[key_name];
//     }
//   });

// };

// let allKeys = {};
// const handleSelectAll = (e) => {
//   if (e.target.checked) {
//     keywords.forEach((key, idx)=>{
//       let {present, ...rest} = key;
//       allKeys[rest.keyword] = rest.suggest;
//     })
//     setAllChecked(true);
//   } else {
//     setAllChecked(false);
//   }
// };

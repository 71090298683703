import React from "react";
function Feature({ title, Images, detail }) {
  
  return (
    <div>
      <h1 data-aos="zoom-in">{title}</h1>
      <p data-aos="zoom-in">{detail}</p>
        <div className="row gx-xl-5 langCity">
          {Images?.map((ele) => {
            return (
              <>
                <div className="col-sm-12 col-md-6 col-lg-4" data-aos="fade-up">
                  <figure>
                    <img src={ele.image} className="img-fluid" />
                    <figcaption>{ele.title}</figcaption>
                    <p className="mt-2">{ele.description}</p>
                  </figure>
                </div>
              </>
            );
          })}
        </div>
    </div>
  );
}

export default Feature;

import React from 'react'
import StepCard from './StepCard'
import StepVideo from './StepVideo'
import StepAudio from './StepAudio'
import AudioStepCard from './AudioStepCard'

function DubAudios({setNeedHelp}) {
  return (
    <>  
        <div className='position-relative'>
            <button className='backBtn align-items-start' onClick={()=>setNeedHelp(false)}>Back</button>
            <h2 class="text-center text-white mb-4">How to Dub Image To Story</h2>
        </div>
       <StepAudio/>
       <AudioStepCard/>
    </>
  )
}

export default DubAudios
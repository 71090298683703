import React, { useState } from "react";
import ReferenceAudio from "./ReferenceAudio";
import NaturalSpeech from "./NaturalSpeech";
import VoiceToVoice from "./VoiceToVoice";
import ConvertVideo from "./ConvertVideo";

function VoiceChange({ category = {}, setCategory }) {
  const [active, setActive] = useState(1);
  const handleClick = (ele) => {
    setActive(ele);
  };

  return (
   <>      {/* <ul className="tabing">
        <li>
          <button
            className={active === 1 ? "active" : ""}
            onClick={() => handleClick(1)}
          >
            <span>
              <img src="/images/speechIcon.png" />
            </span>
            Natural Speech
          </button>
        </li>
        <li>
          <button
            className={active === 2 ? "active" : ""}
            onClick={() => handleClick(2)}
          >
            <span>
              <img src="/images/audioIcon.png" />
            </span>
            Reference Audio
          </button>
        </li>
        <li>
          <button
            className={active === 3 ? "active" : ""}
            onClick={() => handleClick(3)}
          >
            <span>
              <img src="/images/voiceIcon.png" />
            </span>
            Voice to Voice
          </button>
        </li>
      </ul> */}
      {/* [{"jahnvi"=[{"fr":"az---ej"},{"en":"aj---ik"}]}]
      [1]
     */}
        
          <NaturalSpeech category={category}  setCategory={setCategory} />
          {/* <ConvertVideo category={category} /> */}
        
        <div className={active === 2 ? "d-block" : "d-none"}>
          <ReferenceAudio />
        </div>
        <div className={active === 3 ? "d-block" : "d-none"}>
          <VoiceToVoice />
        </div>
    
  
</>

  );
}

export default VoiceChange;

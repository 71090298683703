import React, { useState } from "react";
import { useEffect } from "react";
import CKEditor from "react-ckeditor-component";
import { useSelector, useDispatch } from "react-redux";
import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { SetSEOContent } from "../store/reducer/extra";

function OriginalEditer({
  id,
  seoChracterLength,
  SetSeoChracterLength,
  newshortcontent,
  setnewshortcontent,
}) {
  const dispatch = useDispatch();
  const [scorScribeData, setScorScribeData] = useState(null);
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  // const { Extra = {} } = useSelector((state) => state);
  // let cont = Extra?.seoContent?.content;
  const [CHARACTERS, setCharacters] = useState("");
  const [contentdata, SetContentData] = useState("");
  SetSeoChracterLength(CHARACTERS);

  // console.log(CHARACTERS, "CHARACTERSCHARACTERS");
  // console.log(contentdata, "contentdata");

  setnewshortcontent(contentdata);
  const fetchScoScribeData = async () => {
    try {
      const resp = await API({
        url: `${apiURl.callComponantData}/${id}/${userId}`,
        method: "GET",
      });

      console.log("dfsdfsfsfsdfdsoriginal", resp);

      if (resp) {
        setScorScribeData(resp?.data);
      } else {
        setScorScribeData(null);
      }
    } catch (error) {
      console.error("Error fetching face search data:", error);
      setScorScribeData(null);
    }
  };

  useEffect(() => {
    if (id) {
      console.log("ScoScribeDatarrrrrroriginal", id);
      fetchScoScribeData();
    }
  }, [id]);

  const onBlur = (evt) => {
    console.log("onBlur event called with event info: ", evt);
  };

  const afterPaste = (evt) => {
    console.log("afterPaste event called with event info: ", evt);
  };

  const onChange = (evt) => {
    const content = evt.editor.getData();
    const characterCount = content.replace(/<[^>]*>/g, "").length;
    SetContentData(content);
    setCharacters(characterCount);

    if (content.trim() === "") {
      setScorScribeData({ ...scorScribeData, generated_content: "" });
    } else {
      setScorScribeData({ ...scorScribeData, generated_content: content });
    }
  };

  return (
    <CKEditor
      CHARACTERS={CHARACTERS}
      activeClass="p10"
      content={scorScribeData?.generated_content}
      events={{
        blur: onBlur,
        afterPaste: afterPaste,
        change: onChange,
      }}
    />
  );
}

export default OriginalEditer;

import React from 'react'

function StepImage() {
  return (
    <>
        <div className='refernceAudio mb-5 text-center'>
        <iframe
                    className="textToVideo"
                    src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/d242cab77ce7e08b7c42a021ae2f5d99/iframe?muted=true&loop=true&autoplay=true&controls=false"
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowfullscreen="true"
                  ></iframe>
            {/* <video controls>
                <source src="/images/geetika_2.mp4" type="video/mp4" />
                <source src="rabbit320.webm" type="video/webm" />
            </video> */}
            <div className='watchTitle text-white'>
            Watch the video
                <span>or</span>
            Follow the instruction
            </div>
        </div>
    </>
  )
}

export default StepImage
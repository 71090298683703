import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SetUserData } from "../store/reducer";
import logger from "redux-logger";
import { BASECONFIG } from "../config";
import Header from '../homeComponents/Header'

function Package() {
  const [searchParams, setsearchParams] = useSearchParams();
  const [transactionData, setTransactionData] = useState([]);
  const id = searchParams.get("id");
  const token = searchParams.get("token");
  const session = searchParams.get("session");
  const type = searchParams.get("type");
 // const { id } = useParams();
  console.log("id", id, token, session, type);

  const URL = window.location.origin;

  console.log("id for payment", id);

  const dispatch = useDispatch();

  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  const [detail, setDetail] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [inputField, setInputField] = useState({
    userId: "",
    currency: "usd",
    plandetails: {
      point: "",
      name: "",
      amount: "",
    },
  });
  console.log('url', )

  const navigate = useNavigate();

// useEffect(()=>{


//     console.log("Effect running:", { token, session, type });
//     if (token && session && type) {
//       console.log("ankit");
//       let payload = {
//         type: type,
//         token: token,
//         session: session,
//         id: userId,
//       };

//       // setsearchParams(payload);
//       // console.log("payload111", payload);

//       // let url = `${window.location.origin}/profile/${userId}`
//       // console.log('url', url)
//       // console.log('url', url)

//       API({
//         url:apiURl.paymentVerify,
//         method: "POST",
//         body: {...payload},
//       }).then((data) => {
//         console.log("data?.data", data);
//         if (data?.status || data?.status === "true") {
//           toast.success("Payment Succsessfull");
//           navigate("/");
//           dispatch(SetUserData(data?.data))
//         } else {
//           console.log("toast");
//           toast.error(data?.message);
//         }
//       });
//     }
//     },[token,session,type]);

  const handleConvert = async (e, packageData) => {
    e.preventDefault();
    if (!userId) {
      navigate("/login");
      return;
    }

    try {
      //   let err = validateAll();
      //    if (isValid(err)) {
      // let awsUrl = await getAwsVideoUrl(file, `${userId}/InputVideos/`);
      // const urlParts = awsUrl.split("/");
      // const video_filename = urlParts[urlParts.length - 1];
      // console.log("video_filename", video_filename);

      const payload = {
        ...inputField,
        userId: userId,
        currency: "usd",

        url: `${window.location.origin}/profile/${userId}`,

        plandetails: {
          planId: packageData.planId,
          point: packageData.points,
          name: packageData.name,
          amount: packageData.amount,
        },
      };
      await API({
        url: apiURl.payment,
        method: "POST",
        body: { ...payload },
        formData: false,
      }).then((data) => {
        console.log("data---------", data);
        window.location = data?.Data;
        console.log("payment Data>>>>>>>", data.Data);
      
        if (data?.status || data?.status === "true") {
          toast.success("Success");
        } else {
          console.log("toast");
          // toast.error(data?.message);
        }
      });
    } catch (error) {
      //   setApiErrors({ message: error?.message });
    } finally {
      //   setIsLoading(false);
    }
  };

  const fetchPlanList = async () => {
    try {
      const response = await API({
        url: apiURl?.plan,
        method: "GET",
      });
      console.log("response----plan", response);
      if (response) {
        setPlanData(response);
      } else {
        setPlanData([]);
      }
    } catch (error) {
      throw error;
    }
  };
  console.log("planData", planData);

  useEffect(() => {
    fetchPlanList();
  }, []);

  return (
    <>
    <Header/>
    <section className="packages">
      <div className="container">
      <div className="row justify-content-between">
        {planData?.data?.map((ele) => {
          return (
            <>
              <div className="col-md-6 col-lg-3 position-relative">
                <div className="packTitle">
                <span><pre>{(ele?.name).replaceAll(" ", "\n")}</pre></span>
                </div>
                <div className="packageBox  px-4 pt-5">
                  <h2 className="text-center">
                    <span>$</span>
                    {ele?.price}
                  </h2>
                  <p className="text-center">
                    {ele?.description}
                  </p>

                  <div className="pointNumber">
                    <h6>Credit Points</h6>
                    <span>{ele?.points}</span>
                  </div>
                  <div className="updateBtn">
                    <button
                      onClick={(e) =>
                        handleConvert(e, {
                          planId: ele?._id,
                          name: ele?.name,
                          points: ele?.points,
                          amount: ele?.price,
                        })
                      }
                    >
                      Upgrade Plan
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <div className="row">
        <div className="col-md-12 d-flex align-items-center aiPoints text-center justify-content-center">
          <p>*Ai Dubbing- 10 Credit points</p>
          <p>*SoundScribe- 2 Credit points</p>
          <p>*Text to Image- 5 Credit points</p>
          <p>*Text to Video- 10 Credit points</p>
          <p>*Image to Story- 10 Credit points</p>
          <p>*Audio Generation- 5 Credit points</p>
          <p>*Globalspeak AI- 5 Credit points</p>
          


        </div>
      </div>
      </div>
    </section>
      
    </>
  );
}

export default Package;

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BsStar,
  BsCloudDownload,
  BsLink45Deg,
  BsTrash,
  BsThreeDots,
  BsFillCloudDownloadFill,
  BsStarFill,
  BsArrowsFullscreen,
} from "react-icons/bs";

import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { FaShare } from "react-icons/fa";
import { BiSolidDownload, BiSolidCopy, BiSolidTrashAlt } from "react-icons/bi";

import { FaRegShareSquare } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import ShareSocialMedia from "./ShareSocialMedia";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { useSelector } from "react-redux";
import axios from "axios";
import ConvertTextToImage from "./ConvertTextToImage";

function ProfileCard({
  converted_output,
  category,
  download,
  converted_video,
  converted_video1,
  outputAudio,
  item,
  fetchVideoList,
  fetchfavoriteData,
  RecentViewedData,
  thumbnailimg,
  convertedAudio,
  storyURI,
  storyAudio,
}) {
  const handle = useFullScreenHandle();
  const categoryName = item?.category?.name;
  const [storyString, setStoryString] = useState("");
  const navigate = useNavigate();

  if (storyURI) {
    fetch(`${storyURI}`)
      .then((res) => res.text())
      .then((data) => {
        setStoryString(data);
      });
  }

  console.log(storyString);
  const [inputField, setInputField] = useState({
    userId: "",
    videoId: "",
  });
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  console.log("userId", userId);
  const videoId = item?._id;
  const iframeRef = useRef(null);
  const [toggle, setToggle] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setToggle((current) => !current);
  };
  const [show, setShow] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isFavourite, setIsFavourite] = useState();
  const [isDeleted, setIsDeleted] = useState(item?.isDeleted || false);
  // const [dataDetails, setDataDetails] = useState();

  const [inputData, setInputData] = useState({
    isDeleted: true,
    isFavourite: true,
  });

  const ref = useRef(null); // Create a ref for the card

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(converted_video)
      .then(() => toast.success("Link copied to clipboard"))
      .catch((err) => toast.error("Something went wrong", err));
  };

  const showImage = async () => {
    try {
      //   let err = validateAll();
      //    if (isValid(err)) {
      // let awsUrl = await getAwsVideoUrl(file, `${userId}/InputVideos/`);
      // const urlParts = awsUrl.split("/");
      // const video_filename = urlParts[urlParts.length - 1];
      // console.log("video_filename", video_filename);

      const payload = {
        ...inputField,
        userId: userId,
        videoId: videoId,
      };
      await API({
        url: `${apiURl.users}/${userId}/${videoId}`,
        method: "POST",
        body: { ...payload },
        formData: false,
      }).then((data) => {
        console.log("data", data);

        // if (data?.Data.startsWith('https')) {
        //     window.location.href = data?.Data;
        //   } else {
        //     navigate(data?.Data);
        //   }
      });
    } catch (error) {
      //   setApiErrors({ message: error?.message });
    } finally {
      //   setIsLoading(false);
    }
    setShowImageModal(true);
  };

  // function to hide image modal
  const hideImage = async () => {
    setShowImageModal(false);
    // window.location.reload();
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(converted_video);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "ai file"; // Set the desired file name here
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error("Error downloading the file:", error);
      // Handle the error as needed (e.g., show an error message to the user)
    }
  };

  // const handleDownload = async () => {
  //   try {
  //     const response = await fetch(converted_video);
  //     const blob = await response.blob();

  //     // Determine the content type based on the file extension or type
  //     let contentType;
  //     if (converted_video.endsWith(".mp4")) {
  //       contentType = "video/mp4";
  //     } else if (converted_video.endsWith(".png")) {
  //       contentType = "image/png";
  //     } else if (converted_video.endsWith(".mp3")) {
  //       contentType = "audio/mpeg";
  //     } else {
  //       contentType = "application/octet-stream"; // Default to binary data if type is unknown wav
  //     }

  //     const url = window.URL.createObjectURL(new Blob([blob], { type: contentType }));
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "hello"; // Set the desired file name here
  //     document.body.appendChild(a);
  //     a.click();
  //     a.remove();
  //   } catch (error) {
  //     console.error("Error downloading the file:", error);
  //     // Handle the error as needed (e.g., show an error message to the user)
  //   }
  // };

  // const handleMoveToTrash = async () => {
  //   console.log();
  //   setIsDeleting(true);
  //   try {
  //     // Make the API call to update the isDeleted property of the video
  //     let response = await API({

  //       url: `${apiURl.videoList}/${userId}/${videoId}`,
  //       method: "PUT",
  //       body: { isDeleted: true, isFavourite: false }, // Set isDeleted to true to move to trash
  //       formData: false,
  //     });
  //     // If the API call is successful, you may perform any additional actions here.
  //     if (response.status === true) {
  //       window.location.reload();
  //       console.log("Video moved to trash successfully!");
  //     }
  //   } catch (error) {
  //     console.error("Error moving video to trash:", error);
  //   }
  //   setIsDeleting(false);
  // };

  // const handleRestoreFromTrash = async () => {
  //   setIsDeleting(true);
  //   try {
  //     let response = await API({
  //       url: `${apiURl.videoList}/${userId}/${videoId}`,
  //       method: "PUT",
  //       body: { isDeleted: false }, // Set isDeleted to false to restore from trash
  //       formData: false,
  //     });
  //     if (response.status === true) {
  //       window.location.reload();
  //       console.log("Video restored from trash successfully!");
  //     }
  //   } catch (error) {
  //     console.error("Error restoring video from trash:", error);
  //   }
  //   setIsDeleting(false);
  // };

  // const handleFavorite = async (isFavourite) => {
  //   // Send an API request to update the favorite status of the card on the server
  //   // setIsFavourite(true)
  //   try {
  //     let response = await API({
  //       url: `${apiURl.videoList}/${userId}/${videoId}`,
  //       method: "PUT",
  //       body: { isFavourite: !isFavourite },
  //       formData: false,
  //     });
  //     if (response.status === true) {
  //       window.location.reload();
  //       console.log("Video favorite status updated successfully!");
  //       fetchVideoList();
  //       fetchfavoriteData();
  //     }
  //   } catch (error) {
  //     console.error("Error updating video favorite status:", error);
  //   }
  //   // setIsFavourite(false)
  // };

  // const handlePermanentDelete = async () => {
  //   try {
  //     await API({
  //       url: `${apiURl.deleteParmanent}/${videoId}`,
  //       method: "DELETE",
  //     }).then((data) => {
  //       console.log("data", data);
  //       toast.success(data?.message);
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 1000);
  //     });
  //   } catch (error) {
  //     toast.error("Error deleting video permanently: " + error.message);
  //   }
  // };

  const date = new Date(item?.createdAt || "10/8/2023");

  const redirectingComp = (value, data) => {
    hideImage();
    console.log("reched here", value, JSON.stringify(data));
    navigate(`/AITool/${value}/${data}`);
  };

  return (
    <>
      <div className="cards" ref={ref}>
        {categoryName == "ImageEnhancer" ||
        categoryName == "text_to_image" ||
        categoryName == "interiorDesigner" ? (
          <figure>
            <img
              src={item?.converted_output} // ||  "/images/soundScribe.jpg"
              className="img-fluid"
              onClick={showImage}
            ></img>
            <Link
              to="#"
              onClick={(event) => {
                event.preventDefault();
                // handleFavorite(item?.isFavourite);
                // fetchfavoriteData(item?.isFavourite);
                // fetchVideoList(item?.isFavourite);
              }}
            >
              {/* {!isDeleted && (item?.isFavourite ? <BsStarFill /> : <BsStar />)} */}
            </Link>
          </figure>
        ) : categoryName == "text_to_music" ||
          categoryName == "text_to_speech" ||
          categoryName == "image_to_story" ||
          categoryName == "text_to_video" ||
          categoryName == "AI_Dubbing" ||
          categoryName == "SeoScribe" ||
          categoryName == "FaceSearch" ||
          categoryName == "text_to_speech" ? (
          <figure>
            <img
              src={"/images/soundScribe.jpg"}
              className="img-fluid"
              onClick={showImage}
            ></img>
            <Link
              to="#"
              onClick={(event) => {
                event.preventDefault();
                // handleFavorite(item?.isFavourite);
                // fetchfavoriteData(item?.isFavourite);
                // fetchVideoList(item?.isFavourite);
              }}
            >
              {/* {!isDeleted && (item?.isFavourite ? <BsStarFill /> : <BsStar />)} */}
            </Link>
          </figure>
        ) : (
          <figure>
            <img
              src="/images/soundScribe.jpg"
              alt="Description of Image"
              className="audio-image"
            />
          </figure>
        )}
        {/* <figure>
          <img
            src={ item?.converted_output ||  "/images/soundScribe.jpg"}
            className="img-fluid"
            onClick={showImage}
          ></img>
          <Link
            to="#"
            onClick={(event) => {
              event.preventDefault();
              handleFavorite(item?.isFavourite);
              fetchfavoriteData(item?.isFavourite);
              fetchVideoList(item?.isFavourite);
            }}
          >
            {!isDeleted && (item?.isFavourite ? <BsStarFill /> : <BsStar />)}
          </Link>
        </figure> */}
        <h3>{item?.category?.name || "AI category"}</h3>
        {/* {CardData?.length > 0 ? (
         CardData.map((ele) => {
          return ( */}
        <div className="d-flex align-items-center justify-content-between p-3 toggleData">
          <div className="d-flex cardDetail">
            <span>
              {" "}
              <img src={item?.category?.icon} />
              {/* <h5>{item?.prompt}</h5> */}
              {console.log("card123", item, item.category)}
            </span>

            <div>
              {/* <h6>{item?.prompt} </h6> */}
              <p>
                {date.toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                }) +
                  " " +
                  date.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
              </p>
            </div>
          </div>
          <Link
            to=""
            className={toggle ? "bg-salmon" : ""}
            onClick={() => handleClick(!toggle)}
          >
            <BsThreeDots />
          </Link>
        </div>
        {/* )})):("")} */}
      </div>

      {toggle && (
        <div class="togleBox">
          <ul>
            <li>
              <Link to="">Publish on MintAVibe - Amplify your brand</Link>
            </li>
            <li className="divider"></li>

            {isDeleted ? (
              <>
                <li>
                  {/* <Link to="" onMouseDown={handleRestoreFromTrash}>
                    <BsFillCloudDownloadFill />
                    Restore
                  </Link> */}
                </li>
                <li>
                  {/* <Link to="" onMouseDown={handlePermanentDelete}>
                    
                    <BsTrash />
                    Permanent Delete
                  </Link> */}
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link
                    to=""
                    onMouseDown={(event) => {
                      // event.preventDefault();
                      setShow(true);
                    }}
                  >
                    <FaRegShareSquare /> Share
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    onMouseDown={(event) => {
                      event.preventDefault();
                      handleDownload();
                    }}
                  >
                    {/* <BsCloudDownload /> */}
                    <BiSolidDownload style={{ fontSize: "22px" }} /> Download
                  </Link>{" "}
                </li>
                <li className="divider"></li>
                <li>
                  <Link
                    to=""
                    onMouseDown={(event) => {
                      event.preventDefault();
                      handleCopyLink();
                    }}
                  >
                    {/* <BsLink45Deg /> */}
                    <BiSolidCopy /> Copy Link
                  </Link>
                </li>
                <li>
                  {/* <Link to="" onMouseDown={handleMoveToTrash}>
                    
                    <BiSolidTrashAlt />
                    Send to Trash
                  </Link> */}
                </li>
              </>
            )}
          </ul>
        </div>
      )}
      {/* ================ Share Social Media Modal ======================= */}
      {/* <Modal className='shareSocial' show={show}>
        <Modal.Header>
           <Modal.Title>Share Social Media</Modal.Title>
           <button classname="bgNone" onClick={()=>setShow(false)}><img src='/Images/closeBtn.png'/></button>
        </Modal.Header>
        <Modal.Body>
            <ShareSocialMedia></ShareSocialMedia>
        </Modal.Body>
    </Modal> */}
      {/* ================ Share Social Media Modal ======================= */}

      {/* ================ Share Social Media Modal ======================= */}
      {/* <Modal className='shareSocial' show={show}>
        <Modal.Header>
           <Modal.Title>Rename</Modal.Title>
           <button className='bgNone' onClick={()=>setShow(false)}><img src='/Images/closeBtn.png'/></button>
        </Modal.Header>
        <Modal.Body>
            <ShareSocialMedia></ShareSocialMedia>
        </Modal.Body>
    </Modal> */}
      {/* ================ Share Social Media Modal ======================= */}

      {/* ================ Share Social Media Modal ======================= */}
      <Modal className="shareSocial" show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>Publish To MintAVibe </Modal.Title>
          <button className="bgNone" onClick={() => setShow(false)}>
            <img src="/images/closeBtn.png" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <ShareSocialMedia link={converted_video}></ShareSocialMedia>
        </Modal.Body>
      </Modal>
      {/* ================ Share Social Media Modal ======================= */}

      {/* ================ Image Modal ======================= */}
      <Modal className="shareSocial" show={showImageModal} onHide={hideImage}>
        <Modal.Header>
          <Modal.Title>Detail</Modal.Title>
          <div>
            {/* <BsArrowsFullscreen
              onClick={handle.enter}
              className="me-3"
              title="View in Full Screen"
            /> */}
            <button className="bgNone" onClick={hideImage}>
              <img src="/images/closeBtn.png" />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {categoryName == "ImageEnhancer" ||
          categoryName == "text_to_image" ||
          categoryName == "interiorDesigner" ? (
            // <iframe

            //   className="img-fluid"
            //   allowFullScreen
            //   // onClick={handleComponent(item?._id, item?.category?.name)}
            // />
            <a href={item?.converted_output} target="_blank">
              <img
                src={item?.converted_output}
                alt="Description of Image"
                className="img-fluid"
              />
            </a>
          ) : // <div style={{ position: "relative", display: "inline-block" }}>
          //   <iframe
          //     src={item?.converted_output}
          //     className="img-fluid"
          //     allowFullScreen
          //   />
          //   <button
          //     style={{
          //       position: "absolute",
          //       top: "50%",
          //       left: "100%",
          //       transform: "translate(10%, -50%)",
          //       zIndex: 1,
          //     }}
          //     onClick={() =>  (item?._id)}
          //   >
          //     Your Button Text
          //   </button>
          // </div>
          categoryName == "text_to_music" ||
            categoryName == "text_to_speech" ? (
            <div className="audio-container">
              <img
                src="/images/soundScribe.jpg"
                alt="Description of Image"
                className="audio-image"
              />
              <audio controls className="audio-controls">
                <source src={item?.converted_output} type="audio/ogg" />
              </audio>
            </div>
          ) : categoryName == "image_to_story" ? (
            item?.type === "image_to_story" ? (
              // <div style={{ position: "relative", display: "inline-block" }}>
              //   <iframe
              //     src={item?.converted_output}
              //     className="img-fluid"
              //     allowFullScreen
              //   />
              //   <button
              //     style={{
              //       backgroundColor: "blue",
              //       color: "white",
              //       padding: "10px 20px",
              //       borderRadius: "5px",
              //     }}
              //     onClick={async () => {
              //       try {
              //         // console.log(item?.type,"Imagetostoryddddddd", item);

              //         redirectingComp(item?.category?.name, item?._id);
              //         console.log("Imagetostoryddddddd", item);
              //       } catch (error) {
              //         console.error("Error fetching data:", error);
              //       }
              //     }}
              //   >
              //     show in Detail
              //   </button>
              // </div>
              <div style={{ position: "relative", display: "inline-block" }}>
                {/* Display text from the item object */}
                <p>{item?.converted_output}</p>{" "}
                {/* Example: Displaying description */}
                <button
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "5px",
                  }}
                  onClick={async () => {
                    try {
                      redirectingComp(item?.category?.name, item?._id);
                      console.log("Imagetostoryddddddd", item);
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    }
                  }}
                >
                  Show in Detail
                </button>
              </div>
            ) : (
              <iframe
                src={item?.converted_output}
                className="img-fluid"
                allowFullScreen
                // onClick={handleComponent(item?._id, item?.category?.name)}
              />
            )
          ) : categoryName == "text_to_video" ? (
            <iframe
              src={item?.converted_output}
              className="img-fluid"
              allowFullScreen
              // onClick={handleComponent(item?._id, item?.category?.name)}
            />
          ) : categoryName == "AI_Dubbing" ? (
            <iframe
              src={item?.converted_output}
              className="img-fluid"
              allowFullScreen
              // onClick={handleComponent(item?._id, item?.category?.name)}
            />
          ) : categoryName == "SeoScribe" ? (
            // <iframe  src={item?.converted_output}  className="img-fluid"  allowFullScreen />
            <div style={{ position: "relative", display: "inline-block" }}>
              <iframe
                src={item?.converted_output}
                className="img-fluid"
                allowFullScreen
              />
              <button
                style={{
                  backgroundColor: "blue",
                  color: "white",
                  padding: "10px 20px",
                  borderRadius: "5px",
                }}
                onClick={async () => {
                  try {
                    redirectingComp(item?.category?.name, item?._id);
                    console.log("SeoScribeddddddd", item);
                  } catch (error) {
                    console.error("Error fetching data:", error);
                  }
                }}
              >
                show in Detail
              </button>
            </div>
          ) : categoryName == "FaceSearch" ? (
            <div style={{ position: "relative", display: "inline-block" }}>
              <iframe
                src={item?.converted_output}
                className="img-fluid"
                allowFullScreen
              />
              <button
                style={{
                  backgroundColor: "blue",
                  color: "white",
                  padding: "10px 20px",
                  borderRadius: "5px",
                }}
                onClick={async () => {
                  try {
                    redirectingComp(item?.category?.name, item?._id);
                    console.log("faceddddddd", item);
                  } catch (error) {
                    console.error("Error fetching data:", error);
                  }
                }}
              >
                show in Detail
              </button>
            </div>
          ) : (
            <img
              src="/images/soundScribe.jpg"
              alt="Description of Image"
              className="audio-image"
            />
          )}
          {/* {outputAudio && (
            <div className="audio-container">
              <img
                src="/images/soundScribe.jpg"
                alt="Description of Image"
                className="audio-image"
              />
              <audio controls className="audio-controls">
                <source src={outputAudio} type="audio/ogg" />
              </audio>
            </div>
          )} */}
          {storyString && (
            <div>
              <p style={{ textAlign: "justify" }}>{storyString}</p>
            </div>
          )}
          {storyAudio && (
            <div>
              <audio controls className="audio-controls">
                <source src={storyAudio} type="audio/ogg" />
              </audio>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* ================ Image Modal ======================= */}
    </>
  );
}

export default ProfileCard;

import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import OriginalEditer from "./OriginalEditer";
import { Link } from "react-router-dom";
import Keyword from "./Keyword";
import { Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { SetSEOContent, SetSEOKeywords } from "../store/reducer/extra";
import OptimisedEditer from "./OptimisedEditer";
import { SetpopupReducerData } from "../store/reducer/PopupReducer";
import {
  SetCompletedNotifications,
  SetPendingNotifications,
} from "../store/reducer/extra";

function SeoScribe({ category, setCategory, id }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  let secretKey = User?.data?.secret_key;
  const toggle = () => {
    setShow(!show);
  };

  const [Keywords, setKeywords] = useState({});
  const [dist, setDist] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [scorScribeData, setScorScribeData] = useState(null);
  const [error, setError] = useState(""); // Added error state
  const [inputField, setInputField] = useState({
    topic: "",
    url: "",
  });

  const [seoChracterLength, SetSeoChracterLength] = useState("");
  const [newshortcontent, setnewshortcontent] = useState("");
  console.log(seoChracterLength, "seoChracterLengthseoChracterLength");
  // useEffect(() => {
  //   dispatch(SetSEOContent({}));
  //   dispatch(SetSEOKeywords([]));
  // }, []);

  const handleChange = (e) => {
    setInputField({
      ...inputField,
      [e.target.name]: e.target.value,
    });
  };

  const fetchScoScribeData = async () => {
    try {
      const resp = await API({
        url: `${apiURl.callComponantData}/${id}/${userId}`,
        method: "GET",
      });

      console.log("dfsdfsfsfsdfdsSco", resp);

      if (resp) {
        setScorScribeData(resp?.data); // Assuming resp.data contains your desired data
      } else {
        setScorScribeData(null);
      }
    } catch (error) {
      console.error("Error fetching face search data:", error);
      setScorScribeData(null);
    }
  };

  useEffect(() => {
    if (id) {
      console.log("ScoScribeDatarrrrrr", id);
      fetchScoScribeData();
    }
  }, [id]);

  const fetchCompleteList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.notification}/${userId}`,
        method: "GET",
      });
      if (response.status) {
        console.log(response.data.tasks);
        if (response.data.tasks.PENDING) {
          dispatch(SetPendingNotifications(response.data.tasks.PENDING));
        } else {
          dispatch(SetPendingNotifications([]));
        }
        dispatch(SetCompletedNotifications(response.data.tasks.COMPLETED));
      }
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!inputField.topic || !inputField.url) {
      setError("Please fill out all input fields.");
      setIsLoading(false);
      return;
    }

    let reqObj = {
      ...inputField,
      // userId: userId,
      secret_key: secretKey,
      category: category?._id,
      type: "generate_content",
    };
    console.log(reqObj);
    // return
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BACKEND_URL}/v1/generate-content-new`, //  generate/content`,
        reqObj
      );
      if (response.status === 200 && response.status) {
        fetchCompleteList();
        dispatch(
          SetpopupReducerData({
            modalType: "PENDINGTASK",
            showModal: true,
            category: `${category.name}`,
          })
        );
        setIsLoading(false);

        // let content = response?.data?.data?.response?.extracted_content;
        // let keywords = response?.data?.data?.response?.keywords;
        // console.log(response.data.data.response);
        // dispatch(SetSEOContent({content}));
        // dispatch(SetSEOKeywords(keywords));
        // setIsLoading(false);
      } else {
        alert("Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h2 class="text-center text-white mb-4">Exuber SEO Scribe</h2>
      {error && (
        <p className="error" style={{ color: "red" }}>
          {error}
        </p>
      )}
      <>
        <div className="seoform">
          <div class="row">
            <div className="col-xl-7">
              <div className="row">
                <div class="col-xs-6 col-md-6 form-group mb-3 mb-xl-0">
                  <input
                    class="form-control"
                    id="topic"
                    name="topic"
                    placeholder="Enter Topic - AI"
                    type="text"
                    onChange={handleChange}
                  />
                </div>
                <div class="col-xs-6 col-md-6 form-group ">
                  <input
                    class="form-control"
                    id="url"
                    name="url"
                    placeholder="Enter URL - https://exuber.ai/"
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-5">
              <ul className="btnList">
                {isLoading ? (
                  <li>
                    <button className="btn btn-primary">
                      <Spinner animation="border" size="lg" /> Processing...
                    </button>
                  </li>
                ) : (
                  <li>
                    <Link to="" className="active" onClick={handleSubmit}>
                      Run/Fetch
                    </Link>
                  </li>
                )}
                {/* <li>
                  <Link to href="" onClick={toggle}>
                    Generate Keywords
                  </Link>
                </li> */}
                {seoChracterLength > 4000 ? (
                  // Display popup if the character limit is exceeded
                  <li>
                    <a className="active">Word limit exceeded!</a>
                  </li>
                ) : (
                  <li>
                    <Link to="" onClick={toggle}>
                      Generate Keywords
                    </Link>
                  </li>
                )}
                <li>
                  <Link to href="">
                    Save
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-flex mt-4">
          <div className="accordioMenu w-100">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Original Content</Accordion.Header>
                <h3 className="wordCount">characters:{seoChracterLength}</h3>
                {id && (
                  <Accordion.Body>
                    <OriginalEditer
                      id={id}
                      seoChracterLength={seoChracterLength}
                      SetSeoChracterLength={SetSeoChracterLength}
                      newshortcontent={newshortcontent}
                      setnewshortcontent={setnewshortcontent}
                    />
                  </Accordion.Body>
                )}
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Optimised Content</Accordion.Header>
                {id && scorScribeData?.optimised_content && (
                  <Accordion.Body>
                    <OptimisedEditer id={id} />
                  </Accordion.Body>
                )}
              </Accordion.Item>
            </Accordion>
          </div>
          {id && (
            <div className={"sidebar" + (show ? " showSidebar" : "")}>
              <Keyword
                category={category}
                show={show}
                setShow={setShow}
                id={id}
                newshortcontent={newshortcontent}
              ></Keyword>
            </div>
          )}
        </div>
      </>
    </>
  );
}

export default SeoScribe;

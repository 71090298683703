import React from 'react'

function TexttoImage() {
  return (
    <>
        <h1 data-aos="zoom-in">Exuber's Text to Image</h1>
        {/* <video data-aos="zoom-in" className='my-3' src="/images/texttoImage.mp4" autoPlay="true" height="500" loop muted /> */}
        <iframe className='textToImage'
          src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/fcea45680abcd8814f0d530f1c853a54/iframe?muted=true&loop=true&autoplay=true&controls=false"
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>

        <p data-aos="zoom-in">Introduce a new dimension to creativity with Exuber's 'Text to Image' tool, where your words take visual form and imagination knows no bounds.</p>
        <div className='row'>
            <div className='col-xl-6'>
                <h4>Unleash Creative Potential</h4>
                <p>Transform simple text prompts into vibrant images, unlocking endless possibilities for design, communication, and expression.</p>
            </div>
            <div className='col-xl-6'>
                <h4>Designed for Innovators</h4>
                <p>Tailor-made for creators, marketers, and visionaries, our cutting-edge technology simplifies the visual creation process, saving time and enhancing creativity.</p>
            </div>
        </div>
    </>
  )
}

export default TexttoImage
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BsSend } from "react-icons/bs";
import { useSelector } from "react-redux";
import { API } from "../apiwrapper";
import ChatHistory from "./ChatHistory";

function MYChat({
  access_Key,
  apidata,
  chatInput,
  conversationId,
  setConversationId,
}) {
  console.log(apidata);
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  // let orgId = User?.data?.organization[0]?._id;
  let orgId = User?.data?.organization?.[0]?._id || "null";

  const [chathistory, SetchatHistroy] = useState([]);
  const [historycoonversationid, sethistorycoonversationid] = useState();

  const [userName, setUserName] = useState("");

  console.log(historycoonversationid, "anil");

  // Function to get initials from the full name
  const getInitials = (name) => {
    const nameParts = name.split(" ");
    if (nameParts.length >= 2) {
      const firstName = nameParts[0];
      const lastName = nameParts[nameParts.length - 1];
      return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    } else if (nameParts.length === 1) {
      return nameParts[0].charAt(0);
    }
    return "";
  };

  //get all conversation exists against a userId

  const fetchChatHistory = async (ccID) => {
    try {
      await API({
        url: `/v1/get/chat/new/history/${ccID}`,
        method: "GET",
      }).then((resp) => {
        console.log(resp.data.chatRecord.userChat);
        setConversationId(ccID || resp?.data?.conversationId);
        SetchatHistroy(resp?.data?.chatRecord?.userChat);
        setUserName(resp?.data?.userName);
      });
    } catch (error) {
      throw error;
    }
  };

  // useEffect(() => {
  //   if (conversationId) {
  //     fetchChatHistory();
  //   }
  // }, [conversationId]);

  const uploadChatData = async () => {
    if (!apidata) {
      console.log("response data not getting");
      return;
    } else {
      // console.log(apidata);
      // let cdid = "";
      // if (historycoonversationid) {
      //   cdid = historycoonversationid;
      // } else {
      //   cdid = "";
      // }

      const requestData = {
        userId: userId,
        conversationId: conversationId,
        orgId: orgId,
        userChat: [
          {
            userQstn: apidata?.question,
            modelAnswer: apidata?.answer,
          },
        ],
      };
      console.log("requestData1111", orgId, requestData);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BACKEND_URL}/v1/create/chat/new/history`,
          requestData
        );
        console.log("Upload chat history successful:", response.data);
        // console.log("requestData1111",User, User?.data, orgId, requestData);
        // setConversationId(response?.data?.data?.conversationId);

        if (response.data) {
          fetchChatHistory(response?.data?.data?.conversationId);
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };

  useEffect(() => {
    if (apidata) {
      uploadChatData();
    }
  }, [apidata]);

  return (
    <>
      <div className="conversationsContainer">
        {chathistory ? (
          <div className="MyChat">
            {chathistory?.map((ele) => (
              <div>
                {ele.userQstn && (
                  <div className="userChat d-flex mb-4">
                    <div className="userChatIcon">{getInitials(userName)}</div>
                    <div className="userChatMsgBox"> {ele.userQstn}</div>
                  </div>
                )}
                {ele.modelAnswer && (
                  <div className="chatBotChat d-flex mb-4">
                    <div className="ChatBotIcon">
                      {" "}
                      <img src="/images/chatBotIcon.png"></img>
                    </div>
                    <div className="ChatBotMsgBox">
                      <p>{ele.modelAnswer}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : null}
      </div>

      <ChatHistory
        setConversationId={setConversationId}
        newChat={SetchatHistroy}
        chathistory={chathistory}
        chatInput={chatInput}
      />
    </>
  );
}

export default MYChat;

import React from 'react'

function StepTextToVideo() {
  return (
    <>
        <div className='refernceAudio mb-5 text-center'>
                 <iframe
                    className="textToVideo"
                    src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/392354a04abd334d7d08e27c9b09ff55/iframe?muted=true&loop=true&autoplay=true&controls=false"
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowfullscreen="true"
                  ></iframe>
            {/* <video controls>
                <source src="/images/sumit long.mp4" type="video/mp4" />
                <source src="rabbit320.webm" type="video/webm" />
            </video> */}
            <div className='watchTitle'>
            Watch the video
                <span>or</span>
            Follow the instruction
            </div>
        </div>
    </>
  )
}

export default StepTextToVideo
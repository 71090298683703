import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";

import { storyblokInit, apiPlugin } from "@storyblok/react";


import Teaser from "./components/Teaser";
import Grid from "./components/Grid";

import { persistor, store } from "../src/components/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/homeComponents/ScrollToTop";
import { GoogleOAuthProvider } from "@react-oauth/google";

storyblokInit({
  accessToken: "HdcQigGbqGN0AJqZgY0HPAtt",
  use: [apiPlugin],
  components: {
   
    teaser: Teaser,
    grid: Grid,
  },
  apiOptions: {
    region: "us",
  },
});

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop/>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
    </GoogleOAuthProvider>
);

import React from "react";
import { Modal } from "react-bootstrap";
import { BiCopy } from "react-icons/bi";
import { PopupReducer, SetpopupReducerData } from "../store/reducer";
import { useDispatch, useSelector } from "react-redux";

function PendingTask() {
  const { PopupReducer } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      SetpopupReducerData({
        modalType: "",
        showModal: false,

      })
    );
  };


  const Category = PopupReducer.modal.category?.replace(/_/g, " ")
  console.log("popopop", Category, PopupReducer.modal.showModal, PopupReducer.modal.category)

  return (
    <Modal
      className="shareSocial"
      show={PopupReducer.modal.showModal}
      onHide={() => handleClose()}
    >
      <Modal.Header>
        <Modal.Title>Pending Task</Modal.Title>
        <button className="bgNone" onClick={() => handleClose()}>
          <img src="/images/closeBtn.png" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="pendingTask">
          <figure>
            <img src="/images/pendingTask.svg" alt="" />
          </figure>
          <p>
            Your {Category} request is intitated will notify you once
            its done.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PendingTask;

import React from 'react'
import StepCard from './StepCard'
import StepVideo from './StepVideo'
import StepAudio from './StepAudio'
import AudioStepCard from './AudioStepCard'
import TextToVideoStepCard from './TextToVideoStepCard'
import StepTextToVideo from './StepTextToVideo'

function DubTextToVideo({setNeedHelp}) {
  return (
    <>  
        <div className='position-relative'>
            <button className='backBtn align-items-start' onClick={()=>setNeedHelp(false)}>Back</button>
            <h2 class="text-center text-white mb-4">How to Convert Text To Videos</h2>
        </div>
        <StepTextToVideo/>
       <TextToVideoStepCard/>
    </>
  )
}

export default DubTextToVideo
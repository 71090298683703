/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  BsStar,
  BsClock,
  BsCollection,
  BsSoundwave,
  BsHeadset,
  BsTrash,
  BsFillChatLeftTextFill,
  BsFileEarmarkTextFill,
  BsPersonPlus,
  BsList,
  BsX,
} from "react-icons/bs";
import "./style.css";

import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import WebSocket from "ws";
import { apiURl } from "../store/actions";
import { API } from "../apiwrapper";
import moment from "moment";
// import VideoListings from "./VideoListings";
import SideBar from "./SideBar";
import ProfileCard from "./ProfileCard";
import VideoListings from "./VideoListings";
import VoiceChange from "./VoiceChange";
import TextToAudioListing from "./TextToAudioListing";
import TextToMusic from "./TextToMusic";
import ConvertTextToAudio from "./ConvertTextToAudio";
import ConvertTextToMusic from "./ConvertTextToMusic";
import ShopInVideo from "./ShopInVideo";
import ConvertVideoToText from "./ConvertVideoToText";
import VideoToTextListing from "./VideoToTextListing";
import ConvertImageToVideo from "./ConvertImageToVideo";
import ImageToVideoListing from "./ImageToVideoListing";
import Introduction from "./Introduction";
import SeoScribe from "./SeoScribe";
// import WebSocket from "websocket";
import { io } from "socket.io-client";

import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Package from "./Package";
import NewChat from "./NewChat";
import MYChat from "./MYChat";
import ChatHistory from "./ChatHistory";
import Extractdocument from "./Extractdocument";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASECONFIG } from "../config";
import { SetUserData } from "../store/reducer";
import ConvertTextToImage from "./ConvertTextToImage";
import ConvertTextToVideo from "./ConvertTextToVideo";
import InteriorDesign from "./InteriorDesign";
import ImageToStory from "./ImageToStory";
import Header from "../homeComponents/Header";
import ImageEnhancer from "./ImageEnhancer";
import { useRef } from "react";
import ImageEnhancerTest from "./ImageEnhancerTest";

function Profile() {
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  console.log(userId, "userId");
  let { catg, buyNow } = useParams();
  const [userVideoList, setUserVideoList] = useState([]);
  const [userTextToAudioList, setUserTextToAudioList] = useState([]);
  const [userTextToMusicList, setUserTextToMusicListList] = useState([]);
  const [userVideoToTextList, setUserVideoToTextList] = useState([]);
  const [userImageToVideoList, setUserImageToVideoList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [getTrashData, setTrashData] = useState([]);
  const [getFavouriteData, setFavouriteData] = useState([]);
  const [RecentViewData, setRecentViewedData] = useState([]);
  const [TextToImageData, setTextToImageData] = useState([]);
  const [TextToVideoData, setTextToVideoData] = useState([]);
  const [InteriorDesigner, setInteriorDesigner] = useState([]);
  const [ImageStory, setImageToStory] = useState([]);
  const [imageEnhancer, setImageEnhancer] = useState([]);
  const [GlobalVoiceAi, setGlobalVoiceAi] = useState([]);
  const [seoScribeData, setSeoScribeData] = useState([]);
  const [chathistory, SetchatHistroy] = useState();
  const [allCategory, setAllCategory] = useState([]);
  const [mainTools, setMainTools] = useState([]);
  const [labTools, setLabTools] = useState([]);
  const [category, setCategory] = useState({
    _id: "",
    name: "dashboard",
  });
  // const [socketInstance, setSocketInstance] = useState(null);
  const [socketData, setSocketData] = useState({});
  console.log("catg", buyNow, catg, category);

  console.log(chathistory);
  const [orgId, setOrgId] = useState("");
  const [accessKey, setAccessKey] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [selectedPersona, setSelectedPersona] = useState("Default"); // Default value is an empty string
  const [showLeftMenu, setShowLeftMenu] = useState(true);
  const [active, setActive] = useState(1);
  const [searchParams, setsearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = searchParams.get("id");
  const token = searchParams.get("token");
  const session = searchParams.get("session");
  const type = searchParams.get("type");
  const handleClick = (ele) => {
    console.log("ele", ele);
    setActive(ele);
  };

  const fetchVideoList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.allcontent}/${userId}`,
        method: "GET",
      });
      console.log("response----", response);
      if (response.status) {
        console.log(response?.data?.allData);

        setUserVideoList(response?.data?.AI_Dubbing);
        setUserTextToAudioList(response?.data?.text_to_music);
        setGlobalVoiceAi(response?.data?.text_to_speech);
        setUserVideoToTextList(response?.data?.Video_To_Text);
        setUserImageToVideoList(response?.data?.image_to_video);
        setTrashData(response?.data?.allTrashData);
        setTextToImageData(response?.data?.textToImage);
        setTextToVideoData(response?.data?.textToVideo);
        setInteriorDesigner(response?.data?.interiorDesigner);
        setImageToStory(response?.data?.image_to_story);
        setImageEnhancer(response?.data?.ImageEnhancer);
        setSeoScribeData(response?.data?.SeoScribe);
        setAllData([
          ...response?.data?.allData,
          // ...response?.data?.AI_Dubbing,
          // ...response?.data?.SoundScribe,
          // ...response?.data?.GlobalVoiceAi,
          // ...response?.data?.Video_To_Text,
        ]);
      } else {
        setUserVideoList([]);
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchAllCategory = async () => {
    try {
      await API({
        url: `${apiURl.allCategory}`,
        method: "GET",
      }).then((data) => {
        if (data?.status) {
          console.log(data, "data");
          let allCat = data?.data || [];
          setAllCategory(allCat.all_category);
          console.log(allCat);
          setMainTools(allCat.mainTools);
          setLabTools(allCat.labTools);
          //  setCategory({ _id: allCat?.[0]?._id, name: allCat?.[0]?.name });
        } else {
          setMainTools([]);
          setLabTools([]);
          setAllCategory([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const fetchFavouriteData = async () => {
    try {
      let response = await API({
        url: `${apiURl.favourite}/${userId}`,
        method: "GET",
      });

      if (response) {
        setFavouriteData(response?.data);
      } else {
        setFavouriteData([]);
      }
      console.log("favouraite", getFavouriteData);
    } catch (error) {
      //.log(error)
    }
  };

  const RecentViewedData = async () => {
    try {
      let response = await API({
        url: `${apiURl.recentViewed}/${userId}`,
        method: "GET",
      });

      if (response.status) {
        setRecentViewedData(response?.data);
      } else {
        setFavouriteData([]);
      }
    } catch (error) {
      //.log(error)
    }
  };
  // useEffect(() => {
  //   // Attempt to create a new WebSocket connection
  //   // const newSocket = new WebSocket("ws://aebcc08d82b354e83b7b7494c5cd5254-1052769592.ap-south-1.elb.amazonaws.com:8776");
  //   // const newSocket = new WebSocket("wss://websocket.aiexuber.:8776/"+userId);
  //   // http://192.168.20.148:3001

  //   // Set up event listeners for WebSocket events
  //   newSocket.onopen = () => {
  //     console.log("WebSocket connection opened");
  //     setError(""); // Clear any previous errors
  //   };

  //   newSocket.onmessage = (event) => {
  //     console.log("Received data:", event.data);
  //     setSocketData(event.data);
  //   };

  //   newSocket.onclose = (event) => {
  //     console.log("WebSocket connection closed");
  //     if (event.code === 1000) {
  //       console.log("WebSocket closed gracefully.");
  //     } else {
  //       setError(`WebSocket closed with error code: ${event.code}`);
  //     }
  //   };

  //   newSocket.onerror = (errorEvent) => {
  //     console.error("WebSocket error:", errorEvent);
  //     setError(`WebSocket error: ${errorEvent.message}`);
  //   };

  //   // Close the WebSocket connection when the component unmounts
  //   return () => {
  //     if (newSocket) {
  //       newSocket.close();
  //     }
  //   };
  // }, []);
  // useEffect(() => {
  //   socket.on("receive_message", (data, error) => {
  //     if (error) {
  //       console.log("i am a error at line 36");
  //     }
  //     console.log(data);
  //     // setMessageReceived(data.message);
  //   });
  // }, [socket]);
  // useEffect(() => {
  //   const socket = io("http://192.168.20.148:3001", {
  //     // const socket = io("http://localhost:5001/", {
  //     transports: ["websocket"],
  //     cors: {
  //       origin: "http://localhost:3000/",
  //     },
  //   });

  //   setSocketInstance(socket);

  //   socket.on("connect", (data) => {
  //     console.log("Connection Established...");
  //     console.log(data);
  //   });

  //   socket.on("disconnect", (data) => {
  //     console.log(data);
  //   });

  //   return function cleanup() {
  //     socket.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    if (category?.name === "dashboard") {
      fetchVideoList();
    }
  }, [category]);
  useEffect(() => {
    let index = allCategory.findIndex((ele) => ele.name === catg);
    console.log(catg, "index", index, allCategory);
    if (catg) {
      setCategory({
        _id: index >= 0 ? allCategory[index]?._id : "",
        name: catg,
      });
    }
    if (buyNow) {
      setActive(5);
    } else {
      setActive(1);
    }
  }, [allCategory, catg, buyNow]);

  useEffect(() => {
    fetchAllCategory();
    fetchFavouriteData();
    RecentViewedData();
  }, []);

  useEffect(() => {
    console.log("Effect running:", { token, session, type });
    if (token && session && type) {
      console.log("ankit");
      let payload = {
        type: type,
        token: token,
        session: session,
        id: userId,
      };

      // setsearchParams(payload);
      // console.log("payload111", payload);

      // let url = `${window.location.origin}/profile/${userId}`
      // console.log('url', url)
      // console.log('url', url)

      API({
        url: apiURl.paymentVerify,
        method: "POST",
        body: { ...payload },
      }).then((data) => {
        console.log("data?.data", data);
        if (data?.status || data?.status === "true") {
          toast.success("Payment Succsessfull");
          navigate("/");
          dispatch(SetUserData(data?.data));
        } else {
          console.log("toast");
          toast.error(data?.message);
        }
      });
    }
  }, [token, session, type]);

  console.log(category);

  const handleOrganisation = (value) => {
    console.log(value, " orgnaisiiiiiii");
    setOrganisation(value);
    const currentOrganisation = User?.data?.organization?.find((val) => {
      return value.toLowerCase() === val.name.toLowerCase();
    });

    setOrgId(currentOrganisation._id);
    setAccessKey(currentOrganisation.access_Key);
  };

  const handleDropdownItemClick = (persona) => {
    setSelectedPersona(persona);
  };

  return (
    <section className={`profileMain ${showLeftMenu ? "show" : ""}`}>
      <Header socData={socketData} />
      <div className="menuSectionLeft">
        <button
          className="toggleBtn"
          onClick={() => setShowLeftMenu(!showLeftMenu)}
        >
          <BsX className="cross" /> <BsList className="humber" />
        </button>
        <SideBar
          allCategory={allCategory}
          mainTools={mainTools}
          labTools={labTools}
          category={category}
          setCategory={setCategory}
        />
        {console.log("category", category)}
      </div>
      <div className="container">
        <div className="row justify-content-center">
          {/* <div className="col-lg-4 col-xl-3">
            </div> */}

          <div className={category?.name === "ChatBot" && "col-lg-6 col-xl-8"}>
            {category?.name === "dashboard" ? (
              <div className="maindash">
                <ul className="tabing">
                  <li>
                    <button
                      className={active === 1 ? "active" : ""}
                      onClick={() => handleClick(1)}
                    >
                      <BsCollection /> All
                    </button>
                  </li>
                  <li>
                    <button
                      className={active === 2 ? "active" : ""}
                      onClick={() => handleClick(2)}
                    >
                      <BsClock /> Recent viewed
                    </button>
                  </li>
                  <li>
                    <button
                      className={active === 3 ? "active" : ""}
                      onClick={() => handleClick(3)}
                    >
                      {" "}
                      <BsStar />
                      Favorite
                    </button>
                  </li>
                  <li>
                    <button
                      className={active === 4 ? "active" : ""}
                      onClick={() => handleClick(4)}
                    >
                      {" "}
                      <BsHeadset />
                      AI Dubbing
                    </button>
                  </li>
                  <li>
                    <button
                      className={active === 5 ? "active" : ""}
                      onClick={() => handleClick(5)}
                    >
                      {" "}
                      <BsSoundwave />
                      SoundScribe
                    </button>
                  </li>
                  <li>
                    <button
                      className={active === 6 ? "active" : ""}
                      onClick={() => handleClick(6)}
                    >
                      {" "}
                      <BsSoundwave />
                      Text To Image
                    </button>
                  </li>
                  <li>
                    <button
                      className={active === 8 ? "active" : ""}
                      onClick={() => handleClick(8)}
                    >
                      {" "}
                      <BsSoundwave />
                      Text To Video
                    </button>
                  </li>
                  <li>
                    <button
                      className={active === 9 ? "active" : ""}
                      onClick={() => handleClick(9)}
                    >
                      {" "}
                      <BsSoundwave />
                      Interior Designer
                    </button>
                  </li>

                  <li>
                    <button
                      className={active === 10 ? "active" : ""}
                      onClick={() => handleClick(10)}
                    >
                      {" "}
                      <BsSoundwave />
                      Image To Story
                    </button>
                  </li>

                  <li>
                    <button
                      className={active === 11 ? "active" : ""}
                      onClick={() => handleClick(11)}
                    >
                      {" "}
                      <BsSoundwave />
                      Image Enhancer
                    </button>
                  </li>

                  <li>
                    <button
                      className={active === 12 ? "active" : ""}
                      onClick={() => handleClick(12)}
                    >
                      {" "}
                      <BsSoundwave />
                      Text to Speech
                    </button>
                  </li>

                  <li>
                    <button
                      className={active === 7 ? "active" : ""}
                      onClick={() => handleClick(7)}
                    >
                      {" "}
                      <BsTrash />
                      Trash
                    </button>
                  </li>
                </ul>
                <div className="dashData">
                  <div className={active === 1 ? "all d-block" : "d-none"}>
                    <div className="row">
                      {allData?.map((item) => {
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard
                                item={item}
                                converted_video1={
                                  item?.converted_video ||
                                  item?.converted_text_to_image ||
                                  item?.converted_text_to_video ||
                                  item?.converted_interior_design_image ||
                                  item?.converted_enhanced_image ||
                                  item?.image_filepath
                                }
                                storyURI={item?.converted_image_to_story}
                                storyAudio={
                                  item?.image_to_story_voice_final_output
                                }
                                outputAudio={
                                  item?.converted_music ||
                                  item?.text_to_audio?.converted_audio ||
                                  item?.text_to_speech
                                    ?.final_text_to_speech_audio
                                }
                                converted_video={
                                  item?.converted_video || item?.converted_music
                                }
                                thumbnailimg={
                                  item?.converted_text_to_image ||
                                  item?.converted_interior_design_image ||
                                  item?.converted_enhanced_image ||
                                  item?.image_filepath
                                }
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={active === 2 ? "recentView d-block" : "d-none"}
                  >
                    <div className="row">
                      {RecentViewData?.map((item) => (
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                          <ProfileCard
                            item={item}
                            converted_video1={
                              item?.converted_video ||
                              item?.converted_text_to_video ||
                              item.converted_interior_design_image
                            }
                            outputAudio={item?.converted_music}
                            converted_video={item?.converted_video}
                            RecentViewedData={RecentViewedData}
                            thumbnailimg={
                              item?.converted_text_to_image ||
                              item?.converted_interior_design_image
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={active === 3 ? "favorite d-block" : "d-none"}>
                    <div className="row">
                      {getFavouriteData?.map((item) => {
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard
                                item={item}
                                fetchfavoriteData={fetchFavouriteData}
                                converted_video1={item?.converted_video}
                                outputAudio={item?.converted_music}
                                converted_video={item?.converted_video}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className={active === 4 ? "favorite d-block" : "d-none"}>
                    <VideoListings listing={userVideoList} />
                  </div>
                  <div className={active === 5 ? "favorite d-block" : "d-none"}>
                    <TextToMusic listingData={userTextToAudioList} />
                  </div>
                  <div className={active === 6 ? "trash d-block" : "d-none"}>
                    <div className="row">
                      {TextToImageData?.map((item) => {
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard
                                item={item}
                                // outputAudio={item?.converted_music}
                                // converted_text_to_video={item?.converted_text_to_image}
                                converted_video1={item?.converted_text_to_image}
                                thumbnailimg={item?.converted_text_to_image}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div className={active === 9 ? "trash d-block" : "d-none"}>
                    <div className="row">
                      {InteriorDesigner?.map((item) => {
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard
                                item={item}
                                // outputAudio={item?.converted_music}
                                // converted_text_to_video={item?.converted_text_to_image}
                                converted_video1={
                                  item.converted_interior_design_image
                                }
                                thumbnailimg={
                                  item?.converted_interior_design_image
                                }
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div className={active === 10 ? "trash d-block" : "d-none"}>
                    <div className="row">
                      {ImageStory?.map((item) => {
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard
                              // item={item}
                              // outputAudio={item?.converted_music}
                              // converted_text_to_video={item?.converted_text_to_image}
                              // converted_video1={item.image_filepath}

                              //  thumbnailimg={item?.image_filepath}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div className={active === 11 ? "trash d-block" : "d-none"}>
                    <div className="row">
                      {imageEnhancer?.map((item) => {
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard
                                item={item}
                                // outputAudio={item?.converted_music}
                                // converted_text_to_video={item?.converted_text_to_image}
                                converted_video1={item.converted_enhanced_image}
                                thumbnailimg={item?.converted_enhanced_image}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div className={active === 12 ? "trash d-block" : "d-none"}>
                    <div className="row">
                      {GlobalVoiceAi?.map((item) => {
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard
                                item={item}
                                // outputAudio={item?.converted_music}
                                // converted_text_to_video={item?.converted_text_to_image}
                                outputAudio={item.text_to_audio.converted_audio}
                                thumbnailimg={item?.converted_enhanced_image}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div className={active === 8 ? "trash d-block" : "d-none"}>
                    <div className="row">
                      {TextToVideoData?.map((item) => {
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard
                                item={item}
                                converted_video1={item?.converted_text_to_video}
                                // converted_video1={item?.converted_text_to_image}
                                // thumbnailimg={item?.converted_text_to_image}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div className={active === 7 ? "trash d-block" : "d-none"}>
                    <div className="row">
                      {getTrashData?.map((item) => {
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard
                                item={item}
                                outputAudio={item?.converted_music}
                                converted_video1={item?.converted_video}
                                thumbnailimg={item?.converted_text_to_image}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ) : category.name === "AI_Dubbing" ? (
              <div className="py-5">
                <VoiceChange category={category} setCategory={setCategory} />
              </div>
            ) : category?.name === "text_to_music" ? (
              <div className="py-5">
                <ConvertTextToMusic
                  category={category}
                  setCategory={setCategory}
                />
              </div>
            ) : category?.name === "text_to_speech" ? (
              <div className="py-5">
                <ConvertTextToAudio
                  category={category}
                  setCategory={setCategory}
                />
              </div>
            ) : category?.name === "text_to_image" ? (
              <div className="py-5">
                <ConvertTextToImage
                  category={category}
                  setCategory={setCategory}
                />
              </div>
            ) : category?.name === "text_to_video" ? (
              <div className="py-5 ps-lg-4">
                <ConvertTextToVideo
                  category={category}
                  setCategory={setCategory}
                />
              </div>
            ) : category?.name === "interiorDesigner" ? (
              <div className="py-5 ps-lg-4">
                <InteriorDesign category={category} setCategory={setCategory} />
              </div>
            ) : category?.name === "image_to_story" ? (
              <div className="py-5 ps-lg-4">
                <ImageToStory category={category} setCategory={setCategory} />
              </div>
            ) : category?.name === "ImageEnhancer" ? (
              <div className="py-5 ps-lg-4">
                <ImageEnhancerTest
                  category={category}
                  setCategory={setCategory}
                />
              </div>
            ) : category?.name === "shopInVideo" ? (
              <div className="py-5">
                <ShopInVideo category={category} setCategory={setCategory} />
              </div>
            ) : category?.name === "SeoScribe" ? (
              <div className="py-5">
                <SeoScribe category={category} setCategory={setCategory} />
              </div>
            ) : category?.name === "ChatBot" ? (
              <div className="maindash">
                {active !== 5 ? (
                  <ul className="tabing">
                    <li>
                      <button
                        className={active === 1 ? "active" : ""}
                        onClick={() => handleClick(1)}
                      >
                        <BsFillChatLeftTextFill /> ChatBot AI
                      </button>
                    </li>
                    {/* <li>
                        <button
                          className={active === 2 ? "active" : ""}
                          onClick={() => handleClick(2)}
                        >
                          <BsFillChatLeftTextFill /> My Chats
                        </button>
                      </li> */}
                    <li>
                      <button
                        className={active === 3 ? "active" : ""}
                        onClick={() => handleClick(3)}
                      >
                        {" "}
                        <BsFileEarmarkTextFill />
                        Organisations
                      </button>
                    </li>
                    {/* <li>
                     <button
                       className={active === 4 ? "active" : ""}
                       onClick={() => handleClick(4)}
                     >
                       {" "}
                       <BsHeadset />
                       MintAVibe
                     </button>
                   </li> */}

                    {/* <li>
                      <Link
                        className={active === 5 ? "active" : ""}
                        onClick={() => handleClick(5)}
                        to={`/profile/${userId}/ChatBot`}
                      >
                        <BsPersonPlus />
                       Become Pro 
                      </Link>
                    </li> */}
                    {active === 1 && (
                      <li className="orgBtn d-flex ms-auto ">
                        <div className="orgName">Test</div>
                        <div className="personaName">
                          <img src="/images/doctor-image.jpg" /> Persona Name
                        </div>
                        {organisation && organisation !== "Default" && (
                          <div className="orgName">{organisation}</div>
                        )}
                        {selectedPersona && selectedPersona !== "Default" && (
                          <div className="personaName">
                            <>
                              <img src="/images/doctor-image.jpg" />{" "}
                              {selectedPersona}
                            </>
                          </div>
                        )}
                      </li>
                    )}
                  </ul>
                ) : null}

                <div className="dashData">
                  <div className={active === 1 ? "all d-block" : "d-none"}>
                    <NewChat
                      handleDropdownItemClick={handleDropdownItemClick}
                      selectedPersona={selectedPersona}
                      organisation={organisation}
                      orgId={orgId}
                      accessKey={accessKey}
                      handleOrganisation={handleOrganisation}
                    />
                  </div>
                  <div
                    className={active === 2 ? "recentView d-block" : "d-none"}
                  >
                    <MYChat
                      chatHistoryParent={chathistory}
                      setChatHistoryParent={SetchatHistroy}
                    />
                  </div>
                  <div className={active === 3 ? "favorite d-block" : "d-none"}>
                    <Extractdocument />
                  </div>
                  <div className={active === 4 ? "favorite d-block" : "d-none"}>
                    <VideoListings listing={userVideoList} />
                  </div>
                  <div className={active === 5 ? "favorite d-block" : "d-none"}>
                    <Package />
                  </div>
                </div>
              </div>
            ) : category?.name === "SeoScribe" ? (
              <div className="py-5">
                <SeoScribe category={category} setCategory={setCategory} />
              </div>
            ) : (
              ""
            )}
          </div>
          {/* {category?.name === "ChatBot" && active === 1 ? (
            <div className="col-lg-2 col-xl-3">
              <ChatHistory chatHistoryParent={chathistory} />
            </div>
          ) : (
            ""
          )} */}
        </div>
      </div>
    </section>
  );
}

export default Profile;

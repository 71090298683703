import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetUserData, SetpopupReducerData } from "../store/reducer";

import { Modal } from "react-bootstrap";
import { BsKeyFill } from "react-icons/bs";
import { useState } from "react";
import {
  isValid,
  validateFirstName,
  validateLastName,
} from "../Validation/InputValidation.js";

import { API, getAwsImageUrl } from "../apiwrapper";
import { apiURl } from "../store/actions";

import { toast } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { PopupReducer } = useSelector((state) => state);
  console.log("PopupReducer", PopupReducer);
  const { Extra = {} } = useSelector((state) => state);

  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState({ message: "" });

  const forgotEmail = Extra?.forgotEmail?.forgotEmail;

  const [resetInputs, setResetPasswordInputs] = useState({
    email: forgotEmail,
    newpassword: "",
    cnewpassword: "",
  });
  const handleCloseUp = () => {
    dispatch(SetpopupReducerData({ modalType: "", showModal: false }));
  };

  const handleValidate = (e) => {
    const errors1 = {};
    switch (e.target.name) {
      case "firstName":
        errors1.firstName = validateFirstName(e.target.value);
        break;
      case "lastName":
        errors1.lastName = validateLastName(e.target.value);
        break;
      default:
        break;
    }
    setErrors(errors1);
  };

  const resetPasswordInputs = (e) => {
    setResetPasswordInputs({
      ...resetInputs,
      [e.target.name]: e.target.value,
    });
    handleValidate(e);
    setApiErrors({ message: "" });
  };

  const handleReset = async (e) => {
    e.preventDefault();
    console.log("Reset Password");
    try {
      const response = await API({
        url: apiURl.resetPassword, // Replace with your API endpoint for OTP validation
        method: "POST",
        body: { ...resetInputs }, // Send OTP to the server for validation
        formData: false,
      });
      if (response.status) {
        console.log(response);
        console.log({ email: forgotEmail, password: resetInputs.newpassword, api_type: "login", transporttype: false })

        const loginResponse = await API({
          url: apiURl?.users,
          method: "POST",
          body: { email: forgotEmail, password: resetInputs.newpassword, api_type: "login", transporttype: false },
          formData: false,
        });

        console.log(loginResponse);


        if (loginResponse?.status || loginResponse?.status === "true") {
          toast.success(response.message);
          localStorage.setItem("token", loginResponse?.data?.jwtToken);
          dispatch(SetUserData({ ...loginResponse?.data, signupLogin: false }));
          handleCloseUp();
          navigate("/");
        }
      } else {
        toast.error("Error while resetting your password! Please try again.");
        handleCloseUp();
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      show={PopupReducer.modal.showModal}
      //   show={false}
      hide={handleCloseUp}
      size="md offerPoup loginWidth modal-dialog-centered"
    >
      <>
        <div className="pop_content login-screen">
          {/* <div className="close-button">
          <a href="#" onClick={()=>setChangePasswordModal(false)}>
            <ImageComponent src="/images/cross-button.svg" />
          </a>
        </div> */}
          <h2>Reset Password?</h2>
          <p className="mt-1 mb-4">Please reset your password here...</p>
          <div className="login-section">
            <form
              onSubmit={handleReset}
              // onKeyDown={handleKeyDown}
            >
              <div className="poupBox mb-4">
                {/* {apiErrors.message && (
                <span
                  className="text-danger mb-2 d-block"
                  style={{ fontSize: "14px" }}
                >
                  {apiErrors.message}
                </span>
              )} */}
                <div className="form-div inputWithIcon mb-3">
                  <div className="icon">
                    <BsKeyFill />
                  </div>

                  <input
                    type="password"
                    id="newpassword"
                    name="newpassword"
                    // value={email}
                    placeholder="new password"
                    onChange={(e) => resetPasswordInputs(e)} // onBlur={handleValidate}
                  />
                  {/* {errors.email ? (
                  <span
                    className="text-danger"
                    style={{ fontSize: "14px" }}
                  >
                    {errors.email}
                  </span>
                ) : (
                  ""
                )} */}
                </div>
                <div className="form-div inputWithIcon m-0">
                  <div className="icon">
                    <BsKeyFill />
                  </div>

                  <input
                    type="password"
                    id="cnewpassword"
                    name="cnewpassword"
                    // value={email}
                    placeholder="confirm new password"
                    onChange={(e) => resetPasswordInputs(e)} // onBlur={handleValidate}
                  />
                  {/* {errors.email ? (
                  <span
                    className="text-danger"
                    style={{ fontSize: "14px" }}
                  >
                    {errors.email}
                  </span>
                ) : (
                  ""
                )} */}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <button
                  onClick={() => handleCloseUp()}
                  type="button"
                  className="cancel-button m-0"
                >
                  Cancel
                </button>
                <button
                  className="creat-button m-0"
                  type="submit"
                  // onClick={handleSubmit}
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default ResetPassword;

import { createSlice } from "@reduxjs/toolkit";

export const sliceUser = createSlice({
  name: "users",
  initialState: {
    data: {},
    signUpdata: {},
  },
  reducers: {
    fetched: (state, { payload }) => {
      state.data = payload.data;
    },
    signUpdata: (state, { payload }) => {
      state.signUpdata = payload.data;
    },
    updatePoints: (state, { payload }) => {
      state.data.points = payload;
    },
    
  },
});

// Action creators are generated for each case reducer function
const { fetched, signUpdata, updatePoints } = sliceUser.actions;

export default sliceUser.reducer;

export const SetUserData = (data) => async (dispatch) => {
  if (!data) throw Error("missing Data parameter");
  dispatch(fetched({ data }));
};

export const SetSignUpData = (data) => async (dispatch) => {
  dispatch(signUpdata({ data }));
};

export const UpdateUserPoints = (points) => async (dispatch) => {
    dispatch(updatePoints(points));

};

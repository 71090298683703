
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import ShareSocialMedia from "./ShareSocialMedia";
import { BiX } from "react-icons/bi";

import { Row, Spinner } from "react-bootstrap";

function ConvertInteriorImage({ outputImage,inputImage }) {

    console.log(inputImage,"inputImage");
  const audioUrl = outputImage ;
  const linkRef = useRef();
  const [show, setShow] = useState(false);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const handleCopyLink = () => {
 
    navigator.clipboard
      .writeText(audioUrl)
      .then(() => toast.success("Link copied to clipboard"))
      .catch((err) => toast.error("Something went wrong", err))
   
  };

  const handleDownload = async () => {
    const response = await fetch(audioUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "audio.mp3"; // or any other name you want
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  // const handleLoad = () => {
  //   setAudioLoaded(true);
  // };
  return (
    <div>
      <div className="refernceAudio text-center mt-5 ">
     
      <Row className="text-center videoTitle">
          {/* <img src={outputImage} type="audio/ogg" /> */}
          {inputImage.map((ele,index)=>
          <div className="col-md-6">
                    <h3>Uploaded Image</h3>
                   
                    <div className="conVideoBox">
                      <button><BiX/></button>
                      <iframe src={ele.image_filepath}/>
                    </div>
                  </div>
                  )}

                  <div className="col-md-6">
                    <h3>Enhance Image</h3>
                    {/* <img src="/images/video.png"></img>  */}
                    <div className="conVideoBox">
                      <button><BiX/></button>
                      <iframe src={outputImage} />
                    </div>
                 
                  </div>


      
        {/* <iframe
          src={outputAudio}
          width="250"
          height="250"
          title={outputAudio}
        /> */}
            <ul className="allBtn d-flex justify-content-center mt-5">
            <li>
              <button  onClick={() => setShow(true)}> Share</button>
            </li>
            <li>
              <button onClick={handleDownload} >Download</button>
            </li>
            <li>
              {" "}
              <button onClick={handleCopyLink} >Copy Link</button>
            </li>
            {/* <li>
              <button>Delete</button>
            </li> */}
          </ul>
           
          </Row>
      </div>
      <Modal className="shareSocial" show={show}>
        <Modal.Header>
          <Modal.Title>Publish To MintAVibe</Modal.Title>
          <button className="bgNone" onClick={() => setShow(false)}>
            <img src="/images/closeBtn.png" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <ShareSocialMedia link ={outputImage} ></ShareSocialMedia>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ConvertInteriorImage;


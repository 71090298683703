/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import Select, { components } from "react-select";
import { toast } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import {
  isValid,
  validateCPassWord,
  validateEmail,
  validateFirstName,
  validateLastName,
  validateMobile,
  validatePassWord,
  validateRequire,
  validateTermAndPrivacyRequire,
} from "../Validation/InputValidation.js";
import {
  SetSignUpData,
  SetUserData,
  SetpopupReducerData,
} from "../store/reducer";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLoginBtn from "react-facebook-login";
import { SetForgetEmail } from "../store/reducer/extra";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  data,
  innerProps,
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState(isSelected);
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);
  const toggleChecked = () => {
    setIsChecked(!isChecked);
  };
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <span className="p-1">
        {data?.data?.image && (
          <img
            src={data?.data?.image}
            alt={data.label}
            style={{ width: "15px" }}
          />
        )}
        <input
          type="checkbox"
          onChange={toggleChecked} // provide an onChange handler to update the state
          checked={isChecked} // use the state variable to control the checkbox
        />{" "}
        {data.label}
      </span>
    </components.Option>
  );
};

const ControlOption = ({ children, ...rest }) => {
  const { value } = rest?.selectProps;
  return (
    <components.Control {...rest}>
      <span className="p-1 small m-auto">
        {value ? (
          <>
            <img src={value?.data?.image} width="20" alt="" /> {children}
          </>
        ) : (
          children
        )}
      </span>
    </components.Control>
  );
};

function SignUp() {
  const dispatch = useDispatch();

  const { PopupReducer } = useSelector((state) => state);
  const { showModal = false, buyModal = false } = PopupReducer?.modal;

  const [inpData, setInpData] = useState({
    firstName: "",
    lastName: "",
    // contact: "",
    // countryCode: "1",
    email: "",
    password: "",
    cpassword: "",
    referralCode: "",
  });
  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState({ message: "" });
  const [CountryCode, setCountryCode] = useState([]);
  const [defaultCC, setDefaultCC] = useState({});
  const navigate = useNavigate();

  const [showPass, setShowPass] = useState(false);
  const [showCPass, setShowCPass] = useState(false);
  const [termsService, setTermsService] = useState(false);
  const [user, setUser] = useState(null);
  const [location, setLocation] = useState({});

  let regexNum = /^(?=.*[0-9])/;
  let regexSmlChar = /^(?=.*[a-z])/;
  let regexUprChar = /^(?=.*[A-Z])/;
  let regexSpclChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const {
    firstName,
    lastName,
    contact,
    email,
    password,
    cpassword,
    countryCode = "1",
    referralCode,
  } = inpData;

  const handleShowLogin = () => {
    dispatch(
      SetpopupReducerData({
        modalType: "LOGIN",
        showModal: true,
      })
    );
  };
  const handleChange = (e) => {
    setInpData({ ...inpData, [e.target.name]: e.target.value });
    handleValidate(e);
    setApiErrors({ message: "" });
  };
  const handleValidate = (e) => {
    const errors1 = {};
    switch (e.target.name) {
      case "firstName":
        errors1.firstName = validateFirstName(e.target.value);
        break;
      case "lastName":
        errors1.lastName = validateLastName(e.target.value);
        break;
      case "email":
        errors1.email = validateEmail(e.target.value);
        break;
      case "contact":
        errors1.contact = validateMobile(e.target.value);
        break;
      case "password":
        errors1.password = validatePassWord(e.target.value);
        break;
      case "cpassword":
        errors1.cpassword = validateCPassWord(e.target.value, password);
        break;
      case "countryCode":
        errors1.countryCode = validateRequire(e.target.value, "country code");
        break;
      default:
        break;
    }
    setErrors(errors1);
  };

  const validateAll = () => {
    let err1 = {};
    err1.firstName = validateFirstName(firstName);
    err1.lastName = validateLastName(lastName);
    err1.email = validateEmail(email);
    // err1.countryCode = validateRequire(countryCode, "country code");
    // err1.contact = validateMobile(contact);
    err1.password = validatePassWord(password);
    err1.cpassword = validateCPassWord(cpassword, password);
    err1.termsService = validateTermAndPrivacyRequire(termsService);
    return err1;
  };
  const handleLogin = () => {
    navigate("/login");
  };
  const handleTCS = async (e) => {
    try {
      setTermsService(e.target.checked);
      const res = await axios.get("https://geolocation-db.com/json/");
      setLocation(res?.data);
    } catch (error) {
      throw error;
    }
  };

  const handleChangeMulti = (val, name) => {
    setInpData({
      ...inpData,
      [name]: val.value,
    });
  };

  const handleVerify = (e) => {
    dispatch(
      SetpopupReducerData({
        modalType: "OTP",
        showModal: true,
      })
    );
  };

  const fetchCountryCode = async () => {
    try {
      await API({
        url: `${apiURl.countryCode}`,
        method: "GET",
      }).then((data) => {
        let allCC = data?.data || [];
        setCountryCode(allCC);
        let defVal =
          allCC?.find((ele) => ele?.phone?.countryCode == countryCode) || {};
        setDefaultCC(defVal);
      });
    } catch (error) {
      throw error;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let signUpButton = document.getElementById("signUp");
    signUpButton.disabled = true;
    try {
      console.log("Sign up")
      let err = validateAll();
      if (isValid(err)) {
        await API({
          url: apiURl.register,
          method: "POST",
          body: {
            transporttype: true,
            api_type: "signup",
            ...inpData,
            // contact: `+${countryCode}${contact}`,
          },
          formData: false,
        }).then((data) => {
          console.log(inpData);
          console.log("Signup response", data);
          if (data?.status || data?.status === "true") {
            let payload = {
              ...inpData,
              // contact: `+${countryCode}${contact}`,
              termsService,
              location,
              jwtToken: data.data.jwtToken,
              transporttype: true,
              api_type: "signup",
            };
            // dispatch(handleVerify());/
            dispatch(SetForgetEmail({}));
            dispatch(SetSignUpData(payload));
            handleVerify();
            // navigate("/verify-otp");

            

            // // navigate("/");
          } else {
            setApiErrors({ message: data?.message });
            toast.error(data?.message);
          }
        });
      } else {
        setErrors(err);
      }
    } catch (error) {
      setApiErrors({ message: error.message });
      throw error;
    } finally {
      signUpButton.disabled = false;
    }
  };
  useEffect(() => {
    fetchCountryCode();
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleClosePopup = () => {
    dispatch(SetpopupReducerData({ modalType: "", showModal: false }));
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => setUser(tokenResponse),
  });

  const fetchData = async () => {
    try {
      const userInfoRes = await axios.get(
        `https://openidconnect.googleapis.com/v1/userinfo?access_token=${user.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        }
      );

      const obj = userInfoRes?.data;

      console.log(obj);

      const payload = {
        firstName: obj?.given_name,
        lastName: obj?.family_name,
        email: obj?.email,
        profileUrl: obj?.picture,
        gId: obj?.sub,
        token: user.access_token,
        method: "GOOGLE",
        isVerified: true,
      };

      console.log(payload);

      const response = await API({
        url: apiURl.register,
        method: "POST",
        body: payload,
        formData: false,
      });

      if (response.status) {
        console.log(response.data);
        localStorage.setItem("token", response?.data?.jwtToken);

        dispatch(SetUserData({ ...response?.data, signupLogin: false }));
        console.log(response);
        handleClosePopup();
        console.log(response.message);
        toast.success(response.message);
      }
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  const componentClicked = () => {
    console.log("Login with facebook button clciked");
  };

  const responseFacebook = async (response) => {
    try {
      const name = response.name.split(" ");

      const payload = {
        firstName: name[0],
        lastName: name[1],
        email: response?.email,
        profileUrl: response?.picture?.data?.url,
        gId: response?.userID,
        token: response?.accessToken,
        method: "FACEBOOK",
        isVerified: true,
      };

      console.log(payload)

      const res = await API({
        url: apiURl.register,
        method: "POST",
        body: payload,
        formData: false,
      });

      console.log(payload);
      if (res.status) {
        console.log(res.data);
        localStorage.setItem("token", res?.data?.jwtToken);

        dispatch(SetUserData({ ...res?.data, signupLogin: false }));
        handleClosePopup();
        toast.success(res.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Modal
        size="lg offerPoup modal-dialog-centered"
        show={showModal}
        hide={handleClosePopup}
      >
        <div className="pop_content login-screen">
          <div>
            {/* <div className="text-center mt-lg-4 mb-lg-3">
              <img src="/images/logo.svg" />
            </div> */}
            <h2>Create an account</h2>
            {/* <p className="mb-4">Welcome to CrossTower TicketVerse</p> */}
          </div>
          <div className="login-section">
            <form
              action=""
              method="post"
              onSubmit={handleSubmit}
              onKeyDown={handleKeyDown}
            >
              <div className="poupBox">
                <div className="row gx-3">
                  <div className="col-sm-6">
                    <div className="form-div inputWithIcon">
                      <div className="icon">
                        <img src="/images/name-icon.svg" alt="" />
                      </div>
                      <input
                        type="text"
                        id="First_Name"
                        name="firstName"
                        value={firstName}
                        placeholder="First Name"
                        // onBlur={handleValidate}
                        onChange={(e) => {
                          if (e.target.value.includes(" ")) {
                            e.target.value = e.target.value.replace(" ", "");
                          }
                          handleChange(e);
                        }}
                        autoComplete={false}
                      />
                      {errors.firstName ? (
                        <span
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          {errors.firstName}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-div inputWithIcon">
                      <div className="icon">
                        <img src="/images/name-icon.svg" alt="" />
                      </div>
                      <input
                        type="text"
                        id="Last_Name"
                        name="lastName"
                        value={lastName}
                        placeholder="Last Name"
                        // onBlur={handleValidate}
                        onChange={(e) => {
                          if (e.target.value.includes(" ")) {
                            e.target.value = e.target.value.replace(" ", "");
                          }
                          handleChange(e);
                        }}
                        autoComplete={false}
                      />
                      {errors.lastName ? (
                        <span
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          {errors.lastName}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {/* <div className="col-sm-6">
                    <div className="row gx-2">
                      <div className="col-5 col-sm-5">
                        {CountryCode?.length && (
                          <Select
                            className="countryNumber"
                            name="countryCode"
                            components={{
                              Option: InputOption,
                              Control: ControlOption,
                            }}
                            classNamePrefix="countryCode"
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            placeholder={`Select...`}
                            options={
                              CountryCode?.length
                                ? CountryCode?.map((item, index) => ({
                                    value: item?.phone?.countryCode,
                                    label:
                                      `${item?.info?.longName} ` || "Dummy",
                                    data: {
                                      image: item?.info?.flag || "",
                                    },
                                  }))
                                : []
                            }
                            defaultValue={
                              CountryCode?.length
                                ? {
                                    value: defaultCC?.phone?.countryCode,
                                    label:
                                      `${defaultCC?.info?.longName}` || "Dummy",
                                    data: {
                                      image: defaultCC?.info?.flag || "",
                                    },
                                  }
                                : {}
                            }
                            onChange={(e) =>
                              handleChangeMulti(e, "countryCode")
                            }
                          />
                        )}
                      </div>
                      <div className="col-7 col-sm-7">
                        <input
                          className="input[type=number]::-webkit-outer-spin-button"
                          type="text"
                          id="contact"
                          name="contact"
                          value={contact}
                          autoComplete={false}
                          placeholder="Phone Number"
                          // onBlur={handleValidate}
                          // onChange={handleChange}
                          onChange={(e) => {
                            const re = /^[0-9]{0,12}$/;
                            const inputValue = e.target.value;
                            if (inputValue === "" || re.test(inputValue)) {
                              if (e.target.value.includes(" ")) {
                                e.target.value = inputValue.replace(" ", "");
                              }
                              handleChange(e);
                            }
                          }}
                        />
                      </div>
                      <div className="col-12">
                        {errors.countryCode || errors.contact ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "14px" }}
                          >
                            {errors.countryCode || errors.contact}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div> */}

                  <div className="col-sm-6">
                    <div className="form-div inputWithIcon">
                      <div className="icon">
                        <img src="/images/envelop.svg" alt="" />
                      </div>
                      <input
                        type="email"
                        id="Email"
                        name="email"
                        value={email}
                        autoComplete={false}
                        placeholder="Email"
                        // onBlur={handleValidate}
                        onChange={(e) => {
                          if (e.target.value.includes(" ")) {
                            e.target.value = e.target.value.replace(" ", "");
                          }
                          handleChange(e);
                        }}
                      />
                      {errors.email ? (
                        <span
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          {errors.email}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-div mb-3 mb-sm-0 inputWithIcon">
                      <div className="icon">
                        <img src="/images/password.svg" alt="" />
                      </div>
                      <input
                        type={showPass ? "text" : "password"}
                        name="password"
                        autocomplete=""
                        value={password}
                        autoComplete={false}
                        placeholder="Password"
                        id="password"
                        // onBlur={handleValidate}
                        onChange={(e) => {
                          if (e.target.value.includes(" ")) {
                            e.target.value = e.target.value.replace(" ", "");
                          }
                          handleChange(e);
                        }}
                      />
                      <i
                        className={`${
                          showPass ? " fa fa-eye " : " fa fa-eye-slash "
                        } eye-icon`}
                        id="togglePassword"
                        onClick={() => setShowPass(!showPass)}
                      ></i>
                      {errors.password ? (
                        <>
                          <span
                            className="text-danger"
                            style={{ fontSize: "14px" }}
                          >
                            {`${errors.password}`}
                          </span>
                          <ul>
                            <li
                              className={
                                password.length >= 8
                                  ? "text-success"
                                  : "text-danger"
                              }
                              style={{ fontSize: "14px" }}
                            >
                              {password.length >= 8 && (
                                <i className="fas fa-check-circle"></i>
                              )}{" "}
                              Minimum 8 characters
                            </li>

                            <li
                              className={
                                regexUprChar.test(password)
                                  ? "text-success"
                                  : "text-danger"
                              }
                              style={{ fontSize: "14px" }}
                            >
                              {regexUprChar.test(password) && (
                                <i className="fas fa-check-circle"></i>
                              )}{" "}
                              Atleast 1 uppercase{" "}
                            </li>
                            <li
                              className={
                                regexNum.test(password)
                                  ? "text-success"
                                  : "text-danger"
                              }
                              style={{ fontSize: "14px" }}
                            >
                              {regexNum.test(password) && (
                                <i className="fas fa-check-circle"></i>
                              )}{" "}
                              Atleast 1 number{" "}
                            </li>
                            <li
                              className={
                                regexSmlChar.test(password)
                                  ? "text-success"
                                  : "text-danger"
                              }
                              style={{ fontSize: "14px" }}
                            >
                              {regexSmlChar.test(password) && (
                                <i className="fas fa-check-circle"></i>
                              )}{" "}
                              Atleast 1 lowercase
                            </li>
                            <li
                              className={
                                regexSpclChar.test(password)
                                  ? "text-success"
                                  : "text-danger"
                              }
                              style={{ fontSize: "14px" }}
                            >
                              {regexSpclChar.test(password) && (
                                <i className="fas fa-check-circle"></i>
                              )}{" "}
                              Atleast 1 special character
                            </li>
                          </ul>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-div mb-3 mb-sm-0 inputWithIcon">
                      <div className="icon">
                        <img src="/images/password.svg" alt="" />
                      </div>
                      <input
                        type={showCPass ? "text" : "password"}
                        name="cpassword"
                        value={cpassword}
                        autocomplete=""
                        placeholder="Confirm Password"
                        id="cpassword"
                        // onBlur={handleValidate}
                        onChange={(e) => {
                          if (e.target.value.includes(" ")) {
                            e.target.value = e.target.value.replace(" ", "");
                          }
                          handleChange(e);
                        }}
                        autoComplete={false}
                      />
                      <i
                        className={`${
                          showCPass ? " fa fa-eye " : " fa fa-eye-slash "
                        } eye-icon`}
                        id="togglePassword"
                        onClick={() => setShowCPass(!showCPass)}
                      ></i>
                      {errors.cpassword ? (
                        <span
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          {errors.cpassword}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-div inputWithIcon">
                      <div className="icon">
                        <img src="/images/envelop.svg" alt="" />
                      </div>
                      <input
                        type="text"
                        id="referralCode"
                        name="referralCode"
                        value={referralCode}
                        // autoComplete={false}
                        placeholder="Referral Code"
                        // onBlur={handleValidate}
                        onChange={(e) => {
                          if (e.target.value.includes(" ")) {
                            e.target.value = e.target.value.replace(" ", "");
                          }
                          handleChange(e);
                        }}
                      />
                      <span className="text-white">Optional*</span>
                      {/* {errors.email ? (
                        <span
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          {errors.email}
                        </span>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </div>
                {apiErrors.message && (
                  <span
                    className="text-danger mb-2 d-block"
                    style={{ fontSize: "14px" }}
                  >
                    {apiErrors.message}
                  </span>
                )}
              </div>
              <div className="row gx-0 mt-3">
                <div className="col-12">
                  <div className="check-box-div">
                    <input
                      type="checkbox"
                      className="check-button"
                      name="remember"
                      onChange={handleTCS}
                    />
                    <span style={{ fontSize: "12px" }}>
                      {" "}
                      By clicking on Sign up, you are acknowledging that you
                      have read and agreed to our{" "}
                      <Link
                        to="https://crosstower.com/terms-and-conditions/"
                        target="_blank"
                      >
                        Terms & Conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        to={"https://crosstower.com/privacy-policy/"}
                        target={"_blank"}
                      >
                        Privacy Policy
                      </Link>
                      .
                      {/* By clicking on continue, you are indicating that you have
                      read and agree to our 
                      <Link to="https://crosstower.com/terms-of-use/" target="_blank">{" "}Terms of Use</Link>{" "}&{" "}
                      <Link to={"https://crosstower.com/privacy-policy/"} target={"_blank"}>{" "}Privacy Policy{" "}</Link> */}
                    </span>
                  </div>
                  {errors.termsService ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.termsService}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row align-items-center mt-3 mt-lg-4 mt-xl-3">
                <div className="col-lg-6">
                  <p className="text-xl-start regLnk">
                    Are you already a member? Please{" "}
                    <Link to={"#"} onClick={handleShowLogin}>
                      Log in
                    </Link>
                    .
                  </p>
                </div>
                <div className="col-lg-6 d-flex justify-content-between justify-content-lg-end mt-4 mt-lg-0">
                  <button
                    className="cancel-button m-0"
                    onClick={handleClosePopup}
                    type="button"
                  >
                    Cancel
                  </button>
                  <input
                    className="creat-button m-0 ms-4"
                    type="submit"
                    // onClick={handleSubmit}
                    id="signUp"
                    // disabled={!termsService || false}
                    value={" Sign up"}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="socialMediaBtn">
                  <div className="orTitle"><span>Or Continue with</span></div>
                    <div className="d-flex justify-content-center socialMediaBtn column-gap-3">
                      <button className="googleBtn" onClick={() => googleLogin()}><i class="fab fa-google"></i> Continue with Google</button>
                      <button className="facebookBtn"><FacebookLoginBtn appId="972149347184713" autoLoad={false} fields="name, email, picture" textButton="Continue with Facebook" onClick={componentClicked} callback={responseFacebook} icon="fa-facebook"/></button>
                    </div>
                  </div>           

        </div>
      </Modal>
    </div>
  );
}

export default SignUp;

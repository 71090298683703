import React, { useEffect, useRef, useState } from "react";
import Header from "../homeComponents/Header";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";

function AboutUs() {
//   let path = window.location.pathname.split("/")[2];

  let scrollToRef = useRef(null);
//   useEffect(() => {
//     var position = 400;
//     var elementPosition = scrollToRef.current.getBoundingClientRect().top;
//     var offsetPosition = elementPosition + window.pageYOffset + position;

//     window.scrollTo({
//       top: offsetPosition,
//       behavior: "smooth",
//     });
//   }, []);

  return (
    <>
      <Header />
      <div className="bgColor">
        <section className="aboutUs">
          <div className="container">
            <div className="row">
              <div className="col-md-6 my-auto">
                <h1>About Us</h1>
                <p>
                  Journey into Exuber, the pinnacle of AI-driven creativity and
                  machine learning excellence. Standing tall as a beacon in the
                  expansive universe of Artificial Intelligence tools, we
                  present an exquisite suite of the best AI tools, meticulously
                  designed to elevate and resonate with your innate creativity.
                  Expertly merging machine learning algorithms with
                  state-of-the-art AI functionality, Exuber is more than just a
                  platform—it's a testament to what's possible when technology
                  meets imagination.
                </p>
              </div>
              <div className="col-md-6">
                <img
                  className="img-fluid"
                  src="./images/exuber-logo-dark.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section ref={scrollToRef} className="whyChose" id="why">
          <div className="container">
            <div className="row pt-5">
              <h2>Why Choose Exuber?</h2>
              <div className="col-lg-6">
                <div className="whyBox_2">
                  <h3>All-encompassing AI Suite</h3>
                  <p>
                    Dive into a platform that is the epitome of Artificial
                    Intelligence and machine learning tools. One comprehensive
                    space housing the zenith of AI capabilities, streamlining
                    and automating your creative processes.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="whyBox_2">
                  <h3>Limitless Exploration</h3>
                  <p>
                    Our innovative credit points system unlocks a universe of
                    endless AI marvels, fusing the magic of creativity with the
                    logic of automation.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="whyBox_2">
                  <h3>Community and Collaboration</h3>
                  <p>
                    Be part of a thriving global network of innovators, artists,
                    and dreamers, all propelled by the shared vision of
                    leveraging AI for boundless creativity.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="whyBox_2">
                  <h3>Optimized Performance</h3>
                  <p>
                    Relish in AI tools, crafted with machine learning
                    algorithms, fine-tuned for efficiency, speed, and unmatched
                    accuracy. Our platform ensures that your creative pursuits
                    are backed by the most advanced technology.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="whyBox_2">
                  <h3>Empowering Creativity</h3>
                  <p>
                    At Exuber, we believe in harmonising Artificial Intelligence
                    with human imagination. We don't overshadow, but rather
                    amplify and enhance your unique creative voice through
                    advanced AI solutions.
                  </p>
                  <p>
                    Dive into the Exuber experience, where every interaction
                    promises innovation. In this sanctuary, technology, and
                    creativity don't just coexist; they collaborate, evolve, and
                    flourish together.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AboutUs;

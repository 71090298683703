import React from 'react'

function ImagetoStory() {
  return (
    <>


        <h1 data-aos="zoom-in">Exuber's Image to Story</h1>
        <iframe className='textToVideo'
            src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/22028b41b02d9c3a5f55bd20cf5fe471/iframe?muted=true&loop=true&autoplay=true&controls=false"
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>

        <p data-aos="zoom-in">Step into a realm where every picture weaves a tale. With Exuber's 'Image to Story' tool, watch as static images come to life, unfolding enchanting narratives right before your eyes.</p>
        <div className='row'>
            <div className='col-xl-6'>
                <h4>Awaken Imagination:</h4>
                <p>Go beyond the confines of a single frame. Upload any image and let our AI craft stories that resonate, broadening the depth and emotion of your visuals.</p>
            </div>
            <div className='col-xl-6'>
                <h4>Engage & Captivate</h4>
                <p>Enhance content engagement by transforming standard photos into compelling stories. Perfect for social media, marketing campaigns, or simply to satiate your creative curiosity.</p>
            </div>
        </div>


    </>
  )
}

export default ImagetoStory
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
// import { Link } from "react-router-dom";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import ProfileCard from "./ProfileCard";

function VideoListings({ listing = [] }) {
  console.log('listinggg', listing)
  return (
    // <div className="row">
    <div className="row">
      {listing?.length > 0
        ? listing.map((item) => {
            return (
              <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
              <ProfileCard
              item={item}
              converted_video1={item?.converted_video}
              // createdAt={moment(item.createdAt).format("MM/DD/YYYY")}
              converted_video={item?.converted_video}         
              outputAudio={item?.converted_music}         
              />
              </div>
            );
          })
        : ""}
    </div>
  );
}

export default VideoListings;

import React, { useEffect } from "react";
import { BsChatLeftDots, BsTrash } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import UploadDocument from "./UploadDocument";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { SetUserData } from "../store/reducer";
function Organisations({
  viewData,
  setViewData,
  organizationSearch,
  setOrganizationSearch,
}) {
  const { User = {} } = useSelector((state) => state);
  let email = User?.data?.email;
  console.log(email, "emaildata");

  let userId = User?.data?._id;
  const userinfo = User?.data?.organization;
  console.log(userinfo, "userinfo");

  const dispatch = useDispatch();
  const [Delete, setDelete] = useState(false);
  const [newdata, setnewdata] = useState([]);

  console.log(newdata, "newdataabc");
  const handleCheckboxClick = (docId) => {
    setDelete((prevState) => ({
      ...prevState,
      [docId]: !prevState[docId],
    }));
  };

  const deleteDocument = async (orgId) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BACKEND_URL}/v1/users/${userId}/${orgId}`
      );
      if (response.status) {
        dispatch(
          SetUserData({
            ...User?.data,
            organization: response?.data?.data?.organization,
          })
        );
        newflow();
        console.log("Organization deleted successfully");
        toast.success("Organization deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      toast.success(error);
    }
  };

  const newflow = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BACKEND_URL}/v1/upload/doc?email=${email}`
      );
      if (response.status) {
        // console.log(response?.data?.data?.orgData, "abcabs");
        // dispatch(
        //   SetUserData({
        //     ...User?.data,
        //     organization: response?.data?.data?.orgData,
        //   })
        // );
        setnewdata(response?.data?.data?.orgData);
        setOrganizationSearch(response?.data?.data?.orgData);
      }
    } catch (error) {
      console.error("Error fech listing:", error);
    }
  };

  useEffect(() => {
    newflow();
  }, [email]);

  return (
    <>
      <div className="uploadTable">
        <table class="table">
          {/* <caption>List of users</caption> */}
          <thead>
            <tr>
              <th scope="col">No.</th>
              <th scope="col">Name</th>
              {/* <th scope="col">Users</th> */}
              <th scope="col">Type</th>
              <th scope="col">&nbsp;</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {organizationSearch?.length > 0 ? (
              organizationSearch?.map((ele, index) => (
                <tr key={ele._id}>
                  <td>
                    <div className="d-flex align-items-center align-self-stretch">
                      <div className="d-flex align-items-center align-self-stretch">
                        <input
                          type="checkbox"
                          onClick={() => handleCheckboxClick(ele._id)}
                        />
                      </div>
                    </div>
                  </td>
                  <td>{ele.name}</td>
                  {/* <td>{ele._id}</td> */}
                  <td>{ele.type}</td>

                  <td align="center">
                    <button
                      className="viewBtn blue m-0"
                      onClick={() => setViewData(ele)}
                    >
                      View
                    </button>
                    {/* <button className="viewBtn blue m-0" onClick={() => setViewData({id:userId}) }>View</button> */}
                  </td>

                  {Delete[ele._id] ? (
                    <td>
                      <Link to="">
                        <BsTrash onClick={() => deleteDocument(ele._id)} />
                      </Link>
                    </td>
                  ) : (
                    <td>&nbsp;</td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-danger">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Organisations;

//older
// import React, { useEffect } from "react";
// import { BsChatLeftDots, BsTrash } from "react-icons/bs";
// import { useDispatch, useSelector } from "react-redux";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import UploadDocument from "./UploadDocument";
// import { useState } from "react";
// import axios from "axios";
// import { toast } from "react-toastify";
// import { SetUserData } from "../store/reducer";
// function Organisations({ viewData, setViewData }) {
//   const { User = {} } = useSelector((state) => state);
//   let userId = User?.data?._id;
//   const userinfo = User?.data?.organization;
//     const email=User?.data?.email;
//     console.log(email, "userinfoabc");
// const[newdata,setnewdata]=useState([]);

// console.log(newdata,"newdtata")
//   const dispatch = useDispatch();
//   const [Delete, setDelete] = useState(false);

//   const handleCheckboxClick = (docId) => {
//     setDelete((prevState) => ({
//       ...prevState,
//       [docId]: !prevState[docId],
//     }));
//   };

//   const deleteDocument = async (orgId) => {
//     try {
//       const response = await axios.put(
//         `${process.env.REACT_APP_API_BACKEND_URL}/v1/users/${userId}/${orgId}`
//       );
//       if (response.status) {
//         dispatch(
//           SetUserData({
//             ...User?.data,
//             organization: response.data?.data?.organization,
//           })
//         );
//         console.log("Organization deleted successfully");
//         toast.success("Organization deleted successfully");
//       }
//     } catch (error) {
//       console.error("Error deleting document:", error);
//       toast.success(error);
//     }
//   };

//   const newfllow = async (searchQuery) => {
//     try {
//       // http://localhost:3001/v1/upload/doc?email=anil1900@yopmail.com&userId=651ff20cf85cc538395e1cc5

//       const response = await axios.get(
//         `${process.env.REACT_APP_API_BACKEND_URL}/v1/upload/doc?email=${email}&userId=${userId}`
//       );
//       if (response.status) {

//          setnewdata(response?.data?.data?.orgData);
//         console.log("Search results:", response.data);
//       }
//     } catch (error) {
//       console.error("Error searching documents:", error);
//     }
//   };

//   useEffect(()=>{
//     newfllow();
//   },[])
//   return (
//     <>
//       <div className="uploadTable">
//         <table class="table">
//           {/* <caption>List of users</caption> */}
//           <thead>
//             <tr>
//               <th scope="col">No.</th>
//               <th scope="col">Name</th>
//               {/* <th scope="col">Users</th> */}
//               <th scope="col">Type</th>
//               <th scope="col">&nbsp;</th>
//               <th scope="col">&nbsp;</th>
//             </tr>
//           </thead>
//           <tbody>
//             {newdata?.map((ele, index) => (
//               <tr key={ele._id}>
//                 <td>
//                   <div className="d-flex align-items-center align-self-stretch">
//                     <div className="d-flex align-items-center align-self-stretch">
//                       <input
//                         type="checkbox"
//                         onClick={() => handleCheckboxClick(ele._id)}
//                       />
//                     </div>
//                   </div>
//                 </td>
//                 <td>{ele.name}</td>
//                 {/* <td>{ele._id}</td> */}
//                 <td>{ele.type}</td>

//                 <td align="center">
//                   <button
//                     className="viewBtn blue m-0"
//                     onClick={() => setViewData(ele)}
//                   >
//                     View
//                   </button>
//                   {/* <button className="viewBtn blue m-0" onClick={() => setViewData({id:userId}) }>View</button> */}
//                 </td>

//                 {Delete[ele._id] ? (
//                   <td>
//                     <Link to="">
//                       <BsTrash onClick={() => deleteDocument(ele._id)} />
//                     </Link>
//                   </td>
//                 ) : (
//                   <td>&nbsp;</td>
//                 )}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </>
//   );
// }

// export default Organisations;

import React from 'react'
import {BsTrash,BsFillPencilFill} from "react-icons/bs";
function ApiTable() {
  return (
    <table class="table table-dark">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Key</th>
        <th scope="col">Created</th>
        <th scope="col">Last used </th>
        <th scope="col">&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>harry</td>
        <td>sk-...q7uT</td>
        <td>Sep 19, 2023</td>
        <td>Never</td>
        <td><button><BsFillPencilFill/></button><button className="orng"><BsTrash/></button></td>
      </tr>
      <tr>
        <td>porter</td>
        <td>sk-...q7uT</td>
        <td>Sep 19, 2023</td>
        <td>Never</td>
        <td><button><BsFillPencilFill/></button><button className="orng"><BsTrash/></button></td>
      </tr>
      <tr>
        <td>Secret key</td>
        <td>sk-...q7uT</td>
        <td>Sep 19, 2023</td>
        <td>Never</td>
        <td><button><BsFillPencilFill/></button><button className="orng"><BsTrash/></button></td>
      </tr>
    </tbody>
  </table>
  )
}

export default ApiTable
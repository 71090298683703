import React, { useState } from "react";
import { Row, Spinner } from "react-bootstrap";

import { Link, useNavigate, } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import ShareSocialMedia from "./ShareSocialMedia";
import { BiX } from "react-icons/bi";

function ConvertedVideo({ inputed_Video, outputed_Video , setCategory}) {
  const videoUrl = outputed_Video;
  console.log('videoUrl', videoUrl)

  const [show, setShow] = useState(false);

  const handleCopyLink = () => {
 
    navigator.clipboard
      .writeText(videoUrl)
      .then(() => toast.success("Link copied to clipboard"))
      .catch((err) => toast.error("Something went wrong", err))
   
  };

  const handleDownload = async () => {
  
    const response = await fetch(videoUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "video.mp4"; // or any other name you want
    document.body.appendChild(a);
    a.click();
    a.remove();
 
  };

  // const handleLoad = () => {
  //   setVideoLoaded(true);
  // };



  return (
    <>
      <div className="refernceAudio mt-5">
        {
          inputed_Video.map((ele, index) => {
              return (
                <Row className="text-center videoTitle" key={index}>
                  <div className="col-md-6">
                    <h3>Uploaded Video</h3>
                    {/* <img src="/images/video.png"></img> */}
                    <div className="conVideoBox">
                      <button><BiX/></button>
                      <iframe src={ele.uploaded_video} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h3>Generated Video</h3>
                    {/* <img src="/images/video.png"></img>  */}
                    <div className="conVideoBox">
                      <button><BiX/></button>
                      <iframe src={outputed_Video} />
                    </div>
                 
                  </div>
          
                {videoUrl.length !== 0 &&( <ul className="allBtn d-flex justify-content-center mt-5">
                    <li>
                   <button  onClick={() => setShow(true)} > Share</button>
                    </li>
                    <li>
                   <button onClick={handleDownload} >Download</button>
                    </li>
                    <li>
                   {" "}
                   <button onClick={handleCopyLink} >Copy Link</button>
                    </li>
               </ul>)}
              
                </Row>
              );
            })
          }
    
      </div>
      <div className="text-center">
        <Link to= "" className="seeTitle" onClick={() => setCategory({ _id: "", name: "dashboard" })} >
          See All Videos in Dashboard
        </Link>
      </div>
    
      <Modal className="shareSocial" show={show}>
        <Modal.Header>
          <Modal.Title>Publish To MintAVibe</Modal.Title>
          <button className="bgNone" onClick={() => setShow(false)}>
            <img src="/images/closeBtn.png" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <ShareSocialMedia link ={videoUrl} data={inputed_Video}></ShareSocialMedia>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ConvertedVideo;

import React from 'react'

function TexttoVideo() {
  return (

    <>
    
        <h1 data-aos="zoom-in">Exuber's Text to Video</h1>
        {/* <iframe className='textToImage'
            src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/fcea45680abcd8814f0d530f1c853a54/iframe?muted=true&loop=true&autoplay=true&controls=false"
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe> */}

        <iframe className='textToVideo'
            src="https://customer-yafp6ovyvsh2zn3m.cloudflarestream.com/b101c3c167165b6859de914dfcf89606/iframe?muted=true&loop=true&autoplay=true&controls=false" 
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true" ></iframe>

        <p data-aos="zoom-in">Breathe life into words with Exuber's Text to Video: where your written prompts evolve into dynamic visuals in moments.</p>
        <div className='row'>
            <div className='col-xl-6'>
                <h4>Tailored Creations</h4>
                <p>Customize your videos down to the finest detail, ensuring they resonate perfectly with your vision.</p>
            </div>
            <div className='col-xl-6'>
                <h4>Enhanced Personal Touch</h4>
                <p>Stand out by embedding your unique voice, making every video genuinely yours and captivating your audience.</p>
            </div>
        </div>
    
    
    </>

  )
}

export default TexttoVideo
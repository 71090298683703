import React, { useEffect, useState } from "react";
import { API } from "../apiwrapper";
import { apiURl } from "../store/actions";
import { useSelector } from "react-redux";
import { CLOSED } from "ws";

function NotifyCard({pendingtask}) {
 

  const formatDate = (dt) => {
    const date = new Date(dt);
    return date.toDateString();
  }
  return (
    <>
      <div className="noifyListMain">
        {pendingtask?.map((ele) => {
          return (
            <>
              <div className="noifyList d-flex">
                <div className="position-relative">
                  <figure>
                    <img
                      src={ele?.image_filepath || "/images/artist-alex.jpg"}
                      alt=""
                    />
                  </figure>
                  <div className="toolIcon">
                    <img
                      src={ele?.categoryIcon || "/images/SeoScribe.svg"}
                      alt=""
                    />
                  </div>
                </div>
                <div className="ms-lg-4 ms-0 mt-4 mt-lg-0">
                  <p>
                    Your request for {ele.categoryName} is intitated will notify you
                    once its done.
                  </p>
                  <div className="date">{
                    formatDate(ele.createdAt)
                  }</div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default NotifyCard;

import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, FormGroup, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  SetSignUpData,
  SetUserData,
  SetpopupReducerData,
} from "../store/reducer";

const Congratulation = () => {
  // const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   setShowModal(true);
  // }, []);

  const dispatch = useDispatch();

  const { PopupReducer } = useSelector((state) => state);
  const { showModal = false } = PopupReducer?.modal;


  const handleClosePopup = () => {
    dispatch(SetpopupReducerData({ modalType: "", showModal: false }));
  };

  return (
    <Modal className="shareSocial" show={showModal} onHide={handleClosePopup}>
       <Modal.Header className='text-center border-0'>
          <button className="bgNone d-inline-block ml-auto btnClose" onClick={handleClosePopup}><img src="/images/closeBtn.png" /></button>
        </Modal.Header>
      <Modal.Body>
      <div className="shareIcon congo publish text-center pt-0">
            <figure className='text-center'>
              <img src='/images/sparkBg.png'></img>
              <figcaption>200<span>Points</span></figcaption>
            </figure>
            <h2>Congratulations </h2>
            <p>200 points has been credited to your wallet for login into Exuber.ai</p>
      </div>
      </Modal.Body>
    </Modal>
  );
};

export default Congratulation;

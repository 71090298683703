import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import ShareSocialMedia from "./ShareSocialMedia";

import { FaShare } from "react-icons/fa";
import { BiSolidDownload, BiSolidCopy, BiSolidTrashAlt } from "react-icons/bi";

function StoryToAudio({ outputAudio }) {
  const audioUrl = outputAudio ;
  // console.log(audioUrl,"audioUrlbelwal");
  const linkRef = useRef();
  const [show, setShow] = useState(false);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const handleCopyLink = () => {
 
    navigator.clipboard
      .writeText(audioUrl)
      .then(() => toast.success("Link copied to clipboard"))
      .catch((err) => toast.error("Something went wrong", err))
   
  };

  const handleDownload = async () => {
    const response = await fetch(audioUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "audio.mp3"; // or any other name you want
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  // const handleLoad = () => {
  //   setAudioLoaded(true);
  // };
  return (
    <div>
      
      <div className="refernceAudio text-center mt-5 ">
      <h4>Generated Audio</h4>

        <audio controls>
          <source src={outputAudio} type="audio/ogg" />
        </audio>
        {/* <iframe
          src={outputAudio}
          width="250"
          height="250"
          title={outputAudio}
        /> */}
            <ul className="allBtn d-flex justify-content-center mt-5">
            <li>
              <button  onClick={() => setShow(true)}><FaShare className="me-2" /> Share</button>
            </li>
            <li>
              <button onClick={handleDownload} ><BiSolidDownload className="me-1" style={{fontSize : "22px"}} /> Download</button>
            </li>
            <li>
              {" "}
              <button onClick={handleCopyLink} ><BiSolidCopy className="me-2" /> Copy Link</button>
            </li>
            {/* <li>
              <button>Delete</button>
            </li> */}
          </ul>
      </div>
      <Modal className="shareSocial" show={show}>
        <Modal.Header>
          <Modal.Title>Publish To MintAVibe</Modal.Title>
          <button className="bgNone" onClick={() => setShow(false)}>
            <img src="/images/closeBtn.png" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <ShareSocialMedia link ={audioUrl} data={outputAudio}></ShareSocialMedia>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default StoryToAudio;

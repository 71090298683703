import React from 'react'

function SecondBanner({ name, discription, image,subdescription ,detail,subdescriptions}) {
  return (
    <div>
        <h1 data-aos="zoom-in-right">{name}</h1>
        <figure className='my-4' data-aos="zoom-in-left">
          <img src={image} className="img-fluid img-W50 img-WFull rounded-3" />
        </figure>
        <p data-aos="zoom-in">{discription}</p>
        <p data-aos="zoom-in">{detail}</p>
        {subdescription?.map((ele, index)=>{
          return(
            <>
            <p key={index}>{ele.subtopic}</p>
            </>
          )
          })}
        <div className='row gx-3'>
          {subdescriptions?.map((ele) => {
            return (
              <>
              <div className='col-xl-4 mt-2' data-aos="zoom-in">
                <div className='shopBoxProd'>
                  <h4>{ele.miniTitle}</h4>
                  <p>{ele.minidescription}</p>
                </div>
              </div>
              </>
            );
          })}
        </div>
    </div>
  )
}

export default SecondBanner

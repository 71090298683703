/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiURl } from "../store/actions";
import { API, getAwsVideoUrl } from "../apiwrapper";
import { isValid, validateRequire } from "../Validation/InputValidation";
import { useNavigate } from "react-router";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import ConvertedVideoList from "./ConvertedVideoList";
import { SetpopupReducerData } from "../store/reducer/PopupReducer";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-hot-toast";
import { SetUserData, UpdateUserPoints } from "../store/reducer";
import { Link } from "react-router-dom";

import DubImage from "./DubImage";

import {
  SetCompletedNotifications,
  SetPendingNotifications,
} from "../store/reducer/extra";
import { BsInfoCircle } from "react-icons/bs";
import StoryToAudio from "./StoryToAudio";

function ConvertStoryToAudio({
  category,
  setCategory,
  viewStoryData,
  setViewStoryData,
  outputStory,
  setConvertedStory,
  target_language,
  objId,
}) {
  const { User = {} } = useSelector((state) => state);
  console.log("categoryyy1", category, target_language, outputStory);
  let userId = User?.data?._id;
  let secretKey = User?.data?.secret_key;
  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState({ message: "" });
  const [languages, setLanguage] = useState([]);
  const [categorizedLangauage, setCategorizedLanguage] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [convertedAudio, setConvertedAudio] = useState("");

  const [thumbnail, setThumbnail] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState("");
  const [samplevoice, setSampleVoice] = useState("");
  const [voice, setVoice] = useState("");
  const dispatch = useDispatch();
  const [needhelp, setNeedHelp] = useState(false);
  const [audiofile, setAudioFile] = useState("");
  const [audiofileName, setAudioFileName] = useState("");
  const [langCode, setlangCode] = useState(target_language);

  //  console.log(target_language,"target_languagethird");
  //   console.log(langCode,"langCodeabc");
  //   console.log(audiofileName,"audiofileName");
  //  console.log(categorizedLangauage,"categorizedLangauage");
  //  console.log(selectedVoice,"selectedVoice");

  const [inputField, setInputField] = useState({
    category: "",
    custom_voice_path: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "voice_name") {
      setSelectedVoice(value);
    }

    setInputField({
      ...inputField,
      [name]: value,
    });
    setApiErrors({ message: "" });
    setApiErrors({});
  };

  const fetchLanguage = async () => {
    try {
      await API({
        url: `${apiURl.languages}`,
        method: "GET",
      }).then((data) => {
        console.log("dataAAAAA", data);
        console.log("check--", data?.data);
        let newarr = data?.data;

        var obj = {};
        newarr?.map((i) => {
          {
            console.log("iiiii", i);
            obj[i["langCode"]] = i["langName"];
          }
        });
        console.log("objects----", obj);
        setVoice(obj);

        if (data?.status) {
          let allLang = data?.data || [];

          setLanguage(allLang);
        } else {
          setLanguage([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const fetchLanguageSet = async () => {
    try {
      await API({
        url: `${apiURl.languages}?langCode=${target_language}`,
        method: "GET",
      }).then((data) => {
        console.log("data", data);
        if (data?.status) {
          let allLang = data?.data || [];
          console.log("alllang", allLang);
          setCategorizedLanguage(allLang);
          fetchSampleVoice();
        } else {
          setCategorizedLanguage([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const fetchSampleVoice = async () => {
    var samplevoice = selectedVoice;
    if (samplevoice) {
      console.log("samplevoice", samplevoice.split("("));
      var result = samplevoice.split("(");
      var gender = result[1].replace(")", "");
      console.log("gender", gender);
    }
    // var cc= obj[target_language];
    console.log("cc", voice[target_language]);
    try {
      await API({
        url: `${apiURl.samplevoice}/${voice[target_language]}/${gender}/${result[0]}`,
        method: "GET",
      }).then((data) => {
        console.log("data", data);
        if (data?.status) {
          setSampleVoice(data?.data);
          let allLang = data?.data || [];
          console.log("alllang", allLang);
          //  setCategorizedLanguage(allLang);
        } else {
          //  setCategorizedLanguage([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (selectedVoice) {
      fetchSampleVoice();
    }
  }, [selectedVoice]);

  useEffect(() => {
    fetchLanguageSet();
    // fetchLanguage();
  }, []);

  console.log("sampleVoice", samplevoice);

  const validateAll = () => {
    let errors = {};
    if (!outputStory.trim()) {
      errors.image_to_story_prompt = "Generated Story is required.";
    }

    // Validating the selected voice
    if (!audiofile) {
      if (!selectedVoice) {
        errors.voice_name = "Please select a voice.";
      }
    }

    // Validating the audio file if it's being used
    if (audiofile && audiofile.type.split("/")[0] !== "audio") {
      errors.file = "Please upload a valid audio file.";
    }

    if (audiofile && selectedVoice) {
      errors.file_conflict =
        "Either upload an audio file or select voice, not both.";
    }

    return errors;
  };

  const fetchCompleteList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.notification}/${userId}`,
        method: "GET",
      });
      if (response.status) {
        console.log(response.data.tasks);
        if (response.data.tasks.PENDING) {
          dispatch(SetPendingNotifications(response.data.tasks.PENDING));
        } else {
          dispatch(SetPendingNotifications([]));
        }
        dispatch(SetCompletedNotifications(response.data.tasks.COMPLETED));
      }
    } catch (error) {
      throw error;
    }
  };

  const handleAudioFile = (e) => {
    var file = e.target.files[0];

    setAudioFile(file);
    setAudioFileName(file.name);
    setErrors({ ...errors, file: "" });
  };

  const handleConvert = async (e) => {
    e.preventDefault();
    if (!userId) {
      navigate("/login");
      return;
    }
    try {
      // let err = validateAll();
      // if (isValid(err)) {
      let err = validateAll();
      setErrors(err);

      if (Object.keys(err).length === 0) {
        let audioUrl = await getAwsVideoUrl(
          audiofile,
          `${userId}/InputAudios/`
        );
        console.log("sdfdsfdsfdsfds", audiofile);

        let payload;

        if (audioUrl) {
          // If custom_voice_path (audioUrl) is available, use it
          payload = {
            // ...inputField,
            secret_key: secretKey,
            id: objId,
            category: category,
            generated_story: outputStory,
            custom_voice_path: audioUrl,
            type: "image_to_story_custom_voice",
          };
        } else {
          // If custom_voice_path is not available, use voice_name and target_language
          payload = {
            // ...inputField,
            secret_key: secretKey,
            id: objId,
            category: category,
            generated_story: outputStory,
            target_language: target_language,
            voice_name: selectedVoice,
            type: "",
          };
        }

        // const payload = {
        //       ...inputField,
        //       // userId: userId,
        //       secret_key: secretKey,
        //       id:objId,
        //     category: category,
        //     prompt:outputStory,
        //     target_language: target_language,
        //     custom_voice_path:audioUrl,
        //     voice_name:selectedVoice,
        //     type:'image_to_story_custom_voice'
        //     };
        //       secret_key,
        // category,
        // prompt,
        // voice_name,
        // target_language,
        // custom_voice_path
        console.log("paylooodddd", { ...payload });
        // return
        setIsLoading(true);

        await API({
          url: apiURl.ImageToStoryVoice,

          method: "POST",
          body: { ...payload },
          formData: false,
        }).then((data) => {
          if (data?.status || data?.status === "true") {
            console.log("data------>storytoaudiodata", data);
            fetchCompleteList();
            dispatch(
              SetpopupReducerData({
                modalType: "PENDINGTASK",
                showModal: true,
                category: `${category.name}`,
              })
            );
            // setConvertedAudio(data?.data.response?.response);
            // if (data?.data?.response?.error) {
            //   toast.error(data?.data?.response?.error);
            // } else {
            //   // getConvertedVideo(data?.data?.response?.rep_id);
            //   // toast.success(
            //   //   "Your project is in the works! We'll drop you an email once it's all set and ready. Stay tuned!",
            //   //   { duration: 7000 }
            //   // );
            // }

            dispatch(SetUserData(data?.data?.updatedUserInfo));
          } else {
            setApiErrors({ message: data?.message });
            setErrors({});
          }
        });
      } else {
        setErrors(err);
      }
    } catch (error) {
      setApiErrors({ message: error?.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {needhelp ? (
        <DubImage setNeedHelp={setNeedHelp} />
      ) : (
        <>
          <h2 className="text-center text-white mb-4">Story To Audio</h2>
          <div className="refernceAudio">
            <form id="contact" method="post" className="form" role="form">
              <div className="row">
                <div className="col-12 mb-4">
                  <div className="d-flex justify-content-between">
                    <label htmlFor="audio_text" className="form-label">
                      Genrated Story <span className="text-danger"> *</span>
                    </label>
                    <button
                      onClick={() => setNeedHelp(true)}
                      className="needHelp"
                    >
                      Need Help?
                    </button>
                  </div>
                  <textarea
                    className="form-control"
                    type="text"
                    id="image_to_story_prompt"
                    name="image_to_story_prompt"
                    value={outputStory}
                    rows={8}
                    onChange={(e) => setConvertedStory(e.target.value)}
                  />
                  {errors.generated_story ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.generated_story}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-xxl-4 col-md-6 form-group pb-3">
                  <label>
                    Choose a voice<span className="text-danger"> *</span>
                  </label>
                  <select
                    className="form-control"
                    id="country"
                    onChange={handleChange}
                    value={selectedVoice}
                    name="voice_name"
                  >
                    <option value={""}>Choose Voice</option>
                    {categorizedLangauage[0]?.allLang?.map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                  </select>
                  {errors.voice_name ? (
                    <span className="text-danger" style={{ fontSize: "14px" }}>
                      {errors.voice_name}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {/* {samplevoice && ( */}
                <div className="col-xxl-4 form-group pb-3">
                  <label>
                    Sample Voice<span className="text-danger"> *</span>
                  </label>
                  <audio src={samplevoice} controls />
                </div>
                {/* )} */}
              </div>
              {/* {apiErrors.message ? (
            <span className="text-danger text-center">{apiErrors.message}</span>
          ) : (
            ""
          )} */}
              <p class="text-center">OR</p>

              <div class="customUpload sizedecrese">
                <input
                  className="form-control file-border"
                  type="file"
                  id="formFile"
                  accept="audio/*"
                  onChange={handleAudioFile}
                />
                <span class="upload-btn" id="upload-btn">
                  {audiofileName}
                </span>
              </div>
              {errors.file_conflict && (
                <span className="text-danger" style={{ fontSize: "14px" }}>
                  {errors.file_conflict}
                </span>
              )}

              <div className="row">
                <div className="col-xs-12 col-md-12 form-group synBtn text-center">
                  {isLoading ? (
                    <button className="btn btn-primary mt-4">
                      <Spinner animation="border" size="lg" /> Processing...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary mt-4"
                      onClick={handleConvert}
                      disabled={isLoading}
                    >
                      Continue
                    </button>
                  )}

                  {/* <button className="" type="">
            <img src="/images/soundwave.png" /> synthesize
          </button> */}
                </div>
              </div>
            </form>
          </div>
          {/* {convertedVideo && (
        <ConvertedVideoList
          inputed_Video={videolisting}
          outputed_Video={convertedVideo}
          
        />
      )} */}
          {convertedAudio && <StoryToAudio outputAudio={convertedAudio} />}
        </>
      )}
    </>
  );
}

export default ConvertStoryToAudio;

import { useEffect, useState } from "react";
import "./App.css";
import "./style.css";

import OpenModal from "./components/PopupModal/OpenModal";
import { useSelector } from "react-redux";
import Home from "./components/Home/Home";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import RequireAuth from "./components/config/useAuth";
import Profile from "./components/profile/Profile";
import EditProfile from "./components/profile/EditUserProfile";
import ConvertVideo from "./components/profile/ConvertVideo";
import { ToastContainer } from "react-toastify";
import { Toaster } from 'react-hot-toast';
import Package from "./components/profile/Package";
import UploadDocument from "./components/profile/UploadDocument";
import Organisations from "./components/profile/Organisations";
import HomeDemo from "./components/Home/HomeDemo";
import AIDubbing from "./components/homeComponents/AIDubbing";
import AboutUs from "./components/Home/AboutUs";
import ContactUs from "./components/Home/ContactUs";
import PrivacyPolicy from "./components/Home/PrivacyPolicy";
import TermsConditions from "./components/Home/TermsConditions";
import Accounts from "./components/profile/Accounts";
import AITool from "./components/homeComponents/AITool";




function App() {

  const [showPopup, setShowPopup] = useState(false);
  const { PopupReducer } = useSelector((state) => state);
  const { showModal = false } = PopupReducer?.modal;
  const userToken = localStorage.getItem("token");

  useEffect(() => {
    setShowPopup(showModal);
    return () => {
      setShowPopup(false);
    };
  }, [showModal]);
  return (
    <>
      {/* <Congratulation/> */}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            theme: {
              primary: "#4aed88",
            },
          },
        }}
      ></Toaster>
      {showPopup && <OpenModal />}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="homedemo" element={<HomeDemo />}></Route>

          <Route path="aidubbing" element={<AIDubbing />}></Route>
          <Route path="aboutus/:params?" element={<AboutUs />}></Route>
          <Route path="contact" element={<ContactUs />}></Route>
          <Route path="privacy" element={<PrivacyPolicy />}></Route>
          <Route path="termsconditions" element={<TermsConditions />}></Route>

          <Route path="*" element={<Navigate to={`/`} replace={true} />} />
          {/* <Route path="listing" element={<NewChat/>}></Route> */}
          {/* <Route path="/Organisations"element={<Organisations/>}></Route> */}
          <Route path="/profile/package" element={<Package />} />
        </Route>
        <Route>

        </Route>
        {/* <Route element={<RequireAuth authorized={userToken ? true : false} />}> */}
        <Route path="/AITool" element={<AITool />}></Route>
        <Route path="/AITool/:categoryname/:dataDetail" element={<AITool />}></Route>
        <Route path="/AITool/:catg/:buyNow?/:uploadDocument?" element={<AITool />} />
        {/* <Route path="/profile/:userId/:catg/:buyNow?/:uploadDocument?" element={<Profile />} /> */}
        <Route path="/profile/:userId" element={<Profile />} />
        <Route path="/account/api-keys/:userId" element={<Accounts />} />
        <Route path="/profile/edit/:userId" element={<EditProfile />} />
        <Route path="/convert_video" element={<ConvertVideo />} />

        {/* <Route path="/profile/:userId/:catg/:uploadDocument?" element={<UploadDocument />} /> */}
        {/* </Route> */}
      </Routes>
    </>
  );
}
export default App;

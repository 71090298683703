/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  BsStar,
  BsClock,
  BsCollection,
  BsSoundwave,
  BsHeadset,
  BsTrash,
  BsFillChatLeftTextFill,
  BsFileEarmarkTextFill,
  BsPersonPlus,
  BsList,
  BsX,
} from "react-icons/bs";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import WebSocket from "ws";
import { apiURl } from "../store/actions";
import { API } from "../apiwrapper";
import moment from "moment";
// import VideoListings from "./VideoListings";
import SideBar from "../profile/SideBar";
import ProfileCard from "../profile/ProfileCard";

import VideoListings from "../profile/VideoListings";
import VoiceChange from "../profile/VoiceChange";
import TextToAudioListing from "../profile/TextToAudioListing";
import TextToMusic from "../profile/TextToMusic";
import ConvertTextToAudio from "../profile/ConvertTextToAudio";
//import ConvertTextToAudio from "../profile/ConvertTextToAudio";
import ConvertTextToMusic from "../profile/ConvertTextToMusic";
import ShopInVideo from "../profile/ShopInVideo";
// import ConvertVideoToText from "../profile/ConvertVideoToText";
// import VideoToTextListing from "../profile/VideoToTextListing";
// import ConvertImageToVideo from "../profile/ConvertImageToVideo";
// import ImageToVideoListing from "../profile/ImageToVideoListing";
// import Introduction from "../profile/Introduction";
import SeoScribe from "../profile/SeoScribe";
// import WebSocket from "websocket";

import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Package from "../profile/Package";
import NewChat from "../profile/NewChat";
import MYChat from "../profile/MYChat";
import ChatHistory from "../profile/ChatHistory";
import Extractdocument from "../profile/Extractdocument";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASECONFIG } from "../config";
import { SetUserData, SetpopupReducerData } from "../store/reducer";
import ConvertTextToImage from "../profile/ConvertTextToImage";
import ConvertTextToVideo from "../profile/ConvertTextToVideo";
import InteriorDesign from "../profile/InteriorDesign";
import ImageToStory from "../profile/ImageToStory";
import Header from "../homeComponents/Header";
import ImageEnhancer from "./ImageEnhancer";
import { useRef } from "react";
import ImageEnhancerTest from "../profile/ImageEnhancerTest";
import InteriorDesigne from "../homeComponents/InteriorDesigne";
import AIToolSidebar from "./AIToolSidebar";
import { io } from "socket.io-client";
import {
  SetCompletedNotifications,
  SetPendingNotifications,
} from "../store/reducer/extra";
import FaceSearch from "./FaceSearch";
import { all } from "axios";

function AITool() {
  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  console.log(userId, "userId");
  let [headerCategory, setheaderCategory] = useState([]);
  let { catg, buyNow } = useParams();
  const [userVideoList, setUserVideoList] = useState([]);
  const [userTextToAudioList, setUserTextToAudioList] = useState([]);
  const [userTextToMusicList, setUserTextToMusicListList] = useState([]);
  const [userVideoToTextList, setUserVideoToTextList] = useState([]);
  const [userImageToVideoList, setUserImageToVideoList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [getTrashData, setTrashData] = useState([]);
  const [getFavouriteData, setFavouriteData] = useState([]);
  const [RecentViewData, setRecentViewedData] = useState([]);
  const [TextToImageData, setTextToImageData] = useState([]);
  const [TextToVideoData, setTextToVideoData] = useState([]);
  const [InteriorDesigner, setInteriorDesigner] = useState([]);
  const [ImageStory, setImageToStory] = useState([]);
  const [imageEnhancer, setImageEnhancer] = useState([]);
  const [GlobalVoiceAi, setGlobalVoiceAi] = useState([]);
  const [AIDubbing, setAIDubbing] = useState([]);
  const [seoScribeData, setSeoScribeData] = useState([]);
  const [chathistory, SetchatHistroy] = useState();
  const [allCategory, setAllCategory] = useState([]);
  const [mainTools, setMainTools] = useState([]);
  const [mainToolsId, setMainToolsId] = useState([]);

  const [FaceSearchData, setFaceSearchData] = useState([]);
  const [labTools, setLabTools] = useState([]);
  const [category, setCategory] = useState({
    _id: "",
    name: "dashboard",
  });
  const [socketData, setSocketData] = useState("");
  const [socket, setSocket] = useState(null);
  console.log("catg", buyNow, catg, category);

  console.log(chathistory);
  const [orgId, setOrgId] = useState("");
  const [accessKey, setAccessKey] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [selectedPersona, setSelectedPersona] = useState("Default"); // Default value is an empty string
  const [showLeftMenu, setShowLeftMenu] = useState(true);
  const [active, setActive] = useState(1);
  const [searchParams, setsearchParams] = useSearchParams();
  const [socketInstance, setSocketInstance] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = searchParams.get("id");
  const token = searchParams.get("token");
  const session = searchParams.get("session");
  const type = searchParams.get("type");
  const [error, setError] = useState("");

  const handleClick = (ele) => {
    console.log("eledadada", ele);
    setActive(ele);
    if (ele === 2) {
      RecentViewedData();
    }
  };

  const fetchVideoList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.allcontentNew}?userId=${userId}`,
        method: "GET",
      });
      let allData = response?.data?.userData;
      console.log("Alldatabelwal", allData);

      if (response) {
        setAllData([...allData]);
        setUserTextToAudioList([...allData]); //
        setGlobalVoiceAi([...allData]); //
        setTextToImageData([...allData]); //
        setTextToVideoData([...allData]); //
        setInteriorDesigner([...allData]); //
        setImageToStory([...allData]); //
        setImageEnhancer([...allData]); //
        setSeoScribeData([...allData]);
        // setRecentViewedData([...allData]);
        setFaceSearchData([...allData]);
        setAIDubbing([...allData]);
      } else {
        setUserVideoList([]);
      }
    } catch (error) {
      throw error;
    }
  };

  // const fetchTrashData = async () => {
  //   try {
  //     const response = await API({
  //       url: `${apiURl?.allcontentNew}?userId=${userId}&isDeleted=${true}`,
  //       method: "GET",
  //     });

  //     let trashData = response?.data?.userData
  //     console.log("response----", response);

  //     if (response) {
  //       console.log('trasssssssData', trashData)
  //       setTrashData([...trashData]);
  //       // setFavouriteData(response?.data);
  //     } else {
  //       // setFavouriteData([])
  //       setTrashData([]);
  //     }
  //     console.log("trasssssssDataNew", response);
  //   } catch (error) {
  //     throw error;
  //     //.log(error)
  //   }
  // };

  const fetchAllCategory = async () => {
    try {
      await API({
        url: `${apiURl.allCategory}`,
        method: "GET",
      }).then((data) => {
        if (data?.status) {
          console.log("data In Category", data);
          let allCat = data?.data || [];
          setAllCategory(allCat.all_category);
          console.log("ALL CATEGORY", allCat);
          setMainTools(allCat.mainTools);
          console.log(
            "main tool id for all",
            allCat.mainTools.map((ids) => {
              console.log("IDSS", ids._id);
              setMainToolsId(ids._id);
            })
          );
          setMainToolsId(allCat.mainTools);
          setLabTools(allCat.labTools);
          //  setCategory({ _id: allCat?.[0]?._id, name: allCat?.[0]?.name });
        } else {
          setMainTools([]);
          setLabTools([]);
          setAllCategory([]);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const fetchFavouriteData = async () => {
    try {
      let response = await API({
        url: `${apiURl.favourite}/${userId}`,
        method: "GET",
      });

      if (response) {
        setFavouriteData(response?.data);
      } else {
        setFavouriteData([]);
      }
      console.log("favouraite", getFavouriteData);
    } catch (error) {
      //.log(error)
    }
  };

  const RecentViewedData = async () => {
    try {
      let response = await API({
        url: `${apiURl.recentViewed}?userId=${userId}&isRecentView=true`,
        method: "GET",
      });

      if (response) {
        console.log(response?.data, "responseresponsebelwal");
        setRecentViewedData(response?.data?.userData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (isRecentView) {
  //     RecentViewedData();
  //   }
  // }, [isRecentView]);

  const fetchCompleteList = async () => {
    try {
      const response = await API({
        url: `${apiURl?.notification}/${userId}`,
        method: "GET",
      });
      if (response.status) {
        console.log(response.data.tasks);
        if (response.data.tasks.PENDING) {
          dispatch(SetPendingNotifications(response.data.tasks.PENDING));
        } else {
          dispatch(SetPendingNotifications([]));
        }
        dispatch(SetCompletedNotifications(response.data.tasks.COMPLETED));
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    // const socket = io("https://ufxtr8f6xa.execute-api.ap-south-1.amazonaws.com/user:3001",{
    //   // const socket = io("http://localhost:5001/", {
    //   // transports: ["websocket"],
    //   cors: {
    //     origin: "https://main.d1e97831t8hjtq.amplifyapp.com",
    //   },
    // });
    // http://aebcc08d82b354e83b7b7494c5cd5254-1052769592.ap-south-1.elb.amazonaws.com:3001/socket.io/?EIO=4&transport=polling&t=OhHDXnq
    // http://aebcc08d82b354e83b7b7494c5cd5254-1052769592.ap-south-1.elb.amazonaws.com:3001/
    const socket = io(
      // "https://ufxtr8f6xa.execute-api.ap-south-1.amazonaws.com",
      "https://websocket-uatapi.exuber.ai",
      {
        // const socket = io('http://localhost:3001', {
        // transports: ['websocket'], // Use WebSocket transport
        // path:"/user/socket.io/",
        // withCredentials: true, // Enable credentials (if needed)
        extraHeaders: {
          // 'Access-Control-Allow-Origin': 'https://main.d1e97831t8hjtq.amplifyapp.com', // Replace with frontend URL
        },
      }
    );
    console.log(socket, "socket");
    setSocketInstance(socket);

    socket.on("connectss", (data) => {
      console.log("abc in here");
      console.log("Connected to server");
      console.log(userId);
      console.log(data);
      setSocketData(data);
      socket.emit("setUserId", { userId });
    });

    socket.on("customDataFromBackend", (data) => {
      console.log("Received custom data from backend:", data);
      console.log(
        data.completedRequests,
        "completedTTTTask",
        data.completedRequests[0].category_name
      );
      let categoryName = data.completedRequests[0].category_name;
      let len = data.completedRequests.length;
      if (len > 0 && data.completedRequests[0].user_id == userId) {
        fetchCompleteList();
        dispatch(
          SetpopupReducerData({
            modalType: "COMPLETEDTASK",
            showModal: true,
            category: categoryName,
          })
        );
      }

      // socket.on("customDataFromBackend", (data) => {
      //   console.log("Received custom data from backend:", data);
      //   let len = data.completedRequests.length;
      //   if (len > 0 && data.completedRequests.user_id === userId) {
      //     fetchCompleteList();
      //     dispatch(
      //       SetpopupReducerData({
      //         modalType: "COMPLETEDTASK",
      //         showModal: true,
      //       })
      //     );
      //   }
      // });

      // socket.emit("ReceivedData", { userId });
      // Set the received data in the state to use in your component
      // setCustomDataFromBackend(data);
    });

    // setLoading(false);

    socket.on("disconnect", (data) => {
      console.log(data);
    });

    // return function cleanup() {
    //   socket.disconnect(); // };
  }, [category]);

  useEffect(() => {
    if (category?.name === "dashboard") {
      fetchVideoList();
    }
  }, [category]);

  useEffect(() => {
    allCategory?.length > 0 &&
      allCategory?.map((ele) => {
        console.log("ele", ele?.name);
        setAllCategory({
          _id: ele?._id,
          name: ele?.name,
        });
      });
  }, []);
  console.log("category000", category);

  useEffect(() => {
    fetchAllCategory();
    // fetchTrashData();
    // fetchFavouriteData();
    // RecentViewedData();
  }, []);

  useEffect(() => {
    console.log("Effect running:", { token, session, type });
    if (token && session && type) {
      console.log("ankit");
      let payload = {
        type: type,
        token: token,
        session: session,
        id: userId,
      };

      // setsearchParams(payload);
      // console.log("payload111", payload);

      // let url = `${window.location.origin}/profile/${userId}`
      // console.log('url', url)
      // console.log('url', url)

      API({
        url: apiURl.paymentVerify,
        method: "POST",
        body: { ...payload },
      }).then((data) => {
        console.log("data?.data", data);
        if (data?.status || data?.status === "true") {
          toast.success("Payment Succsessfull");
          navigate("/");
          dispatch(SetUserData(data?.data));
        } else {
          console.log("toast");
          toast.error(data?.message);
        }
      });
    }
  }, [token, session, type]);

  console.log(category);

  const handleOrganisation = (value) => {
    console.log(value);
    setOrganisation(value);
    const currentOrganisation = User?.data?.organization.find((val) => {
      return value.toLowerCase() === val.name.toLowerCase();
    });
    setOrgId(currentOrganisation._id);
    setAccessKey(currentOrganisation.access_Key);
  };

  const handleDropdownItemClick = (persona) => {
    setSelectedPersona(persona);
  };

  const { categoryname, dataDetail } = useParams();

  if (categoryname) {
    category.name = categoryname;
  }
  console.log("categoryname", categoryname, dataDetail);

  return (
    <section className={`profileMain ${showLeftMenu ? "show" : ""}`}>
      <Header data={socketData} />
      <div className="menuSectionLeft">
        <button
          className="toggleBtn"
          onClick={() => setShowLeftMenu(!showLeftMenu)}
        >
          <BsX className="cross" /> <BsList className="humber" />
        </button>
        <AIToolSidebar
          allCategory={allCategory}
          mainTools={mainToolsId}
          labTools={labTools}
          category={category}
          setCategory={setCategory}
        />
        {console.log("category in AITool.js-440", category, allCategory)}
      </div>
      <div className="container">
        <div className="row justify-content-center">
          {/* <div className="col-lg-4 col-xl-3">
            </div> */}

          <div
            className={
              category?.name === "ChatBot" && "col-lg-6 col-xl-12 chatBoatai"
            }
          >
            {category?.name === "dashboard" ? (
              <div className="maindash">
                <ul className="tabing">
                  <li>
                    <button
                      className={active === 1 ? "active" : ""}
                      onClick={() => handleClick(1)}
                    >
                      <BsCollection /> All
                    </button>
                  </li>
                  <li>
                    <button
                      className={active === 2 ? "active" : ""}
                      onClick={() => {
                        handleClick(2);
                      }}
                    >
                      <BsClock /> Recent viewed
                    </button>
                  </li>
                  {/* {allCategory?.map((ele) => (
                    <li>
                      <button
                        className={active === ele._id ? "active" : ""}
                        onClick={() => {
                          setheaderCategory({ _id: ele._id, name: ele.name });
                          handleClick(ele._id);
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={ele.icon}
                            alt="Image"
                            height="15"
                            width="15"
                          />
                          <span style={{ marginLeft: "8px" }}>{ele.name}</span>
                        </div>
                      </button>
                    </li>
                  ))} */}

                  {allCategory &&
                    Array.isArray(allCategory) &&
                    allCategory.map((ele) => (
                      <li key={ele._id}>
                        <button
                          className={active === ele._id ? "active" : ""}
                          onClick={() => {
                            setheaderCategory({ _id: ele._id, name: ele.name });
                            handleClick(ele._id);
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={ele.icon}
                              alt="Image"
                              height="15"
                              width="15"
                            />
                            <span style={{ marginLeft: "8px" }}>
                              {ele.name}
                            </span>
                          </div>
                        </button>
                      </li>
                    ))}

                  {/* <li>
                    <button
                      className={active === 4 ? "active" : ""}
                      onClick={() => handleClick(4)}
                    >
                      {" "}
                      <BsHeadset />
                      AI Dubbing
                    </button>
                  </li>
                  <li>
                    <button
                      className={active === 5 ? "active" : ""}
                      onClick={() => handleClick(5)}
                    >
                      {" "}
                      <BsSoundwave />
                      SoundScribe
                    </button>
                  </li>
                  <li>
                    <button
                      className={active === 6 ? "active" : ""}
                      onClick={() => handleClick(6)}
                    >
                      {" "}
                      <BsSoundwave />
                      Text To Image
                    </button>
                  </li>
                  <li>
                    <button
                      className={active === 8 ? "active" : ""}
                      onClick={() => handleClick(8)}
                    >
                      {" "}
                      <BsSoundwave />
                      Text To Video
                    </button>
                  </li>
                  <li>
                    <button
                      className={active === 9 ? "active" : ""}
                      onClick={() => handleClick(9)}
                    >
                      {" "}
                      <BsSoundwave />
                      Interior Designer
                    </button>
                  </li>

                  <li>
                    <button
                      className={active === 10 ? "active" : ""}
                      onClick={() => handleClick(10)}
                    >
                      {" "}
                      <BsSoundwave />
                      Image To Story
                    </button>
                  </li>

                  <li>
                    <button
                      className={active === 11 ? "active" : ""}
                      onClick={() => handleClick(11)}
                    >
                      {" "}
                      <BsSoundwave />
                      Image Enhancer
                    </button>
                  </li>

                  <li>
                    <button
                      className={active === 12 ? "active" : ""}
                      onClick={() => handleClick(12)}
                    >
                      {" "}
                      <BsSoundwave />
                      Text to Speech
                    </button>
                  </li> */}

                  <li>
                    <button
                      className={active === 13 ? "active" : ""}
                      onClick={() => handleClick(13)}
                    >
                      {" "}
                      <BsTrash />
                      Trash
                    </button>
                  </li>
                </ul>
                <div className="dashData">
                  <div className={active === 1 ? "all d-block" : "d-none"}>
                    <div className="row">
                      {allData.map((item) => {
                        console.log("iiiiiiiii", item);
                        return (
                          <div
                            key={item.id}
                            className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative"
                          >
                            <ProfileCard
                              item={item}
                              converted_video={item?.converted_output}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={active === 2 ? "recentView d-block" : "d-none"}
                  >
                    <div className="row">
                      {RecentViewData?.filter(
                        (item) => item.isRecentView === true
                      )?.map((item) => {
                        console.log(item, "recentviewdataaaaa");
                        return (
                          <div
                            key={item.id}
                            className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative"
                          >
                            <ProfileCard item={item} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={active === 3 ? "favorite d-block" : "d-none"}>
                    <div className="row">
                      {getFavouriteData?.map((item) => {
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard
                                item={item}
                                fetchfavoriteData={fetchFavouriteData}
                                converted_video1={item?.converted_video}
                                outputAudio={item?.converted_music}
                                converted_video={item?.converted_video}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={active === 4 ? "favorite d-block" : "d-none"}
                    key={userVideoList?._id}
                  >
                    <VideoListings listing={userVideoList} />
                  </div>
                  {/* <div className={active === 5 ? "favorite d-block" : "d-none"}>
                    <TextToMusic listingData={userTextToAudioList} />
                  </div> */}
                  <div
                    className={
                      headerCategory.name === "text_to_music"
                        ? "trash d-block"
                        : "d-none"
                    }
                  >
                    <div className="row">
                      {userTextToAudioList
                        ?.filter(
                          (item) => item.category.name === "text_to_music"
                        )
                        ?.map((item) => {
                          console.log("text_to_musicCategory", item);
                          return (
                            <>
                              <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                                <ProfileCard item={item} />
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div
                    className={
                      headerCategory.name === "text_to_image"
                        ? "trash d-block"
                        : "d-none"
                    }
                    key={TextToImageData?._id}
                  >
                    <div className="row">
                      {TextToImageData?.filter(
                        (item) => item.category.name === "text_to_image"
                      )?.map((item) => {
                        console.log("text_to_imageCategory", item);
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard
                                item={item}
                                // outputAudio={item?.converted_music}
                                // converted_text_to_video={item?.converted_text_to_image}
                                // converted_video1={item?.converted_output}
                                // thumbnailimg={item?.converted_output}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    className={
                      headerCategory.name === "interiorDesigner"
                        ? "trash d-block"
                        : "d-none"
                    }
                  >
                    <div className="row">
                      {InteriorDesigner?.filter(
                        (item) => item.category.name === "interiorDesigner"
                      )?.map((item) => {
                        console.log("InteriorDesignerCategory", item);
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard item={item} />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    className={
                      headerCategory.name === "image_to_story"
                        ? "trash d-block"
                        : "d-none"
                    }
                  >
                    <div className="row">
                      {ImageStory?.filter(
                        (item) => item.category.name === "image_to_story"
                      )?.map((item) => {
                        console.log("image_to_storyCategory", item);
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard item={item} />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    className={
                      headerCategory.name === "ImageEnhancer"
                        ? "trash d-block"
                        : "d-none"
                    }
                  >
                    <div className="row">
                      {imageEnhancer
                        ?.filter(
                          (item) => item.category.name === "ImageEnhancer"
                        )
                        ?.map((item) => {
                          console.log("ImageEnhancerCategory", item);
                          return (
                            <>
                              <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                                <ProfileCard item={item} />
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>

                  <div
                    className={
                      headerCategory.name === "text_to_speech"
                        ? "trash d-block"
                        : "d-none"
                    }
                  >
                    <div className="row">
                      {GlobalVoiceAi?.filter(
                        (item) => item.category.name === "text_to_speech"
                      )?.map((item) => {
                        console.log("text_to_speechCategory", item);
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard item={item} />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    className={
                      headerCategory.name === "text_to_video"
                        ? "trash d-block"
                        : "d-none"
                    }
                  >
                    <div className="row">
                      {TextToVideoData?.filter(
                        (item) => item.category.name === "text_to_video"
                      )?.map((item) => {
                        console.log("text_to_videoCategory", item);
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard item={item} />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    className={
                      headerCategory.name === "AI_Dubbing"
                        ? "trash d-block"
                        : "d-none"
                    }
                  >
                    <div className="row">
                      {AIDubbing?.filter(
                        (item) => item.category.name === "AI_Dubbing"
                      )?.map((item) => {
                        console.log("AI_DubbingCategory", item);
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard item={item} />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={
                      headerCategory.name === "SeoScribe"
                        ? "trash d-block"
                        : "d-none"
                    }
                  >
                    <div className="row">
                      {seoScribeData
                        ?.filter((item) => item.category.name === "SeoScribe")
                        ?.map((item) => {
                          console.log("SeoScribeCategory", item);
                          return (
                            <>
                              <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                                <ProfileCard item={item} />
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div
                    className={
                      headerCategory.name === "FaceSearch"
                        ? "trash d-block"
                        : "d-none"
                    }
                  >
                    <div className="row">
                      {FaceSearchData?.filter(
                        (item) => item.category.name === "FaceSearch"
                      )?.map((item) => {
                        console.log("FaceSearch", item);
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard item={item} />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className={active === 13 ? "trash d-block" : "d-none"}>
                    <div className="row">
                      {getTrashData?.map((item) => {
                        return (
                          <>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 position-relative">
                              <ProfileCard
                                item={item}
                                outputAudio={item?.converted_music}
                                converted_video1={item?.converted_video}
                                thumbnailimg={item?.converted_text_to_image}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ) : category.name === "AI_Dubbing" ? (
              <div className="py-5">
                <VoiceChange category={category} setCategory={setCategory} />
              </div>
            ) : category?.name === "text_to_music" ? (
              <div className="py-5">
                <ConvertTextToMusic
                  category={category}
                  setCategory={setCategory}
                />
              </div>
            ) : category?.name === "text_to_speech" ? (
              <div className="py-5">
                <ConvertTextToAudio
                  category={category}
                  setCategory={setCategory}
                />
              </div>
            ) : category?.name === "text_to_image" ? (
              <div className="py-5">
                <ConvertTextToImage
                  category={category}
                  setCategory={setCategory}
                />
              </div>
            ) : category?.name === "text_to_video" ? (
              <div className="py-5 ps-lg-4">
                <ConvertTextToVideo
                  category={category}
                  setCategory={setCategory}
                />
              </div>
            ) : category?.name === "interiorDesigner" ? (
              <div className="py-5 ps-lg-4">
                <InteriorDesign category={category} setCategory={setCategory} />
              </div>
            ) : category?.name === "image_to_story" ? (
              <div className="py-5 ps-lg-4">
                <ImageToStory
                  category={category}
                  setCategory={setCategory}
                  id={dataDetail}
                />
              </div>
            ) : category?.name === "ImageEnhancer" ? (
              <div className="py-5 ps-lg-4">
                <ImageEnhancerTest
                  category={category}
                  setCategory={setCategory}
                />
              </div>
            ) : category?.name === "shopInVideo" ? (
              <div className="py-5">
                <ShopInVideo category={category} setCategory={setCategory} />
              </div>
            ) : category?.name === "SeoScribe" ? (
              <div className="py-5">
                <SeoScribe
                  category={category}
                  setCategory={setCategory}
                  id={dataDetail}
                />
              </div>
            ) : category?.name === "FaceSearch" ? (
              <div className="py-5">
                <FaceSearch
                  category={category}
                  setCategory={setCategory}
                  id={dataDetail}
                />
              </div>
            ) : category?.name === "ChatBot" ? (
              <div className="maindash">
                {active !== 5 ? (
                  <ul className="tabing">
                    <li>
                      <button
                        className={active === 1 ? "active" : ""}
                        onClick={() => handleClick(1)}
                      >
                        <BsFillChatLeftTextFill /> ChatBot AI
                      </button>
                    </li>
                    {/* <li>
                        <button
                          className={active === 2 ? "active" : ""}
                          onClick={() => handleClick(2)}
                        >
                          <BsFillChatLeftTextFill /> My Chats
                        </button>
                      </li> */}
                    <li>
                      <button
                        className={active === 3 ? "active" : ""}
                        onClick={() => handleClick(3)}
                      >
                        {" "}
                        <BsFileEarmarkTextFill />
                        Organisations
                      </button>
                    </li>
                    {/* <li>
                     <button
                       className={active === 4 ? "active" : ""}
                       onClick={() => handleClick(4)}
                     >
                       {" "}
                       <BsHeadset />
                       MintAVibe
                     </button>
                   </li> */}

                    {/* <li>
                      <Link
                        className={active === 5 ? "active" : ""}
                        onClick={() => handleClick(5)}
                        to={`/profile/${userId}/ChatBot`}
                      >
                        <BsPersonPlus />
                       Become Pro 
                      </Link>
                    </li> */}
                    {active === 1 && (
                      <li className="orgBtn d-flex ms-auto ">
                        <div className="orgName">Test</div>
                        <div className="personaName">
                          <img src="/images/doctor-image.jpg" /> Persona Name
                        </div>
                        {organisation && organisation !== "Default" && (
                          <div className="orgName">{organisation}</div>
                        )}
                        {selectedPersona && selectedPersona !== "Default" && (
                          <div className="personaName">
                            <>
                              <img src="/images/doctor-image.jpg" />{" "}
                              {selectedPersona}
                            </>
                          </div>
                        )}
                      </li>
                    )}
                  </ul>
                ) : null}

                <div className="dashData">
                  <div className={active === 1 ? "all d-block" : "d-none"}>
                    <NewChat
                      handleDropdownItemClick={handleDropdownItemClick}
                      selectedPersona={selectedPersona}
                      organisation={organisation}
                      orgId={orgId}
                      accessKey={accessKey}
                      handleOrganisation={handleOrganisation}
                    />
                  </div>
                  <div
                    className={active === 2 ? "recentView d-block" : "d-none"}
                  >
                    <MYChat
                      chatHistoryParent={chathistory}
                      setChatHistoryParent={SetchatHistroy}
                    />
                  </div>
                  <div className={active === 3 ? "favorite d-block" : "d-none"}>
                    <Extractdocument />
                  </div>
                  <div className={active === 4 ? "favorite d-block" : "d-none"}>
                    <VideoListings listing={userVideoList} />
                  </div>
                  <div className={active === 5 ? "favorite d-block" : "d-none"}>
                    <Package />
                  </div>
                </div>
              </div>
            ) : category?.name === "SeoScribe" ? (
              <div className="py-5">
                <SeoScribe category={category} setCategory={setCategory} />
              </div>
            ) : (
              ""
            )}
          </div>
          {/* {category?.name === "ChatBot" && active === 1 ? (
            <div className="col-lg-2 col-xl-3">
              <ChatHistory chatHistoryParent={chathistory} />
            </div>
          ) : (
            ""
          )} */}
        </div>
      </div>
    </section>
  );
}

export default AITool;

import React from 'react'
import StepCard from './StepCard'
import StepVideo from './StepVideo'
import StepAIDubbing from './StepAIDubbing'

function DubAIDubbing({setNeedHelp}) {
  return (
    <>  
        <div className='position-relative'>
            <button className='backBtn align-items-start' onClick={()=>setNeedHelp(false)}>Back</button>
            <h2 class="text-center text-white mb-4">How to Dub AI Dubbing</h2>
        </div>
        <StepAIDubbing/>
        <StepCard/>
    </>
  )
}

export default DubAIDubbing
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { apiURl } from "../store/actions";
import { API } from "../apiwrapper";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function WalletPopup({setSHowModal}) {
    // const [searchParams, setsearchParams] = useSearchParams();
     const [transactionData,setTransactionData] =useState([]);
    // const token = searchParams.get("token");
    // const session = searchParams.get("session");
    // const type = searchParams.get("type");

    // useEffect(() => {
    //     if (token && session && type) {
    //       let payload = {
    //         type: type,
    //         token: token,
    //         session: session,
    //         id: id,
    //       };
    //       console.log('payload', payload)
    //       setsearchParams(payload);
    //       console.log("payload", payload);
          
    //       API({
    //         url: apiURl.paymentVerify,
    //         method: "POST",
    //         body: payload,
    //       }).then((data) => {
    //         if (data?.status || data?.status === "true") {
    //           toast.success(data?.message, {
    //             position: toast.POSITION.TOP_RIGHT,
    //           });
    //           navigate("/");
    //         } else {
    //           console.log("toast");
    //           toast.error(data?.message);
    //         }
    //       });
    //     }
    //   }, [token, session, type]);
    
    //   const { id } = useParams();
    
    //   const URL = window.location.origin;
    
    //   console.log("id for payment", id);


  const { User = {} } = useSelector((state) => state);
  let userId = User?.data?._id;
  // const [detail, setDetail] = useState([]);
  // const [inputField, setInputField] = useState({
   
  //   currency: "",
  //   amount: "",
  //   url: "",
  // });
  // const navigate = useNavigate();
  // const handleConvert = async (e) => {
  //   e.preventDefault();
  //   if (!userId) {
  //     navigate("/login");
  //     return;
  //   }

  //   try {
  //     //   let err = validateAll();
  //     //    if (isValid(err)) {
  //     // let awsUrl = await getAwsVideoUrl(file, `${userId}/InputVideos/`);
  //     // const urlParts = awsUrl.split("/");
  //     // const video_filename = urlParts[urlParts.length - 1];
  //     // console.log("video_filename", video_filename);

  //     const payload = {
  //       ...inputField,
  //       userId: userId,
  //       currency: "usd",
  //       amount: 252,
  //       url:"https://exuber.ai/",
  //     };
  //     await API({
  //       url: apiURl.payment,
  //       method: "POST",
  //       body: { ...payload },
  //       formData: false,
  //     }).then((data) => {
  //       console.log('data', data)

  //       if (data?.Data.startsWith('https')) {
  //           window.location.href = data?.Data;
  //         } else {
  //           navigate(data?.Data);
  //         }
      
  //     });
      
  //   } catch (error) {
  //     //   setApiErrors({ message: error?.message });
  //   } finally {
  //     //   setIsLoading(false);
  //   }
  // };
    
  const transactionDetail = async () =>{
    try {
        const response = await API({
          url: `${apiURl?.transaction}/${userId}`,
          method: "GET",
        });
        console.log('responsee-------', response)
        if (response) {
            setTransactionData(response?.data)
      
        } else {
          setTransactionData([]);
        }
      } catch (error) {
        throw error;
      }
  }
  console.log('transactionData', transactionData)

  useEffect(()=>{
    transactionDetail();
  },[])

  return (
    <>
      <div className="wallet p-0">
        <div className="d-flex align-items-center justify-content-between py-4 px-5">
          <div className="d-flex align-items-center">
            <figure>
              <img src="/images/wallet.png"></img>
            </figure>
            <div>
              <h6>Balance</h6>
              <h2>{User?.data?.points}</h2>
            </div>
          </div>
          <Link to={"/profile/package"} onClick={()=>setSHowModal(false)}>
            Buy Now
          </Link>
        </div>
        <hr />
      
        <div className="history px-5 pb-5 pt-4">
          <h3 className="pb-3">Transaction history</h3>
          {transactionData?.transactions?.map((ele)=>{
           
            const date = new Date(ele?.updatedAt);
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'UTC' };
            const formattedDate = date.toLocaleString('en-US', options);
            console.log('date', date)
            return(
                <>
                 <ul>
            <li className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <span className="d-inline-block me-2">
                  <img src="/images/whiteCoin.png" />
                </span>
                <p className="me-lg-5 me-3">-{ele.points}</p>
                <p>Deducted for using {ele?.categoryId?.name}</p>
              </div>
              <div>
                <p>{ formattedDate}</p>
              </div>
            </li>
          </ul>
                </>
            )
           
        })} 
        </div>
      </div>
    </>
  );
}

export default WalletPopup;

import React, { useRef, useState } from "react";
import {
  isValid,
  validateEmail,
  validateName,
} from "../Validation/InputValidation.js";
import { toast } from "react-toastify";
import axios from "axios";

function ContactForm() {
  const [contactInputs, setContactInputs] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const [apiErrors, setApiErrors] = useState({ message: "" });

  const { name = " ", email = " ", message = " " } = contactInputs;

  const handlechange = (e) => {
    setContactInputs({
      ...contactInputs,
      [e.target.name]: e.target.value,
    });
    handleValidate(e);
    setApiErrors({ message: "" });
  };
  const handleValidate = (e) => {
    const errors1 = {};
    switch (e.target.name) {
      case "name":
        errors1.name = validateName(e.target.value);
        break;
      case "email":
        errors1.email = validateEmail(e.target.value);
        break;
      default:
        break;
    }
    setErrors(errors1);
  };
  const validateAll = () => {
    let err1 = {};
    err1.name = validateName(name);
    err1.email = validateEmail(email);
    return err1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let err = validateAll();
      if (isValid(err)) {
        const apiURL = `${process.env.REACT_APP_API_BACKEND_URL}/v1/contactUs
          `;

        const reqObj = contactInputs;

        const response = await axios.post(apiURL, reqObj);

        if (response.status === 201) {
          toast.success(
            "Thanks for contacting us. We'll get back to you shortly!"
          );
          setContactInputs({ name: "", email: "", message: "" });
        }
      }
    } catch (error) {
      setApiErrors({ message: error.message });
      toast.error(error.message);

      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="contact_form">
        <h3>Leave a message</h3>
        <p>Let us know what you're thinking!!</p>
        <form id="contact" onSubmit={handleSubmit} className="form" role="form">
          <div className="row">
            <div className="col-md-6 form-group mb-4">
              <input
                className="form-control"
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                value={name}
                onChange={(e) => handlechange(e)}
              />
            </div>
            <div className="col-md-6 form-group mb-4">
              <input
                className="form-control"
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => handlechange(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 form-group mb-4">
              <textarea
                className="form-control"
                id="message"
                name="message"
                placeholder="Message"
                value={message}
                onChange={(e) => handlechange(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-12 form-group">
              <button className="" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default ContactForm;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaTelegramPlane,
  FaSnapchatGhost,
  FaTiktok,
} from "react-icons/fa";
import { BsLink45Deg } from "react-icons/bs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function ShareSocialMedia({ link,data }) {
  const [copyStatus, setCopyStatus] = useState("copy");
  const handleCopyLink = () => {
    // e.preventDefault();
    navigator.clipboard.writeText(link)
      .then(() => 
        toast.success("Link copied to clipboard"))
      .catch((err) => toast.error("Something went wrong", err))// revert back to "copy" after 2 seconds
      }
      


    const redirectSocialTab = (e, type) => {
        e.preventDefault();
        const ogUrl = window.location.href;
        const ogTitle = data?.video_filename || "";
        const ogDescription = "";
        const ogImage =  "./images/83usyk3k.png";
        switch (type) {
          case "FB":
            window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                ogUrl
              )}&og:image=${encodeURIComponent(
                ogImage
              )}&og:description=${encodeURIComponent(ogDescription)}`,
              "_blank"
            );
            break;
          case "TW":
            window.open(
              `https://twitter.com/share?url=${encodeURIComponent(
                ogUrl
              )}&text=${encodeURIComponent(ogTitle)}
              &og:image=${encodeURIComponent(
                ogImage
              )}&og:description=${encodeURIComponent(ogDescription)}
              &hashtags=NFT`,
              "_blank"
            );
            break;
          case "TEL":
            window.open(
              `https://telegram.me/share/url?url=${encodeURIComponent(
                ogUrl
              )}&text=${encodeURIComponent(ogTitle)}
              &og:image=${encodeURIComponent(
                ogImage
              )}&og:description=${encodeURIComponent(ogDescription)}
              `,
              "_blank"
            );
            break;
          case "INSTA":
            window.open(
              `https://www.instagram.com/sharer/sharer.php?u=${encodeURIComponent(
                ogUrl
              )}&og:image=${encodeURIComponent(
                ogImage
              )}&og:description=${encodeURIComponent(ogDescription)}`,
              "_blank"
            );
            break;
          case "LINKDIN":
            window.open(
              `https://www.linkedin.com/sharer/sharer.php?u=${encodeURIComponent(
                ogUrl
              )}&og:image=${encodeURIComponent(
                ogImage
              )}&og:description=${encodeURIComponent(ogDescription)}`,
              "_blank"
            );
            break;
            case "SNAP":
            window.open(
              `https://www.snap.com/sharer/sharer.php?u=${encodeURIComponent(
                ogUrl
              )}&og:image=${encodeURIComponent(
                ogImage
              )}&og:description=${encodeURIComponent(ogDescription)}`,
              "_blank"
            );
            break;
            case "TIK":
              window.open(
                `https://www.tiktok.com/sharer/sharer.php?u=${encodeURIComponent(
                  ogUrl
                )}&og:image=${encodeURIComponent(
                  ogImage
                )}&og:description=${encodeURIComponent(ogDescription)}`,
                "_blank"
              );
              break;
          default:
            return;
        }
      };
  return (
    <>
      {/* =========================== Share Social Media =========================== */}
      <div className="shareIcon">
        <h5>Share this link via</h5>
        <ul>
          <li>
            <Link to="" 
             onClick={(e) => redirectSocialTab(e, "FB")}
            >
              <FaFacebookF />
            </Link>
          </li>
          <li>
            <Link to="" 
             onClick={(e) => redirectSocialTab(e, "TW")}
            className="twitter">
              <FaTwitter />
            </Link>
          </li>
          <li>
            <Link to="" 
              onClick={(e) => redirectSocialTab(e, "INSTA")}
            className="insta">
              <FaInstagram />
            </Link>
          </li>
          <li>
            <Link to="" 
            onClick={(e) => redirectSocialTab(e, "LINKDIN")}
            className="linked">
              <FaLinkedinIn />
            </Link>
          </li>
          <li>
            <Link to="" 
            onClick={(e) => redirectSocialTab(e, "TEL")}
            className="tele">
              <FaTelegramPlane />
            </Link>
          </li>
          <li>
            <Link to="" 
            onClick={(e) => redirectSocialTab(e, "SNAP")}
            className="snap">
              <FaSnapchatGhost />
            </Link>
          </li>
          <li>
            <Link to="" className="tik">
              <FaTiktok />
            </Link>
          </li>
          <li>
            <Link to="" className="threads">
              <img src="/images/threads.svg" />
            </Link>
          </li>
        </ul>
        <div className="copyLink">
          <p>Copy Code </p>
          <div className="copyInng">
            <BsLink45Deg />
            <div className="link-text">{link}</div>
            <Link to="" className="ml-auto" onClick={handleCopyLink}>
              copy
            </Link>
          </div>
        </div>
      </div>
      {/* =========================== Share Social Media =========================== */}

      {/* =========================== Rename Media =========================== */}
      {/* <div className='shareIcon'>
            <div className='rename'>
                <input placeholder='Title enter here' type='text'></input>
                <div className='d-flex justify-content-between mt-4 rnameBtn'><button className='cancel'>Cancel</button><button>Rename</button></div>
            </div>
        </div> */}
      {/* =========================== Rename Media =========================== */}

      {/* =========================== Publish To MintAVibe Modal =========================== */}
      {/* <div className='shareIcon publish'>
            <figure className='text-center'><img src='/images/thank.svg'></img></figure>
            <h2>Thank you for submission to MintAVibe.</h2>
            <p>Your AI product is being reviewed by MintAVibe team. We will contact you by email.</p>
        </div> */}
      {/* =========================== Publish To MintAVibe Modal =========================== */}
    </>
  );
}

export default ShareSocialMedia;
